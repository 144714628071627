import { Discovery, Lifecycle, Metrics } from '@firebolt-js/sdk';
import { CONFIG } from 'src/config/config';
import { log } from 'src/utilities/console';

class FireboltClass {
    // App Lifecycle Events
    DiscoveryListener = () => {
        log('### Firebolt >> Listen to Discovery Events');
        // Discovery.listen('navigateTo', (value) => {
        //     console.log('### Firebolt >> Discovery.navigateTo()', value);
        //     if (value) {
        //         const deeplinkIntent = this.parseNavIntent(JSON.stringify(value));
        //         if (deeplinkIntent) {
        //             const navigate = useNavigate();
        //             console.log('### Firebolt >> Discovery Navigate (The Intent):', deeplinkIntent);
        //             const link = String(Buffer.from(deeplinkIntent, 'base64'));
        //             const route = link.split('#')[1];
        //             navigate(route);
        //         }
        //     }
        // });
    };
    LifecycleListeners = () => {
        log('### Firebolt >> Listen to Lifecycle Events');
        Lifecycle.listen('background', (value) => {
            log('### Firebolt >> Lifecycle.listen.background', value);
        });

        Lifecycle.listen('foreground', (value) => {
            log('### Firebolt >> Lifecycle.listen.foreground', value);
        });

        Lifecycle.listen('inactive', (value) => {
            log('### Firebolt >> Lifecycle.listen.inactive', value);
        });

        Lifecycle.listen('suspended', (value) => {
            log('### Firebolt >>Lifecycle.listen.suspended', value);
        });

        Lifecycle.listen('unloading', (value) => {
            log('### Firebolt >> Lifecycle.listen.unloading', value);
        });
    };

    clearEvents = () => {
        log('### Firebolt >> Lifecycle.clear()');
        Discovery.clear();
        Lifecycle.clear();
    };

    setReady = () => {
        if (CONFIG.isFireboltEnabled) {
            log('### Firebolt >> Lifecycle.ready()');
            this.LifecycleListeners();
            this.DiscoveryListener();
            Lifecycle.ready();
        }
    };

    navigateToCb = (cb?: Function) => {
        if (CONFIG.isFireboltEnabled) {
            log('### Firebolt >> Navigate To Callback Function');
            // Lifecycle.listen(Lifecycle.LifecycleState.BACKGROUND, cb || noop());
            Discovery.listen('navigateTo', (value: any) => {
                cb && cb(value);
            });
        }
    };

    onBackground = (cb?: Function) => {
        if (CONFIG.isFireboltEnabled) {
            Lifecycle.listen(Lifecycle.LifecycleState.BACKGROUND, (value: any) => {
                log('### Firebolt >> App Background State');
                cb && cb(value);
            });
        }
    };

    onAppClose = () => {
        if (CONFIG.isFireboltEnabled) {
            log('### Firebolt >> Lifecycle.close() DONE');
            Lifecycle.close(Lifecycle.CloseReason.DONE);
            this.clearEvents(); // Clears all event listeners
        }
    };

    onAppExit = () => {
        if (CONFIG.isFireboltEnabled) {
            log('### Firebolt >> Lifecycle.close() USER EXIT');
            Lifecycle.close(Lifecycle.CloseReason.USER_EXIT);
        }
    };

    // onFinished = () => {};

    onForeground = (cb?: Function) => {
        if (CONFIG.isFireboltEnabled) {
            Lifecycle.listen(Lifecycle.LifecycleState.FOREGROUND, (value: any) => {
                log('### Firebolt >> App Foreground State');
                cb && cb(value);
            });
        }
    };

    // Deeplink Handler
    parseNavIntent = (value: string) => {
        const deepLinkObj = JSON.parse(value);
        log('### Firebolt >> Navigation Intent:', deepLinkObj);

        if (deepLinkObj.action && deepLinkObj.data) {
            const deeplinkIntent = deepLinkObj.data.assetId || deepLinkObj.data.entityId;
            log('### Firebolt >> Navigation Intent (The Intent):', deeplinkIntent);
            return deeplinkIntent;
        }
    };

    // Video Player Based Events
    sendMediaProgress = async (entityId: string, progress: number) => {
        if (CONFIG.isFireboltEnabled) {
            log('### Firebolt >> mediaProgress()', entityId, progress);
            const posProgress = progress >= 0 ? progress : 0;
            try {
                await Metrics.mediaProgress(entityId, posProgress);
            } catch (error) {}
        }
    };

    sendWatched = async (
        entityId: string,
        progress?: number,
        completed?: boolean,
        watchedOn?: string
    ) => {
        if (CONFIG.isFireboltEnabled) {
            log('### Firebolt >> sendWatched()', entityId, progress, completed, watchedOn);
            try {
                await Discovery.watched(entityId, progress, completed, watchedOn);
            } catch (error) {}
        }
    };
}

export const Firebolt = new FireboltClass();
