import { BitmovinOptusPlayer } from '..';
import { PlayerClass } from '../player';

export function play(this: PlayerClass) {
    try {
        if (['READY', 'LOADED'].includes(this.playerState)) {
            BitmovinOptusPlayer.play();
        }
    } catch (error) {
        this.setError('0005');
    }
}
