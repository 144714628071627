import { CompetitionIds } from 'src/config/competitionConfig';
import { CardLayouts } from 'src/utilities/card';

type AccessGroupType = 'PREMIUM' | 'BASIC' | 'FREE_WITHOUT_LOGIN' | 'FITNESS';
type AssetTypeType =
    | 'article'
    | 'classic'
    | 'classics'
    | 'egame'
    | 'extended' // "extended/short"
    | 'feature'
    | 'fitness'
    | 'game-nostats'
    | 'game'
    | 'highlights' // "tiny/quick"
    | 'interview'
    | 'linear-channel'
    | 'mega' // "mega"
    | 'minimatch' // "mini"
    | 'news'
    | 'pressconference'
    | 'short' // "short"
    | 'show'
    | 'viewAll'
    | 'content-card';

type PanelTypeType = 'carousel' | 'explore' | 'browse' | 'banner' | 'dynamic_rail';
type ContentCardTypeType = 'DYNAMIC_RAIL' | 'BANNER';
export type DataSourceType = 'FAVOURITE' | 'BUCKET';

type WatchModeType = 'beginning' | 'resume' | '';
type WatchModeLiveType = 'live' | 'startover';

export type CardLayoutType = (typeof CardLayouts)[number];

export interface MediaAssetType {
    accessGroups: AccessGroupType[];
    duration: number;
    id: string;
    type: string;
}
export interface TeamType {
    id: string;
    title: string;
    shortName: string;
    country: string;
    logoUrl: string;
}

export interface CompetitionType {
    id: CompetitionIds;
    title: string;
}
export interface SeasonType {
    id: string;
    title: string;
}
export interface MatchType {
    id?: string;
    awayTeam: TeamType;
    homeTeam: TeamType;
    competition: CompetitionType;
    season: SeasonType;
    kickOffTime: string;
    finishTime: string;
}

export interface CmsTagType {
    key: string;
    values: string[];
}

export interface PlaybackItemType {
    license?: {
        licenseUrl: string;
        name: 'playready' | 'widevine_modular';
        uri: string;
        drmData: string;
    };
    selectedCDN: string;
    encodingType: string;
    videoLanguage: string;
    videoJobID: string;
    protocol: string;
    url: string;
}
export interface PlaybackType {
    items: PlaybackItemType[];
    playbackStatus: 'NOT_STARTED' | 'OK' | 'PENDING';
    isSSAIEnabled: boolean;
    isLive: boolean;
    streamType?: string;
    watchMode?: WatchModeType | WatchModeLiveType;
}
interface DRVideoStreams {
    hls: string;
    dash: string;
}

export const ContentCardActions = {
    browse: '/browse',
    exploreStartsWith: '/competition/',
    settings: '/settings',
    login: '/login',
    videosStartsWith: '/videos/',
    popupImageOverlay: 'popupImageOverlay',
    home: '/home',
    epg: '/epg',

    // TODO: Check specific for Braze CC, Handle this separately.
    none: 'NONE',
    // '/videos/{{assetid}}'
    //  '/competition/{{expid}}',
    // | string; // other action like asset page
    // '/articles/{{assetId}}'
    // 'https://abc-xyz.com.au'
} as const;

type ContentCardActionsType = (typeof ContentCardActions)[keyof typeof ContentCardActions];
export const bannerTypes = {
    noButton: 'NO_BUTTON',
    oneButton: 'ONE_BUTTON',
    twoButtons: 'TWO_BUTTONS',
} as const;

export type BannerType = (typeof bannerTypes)[keyof typeof bannerTypes];
interface ContentCard {
    id: string;
    bannerType: BannerType;
    badgeLabel: string;
    cardClickAction: string;

    primaryButtonAction: ContentCardActionsType;
    primaryButtonLabel: string;

    secondaryButtonAction: ContentCardActionsType;
    secondaryButtonLabel: string;

    noTextOverlay: boolean;

    // contentCardType:string
    // previewUsersOnly: boolean;

    bucketKey: string;
    contentCardType: ContentCardTypeType;
    dataSource: DataSourceType;
    targetUserSegment: string[];
    targetPlatform: string[];
}

export interface ImageSet {
    imageUrl: string;
    'imageUrl_1:1': string;
    'imageUrl_16:9': string;
    'imageUrl_9:16': string;
    'imageUrl_11:4'?: string;
}

export interface AssetType extends ImageSet {
    applyOverlay?: boolean;
    tileIconUrl: string;
    backgroundImageUrl: string;
    id: string;
    source?: string;
    externalId?: string;
    channel?: { id: string; name: string };
    deeplinkUrl?: string;
    accessGroups: AccessGroup[];
    broadcastEndTime: string;
    broadcastStartTime: string;
    createdAt?: string;
    updatedAt?: string;
    cmsTags?: CmsTagType[];
    categoryTitle: string;
    categorySlug: string;
    description: string;
    drmProtected: boolean;
    duration: number;
    duration: number;
    extendedAssets?: MediaAssetType[];
    fullAssets?: MediaAssetType[];
    isInTransition: boolean;
    isLive: boolean;
    match?: MatchType;
    megaAssets?: MediaAssetType[];
    miniAssets?: MediaAssetType[];
    publishStartDate: string;
    tags?: string;
    tinyAssets?: MediaAssetType[];
    title: string;
    slug?: string;
    seoTitle?: string;
    imageSource?: string;
    isSSAIEnabled?: boolean;
    isChannelSSAIEnabled?: boolean;
    isCMAFEnabled?: boolean;
    logo?: string;
    logo_controls?: string;
    type: AssetTypeType;
    video?: DRVideoStreams;

    contentCard?: ContentCard;
    images?: {
        overlay: ImageSet;
    };
}

export interface PanelDataType {
    assets: AssetType[];
    type?: PanelTypeType;
    // displayName: string;
    // id: string;
    // bucketKey: string;
}
export interface PanelDataMap {
    [index: string]: PanelDataType;
}
export interface PanelDataPendingMap {
    [index: string]: boolean;
}

export interface PanelType {
    id: string;
    bucketKey: string;
    layout: CardLayoutType;
    type: PanelTypeType;
    widgetKey: string;
    title: string;
    active: boolean;
    maxTileCount: number;
    config?: string;
    viewAllLabel?: string | null;
    assets: AssetType[] | null;

    // For Content Cards
    contentCardId?: string;
    assetId?: string;
    // dataSource?: 'favourite' | 'bucket';

    targetPlatform: string[];
    targetUserSegment: string[];
}

export interface ExploreData extends PanelType {
    config?: string;
}

export interface LayoutDataType {
    key: string;
    title: string;
    config: any;
    panels: PanelType[];
}

export interface LayoutType {
    [o: string]: LayoutDataType | null;
}
export interface LayoutPendingType {
    [o: string]: boolean;
}

export interface HeaderType {
    imageUrl?: string;
}

export interface EPGEventDataType {
    epgStart: number;
    epgStop: number;
    title: string;
    shortEventDescription: string;
    id: string;
}
export interface EPGDataType {
    [index: string]: null | EPGEventDataType[];
}

export interface LiveAssetType {
    id: string;
    title: string;
    imageUrl: string;
    'imageUrl_1:1': string;
    'imageUrl_9:16': string;
    'imageUrl_16:9': string;
    logo: string;
    logo_controls: string;
    playbackData?: any;
}

export interface ExploreHeroData {
    title: string;
    tileIcon: string;
    background: string;
}

export interface RelatedAssetArrayType {
    [index: string]: AssetType[];
}
export interface LayoutFetchStatusType {
    [o: string]: boolean;
}

export interface AssetFetchStatusType {
    [o: string]: boolean;
}

export interface DetailedAssetsType {
    [o: string]: AssetType;
}

export interface ContentCardsType {
    [o: string]: AssetType;
}

interface PanelsToRender {
    [index: string]: PanelType[];
}

export interface DashboardStateType {
    assetFetchFailed: AssetFetchStatusType;
    detailedAssets: DetailedAssetsType;
    epgData: EPGDataType;
    layout: LayoutType;
    layoutPending: LayoutPendingType;
    layoutFetchFailed: LayoutFetchStatusType;
    liveAssets: null | LiveAssetType[];
    panelData: PanelDataMap;
    panelDataPending: PanelDataPendingMap;
    panelsToRender: PanelsToRender;
    relatedAssets: RelatedAssetArrayType;
    showShimmer: boolean;
    overlayAsset: null | string;
    contentCardsPlatformPending: null | boolean;
    contentCardsPlatform: ContentCardsType;
    contentCardsUserPending: null | boolean;
    contentCardsUser: ContentCardsType;
    contentCardsBrazeAllPending: null | boolean;
    contentCardsBrazeAll: ContentCardsType;
}

export interface PlaybackSessionType {
    sessionId: string;
    device: {
        deviceTime: string;
        // osVersion
        // deviceId
        // deviceMake
        // deviceModel
    };
    app: {
        appId: string;
        appName: string;
        version: string;
        environment: string;
        platform: string;
    };
    attributes: {};
    videoPlayer: {
        type: 'Bitmovin';
        // version: version of Player
        // height: video player height
        // width: video player width
        // mode: fullscreen
    };
    asset: {
        id: string;
        title: string;
        type: string;
        accessType: AccessGroupType[];
        category: string;
        viewProgress?: number;
        streamUrl: url;
        team?: { home: string; away: string };
        // source :"", // ??
        // optaMatchId: asset.id, // ?? Is this correct?
        // cdn :// ??
    };
    browser: {
        userAgent: string;
    };
    userPreference: {};
    screen: { width: number; height: number };
    errors: [];
}
