import { useParams } from 'react-router-dom';
import { useStateValue } from 'src/state';
import { LayoutIdType } from 'src/state/stores/app/app';
import useSidebar from './sidebar.controller';

const useLayout = () => {
    const { state } = useStateValue();
    const { selectedSidebarItem } = useSidebar();
    const { explore } = useParams();
    const {
        dashboardData: { layout },
    } = state;
    const l = explore || selectedSidebarItem?.layoutId || 'football';
    const layoutId: LayoutIdType = l as LayoutIdType;

    const selectedLayout = layoutId && layout[layoutId];

    return { layout: selectedLayout, layoutId };
};

export default useLayout;
