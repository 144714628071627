import { useCallback, useMemo } from 'react';
import { ContentCard, bannerTypes } from 'src/state/stores/dashboard/dashboard.d';
import { Analytics } from 'src/lib/Analytics';
import usePageTitle from './page.title.controller';
import usePanels from '../panels.controller';
import { ContentCardActionPerfomedType, PanelPositionType } from 'src/lib/Analytics/analytics';
import useContentCards from './contentCards.controller';
import useCCAction from './contentCardAction.controller';

const useContentCardActions = (id: string, contentCard: ContentCard, panelIndex: number) => {
    const pageTitle = usePageTitle();

    const { panels } = usePanels();
    const contentCards = useContentCards();
    const asset = useMemo(() => contentCards[id], [contentCards, id]);

    const {
        primaryButtonAction,
        primaryButtonLabel,
        secondaryButtonAction,
        secondaryButtonLabel,
        bannerType,
    } = contentCard;

    const showPrimaryButton =
        (bannerType === bannerTypes.oneButton || bannerType === bannerTypes.twoButtons) &&
        primaryButtonAction &&
        primaryButtonLabel;

    const showSecondaryButton =
        bannerType === bannerTypes.twoButtons && secondaryButtonAction && secondaryButtonLabel;

    const { handleCCAction } = useCCAction(id);

    const panel: PanelPositionType = useMemo(() => {
        const { title, type } = panels[panelIndex];
        return { index: panelIndex, title, type };
    }, [panels, panelIndex]);

    const onClickCard = useCallback(() => {
        const { cardClickAction } = contentCard;
        if (bannerType === 'NO_BUTTON') {
            handleCCAction(cardClickAction);

            const action: ContentCardActionPerfomedType = {
                type: 'CARD',
                label: '', // Card does not have any label or title
                action: cardClickAction,
            };
            Analytics.logContentCardClicked(asset!, action, panel, pageTitle);
        }
    }, [handleCCAction, asset, contentCard, bannerType, panel, pageTitle]);

    const onClickPrimary = useCallback(() => {
        handleCCAction(contentCard.primaryButtonAction);

        const action: ContentCardActionPerfomedType = {
            type: 'PRIMARY_BUTTON',
            label: contentCard.primaryButtonLabel,
            action: contentCard.primaryButtonAction,
        };
        Analytics.logContentCardClicked(asset!, action, panel, pageTitle);
    }, [handleCCAction, asset, contentCard, panel, pageTitle]);

    const onClickSecondary = useCallback(() => {
        handleCCAction(contentCard.secondaryButtonAction);

        const action: ContentCardActionPerfomedType = {
            type: 'SECONDARY_BUTTON',
            label: contentCard.secondaryButtonLabel,
            action: contentCard.secondaryButtonAction,
        };
        Analytics.logContentCardClicked(asset!, action, panel, pageTitle);
    }, [handleCCAction, asset, contentCard, panel, pageTitle]);

    return {
        showPrimaryButton,
        showSecondaryButton,
        onClickPrimary,
        onClickSecondary,
        onClickCard,
    };
};

export default useContentCardActions;
