import { CONFIG } from 'src/config/config';
import { ActionOptions } from 'src/state/reducers.d';
import getNetworkStatusInstance from 'src/utilities/network';
import { authErrorCodes } from '../constants';

const networkStatus = getNetworkStatusInstance();

export const isRetryable = (response: any, retryCount: number, options: ActionOptions) => {
    const { retry = true, maxAttempts = CONFIG.networkMaxRetry, retryCodes = [] } = options || {};
    let allowRetry = retry && retryCount < maxAttempts;
    if (retryCodes.length) {
        if (response && retryCodes.includes(response?.status)) {
            // allowRetry = allowRetry;
        } else {
            allowRetry = false;
        }
    }
    let retryable = true;
    try {
        retryable = response.data.error.retryable !== false;
    } catch (error) {
        // return allowRetry && retryable;
    }

    return allowRetry && retryable && !networkStatus.isError();
};

export const isAuthError = (code: number) => {
    return authErrorCodes.indexOf(code) !== -1;
};
