import React, { useCallback } from 'react';
import Button from 'src/components/Common/Button';
import { WatchModeLiveType, WatchModeType } from 'src/state/stores/dashboard/dashboard';
import styles from './card.module.scss';

interface PropType {
    leftAlign: boolean;
    action: 'live' | 'past' | 'upcoming';
    assetId?: string;
    onClick: (watchMode?: WatchModeLiveType | WatchModeType | boolean) => void;
    isShow: boolean;
}

function CardActions(props: PropType) {
    const { assetId, leftAlign, onClick, isShow } = props;
    const getAlignment = () => {
        if (leftAlign) {
            return {
                justifyContent: 'left',
            };
        }
        return {
            justifyContent: 'center',
        };
    };

    const playLive = useCallback(() => {
        onClick('live');
    }, [onClick]);

    const playStartOver = useCallback(() => {
        onClick('startover');
    }, [onClick]);
    const VodDetail = () => {
        onClick();
    };
    const VodAutoPlay = () => {
        onClick(true);
    };

    return (
        <div className={styles.actions} style={getAlignment()}>
            {props.action === 'live' ? (
                <>
                    <Button
                        assetId={assetId}
                        className={styles.button}
                        classNameFocused={styles.focused}
                        focusId={`btn-${assetId}-first`}
                        onClick={playLive}
                    >
                        Watch Live
                    </Button>
                    <Button
                        assetId={assetId}
                        className={styles.button}
                        classNameFocused={styles.focused}
                        focusId={`btn-${assetId}-start-over`}
                        onClick={playStartOver}
                    >
                        Start Over
                    </Button>
                </>
            ) : null}
            {props.action === 'upcoming' ? (
                <Button
                    assetId={assetId}
                    className={styles.button}
                    classNameFocused={styles.focused}
                    focusId={`btn-${assetId}-first`}
                    onClick={VodDetail}
                >
                    {isShow ? 'View Details' : 'View Match Centre'}
                </Button>
            ) : null}
            {props.action === 'past' ? (
                <Button
                    assetId={assetId}
                    className={styles.button}
                    classNameFocused={styles.focused}
                    focusId={`btn-${assetId}-first`}
                    onClick={VodAutoPlay}
                >
                    Watch now
                </Button>
            ) : null}
        </div>
    );
}

export default React.memo(CardActions);
