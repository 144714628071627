import { useEffect, useRef, useState } from 'react';

export function useFirstRender() {
    const ref = useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return firstRender;
}

export function useSeekButtonActiveClass() {
    const [activeClass, setActiveClass] = useState('');
    useEffect(() => {
        setTimeout(() => {
            setActiveClass('');
        }, 300);
    }, [activeClass]);
    return { activeClass, setActiveClass };
}
