import { exploreLayoutMap } from 'src/config/exploreRailData';
import liveAssetsMetadata from 'src/config/liveAssets';
import { FULLFILLED, PENDING, REJECTED } from 'src/state/middlewares/promise/constants';
import { formatLiveAssetPlaybackData, validateAsset } from 'src/utilities/asset';
import { getUID } from 'src/utilities/common';
import { processImageUrl } from 'src/utilities/url';
import { Action } from '../../reducers.d';
import {
    AssetType,
    ContentCardsType,
    DashboardStateType,
    LiveAssetType,
    PanelDataType,
    PanelType,
} from './dashboard';
import * as ACTIONS from './dashboard.constants';
// import getNetworkStatusInstance from 'src/utilities/network';
import { validateCCAsset } from 'src/utilities/contentCards';

export const INITIAL_STATE_DASHBOARD: DashboardStateType = {
    layout: {},
    layoutPending: {},
    showShimmer: true,
    panelData: {},
    panelDataPending: {},
    panelsToRender: {},
    detailedAssets: {},
    epgData: {},
    liveAssets: null,
    relatedAssets: {},
    layoutFetchFailed: {},
    assetFetchFailed: {},
    overlayAsset: null,
    contentCardsPlatformPending: null,
    contentCardsPlatform: {},
    contentCardsUserPending: null,
    contentCardsUser: {},
    contentCardsBrazeAllPending: null,
    contentCardsBrazeAll: {},
};

// const networkStatus = getNetworkStatusInstance();

export function dashboardReducer(state: DashboardStateType, action: Action) {
    // console.log(action.type);
    // if (networkStatus.isError() && !action.type.endsWith(REJECTED)) {
    //     return state;
    // }
    switch (action.type) {
        case ACTIONS.ACTION_RESET_HOME_LAYOUT: {
            return {
                ...INITIAL_STATE_DASHBOARD,
            };
        }
        case ACTIONS.ACTION_HOME_SHOW_HIDE_SHIMMER: {
            if (state.showShimmer === action.payload) {
                return state;
            }
            return {
                ...state,
                showShimmer: action.payload,
            };
        }
        case ACTIONS.ACTION_HOME_SHOW_HIDE_OVERLAY: {
            return {
                ...state,
                overlayAsset: action.payload,
            };
        }
        case `${ACTIONS.ACTION_FETCH_HOME_LAYOUT}_${PENDING}`: {
            const {
                meta: { layout: layoutId },
            } = action.payload;

            const layoutPending = { ...state.layoutPending };
            layoutPending[layoutId] = true;
            const layoutFetchFailed = { ...state.layoutFetchFailed };
            layoutFetchFailed[layoutId] = false;
            return {
                ...state,
                showShimmer: true,
                layoutPending,
                layoutFetchFailed,
            };
        }
        case `${ACTIONS.ACTION_FETCH_HOME_LAYOUT}_${REJECTED}`: {
            const {
                meta: { layout: layoutId },
            } = action.payload;
            const layoutPending = { ...state.layoutPending };
            layoutPending[layoutId] = false;
            const layoutFetchFailed = { ...state.layoutFetchFailed };
            layoutFetchFailed[layoutId] = true;
            return {
                ...state,
                showShimmer: false,
                layoutFetchFailed,
                layoutPending,
            };
        }
        case `${ACTIONS.ACTION_FETCH_HOME_LAYOUT}_${FULLFILLED}`: {
            const {
                payload,
                meta: { layout: layoutId },
            } = action.payload;
            const { data } = payload || {};
            const layout = { ...state.layout };
            const panelData = { ...state.panelData };
            layout[layoutId] = data || [];
            const l = layout[layoutId] || null;
            if (l !== null) {
                l.panels = (l.panels || []).map((p: PanelType, i: number) => {
                    const id = `${i}_${p.bucketKey || p.widgetKey}_${p.layout}_${getUID()}`;
                    const assetsInConfig =
                        typeof p.config === 'string' ? JSON.parse(p.config || 'null') : null;

                    const assets = assetsInConfig
                        ? assetsInConfig.filter((a: AssetType) => exploreLayoutMap[a.id])
                        : undefined;
                    if (assets) {
                        panelData[id] = { assets, type: p.type };
                    }
                    return {
                        ...p,
                        assets,
                        id,
                    };
                });
                layout[layoutId] = l;
            }
            const layoutPending = { ...state.layoutPending };
            layoutPending[layoutId] = false;
            const layoutFetchFailed = { ...state.layoutFetchFailed };
            layoutFetchFailed[layoutId] = false;
            return {
                ...state,
                layout: layout,
                layoutFetchFailed,
                layoutPending,
                panelData,
            };
        }
        case `${ACTIONS.ACTION_FETCH_HOME_PANEL}_${PENDING}`: {
            const {
                meta: { bucketKey, layoutId },
            } = action.payload;
            const panelDataPending = {
                ...state.panelDataPending,
                [`${layoutId}_${bucketKey}`]: true,
            };
            return {
                ...state,
                panelDataPending,
            };
        }

        case `${ACTIONS.ACTION_FETCH_HOME_PANEL}_${FULLFILLED}`: {
            const {
                payload,
                meta: { bucketKey, layoutId },
            } = action.payload;
            const { assets = [] } = payload || {};
            const newPanelData: PanelDataType = {
                ...payload,
                assets: assets.filter((a: AssetType) => validateAsset(a)),
                bucketKey: bucketKey,
            };
            const panelData = {
                ...state.panelData,
                [`${layoutId}_${bucketKey}`]: newPanelData,
            };
            const panelDataPending = {
                ...state.panelDataPending,
                [`${layoutId}_${bucketKey}`]: false,
            };
            return {
                ...state,
                panelData: panelData,
                panelDataPending: panelDataPending,
            };
        }

        case `${ACTIONS.ACTION_HOME_SET_PANELS_TO_RENDER}`: {
            const { panels, page } = action.payload;
            const panelsToRender = {
                ...state.panelsToRender,
                [page]: panels,
            };
            return {
                ...state,
                panelsToRender,
            };
        }

        case `${ACTIONS.ACTION_CLEAR_ASSET_ERROR}`: {
            const { assetId } = action.payload;
            const assetFetchFailed = { ...state.assetFetchFailed };
            delete assetFetchFailed[assetId];
            return {
                ...state,
                assetFetchFailed,
            };
        }

        case `${ACTIONS.ACTION_FETCH_ASSET}_${PENDING}`: {
            const {
                meta: { assetId },
            } = action.payload;
            const detailedAssets = { ...state.detailedAssets, [assetId]: undefined };
            // detailedAssets[assetId] = detailedAssets[assetId];
            const assetFetchFailed = { ...state.assetFetchFailed };
            delete assetFetchFailed[assetId];

            return {
                ...state,
                detailedAssets,
                assetFetchFailed,
            };
        }
        case `${ACTIONS.ACTION_FETCH_ASSET}_${FULLFILLED}`: {
            const {
                payload,
                meta: { assetId },
            } = action.payload;
            const detailedAssets = { ...state.detailedAssets, [assetId]: payload };
            const assetFetchFailed = { ...state.assetFetchFailed };
            assetFetchFailed[assetId] = false;

            return {
                ...state,
                detailedAssets,
                assetFetchFailed,
            };
        }

        case `${ACTIONS.ACTION_FETCH_ASSET}_${REJECTED}`: {
            const {
                meta: { assetId },
            } = action.payload;
            const detailedAssets = { ...state.detailedAssets, [assetId]: undefined };
            const assetFetchFailed = { ...state.assetFetchFailed };
            assetFetchFailed[assetId] = true;

            return {
                ...state,
                detailedAssets,
                assetFetchFailed,
            };
        }

        case `${ACTIONS.ACTION_FETCH_LIVE_ASSETS}_${PENDING}`: {
            return {
                ...state,
                liveAssets: [],
            };
        }
        case `${ACTIONS.ACTION_FETCH_LIVE_ASSETS}_${FULLFILLED}`: {
            const liveAssets = action.payload.assets.map((a: LiveAssetType) => {
                const lam = liveAssetsMetadata;
                return {
                    ...a,
                    logo: lam[a.id]
                        ? lam[a.id].logo
                        : processImageUrl(a['imageUrl_16:9'], 378, 120),
                    logo_controls: lam[a.id]
                        ? lam[a.id].logo_controls
                        : processImageUrl(a['imageUrl_16:9'], 378, 120),
                };
            });
            return {
                ...state,
                liveAssets,
            };
        }

        case `${ACTIONS.ACTION_FETCH_ASSET_METADATA}_${FULLFILLED}`: {
            const {
                meta: { assetId },
                payload,
            } = action.payload;

            const detailedAssets = { ...state.detailedAssets };
            const detailedAsset = { ...detailedAssets[assetId], ...payload };
            detailedAssets[assetId] = detailedAsset;

            return {
                ...state,
                detailedAssets,
            };
        }
        case `${ACTIONS.ACTION_FETCH_LIVE_ASSET_EPG}_${PENDING}`: {
            const {
                meta: { assetId },
            } = action.payload;

            const epgData = { ...state.epgData };
            epgData[assetId] = null;
            return {
                ...state,
                epgData,
            };
        }
        case `${ACTIONS.ACTION_FETCH_LIVE_ASSET_EPG}_${REJECTED}`: {
            const {
                meta: { assetId },
            } = action.payload;

            const epgData = { ...state.epgData };
            epgData[assetId] = [];
            return {
                ...state,
                epgData,
            };
        }
        case `${ACTIONS.ACTION_FETCH_LIVE_ASSET_EPG}_${FULLFILLED}`: {
            const {
                meta: { assetId },
                payload,
            } = action.payload;

            const epgData = { ...state.epgData };
            epgData[assetId] = formatLiveAssetPlaybackData(payload) || [];
            return {
                ...state,
                epgData,
            };
        }

        case `${ACTIONS.ACTION_FETCH_RELATED_ASSETS}_${FULLFILLED}`: {
            const {
                meta: { assetId },
                payload,
            } = action.payload;
            const { relatedAssets } = state;
            relatedAssets[assetId] = payload.asset;
            return {
                ...state,
                relatedAssets,
            };
        }
        case `${ACTIONS.ACTION_FETCH_CONTENTCARDS_PLATFORM}_${PENDING}`: {
            return {
                ...state,
                contentCardsPlatformPending: true,
            };
        }
        case `${ACTIONS.ACTION_FETCH_CONTENTCARDS_PLATFORM}_${FULLFILLED}`: {
            const { payload } = action;
            const { data } = payload;
            const { assets = [] } = data || {};
            const contentCardsPlatform: ContentCardsType = {};
            assets.forEach((card: AssetType) => {
                if (validateCCAsset(card)) {
                    contentCardsPlatform[card.id] = card;
                }
            });
            return {
                ...state,
                contentCardsPlatform,
                contentCardsPlatformPending: false,
            };
        }
        case `${ACTIONS.ACTION_FETCH_CONTENTCARDS_USER}_${PENDING}`: {
            return {
                ...state,
                contentCardsUserPending: true,
            };
        }
        case `${ACTIONS.ACTION_FETCH_CONTENTCARDS_USER}_${FULLFILLED}`: {
            const { payload } = action;
            const { data } = payload;
            const { assets = [] } = data || {};
            const contentCardsUser: ContentCardsType = {};
            assets.forEach((card: AssetType) => {
                if (validateCCAsset(card)) {
                    contentCardsUser[card.id] = card;
                }
            });
            return {
                ...state,
                contentCardsUser,
                contentCardsUserPending: false,
            };
        }

        case `${ACTIONS.ACTION_FETCH_CONTENTCARDS_BRAZE_ALL}_${PENDING}`: {
            return {
                ...state,
                contentCardsBrazeAllPending: true,
            };
        }
        case `${ACTIONS.ACTION_FETCH_CONTENTCARDS_BRAZE_ALL}_${FULLFILLED}`: {
            const { payload } = action;
            const { assets = [] } = payload;
            const contentCardsBrazeAll: ContentCardsType = {};
            assets.forEach((card: AssetType) => {
                if (validateCCAsset(card)) {
                    contentCardsBrazeAll[card.id] = card;
                }
            });
            return {
                ...state,
                contentCardsBrazeAll,
                contentCardsBrazeAllPending: false,
            };
        }

        case `${ACTIONS.ACTION_FETCH_USER_FAVOURITES}_${PENDING}`: {
            const {
                meta: { bucketKey },
            } = action.payload;
            const panelDataPending = {
                ...state.panelDataPending,
                [`${bucketKey}`]: true,
            };
            return {
                ...state,
                panelDataPending,
            };
        }

        case `${ACTIONS.ACTION_FETCH_USER_FAVOURITES}_${FULLFILLED}`: {
            const {
                payload,
                meta: { bucketKey },
            } = action.payload;
            const { assets = [], asset = [] } = payload || {};
            const newPanelData: PanelDataType = {
                ...payload,
                // TODO: Remove after fixing ML
                asset: asset.filter((a: AssetType) => validateAsset(a)),
                assets: assets.filter((a: AssetType) => validateAsset(a)),
                bucketKey: bucketKey,
            };
            const panelData = {
                ...state.panelData,
                [`${bucketKey}`]: newPanelData,
            };
            const panelDataPending = {
                ...state.panelDataPending,
                [`${bucketKey}`]: false,
            };
            return {
                ...state,
                panelData: panelData,
                panelDataPending: panelDataPending,
            };
        }

        case ACTIONS.ACTION_FOCUS_ASSET: {
            const { assetId, panelId } = action.payload;
            const { panelData } = state;
            const panel = panelData && panelData[panelId];
            const asset = panel?.assets.find((a) => a.id === assetId);
            return {
                ...state,
                focusedAsset: asset,
            };
        }

        default:
            // console.log('DEFAULT');
            break;
    }
    return state;
}
