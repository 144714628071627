import { FocusNode } from 'lal-lrud';
import { LRUDEvent } from 'lal-lrud/dist/types';
import { useTranslation } from 'react-i18next';
import Typography from 'src/components/Common/Typography';
import styles from './areYouStillWatching.module.scss';

interface AreYouStillWatchingProps {
    onWatchingPress: (e: LRUDEvent) => any;
    yTime: number;
}

const AreYouStillWatching = (props: AreYouStillWatchingProps) => {
    const { onWatchingPress, yTime = 12 } = props;

    const { t } = useTranslation(undefined, { keyPrefix: 'areYouStillWatching' });

    return (
        <FocusNode className={styles.container} onKey={onWatchingPress}>
            <div className={styles.backDrop}></div>
            <Typography className={styles.title}>{t('title')}</Typography>
            <Typography className={styles.message1}>{t('message1')}</Typography>
            <Typography className={styles.message2}>
                {t('message2')}
                <strong>{t('message2s', { Y: yTime })}</strong>
            </Typography>
        </FocusNode>
    );
};

export default AreYouStillWatching;
