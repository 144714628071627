import { Segment as S } from '@optussport/fe-bitmovin-player';
import { CONFIG } from 'src/config/config';

export const Segment = {
    trackApiEvent: ({ apiUrl, segmentEventName, requestParams, response }: any) => {
        if (CONFIG.isSegmentEnabled) {
            S.trackApiEvent({ apiUrl, segmentEventName, requestParams, response });
        }
    },
    trackApiErrorEvent: (errorStatus: any) => {
        if (CONFIG.isSegmentEnabled) {
            S.trackApiErrorEvent(errorStatus);
        }
    },
    trackVideoRequested: () => {
        if (CONFIG.isSegmentEnabled) {
            S.trackVideoRequested();
        }
    },
    trackZombieKickSessionFromClient: () => {
        if (CONFIG.isSegmentEnabled) {
            S.trackZombieKickSessionFromClient();
        }
    },
    trackChuckNorrisKick: () => {
        if (CONFIG.isSegmentEnabled) {
            S.trackChuckNorrisKick();
        }
    },
    handleAppKill: (errObj?: any) => {
        if (CONFIG.isSegmentEnabled) {
            S.handleAppKill(errObj);
        }
    },
    trackPlaybackErrorDisplayed: ({ errorType }: any) => {
        if (CONFIG.isSegmentEnabled) {
            S.trackPlaybackErrorDisplayed({ errorType });
        }
    },
    trackPlaybackErrorInteraction: ({ interaction }: any) => {
        if (CONFIG.isSegmentEnabled) {
            S.trackPlaybackErrorInteraction({ interaction });
        }
    },
};
