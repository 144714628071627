import { BitmovinOptusPlayer } from '..';
import { speeds } from '../config/bPlayerConfig';
import { AudioTrackType, QualityType, TextTrackType } from '../player.d';
import { PlayerClass } from '../player';
import { CONFIG } from 'src/config/config';
import Storage from 'src/utilities/storage';

export function getDuration(this: PlayerClass) {
    if (this.isLive()) {
        return this.player?.getMaxTimeShift();
    } else {
        return this.player?.getDuration();
    }
}

export function getCurrentTime(this: PlayerClass) {
    if (this.isLive()) {
        return BitmovinOptusPlayer.getPosition();
    } else {
        return this.player?.getCurrentTime();
    }
}

export function getBufferLength(this: PlayerClass) {
    return Math.min(this.player?.getAudioBufferLength(), this.player?.getVideoBufferLength());
}

export function getQualities(this: PlayerClass) {
    let auto: QualityType = {
        id: 'auto',
        label: 'Auto',
        selected: false,
        height: 0,
        width: 0,
    };
    const allQualities: QualityType[] = this.player?.getAvailableVideoQualities() || [];

    let qualities: QualityType[] = [auto];
    const cq = Storage.getItem('vq') || 'Auto';
    if (allQualities.length) {
        const {
            // high: highBitRate,
            medium: mediumBitRate,
            low: lowBitRate,
        } = CONFIG.playerQualityLevelSettingsBitRate;
        const qSorted = allQualities.sort((a, b) => a.height! - b.height!);

        const high = qSorted.pop();
        const low = qSorted.filter((q) => q.bitrate! / 1000 <= lowBitRate).shift();
        const medium = qSorted
            .filter((q) => q.bitrate! / 1000 > lowBitRate && q.bitrate! / 1000 <= mediumBitRate)
            .pop();

        if (high) {
            high.label = 'High';
            qualities.push(high);
        }

        if (medium) {
            medium.label = 'Medium';
            qualities.push(medium);
        }

        if (low) {
            low.label = 'Low';
            qualities.push(low);
        }
    }

    qualities = qualities.map((q) => ({
        ...q,
        selected: q.label === cq,
    }));

    return qualities;
}

export function getSpeeds(this: PlayerClass) {
    const cSpeed = this.player?.getPlaybackSpeed();
    return speeds.map((s) => ({ ...s, selected: s.speed === cSpeed }));
}

export function getAudioTracks(this: PlayerClass) {
    const audioTracks: AudioTrackType[] = this.player?.getAvailableAudio();
    const currentAudioTrack: AudioTrackType = this.player?.getAudio();
    return audioTracks.map((at) => ({
        ...at,
        selected: at.id === currentAudioTrack.id,
    }));
}

export function getSubtitleTracks(this: PlayerClass) {
    const subtitles: TextTrackType[] = this.player?.subtitles.list();
    return subtitles;
}

export function getVersion(this: PlayerClass) {
    return this.player.version;
}

export function getAssetUrl(this: PlayerClass) {
    return this.assetUrl;
}
