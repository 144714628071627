import Button from 'src/components/Common/Button';
import usePlayer from 'src/controllers/player.controller';
import usePlayerTimeStat from 'src/controllers/playerTimeStat.controller';

import styles from './goLive.module.scss';

const GoLive = (props: { hidden: boolean }) => {
    const { hidden } = props;
    const { Player } = usePlayer();
    const { currentTime } = usePlayerTimeStat();
    const isLive = currentTime === 0;
    const classes = [styles.button];

    const onClick = () => {
        if (!isLive) {
            Player.seek(0);
        }
    };

    if (isLive) {
        classes.push(styles.live);
    }

    return (
        <Button
            open
            focusId="btn-live"
            onClick={onClick}
            className={classes.join(' ')}
            hidden={hidden}
            disabled={hidden}
        >
            {isLive ? 'Live' : 'Back to Live'}
        </Button>
    );
};

export default GoLive;
