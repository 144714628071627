import { Config, StringSet, URLSet } from '../config';
import { env } from '../configs/config.all';

const segmentPS4URLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/yTHRGIffzA9DdRc30nwDiZT33wHZkXgq/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/v2ZAyEioxDVj8uT8QomHTf8TVkFsW8J3/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/v2ZAyEioxDVj8uT8QomHTf8TVkFsW8J3/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/v2ZAyEioxDVj8uT8QomHTf8TVkFsW8J3/analytics.min.js',
};

const drModePS4URLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-ps4.json',
    PP: 'https://dr.optussport.info/assets-ps4.json',
    STAGING: 'https://dr.optussport.info/assets-ps4.json',
    LOCAL: 'https://dr.optussport.info/assets-ps4.json',
};

const brazePS4Keys: StringSet = {
    PROD: '9fdb8954-fa74-4163-ab9d-094dfe78c61c',
    PP: '806da137-0a10-4b34-aeb8-e3aeb98ff91d',
    STAGING: '806da137-0a10-4b34-aeb8-e3aeb98ff91d',
    LOCAL: '806da137-0a10-4b34-aeb8-e3aeb98ff91d',
};

const config_ps4: Partial<Config> = {
    platform: 'PlayStation',
    segmentLibUrl: segmentPS4URLs[env],
    drModeCheckURL: drModePS4URLs[env],
    playbackSettingsSpeedActive: false,
    DPADThrottleDelta: 120,
    drModeCheckActive: true,
    playbackDrmChoice: 'playready',
    noAnimations: true,

    YoSpaceActiveOnLive: false,
    YoSpaceActiveOnVod: false,
    YoSpaceDebug: false,

    isDatadogRumEnabled: false,

    BrazeActive: true, // All users
    BrazeApiKey: brazePS4Keys[env],

    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['av1', 'avc', 'hevc'],
        },
        adaptation: {
            resolution: {
                maxSelectableVideoHeight: 720,
            },
            exclude: true,
        },
        // logs: {
        //     level: LogLevel.DEBUG,
        // },
    },
};

export default config_ps4;
