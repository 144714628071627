import { getPlatformReferral } from 'src/utilities/device';
import FullscreenPopup from '../FullscreenPopup';
import { useMemo } from 'react';

const SubscriptionPending = (props: { hideNotification: () => void }) => {
    const { hideNotification } = props;

    const name = useMemo(() => getPlatformReferral(true), []);

    return (
        <FullscreenPopup
            title={'Subscribe to watch'}
            message={`You need to be an Optus Sport subscriber to access this content. Go to sport.optus.com.au/${name}, or scan the QR code above to see our subscription options.`}
            qrCode={name}
            onClick={hideNotification}
            onExit={hideNotification}
            primaryButtonTitle={'Back'}
        />
    );
};

export default SubscriptionPending;
