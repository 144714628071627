import { memo } from 'react';
import Button from 'src/components/Common/Button';
import { useSeekButtonActiveClass } from 'src/hooks/common';
import styles from './rewind.module.scss';
import { CONFIG } from 'src/config/config';

interface RewindProps {
    seek: (isFF: boolean, step: number) => void;
    hidden: boolean;
}

const Rewind = (props: RewindProps) => {
    const { seek, hidden } = props;
    const { activeClass, setActiveClass } = useSeekButtonActiveClass();
    const seekJumpTime = CONFIG.seekJumpTime
    const onClick = () => {
        setActiveClass(styles.buttonActive);
        seek(false, seekJumpTime);
    };
    return (
        <Button
            focusId="btn-rw"
            iconOnly
            iconPre="rw"
            onClick={onClick}
            className={styles.button}
            classNameActive={activeClass}
            hidden={hidden}
            disabled={hidden}
        >
            {seekJumpTime}
        </Button>
    );
};

export default memo(Rewind);
