import ff from '../../assets/icons/app/fastForward.svg';
import fitness from '../../assets/icons/app/iconFitnessRunningSolid.svg';
import football from '../../assets/icons/app/iconMatchGoal.svg';
import likeUnfilled from '../../assets/icons/app/iconLikeUnfilled.svg';
import liveTv from '../../assets/icons/app/iconLiveTv.svg';
import pause from '../../assets/icons/app/pause.svg';
import play from '../../assets/icons/app/iconPlayerPlay.svg';
import rw from '../../assets/icons/app/rewind.svg';
import search from '../../assets/icons/app/iconSearch.svg';
import settings from '../../assets/icons/app/iconSettings.svg';
import settingsWhite from '../../assets/icons/app/iconSettingsWhite.svg';
import spinner from '../../assets/icons/app/spinner.svg';
import tickOutLine from '../../assets/icons/app/tickOutLine.svg';
import tickSoloGreen from '../../assets/icons/app/iconStatusTickSolo.svg';
import tickOutlineGreen from '../../assets/icons/app/iconStatusTickOutline.svg';
import spoilerOn from '../../assets/icons/app/iconSpoilersOn.svg';
import spoilerOff from '../../assets/icons/app/iconSpoilersOff.svg';
import replayLength3 from '../../assets/icons/app/iconPlayerReplayLength3.svg';
import replayLength5 from '../../assets/icons/app/iconPlayerReplayLength5.svg';
import replayLength9 from '../../assets/icons/app/iconPlayerReplayLength9.svg';
import replayLength10 from '../../assets/icons/app/iconPlayerReplayLength10.svg';
import replayLength15 from '../../assets/icons/app/iconPlayerReplayLength15.svg';
import replayLength25 from '../../assets/icons/app/iconPlayerReplayLength25.svg';
import replayLength30 from '../../assets/icons/app/iconPlayerReplayLength30.svg';
import replayLength45 from '../../assets/icons/app/iconPlayerReplayLength45.svg';
import replayLength90 from '../../assets/icons/app/iconPlayerReplayLength90.svg';
import notificationError from '../../assets/icons/app/iconNotificationError.svg';
import styles from './icon.module.scss';
import { IconType } from '.';

type IconProps = {
    icon: IconType;
    spin?: boolean;
    className?: string;
};

const iconMapping = {
    ff: ff,
    fitness: fitness,
    football: football,
    likeUnfilled: likeUnfilled,
    liveTv: liveTv,
    pause: pause,
    play: play,
    rw: rw,
    search: search,
    settings: settings,
    settingsWhite: settingsWhite,
    spinner: spinner,
    spoilerOff: spoilerOff,
    spoilerOn: spoilerOn,
    tick: tickOutLine,
    tickSoloGreen: tickSoloGreen,
    tickOutlineGreen: tickOutlineGreen,
    replayLength3: replayLength3,
    replayLength5: replayLength5,
    replayLength9: replayLength9,
    replayLength10: replayLength10,
    replayLength15: replayLength15,
    replayLength25: replayLength25,
    replayLength30: replayLength30,
    replayLength45: replayLength45,
    replayLength90: replayLength90,
    notificationError: notificationError,
};

function IconImg(props: IconProps) {
    const { icon, spin = false, className } = props;
    const iconClasses = [styles.icon, className];
    if (spin) {
        iconClasses.push(styles.spin);
    }
    const imgSrc = iconMapping[icon];
    return <img className={iconClasses.join(' ')} src={imgSrc} alt={'no-alt'} />;
}

export default IconImg;
