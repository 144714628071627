import React from 'react';
import Typography from 'src/components/Common/Typography';
import styles from './rightSideCard.module.scss';
import { getAppVersion, getBuildNumber } from 'src/utilities/app';

interface RightSideData {
    primaryHeading: string;
    emailId?: string;
    primaryDesc: string;
    secondaryHeading: string;
    secondaryDesc: string;
    qrCode?: string;
    showVersion: boolean;
}
interface RightSideCard {
    cardData: RightSideData;
}
const RightSideSettingsCard = (props: RightSideCard) => {
    const { cardData } = props;
    const qrCode = {
        backgroundImage: `url(${cardData.qrCode})`,
    };
    return (
        <>
            <Typography className={styles.primaryHeading}>{cardData.primaryHeading}</Typography>
            {cardData.emailId ? (
                <div className={styles.emailLine}>
                    <Typography className={styles.emailDesc}>Logged into:</Typography>
                    <Typography className={styles.email}>{cardData.emailId}</Typography>
                </div>
            ) : null}
            <Typography className={styles.primaryDesc}>{cardData.primaryDesc}</Typography>
            <Typography className={styles.secondaryHeading}>{cardData.secondaryHeading}</Typography>
            <Typography className={styles.secondaryDesc}>{cardData.secondaryDesc}</Typography>
            {cardData.qrCode ? <div className={styles.qrCode} style={qrCode}></div> : null}
            {cardData.showVersion ? (
                <Typography
                    className={styles.versionLabel}
                >{`Version ${getAppVersion()}(${getBuildNumber()})`}</Typography>
            ) : null}
        </>
    );
};

export default React.memo(RightSideSettingsCard);
