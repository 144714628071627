import React from 'react';
import icon from 'src/assets/icons/app/iconShows.svg';
import styles from './showIcon.module.scss';

type ShowIconProps = {
    className?: string;
};

const ShowIcon = (props: ShowIconProps) => {
    const { className } = props;
    const liveIconClasses = [styles.icon];
    const bgStyle = { backgroundImage: '' };
    bgStyle.backgroundImage = `url(${icon})`;
    if (className) {
        liveIconClasses.push(className);
    }
    return (
        <div style={bgStyle} className={liveIconClasses.join(' ')}>
            {' '}
        </div>
    );
};

export default React.memo(ShowIcon);
