import { useOLRUDConsumer } from '../context';
import { getCardPos } from '../utilities/cardPosCache';
import usePage from './page.controller';

const useOLRUD = (panelIndex?: number) => {
    const {
        focusedCardIndex: fci,
        focusedPanelIndex: fpi,
        panelTranslateX: ptx,
        active,
        onLRUDKey,
    } = useOLRUDConsumer();
    const { pageId } = usePage();
    let focusedCardIndex;
    let panelTranslateX;
    if (panelIndex !== undefined) {
        focusedCardIndex = fpi === panelIndex ? fci : getCardPos(pageId, panelIndex).c;
        panelTranslateX = fpi === panelIndex ? ptx : getCardPos(pageId, panelIndex).t;
    }

    return {
        focusedPanelIndex: fpi,
        focusedCardIndex,
        panelTranslateX,
        active,
        onLRUDKey,
    };
};

export default useOLRUD;
