import { useMemo, useRef } from 'react';
import { CONFIG } from 'src/config/config';
import useOLRUD from 'src/lib/OLRUD/controllers/oLRUD.controller';
import useOLRUDPanelTops from 'src/lib/OLRUD/controllers/oLRUDPanelTops.controller';
import { isLayoutHero } from 'src/utilities/dashboard';
import { screenHeight } from 'src/utilities/device';
import { isBanner, isHeroThere } from 'src/utilities/panel';
import usePanels from './panels.controller';
import usePanelsData from './panels.fetch.controller';
import useViewAll from './viewAll.controller';
import { getRailHeight } from 'src/components/ui/Card/cardsizes';

import usePage from 'src/lib/OLRUD/controllers/page.controller';
import usePanelsToRender from './panels.toRender.controller';
import usePageBucketKey from './dashboard/page.bucketKey.controller';

const useDashboardBody = () => {
    const { panels, isBrowse, error } = usePanels();

    const { isViewAll } = useViewAll();
    const { focusedPanelIndex } = useOLRUD(0);
    const { panelTops } = useOLRUDPanelTops();
    const bucketKey = usePageBucketKey();
    usePanelsData({ bucketKey, focusedPanelIndex });
    usePanelsToRender({ bucketKey });

    const bannerRailHeight = getRailHeight('banner');
    const { pageId } = usePage();
    const pageRef = useRef(pageId);

    const railStyle = useMemo(() => {
        let translateY = 0;
        if (!isBrowse) {
            let defPosition = isBanner(panels[focusedPanelIndex])
                ? screenHeight / 2 - bannerRailHeight / 2
                : screenHeight / 2;

            let vOffset = isHeroThere(panels) && focusedPanelIndex === 0 ? 0 : defPosition;
            translateY = Math.abs(panelTops[focusedPanelIndex] - vOffset);
            translateY *= -1;
        }
        let transition = undefined;

        if (pageRef.current !== pageId) {
            transition = 'unset';
            // disable transition for some time on page change
            setTimeout(() => {
                pageRef.current = pageId;
            }, 800);
        }

        return {
            transform: `translate3d(0, ${translateY || 0}px, 0)`,
            transition,
        };
    }, [bannerRailHeight, focusedPanelIndex, isBrowse, panelTops, panels, pageId]);

    let sliceStart = focusedPanelIndex - Math.floor(CONFIG.dashboardWindowMaxPanels / 2) - 1;
    sliceStart = sliceStart < 0 ? 0 : sliceStart;

    const heroPanel = useMemo(() => panels.find((p) => isLayoutHero(p.layout)), [panels]);
    const otherPanels = useMemo(() => panels.filter((p) => !isLayoutHero(p.layout)), [panels]);
    const panelSlice = useMemo(() => {
        const sliceEnd = sliceStart + CONFIG.dashboardWindowMaxPanels;
        return otherPanels.slice(sliceStart, sliceEnd);
    }, [otherPanels, sliceStart]);

    const indexOffset = sliceStart + (heroPanel ? 1 : 0);

    return {
        panelSlice,
        heroPanel,
        isBrowse,
        showErrorPopup: error,
        indexOffset,
        railStyle,
        isViewAll,
    };
};

export default useDashboardBody;
