import Storage from './storage';
import { log } from './console';

let deviceModel = 'N/A';

export const isWebos = () => !!window.webos;

export const initWebosChild = () => {
    const webOS = window.webOS;
    webOS.service.request('luna://com.webos.service.tv.systemproperty', {
        method: 'getSystemInfo',
        parameters: {
            keys: ['modelName', 'firmwareVersion', 'UHD', 'sdkVersion'],
        },
        onComplete: function (inResponse: any) {
            var isSucceeded = inResponse.returnValue;
            if (isSucceeded) {
                Storage.setItem('p', { ...inResponse });
            }
        },
    });
};

export const getWebOSVersion = () => {
    // const webOSVersion = (platform.os?.version || 0).toString();
    // return webOSVersion;
    const webOS = window.webOS;
    let webOSDevice = Storage.getItem('p');
    if (!webOSDevice?.sdkVersion) {
        webOS.service.request('luna://com.webos.service.tv.systemproperty', {
            method: 'getSystemInfo',
            parameters: {
                keys: ['modelName', 'firmwareVersion', 'UHD', 'sdkVersion'],
            },
            onComplete: function (inResponse: any) {
                var isSucceeded = inResponse.returnValue;
                if (isSucceeded) {
                    Storage.setItem('p', { ...inResponse });
                    webOSDevice = Storage.getItem('p');
                    return inResponse.sdkVersion;
                }
            },
        });
    }

    return webOSDevice?.sdkVersion || '0';
};

export const getLGUDID = () => {
    const webOSDev = window.webOSDev;
    let webOSAdId = sessionStorage.getItem('adid');

    if (webOSDev && !webOSAdId) {
        webOSDev.LGUDID({
            onSuccess: function (res: any) {
                // user device from server
                log('======= succesfully capture LGDUD', res);
                sessionStorage.setItem('adid', res.id);
                webOSAdId = res.id;
            },
            onFailure: function (res: any) {
                // API calling error
                log('=======> LGUDID Failed', res);
            },
        });
    }

    return webOSAdId || null;
};

export const getLGModel = () => deviceModel;
