import styles from './dashboardError.module.scss';
import Typography from 'src/components/Common/Typography';
import IconImg from 'src/components/Icons/IconImg';
import { useTranslation } from 'react-i18next';

const DashboardError = () => {
    const { t } = useTranslation(undefined, { keyPrefix: 'dashboard' });
    return (
        <div className={styles.errorContainer}>
            <IconImg icon={'notificationError'}></IconImg>
            <Typography className={styles.errorText}>{t('emptyPageText')}</Typography>
        </div>
    );
};

export default DashboardError;
