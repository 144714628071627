import { FocusNode } from 'lal-lrud';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styles from './channelButton.module.scss';

interface ChannelButtonProp {
    logo: string;
    onChange: (id: string) => void;
    id: string;
}

const ChannelButton = (prop: ChannelButtonProp) => {
    const { logo, onChange, id } = prop;
    const params = useParams();
    const { assetId } = params;
    const bg = {
        backgroundImage: `url(${logo})`,
    };
    const onClick = useCallback(() => {
        onChange(id);
    }, [onChange, id]);
    const containerClass = [styles.container];
    if (assetId === id) {
        containerClass.push(styles.active);
    }

    return (
        <FocusNode
            onSelected={onClick}
            onClick={onClick}
            className={containerClass.join(' ')}
            focusedClass={styles.focused}
            focusId={id}
        >
            <div style={bg} className={styles.logo}></div>
        </FocusNode>
    );
};

export default React.memo(ChannelButton);
