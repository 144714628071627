import { PlayerClass } from '../player';

export function reset(this: PlayerClass) {
    this.setError(null);
    this.player = null;
    this.assetUrl = null;
    this.error = null;
    this.sourceConfig = null;
}

export function resetSource(this: PlayerClass) {
    this.player = null;
    this.assetUrl = null;
    this.assetId = null;
    this.sourceConfig = null;
}
