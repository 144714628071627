import { useStateValue } from 'src/state';
import useUser from '../auth/user.controller';
import { useMemo } from 'react';

import { ContentCardsType } from 'src/state/stores/dashboard/dashboard';
import useBrazeContentCards from 'src/lib/braze/controllers/brazeContentCards.controller';

const useContentCards = () => {
    const { state } = useStateValue();
    const { userData } = useUser();

    const {
        dashboardData: { contentCardsPlatform, contentCardsUser, contentCardsBrazeAll },
    } = state;

    const contentCards = useMemo(() => {
        // Return platform or user specific content cards
        return userData ? contentCardsUser : contentCardsPlatform;
    }, [userData, contentCardsUser, contentCardsPlatform]);

    const ccCardIds = useBrazeContentCards();

    const contentCardWithBrazeCC = useMemo(() => {
        const brazeAContentCards: ContentCardsType = {};

        if (!ccCardIds) {
            return {};
        }
        ccCardIds.forEach((c) => {
            if (contentCardsBrazeAll[c]) {
                if (contentCardsBrazeAll[c].contentCard) {
                    brazeAContentCards[c] = contentCardsBrazeAll[c];
                }
            }
        });

        const all: ContentCardsType = {
            ...contentCards,
            ...brazeAContentCards,
        };
        return all;
    }, [contentCardsBrazeAll, contentCards, ccCardIds]);

    return contentCardWithBrazeCC;
};

export default useContentCards;
