import React from 'react';
import Icon from 'src/components/Icons';
import styles from './spinner.module.scss';

type SpinnerProps = {
    fullScreen?: boolean;
    className?: string;
    background?: string;
};

function Spinner(props: SpinnerProps) {
    const { fullScreen, className, background } = props;
    const conatinerClasses = [className];
    if (fullScreen) {
        conatinerClasses.push(styles.fullScreen);
    }
    const bgStyle = {
        backgroundImage: '',
    };
    if (background) {
        bgStyle.backgroundImage = `url(${background})`;
    }
    return (
        <div className={conatinerClasses.join(' ')} style={bgStyle}>
            <Icon className={styles.spinner} icon="spinner" spin={true}></Icon>
        </div>
    );
}

export default Spinner;
