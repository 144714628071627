import { useEffect } from 'react';
import { Segment } from 'src/lib/bitmovin/segment';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';

export const useKickedOnPlayback = (isKickedOnPlayback: boolean) => {
    // Sent Segment events when kicked by the Playback API
    useEffect(() => {
        if (isKickedOnPlayback) {
            const player = getActivePlayer();
            player.unload(false);
            Segment.trackApiErrorEvent('Playback API Error| 401 device limit exceed error');
            Segment.trackPlaybackErrorDisplayed({
                errorType: 'DEVICE_LIMIT_EXCEEDED',
            });
        }
    }, [isKickedOnPlayback]);
};
