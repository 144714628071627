import React from 'react';
import Button from 'src/components/Common/Button';
import styles from './button.module.scss';

type PlayButtonProps = {
    onClick: () => void;
    buttonText?: string;
    focusId?: string;
};

function PlayButton(props: PlayButtonProps) {
    const { onClick, buttonText = 'Play', focusId } = props;
    return (
        <Button className={styles.button} onClick={onClick} focusId={focusId}>
            {buttonText}
        </Button>
    );
}

export default PlayButton;
