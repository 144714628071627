import styles from './circularProgress.module.scss';
interface circularProgressProps {
    value?: number;
    thickness?: number;
    size?: number;
    variant?: 'determinate' | 'indeterminate';
    className?: string;
}

const CircularProgress = (props: circularProgressProps) => {
    const { value, variant = 'indeterminate', size = 50, thickness = 5, className } = props;
    const containerStyle = { width: size, height: size };
    const conatinerClasses = [styles.root, className];

    conatinerClasses.push(styles[variant]);

    const circleStyle = {
        strokeDasharray: 0,
        strokeDashoffset: 0,
    };

    if (variant === 'determinate' && value !== undefined) {
        const circumference = 2 * Math.PI * ((size - thickness) / 2);
        circleStyle.strokeDasharray = circumference;
        // circleStyle.strokeDashoffset = ((100 - value) / 100) * circumference;
        circleStyle.strokeDashoffset = ((100 - value) / 100) * circumference;
    }

    return (
        <span className={conatinerClasses.join(' ')} role="progressbar" style={containerStyle}>
            <svg className="" viewBox="22 22 44 44">
                {variant === 'determinate' ? <circle className={styles.circleShadow} cx="44" cy="44" r="19.5" fill="none" strokeWidth="5"></circle> : null}
                <circle className={styles.circle} cx="44" cy="44" r="19.5" fill="none" strokeWidth="5" style={circleStyle}></circle>
            </svg>
        </span>
    );
};

export default CircularProgress;
