import { useEffect } from 'react';
import { useStateValue } from 'src/state';

import useUser from '../auth/user.controller';
import { applyAxiosRequestInterceptor } from 'src/utilities/http/axios.request.interceptor';
import { applyAxiosResponseInterceptor } from 'src/utilities/http/axios.response.interceptor';

const useAxiosApplyInterceptor = () => {
    const { dispatch } = useStateValue();
    const { auth } = useUser();

    useEffect(() => {
        if (auth) {
            return applyAxiosRequestInterceptor(auth);
        }
    }, [auth]);

    useEffect(() => {
        applyAxiosResponseInterceptor(dispatch);
    }, [dispatch]);
};

export default useAxiosApplyInterceptor;
