import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import usePlayer from './player.controller';
import useBackButton from './keyPress/backButton.controller';
import debounce from 'lodash.debounce';
import { LRUDEvent } from 'lal-lrud/dist/types';
import { CONFIG } from 'src/config/config';

const useAreYouStillWatching = () => {
    const [userWatchingState, setUserWatchingState] = useState<'AWAY' | 'WATCHING' | 'UNKNOWN'>(
        'WATCHING'
    );
    const [ayswAutoExitTime, setAyswAutoExitTime] = useState<number | undefined>(undefined);
    const navigate = useNavigate();
    const timerRef = useRef<any>(null);
    const exitTimerRef = useRef<any>(null);
    const { Player } = usePlayer();

    const callBack = () => {
        if (userWatchingState !== 'WATCHING') {
            setWatching();
            return true;
        }
        return false;
    };
    useBackButton({ callBack, id: 'are you still watching', rank: 3 });

    const resetTimeouts = () => {
        clearTimeout(timerRef.current);
        setAyswAutoExitTime(CONFIG.AYSWAutoExitTimeout / 1000);
    };

    useEffect(() => {
        if (CONFIG.AYSWActive) {
            timerRef.current = setTimeout(() => {
                setAyswAutoExitTime(CONFIG.AYSWAutoExitTimeout / 1000);
                setUserWatchingState('UNKNOWN');
            }, CONFIG.AYSWNotificationTimeout);
        }
        return () => {
            resetTimeouts();
        };
    }, []);

    useEffect(() => {
        if (userWatchingState === 'UNKNOWN' && exitTimerRef.current === null) {
            exitTimerRef.current = setInterval(() => {
                setAyswAutoExitTime((y) => {
                    if (!y) {
                        // setUserAway(true);
                        Player.zombieKicked = true;
                        navigate(-1);
                        clearInterval(exitTimerRef.current);
                        exitTimerRef.current = null;
                        return y;
                    } else {
                        return y - 1;
                    }
                });
            }, 1000);
            return () => {
                clearInterval(exitTimerRef.current);
                exitTimerRef.current = null;
            };
        }
    }, [userWatchingState, ayswAutoExitTime, Player, navigate]);

    const onKeyPress = useRef(
        debounce(
            (e?: LRUDEvent) => {
                e?.preventDefault();
                e?.stopPropagation();

                if (timerRef.current) {
                    resetTimeouts();
                }

                // TODO: Improve this logic
                // Dirty solution for CTV-1167. Unable to stop the Propogation of Key events.
                setTimeout(() => {
                    setUserWatchingState('WATCHING');
                }, 200);
                if (CONFIG.AYSWActive) {
                    timerRef.current = setTimeout(() => {
                        if (!Player.player.isPaused()) {
                            setAyswAutoExitTime(CONFIG.AYSWAutoExitTimeout / 1000);
                            setUserWatchingState('UNKNOWN');
                        }
                    }, CONFIG.AYSWNotificationTimeout);
                }
            },
            2000,
            { leading: true, trailing: false }
        )
    );

    const setWatching = useCallback(() => {
        setUserWatchingState('WATCHING');
        setAyswAutoExitTime(undefined);
    }, []);

    return { userWatchingState, setWatching, onKeyPress: onKeyPress.current, ayswAutoExitTime };
};

export default useAreYouStillWatching;
