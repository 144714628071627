import { pxScale } from 'src/utilities/styles';

export type ImageKeyType = 'imageUrl' | 'imageUrl_1:1' | 'imageUrl_16:9' | 'imageUrl_1:1';
export interface cardSizeType {
    width: number;
    height: number;
    imgWidth: number;
    imgHeight: number;
    showAllmargin?: number;
    showAllRowMargin?: number;
    margin: number;

    imageKey: ImageKeyType;
    showPaneltitle: Boolean;
    panelTitleHeight?: number;
    panelTitleBMargin?: number;
}

export interface cardSizesType {
    [char: string]: cardSizeType;
}

const defPanelTitleHeight = pxScale(53);
const defPanelTitleBMargin = pxScale(32);

const defPanelBMargin = pxScale(60);

const cardSizes: cardSizesType = {
    hero: {
        width: pxScale(1920),
        height: pxScale(832),
        imgWidth: pxScale(1920),
        imgHeight: pxScale(1080),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: false,
        margin: 0,
    },
    browse: {
        width: pxScale(412),
        height: pxScale(240),
        imgWidth: pxScale(412),
        imgHeight: pxScale(204),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(60),
        margin: pxScale(4),
    },
    explore_tile: {
        width: pxScale(412),
        height: pxScale(240),
        imgWidth: pxScale(412),
        imgHeight: pxScale(204),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(60),
        margin: pxScale(4),
    },
    live: {
        width: pxScale(412),
        height: pxScale(204),
        imgWidth: pxScale(412),
        imgHeight: pxScale(204),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(60),
        margin: pxScale(4),
    },

    banner: {
        width: pxScale(1745),
        height: pxScale(640),
        imgWidth: pxScale(1685),
        imgHeight: pxScale(640),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: false,
        margin: pxScale(4),
    },
    // TODO: Design and Spec Pending
    rectangular_tile_xs: {
        width: pxScale(412),
        height: pxScale(239),
        imgWidth: pxScale(412),
        imgHeight: pxScale(239),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(60),
        margin: pxScale(4),
    },
    // TODO: Design and Spec Pending
    rectangular_tile_m: {
        width: pxScale(412),
        height: pxScale(239),
        imgWidth: pxScale(412),
        imgHeight: pxScale(239),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(60),
        margin: pxScale(4),
    },
    // TODO: Design and Spec Pending
    rectangular_tile_s: {
        width: pxScale(412),
        height: pxScale(239),
        imgWidth: pxScale(412),
        imgHeight: pxScale(239),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(60),
        margin: pxScale(4),
    },
    rectangular_tile: {
        width: pxScale(412),
        height: pxScale(239),
        imgWidth: pxScale(412),
        imgHeight: pxScale(239),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(60),
        margin: pxScale(4),
    },
    square_tile: {
        width: pxScale(341),
        height: pxScale(371),
        imgWidth: pxScale(341),
        imgHeight: pxScale(371),

        imageKey: 'imageUrl_1:1',
        showPaneltitle: true,

        showAllmargin: pxScale(20),
        showAllRowMargin: pxScale(90),
        margin: pxScale(4),
    },
    disaster: {
        width: pxScale(650),
        height: pxScale(400),
        imgWidth: pxScale(650),
        imgHeight: pxScale(400),

        imageKey: 'imageUrl_16:9',
        showPaneltitle: false,
        margin: 0,
    },
};

export const getCardSize = (layout: string) => {
    return cardSizes[layout] || cardSizes.rectangular_tile;
};

export const getRailHeight = (layout: string, withBMargin: boolean = false) => {
    const { height, panelTitleHeight, panelTitleBMargin, showPaneltitle } =
        cardSizes[layout] || cardSizes.rectangular_tile;

    const titleHeight =
        (panelTitleHeight === undefined ? defPanelTitleHeight : panelTitleHeight) +
        (panelTitleBMargin === undefined ? defPanelTitleBMargin : panelTitleBMargin);

    const railHeight =
        height + (showPaneltitle ? titleHeight : 0) + (withBMargin ? defPanelBMargin : 0);

    return railHeight;
};

// export default cardSizes;
