import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { CONFIG } from 'src/config/config';
import RouteTags from 'src/routes/routeTags';
import { useStateValue } from 'src/state';
import { resetLayout } from 'src/state/stores/dashboard/dashboard.actions';

const useDataRefreshOnTimeout = () => {
    const isDashboard = useMatch(RouteTags.dashboard);
    const isDashboardExplore = useMatch(RouteTags.dashboardExplore);
    const isDashboardExploreViewAll = useMatch(RouteTags.dashboardExploreViewAll);
    const { dispatch } = useStateValue();

    useEffect(() => {
        if (isDashboard || isDashboardExplore || isDashboardExploreViewAll) {
            const timer = setInterval(() => {
                dispatch(resetLayout());
            }, CONFIG.DataRefreshMilliseconds);

            return () => {
                clearInterval(timer);
            };
        }
    }, [isDashboard, isDashboardExplore, isDashboardExploreViewAll, dispatch]);
};

export default useDataRefreshOnTimeout;
