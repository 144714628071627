import { PlayerClass } from '../player';

export function ready(this: PlayerClass) {
    return new Promise((resolve) => {
        if (this.videoElm) {
            resolve(this.player);
        } else {
            const onPlayerReady = () => {
                resolve(this.player);
                document.removeEventListener('player-ready', onPlayerReady);
            };
            document.addEventListener('player-ready', onPlayerReady);
        }
    });
}
