import { useEffect, useState } from 'react';
import getNetworkStatusInstance from 'src/utilities/network';

const networkStatus = getNetworkStatusInstance();
const useNetworkError = () => {
    const networkError = networkStatus.isError();

    const [showError, setShowError] = useState(true);

    useEffect(() => {
        if (networkError === false) {
            setShowError(true);
        }
    }, [networkError]);

    const clearError = () => {
        setShowError(false);
        networkStatus.clearError();
    };

    return {
        networkError,
        clearError,
        showError,
    };
};

export default useNetworkError;
