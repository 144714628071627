import { memo, useEffect, useState } from 'react';
import { FocusNode } from 'lal-lrud';
import Button from 'src/components/Common/Button';
import PlayerUISettingsTrolley from './common/UISettingsTrolley';
import Typography from 'src/components/Common/Typography';
import IconImg from 'src/components/Icons/IconImg';
import usePlayer from 'src/controllers/player.controller';
import { SpeedType } from 'src/lib/bitmovin/player.d';
import styles from './common/settings.module.scss';

const SpeedOptions = () => {
    const { Player } = usePlayer();
    const [speeds, setSpeeds] = useState<SpeedType[]>(Player.getSpeeds());

    useEffect(() => {
        Player.ready().then(() => {
            const ss = Player.getSpeeds();
            setSpeeds(ss);
        });
    }, [Player]);

    const setSpeed = (speed: number) => {
        Player.setSpeed(speed);
        const ss = Player.getSpeeds();
        setSpeeds(ss);
    };
    const selectedSpeedIndex = speeds.findIndex((speed) => speed.selected);

    return (
        <FocusNode orientation="vertical">
            <div className={styles.section}>
                <Typography className={styles.title}>Speed</Typography>
                <PlayerUISettingsTrolley index={selectedSpeedIndex}>
                    {speeds.map((speed) => (
                        <div
                            className={[
                                styles.track,
                                speed.selected ? styles.selected : undefined,
                            ].join(' ')}
                            key={speed.id}
                        >
                            <IconImg icon={'tickSoloGreen'}></IconImg>
                            <Button
                                focusId={`speed-${speed.speed}`}
                                onClick={() => setSpeed(speed.speed)}
                                open
                                className={styles.title}
                                classNameFocused={styles.focused}
                            >
                                {speed.label}
                            </Button>
                        </div>
                    ))}
                </PlayerUISettingsTrolley>
            </div>
        </FocusNode>
    );
};

export default memo(SpeedOptions);
