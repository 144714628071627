import { FocusNode } from 'lal-lrud';
import { FocusEvent, LRUDEvent } from 'lal-lrud/dist/types';
import React, { useCallback } from 'react';
import Icon, { IconType } from 'src/components/Icons';
import IconImg from 'src/components/Icons/IconImg';
import { useFocusCache } from 'src/controllers/focusCache.controller';
import { useFocusOnMouseOver } from 'src/hooks/useFocusOnMouseOver';
import styles from './button.module.scss';

interface ButtonProps {
    children?: React.ReactChild;
    onClick?: (e: LRUDEvent) => any;
    className?: string;
    classNameActive?: string;
    classNameFocused?: string;
    disabled?: boolean;
    mini?: boolean;
    iconOnly?: boolean;
    noBg?: boolean;
    open?: boolean;
    error?: boolean;
    iconPre?: IconType;
    iconPost?: IconType;
    spin?: boolean;
    focusId?: string;
    assetId?: string;
    trackFocus?: boolean;
    largeSvg?: boolean;
    hidden?: boolean;
}
const Button = (props: ButtonProps) => {
    const {
        disabled,
        children,
        className,
        onClick,
        mini,
        error,
        iconOnly,
        noBg,
        open,
        iconPre,
        iconPost,
        spin = false,
        classNameFocused,
        classNameActive,
        focusId,
        assetId,
        trackFocus,
        largeSvg,
        hidden = false,
    } = props;

    const buttonClasses = [styles.button, className];
    if (mini) {
        buttonClasses.push(styles.mini);
    }
    if (error) {
        buttonClasses.push(styles.error);
    }
    if (iconOnly) {
        buttonClasses.push(styles.iconOnly);
    }
    if (noBg) {
        buttonClasses.push(styles.noBg);
    }
    if (open) {
        buttonClasses.push(styles.open);
    }
    if (largeSvg) {
        buttonClasses.push(styles.largeSvg);
    }
    if (hidden) {
        buttonClasses.push(styles.hide);
    }

    const { setFocusCache } = useFocusCache();

    const onSelect = useCallback(
        (e: LRUDEvent) => {
            if (onClick && !hidden) {
                return onClick(e);
            }
            return undefined;
        },
        [hidden, onClick]
    );

    const onFocused = useCallback(
        (e: FocusEvent) => {
            if (trackFocus) {
                if (!focusId) {
                    console.error(`Page button doesn't have focusId`);
                }
                setFocusCache(e);
            }
        },
        [focusId, setFocusCache, trackFocus]
    );

    const focusedClasses = [styles.focused, classNameFocused];
    const activeClasses = [styles.active, classNameActive];
    const { onMouseOver } = useFocusOnMouseOver(focusId);
    return (
        <FocusNode
            elementType="div"
            className={buttonClasses.join(' ')}
            disabled={disabled}
            onSelected={onSelect}
            onFocused={onFocused}
            onClick={onSelect}
            focusedClass={focusedClasses.join(' ')}
            activeClass={activeClasses.join(' ')}
            disabledClass={styles.disabled}
            focusId={focusId}
            onMouseOver={onMouseOver}
            data-asset-id={assetId}
        >
            {iconPre && iconOnly ? <IconImg icon={iconPre} spin={spin}></IconImg> : null}
            {iconPre && !iconOnly ? <Icon icon={iconPre} spin={spin}></Icon> : null}
            {children ? <span>{children}</span> : null}
            {iconPost && iconOnly ? <Icon icon={iconPost} spin={spin}></Icon> : null}
            {iconPost && !iconOnly ? <IconImg icon={iconPost} spin={spin}></IconImg> : null}
        </FocusNode>
    );
};

export default Button;
