import { PlayerClass } from '../player';

export function isLive(this: PlayerClass) {
    try {
        return this.playerState !== 'DESTROYED' && this.player?.isLive();
    } catch (error) {
        return false;
    }
}

export function isPlaying(this: PlayerClass) {
    return this.player?.isPlaying();
}

export function isPaused(this: PlayerClass) {
    return this.player?.isPaused();
}

export function isStalled(this: PlayerClass) {
    return this.player?.isStalled();
}

export function isAdPlaying(this: PlayerClass) {
    return !!this.player?.ads?.getActiveAdBreak();
}
