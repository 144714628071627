import { useEffect, useRef } from 'react';

const useMountedRef = () => {
    const mounted = useRef(true);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);
    return mounted;
};

export default useMountedRef;
