import Apis from 'src/apis';
import { Action } from 'src/state/reducers.d';
import { SessionType } from './user';

import * as ACTIONS from './user.constants';

export const postAuthInit = (): Action => ({
    type: ACTIONS.ACTION_LOGIN_INIT,
    payload: Apis.user.postAuthInit(),
});

export const postAuthPoll = (deviceCode: string): Action => ({
    type: ACTIONS.ACTION_LOGIN_POLL,
    payload: {
        action: Apis.user.postAuthPoll(deviceCode),
        retry: false,
    },
});

export const getUserDetail = (userID: string): Action => ({
    type: ACTIONS.ACTION_FETCH_USER,
    payload: Apis.user.getUserDetail(userID),
});

export const postRefreshToken = (refreshToken: string): Action => ({
    type: ACTIONS.ACTION_TOKEN_REFRESH,
    payload: Apis.user.postRefreshToken(refreshToken),
});

export const restoreSession = (session: SessionType): Action => ({
    type: ACTIONS.ACTION_RESTORE_SESSION,
    payload: session,
});

export const logoutUser = (userID: string): Action => ({
    type: ACTIONS.ACTION_LOGOUT_USER,
    payload: {
        action: Apis.user.logoutUser(userID),
        retry: false,
    },
});

export const globalLogoutUser = (userID: string, accessToken: string, idToken: string, tokenType: string): Action => ({
    type: ACTIONS.ACTION_GLOBAL_LOGOUT_USER,
    payload: {
        action: Apis.user.globalLogoutUser(userID, accessToken, idToken, tokenType),
        retry: false,
    },
});