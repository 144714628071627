import { VideoElmEvent, PlaybackEvent, AdEvent } from '../player.d';
import { PlayerClass } from '../player';

export function on(
    this: PlayerClass,
    event: AdEvent | VideoElmEvent | PlaybackEvent,
    cb: (e: Event) => void
) {
    this.eventCbs[event] = this.eventCbs[event] || [];
    this.eventCbs[event].push(cb);
    return () => {
        this.eventCbs[event] = this.eventCbs[event]?.filter((c) => c !== cb);
    };
}
