import useTheme from 'src/controllers/theme.controller';
import styles from './shimmerPanel.module.scss';
import { CardLayoutType } from 'src/state/stores/dashboard/dashboard';
import { getCardSize } from '../../Card/cardsizes';

interface ShimmerPanelProps {
    cardLayout: CardLayoutType;
}

const ShimmerPanel = (props: ShimmerPanelProps) => {
    const { cardLayout } = props;
    const { height, width, showPaneltitle } = getCardSize(cardLayout);
    const { theme } = useTheme();
    const boxClasses = [styles.box, styles.shine, theme === 'light' && styles.light];
    const boxStyles = { height, width };
    if (styles[cardLayout]) {
        boxClasses.push(styles[cardLayout]);
    }

    return (
        <div className={styles.shimmer}>
            {showPaneltitle ? <div className={styles.title} /> : null}
            <div className={styles.container}>
                <div style={boxStyles} className={boxClasses.join(' ')} />
                <div style={boxStyles} className={boxClasses.join(' ')} />
                <div style={boxStyles} className={boxClasses.join(' ')} />
                <div style={boxStyles} className={boxClasses.join(' ')} />
                <div style={boxStyles} className={boxClasses.join(' ')} />
            </div>
        </div>
    );
};

export default ShimmerPanel;
