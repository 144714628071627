import { FocusNode } from 'lal-lrud';
import Button from 'src/components/Common/Button';
import CircularProgress from 'src/components/Common/CircularProgress';
import Typography from 'src/components/Common/Typography';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import useTheme from 'src/controllers/theme.controller';
import styles from './fullscreenPopup.module.scss';
import { NotificationKeyType, NotificationMessages } from './notifications';
import IconImg from 'src/components/Icons/IconImg';
import { IconType } from 'src/components/Icons';

interface FullscreenPopupProp {
    id?: NotificationKeyType;
    onClick?: () => void;
    onClickSecondary?: () => void;
    onExit?: () => void;
    open?: boolean;
    icon?: IconType;
    spinner?: boolean;
    title?: string;
    primaryButtonTitle?: string;
    message?: string;
    titleMessage?: string;
    isTrap?: boolean;
    fullyOpaque?: boolean;
    qrCode?: string;
}

const FullscreenPopup = (props: FullscreenPopupProp) => {
    const {
        id,
        onClick,
        onClickSecondary,
        onExit,
        spinner = false,
        primaryButtonTitle,
        isTrap = true,
        fullyOpaque,
        qrCode,
    } = props;
    const { theme } = useTheme();
    const { title, message, titleMessage, icon } = {
        ...(id && NotificationMessages[id]),
        ...props,
    };
    const { primaryButton = null, secondaryButton = null } = id ? NotificationMessages[id] : {};

    if (primaryButton && !onClick) {
        throw new Error('On Click is needed when a primary button is there!');
    }
    if (secondaryButton && !onClickSecondary) {
        throw new Error('On Click is needed when a Secondary button is there!');
    }

    const containerClass = [styles.container];

    if (theme === 'light') {
        containerClass.push(styles.light);
    }

    fullyOpaque && containerClass.push(styles.fullyOpaque);

    let callBack = () => {
        if (onExit) {
            onExit();
            return true;
        }
        return false;
    };
    useBackButton({ callBack, id: 'full screen popup', rank: 5 });
    const showPrimaryButton = !!(primaryButton || primaryButtonTitle);
    const onMountAssignFocusTo = showPrimaryButton ? 'actionButton' : undefined;

    return (
        <FocusNode
            isTrap={isTrap}
            className={containerClass.join(' ')}
            onMountAssignFocusTo={onMountAssignFocusTo}
            focusedClass={styles.focused}
        >
            {icon && <IconImg icon={icon} className={styles.icon} />}
            {qrCode && <div className={[styles.connect, styles[qrCode]].join(' ')} />}
            {title ? <Typography className={styles.title}>{title}</Typography> : null}
            {titleMessage ? (
                <Typography className={styles.titleMessage}>{titleMessage}</Typography>
            ) : null}
            {message ? <Typography className={styles.message}>{message}</Typography> : null}
            {spinner ? <CircularProgress variant="indeterminate"></CircularProgress> : null}
            <div className={styles.actions}>
                {showPrimaryButton ? (
                    <Button focusId={'actionButton'} className={styles.button} onClick={onClick}>
                        {primaryButton || primaryButtonTitle}
                    </Button>
                ) : null}
                {secondaryButton ? (
                    <Button className={styles.button} onClick={onClickSecondary}>
                        {secondaryButton}
                    </Button>
                ) : null}
            </div>
        </FocusNode>
    );
};

export default FullscreenPopup;
