import { Config, StringSet, URLSet } from '../config';
import { baseMetadataUrls, baseUrls, env } from '../configs/config.all';

const segmentFoxtelURLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/Qaz1QLoPAi6VaiLaZeBdQCGzgYdjwwVB/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/Db2soSxzEZBujhnKuqlzhFjAw1ZnX5Bv/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/Db2soSxzEZBujhnKuqlzhFjAw1ZnX5Bv/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/Db2soSxzEZBujhnKuqlzhFjAw1ZnX5Bv/analytics.min.js',
};

const drModeFoxtelURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-foxteltv.json',
    PP: 'https://dr.optussport.info/assets-foxteltv.json',
    STAGING: 'https://dr.optussport.info/assets-foxteltv.json',
    LOCAL: 'https://dr.optussport.info/assets-foxteltv.json',
};

const brazeFoxtelKeys: StringSet = {
    PROD: '3c9001e5-52d6-48b3-b787-e6f44483cfa9',
    PP: '424bfb3d-bdac-42fe-82fe-82cc9aff214f',
    STAGING: '424bfb3d-bdac-42fe-82fe-82cc9aff214f',
    LOCAL: '424bfb3d-bdac-42fe-82fe-82cc9aff214f',
};

const config_iq: Partial<Config> = {
    platform: 'Foxtel',
    segmentLibUrl: segmentFoxtelURLs[env],
    drModeCheckURL: drModeFoxtelURLs[env],
    noAnimations: true,
    exitAppPopupEnabled: true,

    playbackSettingsSpeedActive: false,
    playbackSupportsCmafLive: false,
    playbackSupportsCmafVod: false,
    stallDetectorEnabled: true,

    YoSpaceActiveOnLive: false,
    YoSpaceActiveOnVod: false,
    adUIEnabledOnLive: false,
    adUIEnabledOnVod: false,

    dataDogReplayRate: 0, // Turning off Replay feature for Foxtel.

    BrazeActive: true, // All users
    BrazeApiKey: brazeFoxtelKeys[env],

    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['avc', 'av1', 'hevc'],
        },
        tweaks: {
            dword_base_media_decode_timestamps: true,
        },
    },
};

const config_hubbl: Partial<Config> = {
    platform: 'Hubbl',

    segmentLibUrl: segmentFoxtelURLs[env],
    drModeCheckURL: drModeFoxtelURLs[env],
    noAnimations: true,
    exitAppPopupEnabled: true,

    playbackSettingsSpeedActive: false,
    playbackSupportsCmafLive: true,
    playbackSupportsCmafVod: true,

    YoSpaceActiveOnLive: false,
    YoSpaceActiveOnVod: true,
    adUIEnabledOnLive: false,
    adUIEnabledOnVod: true,

    isFireboltEnabled: true,
    dataDogReplayRate: 0, // Turning off Replay feature for Foxtel.

    BrazeActive: true,
    BrazeApiKey: brazeFoxtelKeys[env],

    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['hevc', 'avc', 'av1'],
        },
    },
};

const config_foxtel = (isHubbl: boolean) => {
    return isHubbl ? config_hubbl : config_iq;
};
export default config_foxtel;
