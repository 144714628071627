import { memo } from 'react';
import { Message } from '../../../braze';
import styles from './banner.module.scss';
import Typography from 'src/components/Common/Typography';
import Button from '../../Button';

interface BannerMessageProps {
    message: Message;
    focusedBtnIndex: number;
}

const BannerMessage = (props: BannerMessageProps) => {
    const { message: _m, focusedBtnIndex } = props;
    const { header, message, imageUrl, buttons } = _m;
    const showImage = !!imageUrl;
    const imageStyle = {
        backgroundImage: `url(${imageUrl})`,
    };
    const classes = [styles.modal, styles.banner];

    if (showImage) {
        classes.push(styles.hasImage);
    }

    return (
        <div className={styles.backdrop}>
            <div className={classes.join(' ')} style={imageStyle}>
                <div className={styles.mask} />
                <div className={styles.content}>
                    <Typography className={styles.title}>{header}</Typography>
                    <Typography className={styles.subTitle}>{message}</Typography>
                    {buttons.length ? (
                        <div className={styles.buttonContainer}>
                            {buttons.map((b, bi) => (
                                <Button button={b} key={b.id} focused={focusedBtnIndex === bi} />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default memo(BannerMessage);
