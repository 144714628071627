import { memo } from 'react';
import { CONFIG } from 'src/config/config';
import styles from './shimmer.module.scss';

interface ViewAllShimmerProps {
    cardLayout: string;
}

const ViewAllShimmer = (props: ViewAllShimmerProps) => {
    const { cardLayout } = props;
    const boxClasses = [styles.box];
    if (styles[cardLayout]) {
        boxClasses.push(styles[cardLayout]);
    }

    return (
        <div className={styles.shimmer}>
            <div className={styles.title} />
            <div className={styles.trolley}>
                <div className={styles.rail}>
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                </div>
                <div className={styles.rail}>
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                </div>
                <div className={styles.rail}>
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                </div>
                <div className={styles.rail}>
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                    <div className={boxClasses.join(' ')} />
                </div>
            </div>
        </div>
    );
};

export default memo(ViewAllShimmer);
