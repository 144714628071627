// List of Device Id's to turn of CMAF Support
export const disabledCmafDeviceIds = [];
// List of Device Models to turn of CMAF Support
export const disabledCmafDeviceModel = [];

export const enableDDRumDeviceIds = [
    'aa6afd0f-0b13-411f-88d0-d7c23292a25b', // Aze (test)
    'd8abe88d-feb5-49ee-8ecf-679e04474a18', // LG WebOS 5.5 - 65NANO80TNA
    'd8034f07-2e84-41c1-906f-f69a307e0419', // LG WebOS 5.5 - 65NANO80TNA
    '3c798b80-c1cc-4186-acd8-759e3d72e381', // Office LG WebOS 5.5 (PROD)
    '06194702-54f2-4df6-9f62-9b44d7c36bae', // War room WebOS 8
];

export const deviceConfigSetupSecondary = [
    'e93225f7-17bd-49b0-a6a8-86ef4c438ebc', // John's LG WebOS 4.9
    // '2b48dbcc-e2d6-4989-acb6-0e9f41057fbf', // Office LG WebOS 5.5 (PP)
    // List of Devices with 2008 error
    // 'edb4e66c-9840-4032-9c58-e8b43b82b084',
    // 'ccb528a4-dc9a-42bf-b46b-518b86c14a27',
    // '0c61a569-6b8f-44ff-b701-533d01fb2631',
    // 'faccba11-949e-4e98-aac4-6345ea609a94',
    // 'fa4eeb7b-fe88-42ad-8cbb-b0f7752eabfe',
    // 'f1def560-8241-4385-8915-6eb738b543fb',
    // 'ebd37143-64f1-487b-be5a-1dd1cbcfed49',
    // 'ea198129-8492-4fad-82b2-e50ab6d66124',
    // 'd8034f07-2e84-41c1-906f-f69a307e0419',
    // 'f7374408-dcf1-495d-8abe-983af6fde658',
    // 'c683fc9c-d7a8-4b9f-8c7a-dd7379a11457',
    // '2529f632-e990-4eb0-86dc-1a48b3265fb3',
    // 'b27e3f9f-0a0f-474d-9c12-658f50371320',
    // 'a6306036-5e5e-4197-8a51-f6192abef998',
    // '814fc282-bdcd-4ba5-ae92-f2c1bc31de43',
    // '3c45f595-4a5a-44c6-a53d-b1015179aa47',
    // '3b9c2309-a746-4041-86bb-81caf44d5e01',
    // 'bf758da4-606e-41b7-938f-5f49312ffdfe',
    // '747a98a6-b6cc-44e7-8940-7726685d84f3',
    // '71fd8cb4-196b-43fa-87d0-2fdfadfa25af',
    // '71a1161f-a567-4a56-9199-ca5d7ab04125',
    // '6fbb7c42-e355-4768-b328-ecf242e51aae',
    // '6ea894c9-9ee0-4638-8f10-bd4af82e0f88',
    // '69bb80e5-1568-4c47-adf2-449da70fd645',
    // '6c7450c7-4260-4953-bea2-2c0c557ef42f',
    // '6b6eb4af-cce5-48b0-9c09-23b3db77d812',
    // '6b2c1bdc-5004-4553-b94a-61ce8ae76e40',
    // '679aaec4-61f3-4d27-ba58-63e5edd03033',
    // '676f04af-1ee0-481d-876c-5f9655106f42',
    // '75290d4f-374d-47a9-878a-35cc9d2494aa',
    // '74d29cfd-b271-432e-a830-c300e9a4a9e7',
    // '7b4be22a-d5ca-472b-ac28-660957d45adb',
    // '75ced0e9-015e-4186-894b-6e898a7ca9d3',
    // '76385575-212f-43de-a53a-9051a8882f30',
    // '771d6209-5e3b-4335-9ae0-0d0f375008a4',
    // '7892a8dd-9126-44f1-b597-42a6684d4328',
    // '7940c487-d93f-4d56-8c47-231bc8667f3e',
    // '7a851ffd-a232-4fd1-8fe1-60e92b059200',
    // '7af117d1-9dd7-4b77-a55c-e7bbd09d130a',
    // '662d915b-f483-4bf7-a5fa-30c9ac562874',
    // '7d204e72-6762-4928-9927-74a758b4df0f',
    // '7dbf6c36-6572-428f-9daa-599de012f192',
    // '7ef6c569-0a11-4fe6-886e-726f8090aebe',
    // '7ffec2bb-34f9-4031-bb5d-20bb92ba96aa',
    // '675947d9-989f-45f4-8180-4c2949127337',
    // '619a137a-3434-46ad-85c2-b57165b1931d',
    // '65dede29-ff10-4653-8ec1-b09980ab1bcc',
    // '659dac68-7a0d-43f0-a178-0dd0a692dbc2',
    // '5235f8ea-00a9-4657-8475-b534f9eab815',
    // '549c3547-3ec4-4268-a309-4a5e42cdac7f',
    // '5619ab0f-98e9-445e-a713-9a2ccea42edc',
    // '56d2535e-d96a-4ddc-b19a-31dc2e3b2ec5',
    // '576cef2d-b0a3-4406-8b8c-d2b227227c98',
    // '57881852-1615-4dd1-9da8-c60a736781d2',
    // '58ac5638-d95c-4258-b6e2-3124130c7fba',
    // '590d0873-2c99-4d01-8b46-6d682d35249c',
    // '5bc8ba94-5c55-4685-84d5-753d90293bdb',
    // '5c67f358-04f4-4a54-968b-d82cf6fc915a',
    // '5c9f6ccb-9107-4068-a135-99284abd1251',
    // '5d6ec980-d871-4464-89b3-a595d072fec9',
    // '5dc3f091-12c9-4dfe-ad87-b3c49df84fa9',
    // '5f8b9074-b7f8-4dd9-8610-1a3c006cfe14',
    // '5fb97197-67ee-4d12-9f6d-9239eeab2809',
    // '6015f3be-90db-48c3-989c-af0a958befed',
    // '6116fd5b-5f2d-4cf5-9810-71fe7093f164',
    // '617b63e1-8ebd-4d01-bd5f-85c999e04bdd',
    // '81808fa2-93a3-48f4-9733-e7a1307f99f3',
    // '62594cef-8a42-473a-a8d4-a83b4271b69a',
    // '64614986-9220-49ea-ac51-78e13d9afa80',
    // '649e98c8-dd19-413e-8cd4-50e9f3df87aa',
    // '64dcf685-2ca6-4a99-a552-d59a09b32724',
    // '64f5093e-0978-49f4-8526-adaf8de078d8',
    // '64ff0087-410e-4913-980e-c778201df0de',
    // '81346c07-626a-4dd7-86d1-73c02040d14f',
    // '8791721e-799c-4101-abc6-be99b12494cb',
    // '81e4156d-5ff2-4275-872a-bb4999a4b75d',
    // 'a1858ed9-fdb4-424d-9b13-659f9a631024',
    // '96cad89a-6361-48d9-9cad-6f2948dae963',
    // '96faeee0-2c41-4bab-b3f9-5143e25442ae',
    // '978b77c8-0670-44c7-ada8-3614950c53cd',
    // '97f96ed1-b45e-4be4-8616-f799d912fcf0',
    // '9a05bcdd-5a28-4a3c-872a-74495c5c5142',
    // '9af72751-0bdb-4be7-ae35-af96d003428c',
    // '9c18f673-a309-44be-ac12-f9432a865881',
    // '9d0d1f4e-da46-4f46-8720-3712e24786ac',
    // '9d80ba25-38ac-4af8-aef7-67bc891d8d0e',
    // '9def53fc-1497-41c1-96d9-cf26e7243a53',
    // 'a0871e0d-00ac-4045-85a4-3468de925cfe',
    // 'a113e43d-3c88-41fc-815f-f3ef105a66a0',
    // 'a3dde62d-454e-4d4e-a0c2-4c45b8d042f6',
    // '82f16872-c2af-45cc-a04d-a660a57acd9d',
    // 'a3fe06e0-bad9-4569-be76-555e0d2e206a',
    // 'a4f5416c-aa3c-46a6-ba09-08220338b9da',
    // 'a64878d2-b4c4-4068-91fd-4f4bc14660c0',
    // 'a69f68e1-2239-4ce1-b9de-701d0d17ddb8',
    // 'a7076cfe-fa41-4548-af5e-25535cbd46ff',
    // 'a72c5ec5-b194-41c9-b533-833bc4f65e83',
    // 'a84233c5-6094-4356-a7b2-f4b601aef8bc',
    // 'a8b45878-ba01-4187-8f09-2e08fb36a85a',
    // 'acafd376-5460-4e54-8f91-db95ce0c93b4',
    // 'ae905afb-e297-44a9-a7ef-298d0e8f1fb8',
    // 'afc10b48-e47b-408f-85d9-dec05536a0fa',
    // 'b0a662b3-bdc9-4fb3-a5fa-ca4d59e51043',
    // '965ffb5a-ea22-483f-8670-0ca8ca4dc60a',
    // '965236de-c292-4938-ae5e-a663d2280e26',
    // '957c0a46-8d93-4cfb-904e-ba3e64f064bc',
    // '95043cf2-5779-4937-aeca-ee53c6ed4c89',
    // '83cb09b0-7e41-4928-bc62-c4c83632e535',
    // '841246c2-fca4-4883-a2db-e3d653c8994a',
    // '84ac873e-b948-465c-9f1e-5ecd1738f57d',
    // '85e8c747-b9d2-444a-bab6-7c7d66eb98d7',
    // '862bf9af-1b29-4e51-a877-23c34b0586ab',
    // '86393227-b9d6-4a05-baa7-394873885a81',
    // '520a70ab-5e38-4ce7-afe5-fff5571d6492',
    // '88cfe181-bb2d-4d81-b901-32eb37e152de',
    // '8acb8556-98da-4701-b5eb-4eadc15b0287',
    // '8b7e90f3-2456-4670-bd54-92df100835d7',
    // '8d769e11-0fbc-4fda-8d57-96c6e078f524',
    // '8d9cf7d5-374c-4931-ba06-a3aa8e26fb13',
    // '8ea21e5a-0bc4-414b-ba1d-bbb4180aa01c',
    // '8ec6176b-af67-406e-8c28-4ef5988dd6df',
    // '8f544507-855f-4f60-b16d-f6628cb80754',
    // '8f73e06d-c275-481b-af2c-173f26c2a056',
    // '8fe58edc-5c51-446a-8cdd-05a497be3e03',
    // '901ce984-18e5-4102-b6fc-3e8c2dc39e1b',
    // '9059e9e3-0a06-4542-a6ad-a01d0cffb097',
    // '925e28ee-f0f8-4a5b-b231-6c1105fc2d76',
    // '92d9042e-50ba-436a-8ee6-f4f93790add5',
    // '9318d4d0-ada9-49f3-896a-b1c8d152dda5',
    // '93673068-8458-4acc-ab87-43dddf7f867f',
    // '942776c9-e3db-4404-8dba-f4d85c16a0d9',
    // '94691500-18a1-4a7b-bb44-95234d5d8817',
    // '52171e64-f5f0-4588-ad5d-d1b3c278caab',
    // '4d35fb1d-3bff-4351-b152-a429522fbadd',
    // '5113ceb0-c837-438d-9a91-01e05e75e05a',
    // '1eb79022-d408-45c7-b6c4-600bafa53917',
    // '19c6cb23-1b23-4906-826d-6edba5a8f306',
    // '19fbb072-15fd-42d6-8b1a-3ae539b5dcde',
    // '1a309524-7ce3-4acc-ad1b-efc6e554397f',
    // '1a57f1fc-26c2-4e66-915e-92a3a9be2b6f',
    // '1a941841-7b41-4121-957d-80806c3fea2b',
    // '1af72a1c-4a6f-4121-830a-42332087770d',
    // '1b5274e1-52b2-4b99-a07c-cb43b1bc82eb',
    // '1b8a9f92-8171-41dd-b2aa-21941000b43b',
    // '1be97ba0-4414-42c6-b8f7-cb64811b6683',
    // '1c504bff-0d95-4458-b6f7-5f90df6babce',
    // '1d6b9967-bc0b-465d-811c-bec60fc21155',
    // '1e9cddcf-730d-4b96-a20a-84af6e3e23bf',
    // '1ec1d74c-1524-4043-9bb9-19fccb70527e',
    // '505b192c-3f73-4b27-9849-461f8c7c7403',
    // '1f326570-f307-481e-904b-dfdc3a48bf4f',
    // '1f3eea88-6d94-4b75-a3f4-9cfcfe3188a2',
    // '1fb0f957-f5c5-4ccd-b8ac-b77dced1075a',
    // '20e82ed2-c3bd-4ca5-876b-dc0e8ef1943f',
    // '2144cdf2-7e71-4835-b9c3-10f2a5ba149a',
    // '21a5a589-c165-4156-abb4-54dc39037512',
    // '22ec6fcc-c761-433f-ac5b-e6acf2c7bda9',
    // '22f38f53-2f3b-4432-b183-9ee1995546f3',
    // '2319d74c-fd7f-402c-902b-7c8edf3b2805',
    // '239a5475-1642-4c47-871a-690c6a097669',
    // '239c7aa0-34e0-420c-9d3d-4ac23a36fbe3',
    // '24a89eb5-fee5-417d-b693-656fe5570ae0',
    // '1979c1cd-103a-4bdf-b8a1-15a6d2f9ba87',
    // '194d569d-18da-446b-9f07-65100d48214e',
    // '18cff650-6cf1-4217-9863-7c1b1abeebe9',
    // '1672bed5-7058-4a43-89b4-ef1e751dd7f0',
    // '000ca0c9-6099-4d50-97aa-ddf8896fff31',
    // '004bfe83-5d18-438a-aac2-8f3cb60a21f5',
    // '008fcb7c-0c0f-4459-9311-61c8675b7619',
    // '0189a967-0998-4464-ba1a-818200315ace',
    // '01ea2c65-143b-469e-aa4d-25749ef6fdcb',
    // '04be24ee-4c38-4597-8ee1-aafb4f9bf3b7',
    // '052dfb86-cc4a-4cf0-840a-a4eb5e21e31b',
    // '056928fd-8de2-4853-9f0e-0b8c4ffb94fa',
    // '059f008a-88e0-4319-9327-b16342d904af',
    // '05c8cef0-fa6c-4a38-9b11-732fc4e3c35e',
    // '07d933e5-e216-424d-a03a-92ae058174fc',
    // '096a8c4d-f6f8-4ab7-9a4d-ab22156a1291',
    // '0982d5d5-e254-40db-a4ff-5a964ba31f84',
    // '0ab86949-cc56-46e8-9fb8-44704f8e7e05',
    // '0b201c29-a50c-43cc-9238-55c36835150f',
    // '0bc6ed2a-3e96-4188-b09c-7c7121f50c7e',
    // '0e141346-22a9-4b9c-8ce6-39300bdd61be',
    // '0f3912f4-9bf8-4055-bdd0-52b5416cc340',
    // '0ff1ab4f-dd33-4536-93fe-24cdf4af59ec',
    // '0ff7f6a2-69f3-4fca-b51c-acb837c24580',
    // '10004107-15b2-4b91-8f3e-1e4673b85cd1',
    // '11ce5ef4-d657-4692-87be-044384bb9735',
    // '13d8ad4f-e14a-4c57-af89-e62fc73472e0',
    // '153d357a-e5b3-49e4-b8c4-1d80a7023607',
    // '163f7bec-2cff-4428-99e2-211b082f0a1e',
    // '250301ab-791f-4c95-9cc5-0b5f9763888d',
    // '252041ea-d29b-4036-8bc6-e5b5762671f2',
    // '25af5bd3-b1ef-4a74-bfa4-0765133753f8',
    // '3e9ac826-4d1a-4a9b-9eda-c68af41c6039',
    // '3f71830b-0881-40d1-955e-d4a6b599a126',
    // '3f9df38f-af34-48f7-80a4-a4d79b81f755',
    // '3fd474f4-905a-4a6f-aa3e-b1f2a4811d6d',
    // '404699a4-d31f-49a5-8662-47d21c9fdac7',
    // '40f38f95-cc47-49a3-b699-4fb09ef63777',
    // '410ef543-973c-4af3-b5b0-e7b9bdfae440',
    // '415c8241-370c-4e29-9102-75175124a7f6',
    // '41954711-aa2c-49a5-ba0d-9c580e3e974a',
    // '44dbeb01-cd0e-46ae-9bb2-d92e81a0b0d6',
    // '46276d88-7f1c-4f39-979e-874a91ff282e',
    // '4738309b-1847-41b3-bf59-d6a9bc7f912f',
    // '473d38c3-32f6-43d1-8e71-40587cc456c6',
    // '476fe919-22b6-4c29-abbb-728c6c16cc48',
    // '4949f9d2-1dec-42a4-9429-116953cb4270',
    // '49c26eb9-8883-4eaa-a92a-8819d328849b',
    // '4a9d2596-4979-4378-8274-00861bc86a22',
    // '4b287eb6-7374-45e8-9974-26dc8b25541f',
    // '4d1519c5-1790-40e2-b707-2a9d37c4208a',
    // '4d2a451d-5232-4361-986f-000431eade5f',
    // '4ed3ef5d-f334-4b5a-b7b5-9e6b5a56b210',
    // '4f096c06-e09d-4d13-97c8-a28d6191d369',
    // '4f564428-fea9-41ed-be8b-ab407cacb50d',
    // '4f5a6e7c-82a1-4b46-b23b-25c0689905a3',
    // '4f8f56d3-d8d5-46ea-b2f7-4292adcc8e33',
    // '4fe84da9-09de-4e64-8f60-30e693f3c631',
    // '3f508456-9fe7-4f23-94f0-2299bd0a60d8',
    // '3e64a493-b018-43f8-9dcb-2c2921921de5',
    // '26ad9a92-c6ff-4836-8467-f603ae111ca2',
    // '3d877a09-98a7-4cd3-839e-e1add76bc936',
    // '29d46cf0-2f3e-4afe-807a-8877c30af8d3',
    // '2b5af007-20c1-4c83-92d2-1d6d9dad5f61',
    // '2bbcdf59-5d89-4b3a-8314-198e17b77094',
    // '2bde71d2-9838-4b63-9897-4957d3610998',
    // '2bef9f6e-4443-4de3-a65e-122ba42026d2',
    // '2c99d8a0-ced5-4ce9-9b95-823dd271b660',
    // '2d51053f-6422-42dc-8bac-e5d3b04516b3',
    // '2dd57e33-d2e3-4eda-8e9e-5482fd76132a',
    // '2e4b2521-8b86-4ef9-9f0e-af46ed33940c',
    // '2e91ba0a-cad7-49c5-b420-54d2cf70fdab',
    // '2ecefe12-e022-4a57-ba42-1a8c4639d290',
    // '2efa3741-0309-42d4-b4c2-a3a66784ce0e',
    // '2f169a69-a84e-4242-b8bc-da25391735a9',
    // '3033c0b2-733d-419f-91ad-38a5776077de',
    // '311affbb-b974-4b2d-b294-7d3e358923c8',
    // '325b0af3-914f-4fec-9d82-71bb2384b03b',
    // '33cb0938-9cb2-4a84-b1af-fbea83daa528',
    // '35438d47-b027-4c98-baf0-8934b7987510',
    // '3602c710-65ce-4c7f-93e7-a0f730b4a0f0',
    // '369d5bc7-60b3-4b40-8756-5b745dc6f46e',
    // '37f69aee-5e10-4150-872e-3d39d168087a',
    // '38173fe1-222e-46c1-aff8-77d5a9910fa7',
    // '38a22b98-dc83-49a9-95c1-3201cd11d17c',
    // '38b2ea69-cf32-46e8-9cca-57c53b067b41',
    // '3c50be45-a7d0-46bc-98a4-26a3d0733900',
    // 'b123e0df-eb59-4d62-ad8b-0bfe8e080700',
];
