declare global {
    interface Window {
        tizen: boolean;
        vidaatv: any;
        webos: any;
        webOS: any;
        webOSDev: any;
        initBitmovinMux: any;
        analytics: any;
        webapis: any;
        Windows: any;
        OptusRuntimeComponent: any;
    }
}

export const Windows = window.Windows;
export const OptusRuntimeComponent = window.OptusRuntimeComponent;
