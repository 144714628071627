import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { checkifPastOrUpcoming } from './time';
import focusIds from 'src/constants/focusIds';

// TODO: This function duplicates the logic of Match Actions Render, Avoid it!
export const getOnMountFocusIds = (asset: AssetType) => {
    const {
        isLive,
        fullAssets,
        megaAssets,
        miniAssets,
        extendedAssets,
        tinyAssets,
        type,
        broadcastStartTime,
        publishStartDate,
        isInTransition,
    } = asset;

    const haveSubAssets =
        fullAssets?.length ||
        megaAssets?.length ||
        miniAssets?.length ||
        extendedAssets?.length ||
        tinyAssets?.length;

    const focusableIds = [];

    const isUpcoming =
        checkifPastOrUpcoming(broadcastStartTime ? broadcastStartTime : publishStartDate) ===
        'upcoming';

    // The following logic should match the Match Actions Render logic
    if (isLive) {
        if (!isUpcoming) {
            focusableIds.push(`${focusIds.matchActionPrefix.watchlive}${asset.id}`);
            focusableIds.push(`${focusIds.matchActionPrefix.startover}${asset.id}`);
        }
    } else if (haveSubAssets) {
        if (type === 'game' || fullAssets?.length) {
            focusableIds.push(`${focusIds.matchActionPrefix.game}${asset.id}`);
        }

        if (type === 'mega' || megaAssets?.length) {
            focusableIds.push(`${focusIds.matchActionPrefix.mega}${asset.id}`);
        }

        if (type === 'minimatch' || miniAssets?.length) {
            focusableIds.push(`${focusIds.matchActionPrefix.minimatch}${asset.id}`);
        }

        if (type === 'extended' || extendedAssets?.length) {
            focusableIds.push(`${focusIds.matchActionPrefix.extended}${asset.id}`);
        }

        if (type === 'highlights' || tinyAssets?.length) {
            focusableIds.push(`${focusIds.matchActionPrefix.highlights}${asset.id}`);
        }
    } else if (isInTransition || haveSubAssets === 0) {
        focusableIds.push(`${focusIds.matchActionPrefix.game}${asset.id}`);
    }
    return focusableIds;
};
