import React, { useEffect, useState } from 'react';
import { CONFIG } from 'src/config/config';

import styles from './linearProgress.module.scss';

type LinearProgressProps = {
    startTime: number;
    stopTime: number;
};

const LinearProgress = (props: LinearProgressProps) => {
    const { startTime, stopTime } = props;
    const [value, setValue] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            const now = Date.now();
            const currentTime = now - startTime;
            const duration = stopTime - startTime;

            let v = currentTime / duration;
            setValue(v);
        }, CONFIG.miniEPGProgressUpdate);

        return () => {
            clearInterval(timer);
        };
    }, [startTime, stopTime]);

    const width = `${value * 100}%`;

    return (
        <div className={styles.rail}>
            <div className={styles.bar} style={{ width: width }} />
        </div>
    );
};

export default React.memo(LinearProgress);
