import React from 'react';
import Spinner from 'src/components/Common/Spinner';
import Typography from 'src/components/Common/Typography';
import { EPGEventDataType } from 'src/state/stores/dashboard/dashboard';
import { formatTime } from 'src/utilities/time';
import LinearProgress from '../LinearProgress';
import styles from './mEPGProgram.module.scss';

interface MEPGProgramProps {
    program: EPGEventDataType | null | undefined;
    now?: boolean;
}

const MEPGProgram = (props: MEPGProgramProps) => {
    const { program, now } = props;
    const { title, shortEventDescription, epgStart, epgStop } = program || {};
    const programClass = [styles.program];
    if (now) {
        programClass.push(styles.now);
    }
    if (!program) {
        programClass.push(styles.empty);
        return (
            <div className={programClass.join(' ')}>
                {program === null ? (
                    <Typography className={styles.title}>
                        No information available at this time
                    </Typography>
                ) : (
                    <Spinner />
                )}
            </div>
        );
    }
    let time = epgStart && epgStop ? `${formatTime(epgStart)} - ${formatTime(epgStop)}` : '';
    return (
        <div className={programClass.join(' ')}>
            <Typography className={styles.time}>{time}</Typography>
            <Typography className={styles.title}>{title}</Typography>
            <Typography className={styles.subTitle}>{shortEventDescription}</Typography>
            {now && epgStart && epgStop ? (
                <LinearProgress startTime={epgStart} stopTime={epgStop}></LinearProgress>
            ) : null}
        </div>
    );
};

export default React.memo(MEPGProgram);
