import { PanelType } from 'src/state/stores/dashboard/dashboard';
import styles from './heroPanel.module.scss';
import usePanel from 'src/controllers/panel.controller';
import { getRailHeight } from '../../Card/cardsizes';
import HeroCards from '../HeroCards';
import useOLRUD from 'src/lib/OLRUD/controllers/oLRUD.controller';

export interface PanelProps {
    panel: PanelType;
}

const railHeight = getRailHeight('hero');
const HeroPanel = (props: PanelProps) => {
    const { panel } = props;
    const { bucketKey, layout, id: panelId, maxTileCount } = panel;
    const { assets } = usePanel({ bucketKey, maxTileCount, layout });
    const { focusedPanelIndex, active } = useOLRUD(0);

    const focused = active && focusedPanelIndex === 0;
    const heroPanelClass = ['hero-carousel', styles.heroPanel].join(' ');

    return (
        <div className={heroPanelClass} style={{ height: railHeight }}>
            <HeroCards
                assets={assets}
                cardLayout={layout}
                panelId={panelId}
                panelIndex={0}
                focused={focused}
            />
        </div>
    );
};

export default HeroPanel;
