import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { isLayoutHero } from 'src/utilities/dashboard';
import { processImageUrl } from 'src/utilities/url';
import { showOverlayAndLeftAlignActionsBasedOnTags } from 'src/utilities/asset';
import LivePill from '../../LivePill';
import PlayPill from '../../PlayPill';
import PublishTime from '../../PublishTime';
import { getCardSize } from '../cardsizes';
import styles from './bodies.module.scss';
import ShowIcon from '../../ShowIcon';
import useDisasterMode from 'src/controllers/disasterRecovery.controller';
import DurationPill from '../../DurationPill';
import { VIEW_ALL_CARD } from 'src/constants/assetTypes';
import useTheme from 'src/controllers/theme.controller';
import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';

interface BodyProps {
    asset: AssetType;
    actionsNeeded: boolean;
    isFreeAsset: boolean;
    cardLayout: string;
    focused: boolean;
    onClick: () => void;
}
const Body = (props: BodyProps) => {
    const { asset, actionsNeeded, isFreeAsset, cardLayout, focused, onClick } = props;
    const {
        title,
        description,
        isLive,
        broadcastStartTime,
        publishStartDate,
        broadcastEndTime,
        match,
        duration,
    } = asset;
    const { theme } = useTheme(asset);
    const { isDisasterMode } = useDisasterMode();

    const viewAllCardStyle = asset.type === VIEW_ALL_CARD ? styles.viewAllCardContent : '';
    const cardSize = getCardSize(cardLayout);
    const { imageKey, imgHeight, imgWidth } = cardSize;

    const bgUrl = asset[imageKey];
    const backgroundImage = processImageUrl(bgUrl || '', imgWidth, imgHeight);
    const bgStyle = {
        backgroundImage: `url(${backgroundImage}), url(${stockBackground})`,
    };

    const contentClass = [styles.content, viewAllCardStyle];
    if (styles[cardLayout]) {
        contentClass.push(styles[cardLayout]);
    }

    const cardClasses = [styles.cardBody];
    if (styles[cardLayout]) {
        cardClasses.push(styles[cardLayout]);
    }
    if (focused) {
        cardClasses.push(styles.focused);
    }

    const showTitleAndLeftAlign = showOverlayAndLeftAlignActionsBasedOnTags(asset);
    const shouldShowTimer = (): boolean => !isLayoutHero(cardLayout) && !isDisasterMode;
    const hasShowIcon = () => asset.type === 'show';
    const showDurationPill = () => asset.type !== 'game-nostats';

    return (
        <>
            <div className={cardClasses.join(' ')} onClick={actionsNeeded ? undefined : onClick}>
                <div className={styles.bg} style={bgStyle}></div>
                <div className={styles.mask}></div>
                <div className={contentClass.join(' ')}>
                    {showTitleAndLeftAlign ? (
                        <span
                            className={[styles.title, theme === 'light' && styles.light].join(' ')}
                        >
                            {title}
                        </span>
                    ) : null}
                    {shouldShowTimer() ? (
                        isLive ? (
                            <>
                                <ShowIcon></ShowIcon>
                                <LivePill
                                    className={styles.livePill}
                                    matchID={asset.match?.id}
                                    broadcastStartTime={broadcastStartTime}
                                    broadcastEndTime={broadcastEndTime}
                                />
                            </>
                        ) : asset.type !== VIEW_ALL_CARD ? (
                            <>
                                {hasShowIcon() ? <ShowIcon></ShowIcon> : null}
                                <div className={styles.timings}>
                                    <PublishTime
                                        className={styles.publishPill}
                                        publishStartDate={broadcastEndTime ?? publishStartDate}
                                        isMatch={!!match?.id}
                                    ></PublishTime>
                                    {showDurationPill() && <DurationPill duration={duration} />}
                                </div>
                            </>
                        ) : null
                    ) : showTitleAndLeftAlign ? (
                        <span
                            className={[styles.description, theme === 'light' && styles.light].join(
                                ' '
                            )}
                        >
                            {description}
                        </span>
                    ) : null}
                </div>
            </div>
            {isFreeAsset ? <PlayPill className={styles.playPill} /> : null}
        </>
    );
};

export default Body;
