import axios from 'axios';
import { CONFIG } from 'src/config/config';
import { getAppVersion } from 'src/utilities/app';
import { log } from 'src/utilities/console';
import { getDeviceDetails, getDeviceId, getOSVendor } from 'src/utilities/device';
import urls from './urls';
import { REQUIRE_AUTH } from 'src/utilities/http';

export const postAuthInit = () => {
    const oSVendor = getOSVendor();
    const dData = {
        deviceId: getDeviceId(),
        deviceName: `${oSVendor.vendor} ${getDeviceDetails().deviceModel}`,
        vendor: oSVendor.vendor,
        model: getDeviceDetails().deviceModel,
        osName: oSVendor.os,
        osVersion: getDeviceDetails().osVersion,
    };
    log('AUTH DATA', dData);
    return () => axios.post(urls.login_inititate, dData);
};

export const postAuthPoll = (deviceCode: string) => {
    const dData = {
        deviceCode,
    };
    return () => axios.post(urls.login_poll, dData);
};

export const getUserDetail = (userID: string) => {
    const url = urls.user_detail.replace('<userId>', userID);
    return () => axios.get(url, REQUIRE_AUTH);
};

export const postRefreshToken = (refreshToken: string) => {
    const payload = {
        refreshToken,
    };
    const url = urls.token_refresh;
    return () => axios.post(url, payload);
};

export const logoutUser = (userID: string) => {
    const url = urls.logout_user
        .replace('<userId>', userID)
        .replace('<platform>', CONFIG.platform.toLowerCase())
        .replace('<deviceId>', getDeviceId())
        .replace('<appVersion>', getAppVersion());
    return () => axios.delete(url, REQUIRE_AUTH);
};

export const globalLogoutUser = (
    userID: string,
    accessToken: string,
    idToken: string,
    tokenType: string
) => {
    const url = urls.global_logout_user
        .replace('<userId>', userID)
        .replace('<appVersion>', getAppVersion());

    const customRequest = axios.create();
    customRequest.defaults.headers.common['AccessAuthorization'] = accessToken;
    customRequest.defaults.headers.common['Authorization'] = `${tokenType} ${idToken}`;

    return () => customRequest.delete(url);
};
