import { CONFIG } from 'src/config/config';
import { BitmovinOptusPlayer } from '..';
import { PlayerClass } from '../player';

export function ff(this: PlayerClass) {
    if (this.player?.isLive()) {
        let timeShift = this.getCurrentTime() + CONFIG.seekJumpTime;
        if (timeShift < 0) {
            BitmovinOptusPlayer.seek(timeShift);
        } else {
            BitmovinOptusPlayer.seek(0);
        }
    } else {
        const duration = this.getDuration();
        const seekTime = this.getCurrentTime() + CONFIG.seekJumpTime;
        if (this.videoElm) {
            if (seekTime > duration) {
                this.seek(duration - 1);
            } else {
                this.seek(seekTime);
            }
        }
    }
}
