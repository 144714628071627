import { memo } from 'react';
import useDisasterMode from 'src/controllers/disasterRecovery.controller';
import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { showOverlayAndLeftAlignActionsBasedOnTags } from 'src/utilities/asset';
import { isLayoutHero } from 'src/utilities/dashboard';
import { checkifUpcomingLiveOrPast } from 'src/utilities/time';
import { processImageUrl } from 'src/utilities/url';
import LivePill from '../../LivePill';
import CompetitionBg from '../../Match/competition/CompetitionBg';
import CompetitionIcon from '../../Match/competition/CompetitionIcon';
import Team from '../../Match/Team';
import PlayPill from '../../PlayPill';
import PublishTime from '../../PublishTime';
import CardActions from '../CardActions';
import { getCardSize } from '../cardsizes';
import styles from './bodies.module.scss';
import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';

interface BodyProps {
    actionsNeeded: boolean;
    asset: AssetType;
    cardLayout: string;
    focused: boolean;
    isFreeAsset: boolean;
    onClick: () => void;
}
const MatchBody = (props: BodyProps) => {
    const { asset, cardLayout, isFreeAsset, actionsNeeded, focused, onClick } = props;
    const {
        isLive,
        description,
        publishStartDate,
        broadcastStartTime,
        broadcastEndTime,
        match,
        type,
    } = asset;
    const { competition, awayTeam, homeTeam } = match || {};
    const { id: competitionId } = competition || {};
    const { logoUrl: awayTeamLogo, shortName: aTshortName } = awayTeam || {};
    const { logoUrl: homeTeamLogo, shortName: hTshortName } = homeTeam || {};

    const cardClasses = [styles.cardBody, styles.match];
    const contentClass = [styles.content];

    const { isDisasterMode } = useDisasterMode();

    if (styles[cardLayout]) {
        cardClasses.push(styles[cardLayout]);
    }

    if (focused) {
        cardClasses.push(styles.focused);
    }

    const teamClasses = [styles.team];
    if (styles[cardLayout]) {
        teamClasses.push(styles[cardLayout]);
    }

    const shouldLeftAlign = showOverlayAndLeftAlignActionsBasedOnTags(asset);

    const isHero = isLayoutHero(cardLayout);
    const shouldShowTimer = () => !isHero && !isDisasterMode;
    const publishDate = broadcastEndTime ?? publishStartDate;

    const cardSize = getCardSize(cardLayout);
    const { imageKey, imgHeight, imgWidth } = cardSize;
    const bgUrl = asset[imageKey];

    const backgroundImage = processImageUrl(bgUrl || '', imgWidth, imgHeight);
    const bgStyle = {
        backgroundImage: `url(${backgroundImage}), url(${stockBackground})`,
    };

    return (
        <>
            <div className={cardClasses.join(' ')} onClick={actionsNeeded ? undefined : onClick}>
                {competitionId ? (
                    <CompetitionBg
                        className={styles.bg}
                        competitionId={competitionId}
                    ></CompetitionBg>
                ) : null}
                {isHero && <div className={styles.bg} style={bgStyle} />}
                <div className={styles.mask}></div>
                <div className={contentClass.join(' ')}>
                    {competitionId ? (
                        <CompetitionIcon
                            competitionId={competitionId}
                            className={styles.competitionIcon}
                        />
                    ) : null}
                    <div className={styles.matchInfo}>
                        <div className={styles.teams}>
                            <Team logoUrl={homeTeamLogo!} className={teamClasses.join(' ')} />
                            <Team logoUrl={awayTeamLogo!} className={teamClasses.join(' ')} />
                        </div>
                        <div className={styles.info}>
                            {!isHero && (
                                <div className={styles.title}>
                                    <span>{`${hTshortName}`}</span>
                                    <br />
                                    <span>{`v ${aTshortName}`}</span>
                                </div>
                            )}
                            {shouldShowTimer() ? (
                                isLive ? (
                                    <LivePill
                                        className={styles.livePill}
                                        matchID={asset.match?.id}
                                        broadcastStartTime={broadcastStartTime}
                                        broadcastEndTime={broadcastEndTime}
                                    />
                                ) : (
                                    <PublishTime
                                        className={styles.publishPill}
                                        publishStartDate={publishDate}
                                        isMatch={!!match?.id}
                                    ></PublishTime>
                                )
                            ) : (
                                !isHero && <span className={styles.description}>{description}</span>
                            )}
                        </div>
                    </div>
                    {actionsNeeded ? (
                        <CardActions
                            action={checkifUpcomingLiveOrPast(
                                broadcastStartTime ?? publishStartDate,
                                broadcastEndTime ?? publishStartDate
                            )}
                            assetId={asset.id}
                            leftAlign={shouldLeftAlign}
                            onClick={onClick}
                            isShow={type === 'show'}
                        ></CardActions>
                    ) : null}
                </div>
            </div>
            {isFreeAsset && !isHero ? <PlayPill className={styles.playPill} /> : null}
        </>
    );
};

export default memo(MatchBody);
