import { Dispatch } from 'react';
import { CONFIG } from 'src/config/config';
import { Action } from 'src/state/reducers.d';
import getNetworkStatusInstance from 'src/utilities/network';
import { FULLFILLED, PENDING, REJECTED, nonApiCallingActions } from './constants';
import { actionRunner } from './utils/actionRunner';

const networkStatus = getNetworkStatusInstance();

const promiseMiddleware = (dispatch: Dispatch<Action>) => (actiont: Action) => {
    const { type, payload } = actiont;

    const isPayloadFunction = typeof payload === 'function';
    const isPayloadAction = payload && typeof payload === 'object' && payload.action;

    if (!(isPayloadAction || isPayloadFunction)) {
        return Promise.resolve(dispatch(actiont));
    }

    let payloadForPendingDispatch = null;
    let p = payload.pending || undefined;
    if (payload.meta) {
        payloadForPendingDispatch = {
            payload: p,
            meta: payload.meta,
        };
    } else {
        payloadForPendingDispatch = p;
    }

    dispatch({
        type: `${type}_${PENDING}`,
        payload: payloadForPendingDispatch,
    });

    return new Promise((resolve, reject) => {
        const _action = payload.action || payload;
        // @NOTE: if payload.retry = false in our Actions, `reTryOnError` becomes false and automatically
        // hits the `networkMaxRetry`value thus no longer retrying subsequently.
        const reTryOnError = payload.retry !== undefined ? payload.retry : true;

        const ar = actionRunner(_action, reTryOnError ? 0 : CONFIG.networkMaxRetry, payload);

        ar.then((response: any) => {
            if (!nonApiCallingActions.includes(type)) {
                networkStatus.clearError();
            }
            dispatch({
                type: `${type}_${FULLFILLED}`,
                payload: response,
            });
            resolve(response);
        }).catch(async (reason: any) => {
            let payload = reason;
            try {
                payload = reason.payload;
            } catch (error) {}
            dispatch({ type: `${type}_${REJECTED}`, payload });
            let status = null;
            try {
                status = reason && reason.http && reason.http.response.status;
            } catch (error) {}
            await getNetworkStatusInstance().checkIfOffline(status);
            reject(reason);
        });
        return ar;
    });
};

export default promiseMiddleware;
