export const processUrl = (url: string, key: string, value: string) => {
    return url.replace(key, value);
};

export const processImageUrl = (
    url: string,
    width: number = window.innerWidth,
    height: number = window.innerHeight
) => {
    // eslint-disable-next-line no-template-curly-in-string
    return url ? processUrl(url, '${width x height}', `${width}x${height}`) : '';
};
