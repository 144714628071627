import RouteTags from 'src/routes/routeTags';
import { useMatch } from 'react-router-dom';

const useViewAll = () => {
    const isDashboardViewAll = !!useMatch(RouteTags.dashboardViewAll);
    const isExploreViewAll = !!useMatch(RouteTags.dashboardExploreViewAll);
    const isViewAll = isDashboardViewAll || isExploreViewAll;

    return { isViewAll };
};
export default useViewAll;
