import { CONFIG } from 'src/config/config';
import { BitmovinOptusPlayer, Mux } from '..';
import { PlayerClass } from '../player';

export function initTracking(this: PlayerClass, sessionId?: string | null) {
    if (sessionId) {
        BitmovinOptusPlayer.playerSession.setCustomViewingSessionId(sessionId);
    }
    try {
        return BitmovinOptusPlayer.playerSession.getViewingSessionId();
    } catch (error) {
        if (this.isMUXEnabled) {
            Mux.configure(CONFIG.MUXENVKey, CONFIG.MUXDebug);
        }
        return this.generateSessionId();
    }
}
