import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { getDurationString } from 'src/utilities/time';

export const useLiveTime = (broadcastStartTime: string, broadcastEndTime?: string) => {
    const [fDuration, setfDuration] = useState(getDurationString(broadcastStartTime));
    const liveTimer = useRef<any>(null);

    useEffect(() => {
        // CTV-664 - Ensure Interval is cleared when broadcastEndTime has elapsed
        if (broadcastEndTime && DateTime.now() > DateTime.fromISO(broadcastEndTime)) {
            clearInterval(liveTimer.current);
            liveTimer.current = null;
            setfDuration('');
        } else {
            liveTimer.current = setInterval(() => {
                setfDuration(getDurationString(broadcastStartTime));
            }, 1000);
        }
        return () => {
            clearInterval(liveTimer.current);
            liveTimer.current = null;
        };
    }, [broadcastStartTime, broadcastEndTime]);
    return fDuration;
};
