import * as B from '@braze/web-sdk';
import { CONFIG } from 'src/config/config';
import { UserType } from 'src/state/stores/user/user';
import { getAppVersion } from 'src/utilities/app';
import { getDeviceId } from 'src/utilities/device';
import { noop } from 'src/utilities/op';
import { EventCallbacks, EventTypes, InitOptions, Message } from './braze';
import { BRAZE_ROOT_ELM_ID, LOGGED_OUT_USER_TYPE } from './constants';
import { Options, validateCC } from 'src/utilities/contentCards';
import { BannerType } from 'src/state/stores/dashboard/dashboard.d';
// console.log('BRAZE FLAG:', !CONFIG.BrazeOnlyForLoggedInUserActive ? 'ALL' : 'ONLY LOGGED USER');
class BrazeClass {
    message: B.InAppMessage | B.ControlMessage | null = null;
    options: InitOptions = { onMessageHidden: noop, onMessageShown: noop };
    isInitialized: boolean = false;
    eventsQueue: (() => void)[] = [];
    hideTimer: ReturnType<typeof setTimeout> | null = null;
    contentCards: B.ContentCards | null = null;
    contentCardsIds: Set<string> = new Set();

    eventCbs: EventCallbacks = {};

    // initialize the SDK
    init = (options: InitOptions) => {
        this.setOptions(options);

        if (this.isInitialized) {
            return true;
        }

        console.log('BRAZE: ENABLE SDK');
        B.enableSDK();

        console.log('BRAZE: INIT');
        const success = B.initialize(CONFIG.BrazeApiKey, {
            baseUrl: CONFIG.BrazeBaseUrl,
            appVersion: getAppVersion(),

            allowUserSuppliedJavascript: true,
            // these options are necessary because Samsung TVs do not support the web push standard that the SDK expects
            disablePushTokenMaintenance: true,
            manageServiceWorkerExternally: true,
            // change this to allow in-app messages to trigger more frequently
            minimumIntervalBetweenTriggerActionsInSeconds: 10,

            // Prevent the message from closing on escape key
            requireExplicitInAppMessageDismissal: true,

            enableLogging: CONFIG.BrazeDebug,
        });

        this.isInitialized = success;
        if (success) {
            if (CONFIG.BrazeInAppMessagesActive) {
                this.subscribeToInAppMessage();
            }

            if (CONFIG.BrazeContentCardActive) {
                this.subscribeToContentCards();
                this.getCachedContentCards();
                this.refreshContentCards();
            }
        }
        return success;
    };

    destroy = () => {
        console.log('BRAZE: DESTROY');
        if (!B) {
            return;
        }
        B.destroy();
    };

    setOptions = ({ onMessageShown, onMessageHidden }: InitOptions) => {
        this.options = {
            onMessageShown: onMessageShown || this.options.onMessageShown,
            onMessageHidden: onMessageHidden || this.options.onMessageHidden,
        };
    };

    getCachedContentCards = () => {
        const cachedContentCards = B.getCachedContentCards();
        this.handleNewContentCards(cachedContentCards);
        return cachedContentCards;
    };

    refreshContentCards = () => {
        B.requestContentCardsRefresh();
    };

    handleNewContentCards = (contentCards?: B.ContentCards) => {
        if (contentCards) {
            this.contentCards = contentCards || this.contentCards;
        }
        this.contentCardsIds.clear();
        this.getContentCardIds().forEach((id) => this.contentCardsIds.add(id));
        if (this.eventCbs['onContentCard']) {
            this.eventCbs['onContentCard'].forEach((cb) => {
                cb(Array.from(this.contentCardsIds));
            });
        }

        return contentCards;
    };

    subscribeToContentCards = () => {
        B.subscribeToContentCardsUpdates((contentCards) => {
            this.handleNewContentCards(contentCards);
        });
    };

    getContentCardIds = () => {
        const ids: string[] = [];
        const cc = this.contentCards?.cards || [];
        cc.forEach((cc) => {
            // @ts-ignore
            const { extras, description } = cc;

            // TODO: Blindly setting these based on assumptions, fix when required.
            if (extras && (extras.asset_id || extras['asset_id'])) {
                ids.push(extras.asset_id);
            }

            const cleanID = description
                .split(',')
                .map((id: string) => id.trim().replace(/^["'](.+(?=["']$))["']$/, '$1'));
            ids.push(...cleanID);
        });
        return ids;
    };

    subscribeToInAppMessage = () => {
        B.subscribeToInAppMessage((inAppMessage) => {
            this.message = inAppMessage;
            let shown = false;

            this.addMessageEventHandlers();
            console.log('NEW MESSAGE: ', inAppMessage);
            if (this.message instanceof B.HtmlMessage) {
                // this.getContainer()!.innerHTML = this.message.message as string;
                // shown = true;
                // Not Safe, lot of issues.
            } else if (this.message instanceof B.ModalMessage) {
                shown = this.showMessage();
            } else if (this.message instanceof B.SlideUpMessage) {
                shown = this.showMessage();
            } else if (this.message instanceof B.FullScreenMessage) {
                shown = this.showMessage();
            } else {
                // shown = B.showInAppMessage(inAppMessage, this.getContainer());
            }

            if (shown) {
                B.logInAppMessageImpression(inAppMessage);
            }
        });
    };

    showMessage = () => {
        let shown = false;
        if (this.message) {
            if (CONFIG.BrazeInAppMessagesHeadlessActive) {
                const _mMessage = this.message as unknown;
                const mMessage = _mMessage as Message;
                let bannerType: BannerType = 'NO_BUTTON';

                // @ts-ignore
                const { buttons, uri: cardUri, clickAction: cardClickAction } = this.message;

                let cardAction = cardClickAction;
                let primaryButtonClickAction = undefined;
                let primaryButtonUri = undefined;
                let primaryButtonLabel = undefined;
                let secondaryButtonClickAction = undefined;
                let secondaryButtonUri = undefined;
                let secondaryButtonLabel = undefined;

                // @ts-ignore
                const buttonsCount = this.message.buttons.length;

                if (buttonsCount === 1) {
                    bannerType = 'ONE_BUTTON';
                    // @ts-ignore
                    const button = buttons[0];
                    ({
                        clickAction: primaryButtonClickAction,
                        uri: primaryButtonUri,
                        text: primaryButtonLabel,
                    } = button);
                } else if (buttonsCount === 2) {
                    bannerType = 'TWO_BUTTONS';
                    // @ts-ignore
                    const [buttonPrim, buttonSec] = buttons;
                    ({
                        clickAction: primaryButtonClickAction,
                        uri: primaryButtonUri,
                        text: primaryButtonLabel,
                    } = buttonPrim);
                    ({
                        clickAction: secondaryButtonClickAction,
                        uri: secondaryButtonUri,
                        text: secondaryButtonLabel,
                    } = buttonSec);
                }

                const options: Options = {
                    bannerType,
                    cardClickAction: cardAction === 'URI' ? cardUri : cardAction,
                    primaryButtonAction:
                        primaryButtonClickAction === 'URI'
                            ? primaryButtonUri
                            : primaryButtonClickAction,
                    primaryButtonLabel,
                    secondaryButtonAction:
                        secondaryButtonClickAction === 'URI'
                            ? secondaryButtonUri
                            : secondaryButtonClickAction,
                    secondaryButtonLabel,
                };

                console.log('Message Incoming:', this.message, validateCC(options));
                if (validateCC(options, true)) {
                    this.options.onMessageShown(mMessage);
                    shown = true;
                    if (mMessage.dismissType === 'AUTO_DISMISS' && mMessage.duration) {
                        this.hideTimer = setTimeout(() => {
                            this.hideMesage();
                        }, mMessage.duration);
                    }
                }
            } else {
                shown = B.showInAppMessage(this.message, this.getContainer());
                if (shown) {
                    this.options.onMessageShown();
                }
            }
        }
        return shown;
    };

    addMessageEventHandlers = () => {
        this.getButtons().forEach((b) => {
            // @ts-ignore
            b.subscribeToClickedEvent(() => {
                this.hideMesage();
            });
        });

        // @ts-ignore
        this.message.subscribeToDismissedEvent(() => {
            this.hideMesage();
        });
    };

    getButtons = () => {
        const message = this.message;
        // @ts-ignore
        const { buttons = [] } = message || {};
        return buttons as B.InAppMessageButton[];
    };

    getContainer = () => {
        return document.getElementById(BRAZE_ROOT_ELM_ID);
    };

    getUser = () => {
        return B.getUser();
    };

    setUser = (user?: UserType) => {
        const id = (user && user.analyticUserId) || getDeviceId();

        if (id && id !== B.getUser()?.getUserId()) {
            console.log('BRAZE: CHANGE USER');
            B.changeUser(id);
        }

        if (user) {
            this.setUserAttributes(user);
        }
        // else {
        //     this.setLoggedOutUserAttributes();
        // }

        console.log(
            'BRAZE: OPEN SESSION',
            id === getDeviceId() ? 'Device ID: ' : 'User ID: ',
            B.getUser()?.getUserId()
        );
        B.openSession();
        this.processQueue();
    };

    setUserAttributes = (user: UserType) => {
        const currentUser = B.getUser();

        if (!currentUser) {
            return;
        }
        const firstName = user.name?.split(' ').slice(0, -1).join(' ') || '';
        const userType = (user.type && user.type.toLowerCase()) || '';
        if (firstName) {
            currentUser.setFirstName(firstName);
        }
        if (userType) {
            currentUser.setCustomUserAttribute('userType', userType);
        }
    };

    setLoggedOutUserAttributes = () => {
        const currentUser = B.getUser();
        if (!currentUser) {
            return;
        }
        currentUser.setFirstName('');
        // currentUser.setCustomUserAttribute('userType', 'basic');
        currentUser.setCustomUserAttribute('userType', LOGGED_OUT_USER_TYPE);
    };

    disable = () => {
        console.log('BRAZE: DISABLE');
        B.disableSDK();
    };

    logout = () => {
        console.log('BRAZE: WIPE DATA');
        B.wipeData();
    };

    track = (eventName: string, params: object = {}) => {
        if (this.isInitialized) {
            B.logCustomEvent(eventName, params);
            this.refreshContentCards();
        } else {
            this.eventsQueue.push(() => {
                this.track(eventName, params);
            });
        }
    };

    processQueue = () => {
        while (this.eventsQueue.length) {
            const t = this.eventsQueue.pop();
            if (t) {
                t();
            }
        }
    };

    clearEventsQueue = () => {
        this.eventsQueue.length = 0;
    };

    hideMesage = () => {
        const conatainer = this.getContainer();
        if (conatainer) {
            conatainer.innerText = '';
        }
        this.options.onMessageHidden();
        if (this.hideTimer) {
            clearTimeout(this.hideTimer);
        }
    };

    performAction = (buttonIndex: number, headless = CONFIG.BrazeInAppMessagesHeadlessActive) => {
        const message = this.message;
        const buttons = this.getButtons();
        let actionUri: string | null = null;
        if (message instanceof B.InAppMessage) {
            if (buttons && buttons.length) {
                const button = buttons[buttonIndex];
                const { clickAction = null, uri } = button;
                B.logInAppMessageButtonClick(button, message);
                if (clickAction === 'URI' && uri) {
                    actionUri = uri;
                }
            } else {
                // @ts-ignore
                const { clickAction = null, uri } = message;
                B.logInAppMessageClick(message);
                if (clickAction === 'URI' && uri) {
                    actionUri = uri;
                }
            }
        }
        if (!headless && actionUri) {
            window.location.href = actionUri;
        }
        this.hideMesage();
        return actionUri;
    };

    on = (event: EventTypes, cb: (cards: string[]) => void) => {
        this.eventCbs[event] = this.eventCbs[event] || [];
        this.eventCbs[event].push(cb);
        return () => {
            this.eventCbs[event] = this.eventCbs[event]?.filter((c) => c !== cb);
        };
    };
}

const Braze = new BrazeClass();
export default Braze;

// @ts-ignore
window.B = Braze;
