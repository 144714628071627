import { AssetType, CardLayoutType, WatchModeLiveType } from 'src/state/stores/dashboard/dashboard';
import styles from './heroCard.module.scss';
import React, { useCallback } from 'react';
import { getCardSize } from '../../Card/cardsizes';
import useOLRUDonSelect from 'src/lib/OLRUD/controllers/oLRUDonSelect.controller';
import HeroMatchBody from './HeroBodies/HeroMatchBody';
import HeroShowBody from './HeroBodies/HeroShowBody';
import useAsset from 'src/controllers/asset.controller';
import { pxScale } from 'src/utilities/styles';
import { Analytics } from 'src/lib/Analytics';
import usePanels from 'src/controllers/panels.controller';
import usePageTitle from 'src/controllers/dashboard/page.title.controller';
import { PanelPositionType } from 'src/lib/Analytics/analytics';

export interface CardProps {
    asset: AssetType;
    cardLayout: CardLayoutType;
    panelId: string;
    cardIndex: number;
    selectedCardIndex: number;
    cardsLen: number;
    focused: boolean;
}

const cardSize = getCardSize('hero');
const { height, width } = cardSize;

const HeroCard = (props: CardProps) => {
    const { asset, panelId, cardIndex, selectedCardIndex, cardsLen, focused } = props;

    const { panels } = usePanels();
    const pageTitle = usePageTitle();

    const { id, type } = asset;
    const selected = selectedCardIndex === cardIndex;
    const firstEdge = cardIndex === cardsLen - 1 && selectedCardIndex === 0;
    const lastEdge = cardIndex === 0 && selectedCardIndex === cardsLen - 1;
    const isOnRight = (cardIndex > selectedCardIndex || lastEdge) && !firstEdge;
    const translateX = pxScale(selected ? 0 : isOnRight ? 1920 : -1920);

    const cardStyle = {
        width,
        height,
        zIndex: selected ? 2 : 1,
        transform: `translate3d(${translateX}px, 0, 0)`,
        // transform: `translateX(${translateX}px)`,
    };

    const { selectAsset } = useAsset();

    const onClick = useCallback(
        (watchMode?: WatchModeLiveType) => {
            selectAsset(id, asset.type, watchMode);

            const { title, type } = panels[0];
            const panel: PanelPositionType = {
                index: 0,
                title,
                type,
            };
            Analytics.logAssetSelection(asset, cardIndex + 1, panelId, panel, pageTitle);
        },
        [asset, cardIndex, id, pageTitle, panelId, panels, selectAsset]
    );
    useOLRUDonSelect(false, onClick);
    return (
        <div className={styles.card} style={cardStyle} data-asset-type={asset.type}>
            {type === 'game' ? (
                <HeroMatchBody asset={asset} onClick={onClick} focused={focused} />
            ) : (
                <HeroShowBody asset={asset} onClick={onClick} focused={focused} />
            )}
        </div>
    );
};
export default React.memo(HeroCard);
