import { FocusNode, useSetFocus } from 'lal-lrud';
import React, { useEffect, useRef, useState } from 'react';
import SettingsBody from 'src/components/ui/Settings/SettingsBody';
import Sidebar from 'src/components/ui/Sidebar';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import styles from './settings.module.scss';
import { exitApp } from 'src/utilities/device';
import focusIds from 'src/constants/focusIds';
import { CONFIG } from 'src/config/config';
import FullscreenPopup from 'src/components/ui/FullscreenPopup';

function Settings() {
    const setFocus = useSetFocus();
    const [focusIndex, setFocusIndex] = useState(1);
    const [displayExitPopup, setDisplayExitPopup] = useState<boolean>(false);
    const sideBarOnFocus = useRef(false);

    useEffect(() => {
        setFocus(focusIds.setttingsBody);
    }, [setFocus]);

    let callBack = () => {
        if (sideBarOnFocus.current) {
            if (CONFIG.exitAppPopupEnabled) {
                setDisplayExitPopup(true);
            } else {
                exitApp();
            }
            return true;
        }
        setFocus(focusIds.sidebar);
        setDisplayExitPopup(false);
        return true;
    };
    useBackButton({ callBack, id: 'settings-focus-sidebar', rank: 4 });

    const onMove = (e: any) => {
        setFocusIndex(e.nextChildIndex);
    };
    const onSideBarFocus = (e: any) => {
        sideBarOnFocus.current = true;
    };
    const onSideBarBlur = () => {
        sideBarOnFocus.current = false;
    };
    const onPopupExit = () => {
        setDisplayExitPopup(false);
    };

    const onExitApp = () => {
        setDisplayExitPopup(false);
        exitApp();
    };

    return (
        <FocusNode className={styles.dashboard} onMove={onMove} defaultFocusChild={focusIndex}>
            {displayExitPopup && (
                <FullscreenPopup
                    id={'app.exitApp'}
                    onClick={onExitApp}
                    onClickSecondary={onPopupExit}
                    onExit={onPopupExit}
                />
            )}
            <FocusNode
                focusId={focusIds.sidebar}
                onFocused={onSideBarFocus}
                onBlurred={onSideBarBlur}
            >
                <Sidebar></Sidebar>
            </FocusNode>
            <FocusNode focusId={focusIds.setttingsBody} className={styles.settings}>
                <SettingsBody></SettingsBody>
            </FocusNode>
        </FocusNode>
    );
}

export default React.memo(Settings);
