import { FocusNode } from 'lal-lrud';
import React, { useState } from 'react';
import LeftSideContainer from '../LeftSideContainer';
import RightSideContainer from '../RightSideContainer';
import { useForceExit } from 'src/controllers/forceExitKeys.controller';
import styles from './settingsBody.module.scss';

function SettingsBody() {
    const [focusIndex, setFocusIndex] = useState(0);
    const onGridMove = (e: any) => {
        setFocusIndex(e.nextChildIndex);
    };
    useForceExit();

    return (
        <FocusNode
            elementType="div"
            orientation="horizontal"
            className={styles.body}
            onMove={onGridMove}
            defaultFocusChild={focusIndex}
        >
            <LeftSideContainer></LeftSideContainer>
            <RightSideContainer></RightSideContainer>
        </FocusNode>
    );
}

export default React.memo(SettingsBody);
