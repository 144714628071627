import { QualityLabelType } from '../player.d';
import { PlayerClass } from '../player';
import { errorLog } from 'src/utilities/console';
import Storage from 'src/utilities/storage';

export function setQuality(this: PlayerClass, qLabel: QualityLabelType) {
    Storage.setItem('vq', qLabel);
}

export function setSpeed(this: PlayerClass, speed: number) {
    this.player?.setPlaybackSpeed(speed);
}

export function setAudioTrack(this: PlayerClass, id: string) {
    this.player?.setAudio(id);
}

export function setSubtitleTrack(this: PlayerClass, id?: string) {
    const tracks = this.getSubtitleTracks();
    tracks.forEach((t) => {
        if (t.id === id) {
            this.player.subtitles.enable(t.id);
        } else {
            this.player.subtitles.disable(t.id);
        }
    });
}

// Fire Callbacks on Error
export function setError(this: PlayerClass, error: string | Error | number | any) {
    if (error && !this.error) {
        this.pause();
        this.playerState = 'ERROR';
        errorLog(error);
        this.error = error;
        this.errorCbs.forEach((cb) => {
            cb(this.error);
        });
    } else if (!error) {
        this.error = null;
        this.playerState = 'READY_FOR_INIT';
        this.errorCbs.forEach((cb) => {
            cb(this.error);
        });
    } else {
        this.error = error;
    }
}

export function setPlayerContainerElm(this: PlayerClass, containerElm: HTMLDivElement) {
    this.containerElm = containerElm;
}

export function setYospaceErrorCallback(this: PlayerClass, cb: () => void): void {
    // set up the callback which will be executed upon yospace error.
    // setYospaceErrorCallback functioon is typically called by consumers such as vodPlayback.controller.ts
    this.yospaceCallback = cb;
}
