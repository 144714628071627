import { BufferType, MediaType, PlayerAPI, PlayerEvent } from '.';

/**
 *
 * @param bitmovinPlayer Bitmovin Player instance.
 * @param config Optional config for the StallDetector. Also all properties are optional.
 * @param config.logger Callback method which will receive log messages (just one param as string)
 * @param config.watchdogIntervalMs Time in milliseconds the watchdog will wait before re-evaluating. Time update from video element on the
 * Foxtel iQ4 seems to range from 200ms to 450ms at least. Default is 350ms.
 * @param config.nudgeMaxRetry Maximum amount of tries the video element is nudged at a single stuck point. Default is 3.
 * @param config.nudgeOffset Time in seconds the currentTime of the video element is set into the future of the current position.
 * Default is 0.02secs (=1 frame at 50fps).
 * @param config.minBuffer Seconds of forward buffer that is still considered as "having buffer". Consider that buffers aren't always
 * completely empty when a stall happens (e.g. audio is there but no video data as segments are often not fully aligned). Default is 2secs.
 * @returns
 */
function StallDetector(
    bitmovinPlayer: PlayerAPI,
    config: {
        logger: (msg: string) => void;
        nudgeMaxRetry?: number;
        nudgeOffset?: number;
        minBuffer?: number;
    }
) {
    const player = bitmovinPlayer;

    config = config || {};
    const logger = config.logger || (() => {});
    const watchdogIntervalMs = 350;
    const nudgeMaxRetry = config.nudgeMaxRetry || 3;
    const nudgeOffset = config.nudgeOffset || 0.02;
    const minBuffer = config.minBuffer || 2;

    logger(`Setting up StallDetector`);

    const videoElement = player.getVideoElement();
    let lastCurrentTime: number | undefined = undefined;
    let nudgeRetry = 0;
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    let isStuck = false;
    let isSeeking = false;

    player.on(PlayerEvent.TimeChanged, timeChangedHandler);
    player.on(PlayerEvent.Seek, seekStartHandler);
    player.on(PlayerEvent.TimeShift, seekStartHandler);
    player.on(PlayerEvent.Seeked, seekEndHandler);
    player.on(PlayerEvent.TimeShifted, seekEndHandler);
    player.on(PlayerEvent.StallEnded, stallEndedHandler);

    function timeChangedHandler() {
        lastCurrentTime = videoElement.currentTime;
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(watchdog, watchdogIntervalMs);
    }

    function stallEndedHandler() {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(watchdog, watchdogIntervalMs / 2);
    }

    function seekStartHandler() {
        isSeeking = true;
    }

    function seekEndHandler() {
        isSeeking = false;
    }

    function hasDataBuffered() {
        const videoBufferLevel = player.buffer.getLevel(
            BufferType.ForwardDuration,
            MediaType.Video
        );
        const audioBufferLevel = player.buffer.getLevel(
            BufferType.ForwardDuration,
            MediaType.Video
        );
        // audio only content would have a videoBufferLevel.targetLevel of 0
        const hasVideoBuffer =
            videoBufferLevel.targetLevel > 0 ? videoBufferLevel.level! > minBuffer : true;
        // video only content would have an audioBufferLevel.targetLevel of 0
        const hasAudioBuffer =
            audioBufferLevel.targetLevel > 0 ? audioBufferLevel.level! > minBuffer : true;

        return hasVideoBuffer && hasAudioBuffer;
    }

    function shouldPlaybackHappen() {
        const isStalled = player.isStalled();
        const isPlaying = player.isPlaying();
        const hasEnded = player.hasEnded();

        return isPlaying && !isStalled && !hasEnded && !isSeeking;
    }

    function watchdog() {
        // Let's use the video element directly to avoid any side effects in and of the player as this shouldn't be considered a seek.
        const currentTime = videoElement.currentTime;

        const hasBuffer = hasDataBuffered();
        const shouldBePlaying = shouldPlaybackHappen();
        const currentTimeChanged = currentTime !== lastCurrentTime;

        logger(
            `watchdog handler: shouldBePlaying=${shouldBePlaying}, hasBuffer=${hasBuffer} timeChanged=${currentTimeChanged}, isStuck=${isStuck}`
        );

        if (shouldBePlaying && hasBuffer) {
            if (isStuck && currentTimeChanged) {
                // looks like we recovered, all good.
                logger(`Recovered after ${nudgeRetry} of max ${nudgeMaxRetry} nudges.`);
                isStuck = false;
                nudgeRetry = 0;
            }
            if (!currentTimeChanged) {
                logger(
                    `Current time didn't change, looks we're stuck. Trying to nudge ${
                        nudgeRetry + 1
                    }/${nudgeMaxRetry}`
                );
                isStuck = true;
                tryNudgeBuffer(currentTime);

                lastCurrentTime = videoElement.currentTime;
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(watchdog, watchdogIntervalMs);
            }
        } else {
            logger(`should not be playing anyway (isStuck=${isStuck})`);
        }
    }

    function tryNudgeBuffer(currentTime: number) {
        nudgeRetry++;

        if (nudgeRetry <= nudgeMaxRetry) {
            const targetTime = currentTime + (nudgeRetry + 1) * nudgeOffset;
            // let's nudge currentTime to try to overcome this

            logger(`Nudging 'currentTime' from ${currentTime} to ${targetTime}`);

            // Let's use the video element directly to avoid any side effects in and of the player as this shouldn't be considered a seek.
            // This should also work out of the box for live streams as well.
            videoElement.currentTime = targetTime;
        } else {
            logger(
                `Playhead still not moving while enough data buffered @${currentTime}sec after ${nudgeMaxRetry} nudges`
            );
            player.unload();
        }
    }

    function cleanup() {
        timeoutId ?? clearTimeout(timeoutId);

        player.off(PlayerEvent.TimeChanged, timeChangedHandler);
        player.off(PlayerEvent.Seek, seekStartHandler);
        player.off(PlayerEvent.TimeShift, seekStartHandler);
        player.off(PlayerEvent.Seeked, seekEndHandler);
        player.off(PlayerEvent.TimeShifted, seekEndHandler);
    }

    return {
        cleanup,
    };
}

export default StallDetector;
