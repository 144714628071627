import usePanels from 'src/controllers/panels.controller';
import { getPanelsTops } from 'src/utilities/panel';

const useOLRUDPanelTops = () => {
    const { panels } = usePanels();
    const panelTops = getPanelsTops(panels);
    return { panelTops };
};

export default useOLRUDPanelTops;
