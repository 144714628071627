import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Braze from 'src/lib/braze';

import Button from 'src/components/Common/Button';
import Typography from 'src/components/Common/Typography';
import FullscreenPopup from 'src/components/ui/FullscreenPopup';
import { NotificationKeyType } from 'src/components/ui/FullscreenPopup/notifications';
import LoginOptions from 'src/components/ui/Login/LoginOptions';
import UserCode from 'src/components/ui/Login/UserCode';

import useLogin from 'src/controllers/auth/auth.login.controller';

import { DASHBOARD_ROOT } from 'src/routes/routeTags';
import { CONFIG } from 'src/config/config';
import styles from './login.module.scss';

function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const { state: locationState } = location;
    const { t } = useTranslation(undefined, { keyPrefix: 'login' });
    const { expireProgress, userCode, codeExpired, retry, auth, showGenericError } = useLogin();

    const onLoginClick = () => {
        navigate(DASHBOARD_ROOT, { replace: true, state: locationState });
    };
    const onClick = codeExpired || showGenericError ? retry : undefined;
    const onExit = codeExpired || showGenericError ? retry : undefined;
    let notifictionId: NotificationKeyType | null = null;
    if (codeExpired) {
        notifictionId = 'login.codeExpired';
    } else if (auth) {
        notifictionId = 'login.connectionDetected';
    } else if (showGenericError) {
        notifictionId = 'login.genericError';
    }

    useEffect(() => {
        if (CONFIG.BrazeOnlyForLoggedInUserActive) {
            Braze.clearEventsQueue();
        }
    }, []);

    return (
        <div className={styles.loginContainer}>
            <Typography className={styles.title}>{t('title')}</Typography>
            <LoginOptions />
            <UserCode expireProgress={expireProgress} userCode={userCode} />
            <Button className={styles.loginButton} onClick={onLoginClick}>
                {t('button')}
            </Button>
            {notifictionId ? (
                <FullscreenPopup
                    id={notifictionId}
                    spinner={!!auth}
                    onClick={onClick}
                    onExit={onExit}
                ></FullscreenPopup>
            ) : null}
        </div>
    );
}

export default Login;
