import { DateTime, Settings } from 'luxon';
import { CONFIG } from 'src/config/config';
import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { UserType } from 'src/state/stores/user/user';
import { LOGGED_OUT_USER_TYPE } from '../constants';
import { segmentDebugger } from './debugger';
import { getAppId, getAppVersion, getBuildNumber } from 'src/utilities/app';
import {
    getAdvertParams,
    getApplicationName,
    getDeviceDetails,
    getDeviceId,
} from 'src/utilities/device';

Settings.defaultLocale = 'en';

/**
 * This is a common functions to send events to segment
 * @param eventName Name of the event to be sent
 * @param eventCategory Category of the Event
 * @param params extra params sent as Object
 * @param eventType Type of the Event
 * @returns
 */

export const getAnalytics = () => {
    return window.analytics;
};

export const loadSegmentIO = (url: string, onReady: () => void) => {
    const analytics = getAnalytics();
    if (analytics) {
        analytics.load(url);
        // analytics.page();
        analytics.ready(function () {
            segmentDebugger.log('analytics ready');
            onReady();
        });
    }
};

export const setProperty = (name: string, value: string | number | null, userID?: string) => {
    if (!name || !value) return;
    const analytics = getAnalytics();
    if (getAnalytics()) {
        let param: any = {};
        param.name = value;
        if (userID) {
            analytics.identify(userID, param);
        } else {
            analytics.identify(param);
        }
    }
};

export const getContext = () => {
    const now = DateTime.local();
    return {
        app: {
            name: getApplicationSchema().appName,
            version: getApplicationSchema().appVersion,
            build: getBuildNumber(),
        },
        device: {
            advertisingId: getDeviceSchema().advertId,
            adTrackingEnabled: getDeviceSchema().advertConsent,
            manufacturer: getDeviceSchema().deviceMake,
            model: getDeviceSchema().deviceModel,
            name: getDeviceSchema().deviceMake, // @FIXME this should be the name of the device given by the user
        },
        network: {
            bluetooth: false,
            carrier: false,
            cellular: false,
            wifi: window.navigator.onLine, // @FIXME what if the device is connected via ethernet?
        },
        os: {
            name: getDeviceSchema().osName,
            version: getDeviceSchema().osVersion,
        },
        screen: {
            density: window.screen.pixelDepth,
            height: window.screen.height,
            width: window.screen.width,
        },
        timezone: now.zoneName,
    };
};

export const getBasicAppProperties = (
    user: UserType | null,
    eventName: string,
    eventCategory?: string,
    eventType?: string
) => {
    return {
        application: getApplicationSchema(),
        device: getDeviceSchema(),
        user: getUserSchema(user || undefined),
        event: getEventSchema(eventName, eventCategory, eventType),
    };
};

const getApplicationSchema = () => {
    return {
        appName: getApplicationName(),
        appVersion: getAppVersion(),
        environment: CONFIG.env,
        platform: CONFIG.platform,
    };
};

const getDeviceSchema = () => {
    return {
        advertConsent: getAdvertParams().advertConsent,
        advertId: getAdvertParams().advertId,
        advertIdType: getAdvertParams().advertIdType,
        autoplayEnabled: '',
        deviceId: getDeviceId(),
        deviceMake: getDeviceDetails().deviceMake,
        deviceModel: getDeviceDetails().deviceModel,
        deviceTime: Date.now(),
        hasGooglePlayService: '',
        osName: getDeviceDetails().osName,
        osVersion: getDeviceDetails().osVersion,
        widevineSecurityLevel: '',
    };
};

export const getAssetSchema = (asset: AssetType) => {
    return {
        broadcastEndTime: asset.broadcastEndTime,
        broadcastStartTime: asset.broadcastStartTime,
        description: asset.description,
        duration: asset.duration,
        id: asset.id,
        isInTransition: asset.isInTransition,
        isLive: asset.isLive,
        publishStartDate: asset.publishStartDate,
        source: '',
        tags: asset.tags,
        title: asset.title,
        type: asset.type,
        updatedAt: '',
    };
};

export const getUserSchema = (user?: UserType) => {
    return {
        userId: user ? user.analyticUserId : getDeviceId(),
        userTags: user ? user.accessGroups : [],
        userType: user ? user.type : LOGGED_OUT_USER_TYPE,
    };
};

const getEventSchema = (eventName: string, eventCategory?: string, eventType?: string) => {
    return {
        category: eventCategory,
        name: eventName,
        type: eventType,
    };
};

export const formatName = (name: string) => {
    if (name)
        return name
            .replace(/[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '') // @FIXME: Optimise filter special characters if any
            .replace(/\s+/g, '_') // replace multiple space with underscore
            .toLowerCase();
    else return '';
};

export const getAppData = () => {
    return {
        appId: getAppId(),
        appVersion: getAppVersion(),
        appName: getApplicationName(),
        environment: CONFIG.env,
        platform: CONFIG.platform,
        osName: getDeviceDetails().osName,
        osVersion: getDeviceDetails().osVersion,
        ctvDeviceMake: getDeviceDetails().deviceMake,
        ctvDeviceModel: getDeviceDetails().deviceModel,
        ctvAdvertConsent: getAdvertParams().advertConsent,
        ctvAdvertId: getAdvertParams().advertId,
        ctvAdvertIdType: getAdvertParams().advertIdType,
        context: getContext(),
    };
};
