import { useEffect, useState } from 'react';
import { VideoElmEvent, PlaybackEvent } from 'src/lib/bitmovin/player.d';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';
import { isPS4 } from 'src/utilities/ps4';

const timeEvents: PlaybackEvent[] = ['timechanged'];
const timeEventsPs4: VideoElmEvent[] = ['timeupdate'];

const seekEvents: PlaybackEvent[] = ['seeked', 'timeshifted'];
const seekEventsPs4: VideoElmEvent[] = ['seeked'];

const timeBasedEvents = isPS4() ? [...timeEvents, ...timeEventsPs4] : timeEvents;
const seekBasedEvents = isPS4() ? [...seekEvents, ...seekEventsPs4] : seekEvents;

interface PlayerTimeStatProp {
    seekedCb: () => void;
}

// NOTE: CTV-1085 - Currently fixed, but this may need some more refactoring.
const usePlayerTimeStat = (prop?: PlayerTimeStatProp) => {
    const { seekedCb } = prop || {};
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [bufferLength, setBufferLength] = useState(0);

    useEffect(() => {
        const timeChangeHandler = (e: Event) => {
            setDuration(Math.floor(Player.getDuration()));
            setCurrentTime(Math.floor(Player.getCurrentTime()));
            setBufferLength(Math.floor(Player.getBufferLength()));
        };

        const Player = getActivePlayer();

        const subscribe = () => {
            if (Player.player) {
                timeBasedEvents.forEach((e) => {
                    Player.player.on(e, timeChangeHandler);
                });
            }
        };

        const unSubscribe = () => {
            if (Player.player) {
                timeBasedEvents.forEach((e) => {
                    Player.player.off(e, timeChangeHandler);
                });
            }
        };

        document.addEventListener('player-ready', subscribe);

        return unSubscribe;
    }, []);

    useEffect(() => {
        const seekEventHandler = (e: any) => {
            if ((e.type === 'seeked' || e.type === 'timeshifted') && seekedCb) {
                setTimeout(() => {
                    seekedCb();
                }, 1000);
            }
        };

        const Player = getActivePlayer();

        const subscribe = () => {
            if (Player.player && seekedCb) {
                seekBasedEvents.forEach((e) => {
                    Player.player.on(e, seekEventHandler);
                });
            }
        };

        const unSubscribe = () => {
            if (Player.player) {
                seekBasedEvents.forEach((e) => {
                    Player.player.off(e, seekEventHandler);
                });
            }
        };

        document.addEventListener('player-ready', subscribe);

        return unSubscribe;
    }, [seekedCb]);

    return { currentTime, duration, bufferLength };
};

export default usePlayerTimeStat;
