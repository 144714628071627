import { FocusNode } from 'lal-lrud';
import React from 'react';
import Typography from 'src/components/Common/Typography';
import useSettings from 'src/controllers/settings.controller';
import styles from './menuItem.module.scss';
import buttonStyles from 'src/components/Common/Button/button.module.scss';
import FullscreenPopup from '../../FullscreenPopup';
import { getRouteTags } from 'src/routes/routeTags';
import { useNavigate } from 'react-router-dom';
import { Analytics } from 'src/lib/Analytics';

interface MenuItemProps {
    title: string;
    pageId: string;
    isRight?: boolean;
}

const MenuItem = (props: MenuItemProps) => {
    const { title, pageId, isRight } = props;
    const { setSelectedSettingsItem, showLogOutAll, setShowLogOutAll } = useSettings();
    const navigate = useNavigate();

    const onClick = () => {
        Analytics.logSettingsSubMenu(title, isRight);
        setSelectedSettingsItem(pageId);
    };

    const logOutOfAllDevices = () => {
        setShowLogOutAll(false);
        navigate(getRouteTags('globalLogout', '', ''));
    };

    if (showLogOutAll) {
        return (
            <FullscreenPopup
                fullyOpaque
                id={'deviceManagement.logOutAllConfirmation'}
                onClick={() => setShowLogOutAll(false)}
                onClickSecondary={logOutOfAllDevices}
                onExit={() => setShowLogOutAll(false)}
            />
        );
    }

    let buttonStyle = [styles.menuItem];
    if (isRight) {
        buttonStyle = [styles.menuItemRight, buttonStyles.button];
    }

    return (
        <FocusNode
            focusedClass={isRight ? buttonStyles.focused : styles.focused}
            activeClass={styles.active}
            className={buttonStyle.join(' ')}
            onSelected={onClick}
            onClick={onClick}
        >
            {isRight ? (
                <span>{title}</span>
            ) : (
                <Typography className={styles.title}>{title}</Typography>
            )}
        </FocusNode>
    );
};

export default React.memo(MenuItem);
