import { CONFIG } from 'src/config/config';
// import { log } from './console';
const SEGMENT = 'Segment IO : ';
export const segmentDebugger = {
    log: (msg: string, ...args: any) => {
        if (CONFIG.SegmentDebug) {
            console.log(SEGMENT, msg, args);
        }
    },
    error: (msg: string, ...args: any) => {
        if (CONFIG.SegmentDebug) {
            console.log(SEGMENT, msg, args);
        }
    },
};
