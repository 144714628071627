import React from 'react';
import styles from './typography.module.scss';

type TypographyProps = {
    children: React.ReactNode;
    className?: string;
    variant?: 'heading-1';
};

function Typography(props: TypographyProps) {
    const { children, className, variant } = props;
    const classes = [styles.typography, className];
    if (variant && styles[variant]) {
        classes.push(styles[variant]);
    }

    return <div className={classes.join(' ')}>{children}</div>;
}

Typography.defaultProps = {};

export default React.memo(Typography);
