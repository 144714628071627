export const getHttpParams = (response?: any) => {
    let http: any = {};
    if (response && response?.status) {
        http.success = response.data?.success;
        if (response.config) {
            http.request = {};
            http.request.url = response.config.url;
            http.request.header = response.config.headers;
            // TODO : request time is not available here
            http.requestTime = '';
        }
        http.response = {};
        http.response.status = response.status;
        http.response.header = response.headers;
        http.response.body = response.request.response;
    }
    return { http };
};

// Pass as header and this will be replaced by a valid Auth header by the interceptor
export const REQUIRE_AUTH = {
    headers: {
        Authorization: true,
    },
};
