import {
    AssetType,
    BannerType,
    ContentCardActions,
    ContentCardActionsType,
    PanelType,
} from 'src/state/stores/dashboard/dashboard.d';
import { CC_BANNER_PANEL_TYPE, CC_DYNAMIC_PANEL_TYPE, CC_FAVOURITE_DATASOURCE } from './panel';
import { matchPath } from 'react-router-dom';
import RouteTags from 'src/routes/routeTags';

export const ccGetRoute = (action: string) => {
    let path: string = '';

    if (action.startsWith('#')) {
        path = action.substring(1);
    } else if (action.startsWith('/#')) {
        path = action.substring(2);
    }

    if (path) {
        const isMatchingPath = Object.keys(RouteTags).some((rt) => {
            return matchPath(RouteTags[rt], path);
        });
        if (isMatchingPath) {
            return path;
        }
    }
    return null;
};

const ccActionCheck = (action: string, allowLinksAsActions: boolean) => {
    const checkKeys = Object.keys(ContentCardActions);
    return checkKeys.some((k) => {
        // For actions like /competition/{{game}} the keys will ends with `StartsWith` to check if the
        // action starts with it.
        if (
            k.endsWith('StartsWith') &&
            action &&
            // @ts-ignore
            action.startsWith(ContentCardActions[k])
        ) {
            return true;
        }

        if (allowLinksAsActions && action && ccGetRoute(action)) {
            return true;
        }

        // @ts-ignore
        return action === ContentCardActions[k];
    });
};

export interface Options {
    bannerType: BannerType;

    cardClickAction: string;

    primaryButtonAction: ContentCardActionsType;
    primaryButtonLabel: string;

    secondaryButtonAction: ContentCardActionsType;
    secondaryButtonLabel: string;
}

export const validateCC = (options: Options, allowLinksAsActions = false) => {
    const {
        bannerType,
        cardClickAction,
        primaryButtonAction,
        primaryButtonLabel,
        secondaryButtonAction,
        secondaryButtonLabel,
    } = options;

    if (
        bannerType === 'NO_BUTTON' &&
        (ccActionCheck(cardClickAction, allowLinksAsActions) || !cardClickAction)
    ) {
        return true;
    } else if (
        bannerType === 'ONE_BUTTON' &&
        ccActionCheck(primaryButtonAction, allowLinksAsActions) &&
        primaryButtonLabel
    ) {
        return true;
    } else if (
        bannerType === 'TWO_BUTTONS' &&
        ccActionCheck(primaryButtonAction, allowLinksAsActions) &&
        ccActionCheck(secondaryButtonAction, allowLinksAsActions) &&
        primaryButtonLabel &&
        secondaryButtonLabel
    ) {
        return true;
    }
    return false;
};

export const validateCCBannerAsset = (asset: AssetType) => {
    if (asset.type === 'content-card' && asset.contentCard) {
        return validateCC(asset.contentCard);
    }
    return false;
};

export const validateCCBanner = (panel: PanelType, asset: AssetType) => {
    if (panel.type === CC_BANNER_PANEL_TYPE) {
        return validateCCBannerAsset(asset);
    }

    return false;
};

export const validateCCDynamicPanelAsset = (asset: AssetType) => {
    const { contentCard } = asset;
    if (contentCard) {
        const { bucketKey, contentCardType, dataSource } = contentCard;
        if (contentCardType === 'DYNAMIC_RAIL' && dataSource === 'BUCKET' && bucketKey) {
            return true;
        }
    }

    return false;
};

export const validateCCFavPanelAsset = (asset: AssetType) => {
    const { contentCard } = asset;
    if (contentCard) {
        const { dataSource } = contentCard;
        if (dataSource === CC_FAVOURITE_DATASOURCE) {
            return true;
        }
    }
    return false;
};

export const validateCCPanel = (panel: PanelType, asset: AssetType) => {
    const { type, layout } = panel;
    const { contentCard } = asset;
    if (type === CC_DYNAMIC_PANEL_TYPE && layout && contentCard) {
        return validateCCDynamicPanelAsset(asset) || validateCCFavPanelAsset(asset);
    }

    return false;
};

export const validateCCAsset = (asset: AssetType) => {
    return (
        validateCCBannerAsset(asset) ||
        validateCCFavPanelAsset(asset) ||
        validateCCDynamicPanelAsset(asset)
    );
};
