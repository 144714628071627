import { FocusNode } from 'lal-lrud';
import React from 'react';
import useSettings from 'src/controllers/settings.controller';
import MenuCategory from '../MenuCategory';
import styles from './leftContainer.module.scss';

const LeftSideSettingsContainer = () => {
    const { menuCategories } = useSettings();

    return (
        <FocusNode focusedClass={styles.focused} className={styles.body} orientation="vertical" wrapping>
            {menuCategories.map((category, i) => (
                <MenuCategory
                    key={category.categoryId}
                    title={category.categoryTitle}
                    menuItems={category.menuItems}
                ></MenuCategory>
            ))}
        </FocusNode>
    );
};

export default React.memo(LeftSideSettingsContainer);
