import { FocusNode } from 'lal-lrud';
import Cards from 'src/components/ui/Cards';
import styles from './dRBody.module.scss';
import useDisasterMode from 'src/controllers/disasterRecovery.controller';
import { useLocation } from 'react-router-dom';
import useOLRUDPanel from 'src/lib/OLRUD/controllers/oLRUD.panel.controller';
import { memo } from 'react';

const DRBody = () => {
    const panelClasses = [styles.panel];
    const {
        panelData: { assets = [] },
    } = useDisasterMode();

    const { pathname } = useLocation();

    const {
        onLRUDKey,
        focusedCardIndex = 0,
        panelTranslateX = 0,
    } = useOLRUDPanel(assets || [], 'disaster', pathname);

    return (
        <FocusNode className={panelClasses.join(' ')} onKey={onLRUDKey} focusId={`drRail`}>
            <Cards
                assets={assets}
                cardLayout={'disaster'}
                panelId={'disaster'}
                focused={true}
                focusedCardIndex={focusedCardIndex}
                panelTranslateX={panelTranslateX}
                panelIndex={0}
            />
        </FocusNode>
    );
};

export default memo(DRBody);
