import FullscreenPopup from '../FullscreenPopup';

interface PlaybackErrorProps {
    onRetry: () => any;
    onExit: () => any;
    message: string;
}

const PlaybackError = (props: PlaybackErrorProps) => {
    const { message, onRetry, onExit } = props;

    return (
        <FullscreenPopup
            onClick={onRetry}
            onExit={onExit}
            title="Oops! Something went wrong..."
            titleMessage={message}
            message={`Please try again or contact our customer support team via the Optus Sport website or mobile app.`}
            primaryButtonTitle="Retry"
        />
    );
};

export default PlaybackError;
