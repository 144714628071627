import { IconType } from 'src/components/Icons';

export const NotificationMessages: {
    [key: string]: {
        title?: string;
        message?: string;
        titleMessage?: string;
        primaryButton?: string;
        secondaryButton?: string;
        icon?: IconType;
    };
} = {
    'app.noInternet': {
        title: 'No internet connection',
        message: 'It looks like you have no internet connection, please try again',
        primaryButton: 'Try again',
    },
    'app.exitApp': {
        title: 'Are you sure you want to exit the app?',
        message: ' ',
        primaryButton: 'Exit Optus Sport',
        secondaryButton: 'Go back',
    },
    'login.codeExpired': {
        title: 'Your code has expired!',
        message: 'Don’t worry we have a new code for you',
        primaryButton: 'Try again',
        secondaryButton: undefined,
    },
    'login.connectionDetected': {
        title: 'We have detected a connection',
        message: 'Sit tight while we verify the connection.',
        primaryButton: undefined,
        secondaryButton: undefined,
    },
    'login.genericError': {
        title: 'Something went wrong',
        message: 'Please try again',
        primaryButton: 'Go back',
        secondaryButton: undefined,
    },
    'asset.genericError': {
        title: 'Something went wrong',
        // message: 'Please try again',
        primaryButton: 'Go back',
        secondaryButton: undefined,
    },

    'live.loginPending': {
        titleMessage: 'To access Optus Sport TV, Please log in to a premium subscription account',
        primaryButton: 'Log in',
        secondaryButton: undefined,
    },
    'live.error': {
        title: 'Oops! Something went wrong...',
        primaryButton: 'Try again',
        secondaryButton: 'Go home',
    },
    'player.streamKicked': {
        title: 'Device Limit Exceeded',
        message:
            "You've exceeded the maximum number of concurrent streams. Please log out of other devices and try again.",
        primaryButton: 'Try Again',
        secondaryButton: 'Go home',
    },
    'player.playbackNotStarted': {
        title: 'Live stream not ready',
        message:
            'Sorry, there was a last minute change to the start time. Please try again in a few minutes.',
        primaryButton: 'Try Again',
        secondaryButton: 'Go home',
    },
    'player.geoLocationError': {
        title: 'This video cannot be played',
        message:
            'Sorry, the content you are trying to view is not available to users outside of Australia',
        primaryButton: 'Done',
        secondaryButton: undefined,
    },
    'player.genericError': {
        title: 'This video cannot be played',
        message: 'Sorry, Something went wrong, Please try again.',
        primaryButton: 'Try Again',
        secondaryButton: 'Go home',
    },
    'deviceManagement.deviceExceeded': {
        title: 'Please review your devices',
        message:
            'You have reached the maximum number of devices using your Optus Sport account. To watch on this device, please log out of one or more of your other devices',
        primaryButton: 'Device Management',
        secondaryButton: 'Back',
    },
    'deviceManagement.logOutAllConfirmation': {
        title: 'Are you sure you want to log out of all devices?',
        message:
            'You will be logged out of this device immediately. It may take up to 4 hours to log out of the remaining devices.',
        primaryButton: 'Back',
        secondaryButton: 'Log out of all devices',
    },
    'deviceManagement.logOutAllSuccess': {
        title: 'You have successfully logged out of all devices',
        message:
            "Log in again on this device to continue watching.\n\nIf you believe there's been unauthorised access to your account, we recommend you log in and change your password immediately.",
        icon: 'tickOutlineGreen',
    },
};

export type NotificationKeyType = keyof typeof NotificationMessages;
