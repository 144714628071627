import { memo } from 'react';
import { FocusNode } from 'lal-lrud';
import { getCardSize } from 'src/components/ui/Card/cardsizes';
import CompetitionBg from 'src/components/ui/Match/competition/CompetitionBg';
import CompetitionIcon from 'src/components/ui/Match/competition/CompetitionIcon';
import Team from 'src/components/ui/Match/Team';
import { AssetType } from 'src/state/stores/dashboard/dashboard';
import {
    shouldDisableStartover,
    showOverlayAndLeftAlignActionsBasedOnTags,
} from 'src/utilities/asset';
import { checkifUpcomingLiveOrPast } from 'src/utilities/time';
import { processImageUrl } from 'src/utilities/url';
import HeroCardActions from '../HeroCardActions';
import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';

import styles from './heroBodies.module.scss';

interface BodyProps {
    asset: AssetType;
    focused: boolean;
    onClick: () => void;
}

const cardLayout = 'hero';
const cardSize = getCardSize(cardLayout);
const { imageKey, imgHeight, imgWidth } = cardSize;

const HeroMatchBody = (props: BodyProps) => {
    const { asset, focused, onClick } = props;
    const { publishStartDate, broadcastStartTime, broadcastEndTime, match, type } = asset;
    const { competition, awayTeam, homeTeam } = match || {};
    const { id: competitionId } = competition || {};
    const { logoUrl: awayTeamLogo } = awayTeam || {};
    const { logoUrl: homeTeamLogo } = homeTeam || {};

    const cardClasses = [styles.cardBody, styles.match];

    const shouldLeftAlign = showOverlayAndLeftAlignActionsBasedOnTags(asset);
    const hideStartOverButton = shouldDisableStartover(asset);

    const bgUrl = asset[imageKey];

    const backgroundImage = processImageUrl(bgUrl || '', imgWidth, imgHeight);
    const bgStyle = {
        backgroundImage: `url(${backgroundImage}), url(${stockBackground})`,
    };

    const startTime = broadcastStartTime ?? publishStartDate;
    const endTime = broadcastEndTime ?? publishStartDate;
    const action = checkifUpcomingLiveOrPast(startTime, endTime);

    return (
        <FocusNode className={cardClasses.join(' ')}>
            {competitionId ? (
                <CompetitionBg className={styles.bg} competitionId={competitionId}></CompetitionBg>
            ) : null}
            <div className={styles.bg} style={bgStyle} />
            <div className={styles.mask}></div>
            <div className={styles.content}>
                {competitionId ? (
                    <CompetitionIcon
                        competitionId={competitionId}
                        className={styles.competitionIcon}
                    />
                ) : null}
                <div className={styles.matchInfo}>
                    <div className={styles.teams}>
                        <Team logoUrl={homeTeamLogo!} className={styles.team} />
                        <Team logoUrl={awayTeamLogo!} className={styles.team} />
                    </div>
                </div>
                <HeroCardActions
                    action={action}
                    assetId={asset.id}
                    leftAlign={shouldLeftAlign}
                    onClick={onClick}
                    showStartOver={!hideStartOverButton}
                    isShow={type === 'show'}
                    focused={focused}
                ></HeroCardActions>
            </div>
        </FocusNode>
    );
};

export default memo(HeroMatchBody);
