import { useEffect } from 'react';
import { useStateValue } from 'src/state';
import {
    getContentCardsBrazeAll,
    getContentCardsPlatform,
    getContentCardsUser,
} from 'src/state/stores/dashboard/dashboard.actions';
import useUser from '../auth/user.controller';
import { CONFIG } from 'src/config/config';
import { noop } from 'src/utilities/op';

const useContentCardFetch = () => {
    const { state, dispatch } = useStateValue();

    const { auth } = useUser();
    const { userId } = auth || {};
    const {
        dashboardData: {
            contentCardsPlatformPending,
            contentCardsUserPending,
            contentCardsBrazeAllPending,
        },
    } = state;

    useEffect(() => {
        if (CONFIG.contentCardCMSActive && userId && contentCardsUserPending === null) {
            setTimeout(() => {
                dispatch(getContentCardsUser(userId)).catch(noop);
            });
        }
    }, [dispatch, userId, contentCardsUserPending]);

    useEffect(() => {
        if (CONFIG.contentCardCMSActive && contentCardsPlatformPending === null) {
            dispatch(getContentCardsPlatform()).catch(noop);
        }
    }, [dispatch, contentCardsPlatformPending]);

    useEffect(() => {
        if (CONFIG.BrazeContentCardActive && contentCardsBrazeAllPending === null) {
            dispatch(getContentCardsBrazeAll()).catch(noop);
        }
    }, [dispatch, contentCardsBrazeAllPending]);
};

export default useContentCardFetch;
