import React, { useRef } from 'react';
import styles from './playpill.module.scss';

type PlayPillProps = {
    className?: string;
};

function PlayPill(props: PlayPillProps) {
    const { className } = props;
    const playPillClasses = useRef([styles.playPill]);
    if (className) {
        playPillClasses.current.push(className);
    }
    return <div className={playPillClasses.current.join(' ')}>FREE</div>;
}

export default React.memo(PlayPill);
