import { useNavigate } from 'react-router-dom';
import PlayerUI from 'src/components/Player/PlayerUI';
import FullscreenPopup from 'src/components/ui/FullscreenPopup';
import PlaybackError from 'src/components/ui/PlaybackError';
import usePlaybackError from 'src/controllers/playbackError.controller';
import useVodPlayback from 'src/controllers/vodPlayback.controller';
import RouteTags from 'src/routes/routeTags';
import styles from './playback.module.scss';
import { shouldDisableTrickplay } from 'src/utilities/asset';
import { useCallback } from 'react';
import { Segment } from 'src/lib/bitmovin/segment';

const VodPlayback = () => {
    const navigate = useNavigate();

    const {
        playbackNotStarted,
        isGeoBlocked,
        isKicked,
        isKickedOnPlayback,
        isLive,
        poster,
        title,
        retryPlayback,
        asset,
        streamType,
        playbackError,
    } = useVodPlayback();
    const { playerError, retryOnError } = usePlaybackError(retryPlayback);
    const isMiniUI = shouldDisableTrickplay(asset, streamType);

    const bgStyle = { backgroundImage: '' };

    if (poster) {
        bgStyle.backgroundImage = `url(${poster})`;
    }

    const navigateToDeviceManagement = useCallback(() => {
        Segment.trackPlaybackErrorInteraction({
            interaction: 'SHOW_DEVICE_MANAGEMENT',
        });
        navigate(RouteTags.deviceManagement, { replace: true });
    }, [navigate]);

    const goBack = () => {
        navigate(RouteTags.root, { replace: true });
        return true;
    };

    if (isGeoBlocked) {
        return (
            <FullscreenPopup
                id={'player.geoLocationError'}
                onClick={goBack}
                onExit={goBack}
            ></FullscreenPopup>
        );
    }
    if (playbackNotStarted) {
        return (
            <FullscreenPopup
                id={'player.playbackNotStarted'}
                onClick={retryOnError}
                onExit={goBack}
                onClickSecondary={goBack}
            />
        );
    }
    if (playbackError) {
        return (
            <FullscreenPopup
                id={'player.genericError'}
                onClick={retryOnError}
                onExit={goBack}
                onClickSecondary={goBack}
                primaryButtonTitle="Retry"
            />
        );
    }

    return (
        <div className={styles.playback}>
            {poster ? <div className={styles.poster} style={bgStyle} /> : null}

            <PlayerUI
                title={title}
                isLive={!!isLive}
                isShowMiniEPG={false}
                isPlayerError={!!playerError}
                isKicked={isKicked || isKickedOnPlayback}
                isMiniUI={isMiniUI ?? false}
            />

            {isKickedOnPlayback ? (
                <FullscreenPopup
                    id={'deviceManagement.deviceExceeded'}
                    onClick={navigateToDeviceManagement}
                    onClickSecondary={goBack}
                    onExit={goBack}
                ></FullscreenPopup>
            ) : playerError ? (
                <PlaybackError message={playerError} onRetry={retryOnError} onExit={goBack} />
            ) : isKicked ? (
                <FullscreenPopup
                    id={'player.streamKicked'}
                    onClick={retryOnError}
                    onClickSecondary={goBack}
                    onExit={goBack}
                ></FullscreenPopup>
            ) : null}
        </div>
    );
};

export default VodPlayback;
