type keyType =
    | 'deviceId' //Device Id
    | 'd' //Device
    | 'did' //Device UUID
    | 'p' //Platform details
    | 'u' // User
    | 's' // Session
    | 'rt' // Refresh Token
    | 'lpla' // Last Played Linear Asset
    | 'ast' // App Suspended Time
    | 'vq' // videoQuality Label
    | 'vsub'; // video Subtitle

const storeAsString = ['deviceId'];

type dataType = object | string | number;

const appendIntoObjectWithKey = (key: keyType, data: object) => {
    let storedData = {};
    try {
        storedData = JSON.parse(localStorage.getItem(key) || '');
    } catch (err) {
        //
    }
    storedData = { ...storedData, ...data };
    localStorage.setItem(key, JSON.stringify(storedData));
};

const Storage = {
    getItem: (key: keyType) => {
        const asString = storeAsString.indexOf(key) !== -1;
        try {
            const data = localStorage.getItem(key);
            return asString ? data : JSON.parse(data || '');
        } catch (err) {
            return localStorage.getItem(key) || undefined;
        }
    },
    setItem: (key: keyType, data: dataType) => {
        const asString = storeAsString.indexOf(key) !== -1;
        try {
            const d = asString && typeof data === 'string' ? data : JSON.stringify(data);
            localStorage.setItem(key, d);
        } catch (err) {
            return null;
        }
        return null;
    },
    appendItem: (key: keyType, data: object) => {
        try {
            appendIntoObjectWithKey(key, data);
        } catch (err) {
            return null;
        }
        return null;
    },
    removeItem: (key: keyType) => localStorage.removeItem(key),
};

export default Storage;
