import axios from 'axios';
import { AuthType } from 'src/state/stores/user/user';
import { CONFIG } from 'src/config/config';
import { REQUIRE_AUTH } from '.';

axios.defaults.timeout = CONFIG.networkTimeout;
delete axios.defaults.headers.common['Accept'];

export const applyAxiosRequestInterceptor = (auth: AuthType) => {
    const requestInterceptor = axios.interceptors.request.use((req) => {
        if (req.headers?.Authorization === REQUIRE_AUTH.headers.Authorization) {
            const header = `${auth.TokenType} ${auth.IdToken}`;
            req.headers.Authorization = header;
        }
        return { ...req };
    });

    return () => {
        axios.interceptors.request.eject(requestInterceptor);
    };
};
