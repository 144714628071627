import { useCallback, useEffect, useRef, useState } from 'react';
import Braze from '..';
import useUser from 'src/controllers/auth/user.controller';
import useAppReady from 'src/controllers/appReady.controller';
import { CONFIG } from 'src/config/config';
import { Message } from '../braze';
import { useMatch } from 'react-router-dom';
import RouteTags from 'src/routes/routeTags';
import { isKeyEnterButton, isKeyLeft, isKeyRight } from 'src/utilities/keys';
import useKeyPress from 'src/controllers/keyPress/keyPress.controller';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import useCCAction from 'src/controllers/dashboard/contentCardAction.controller';
import { isPS4 } from 'src/utilities/ps4';

const useBraze = () => {
    const { ready, isDisasterMode } = useAppReady();
    const { user } = useUser();
    const isOnLogout = !!useMatch(RouteTags.logout);
    const isOnGlobalLogout = !!useMatch(RouteTags.globalLogout);

    const [isBrazeIntialized, setIsBrazeIntialized] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [focusedBtnIndex, setFocusedBtnIndex] = useState(0);
    const [message, setMessage] = useState<Message | null>(null);

    const focusedBtnIndexRef = useRef(focusedBtnIndex);

    const onMessageShown = useCallback((m: Message | undefined) => {
        setIsMessageVisible(true);
        if (m) {
            setMessage(m);
        }
    }, []);

    const onMessageHidden = useCallback(() => {
        setIsMessageVisible(false);
        setFocusedBtnIndex(0);
        setMessage(null);
    }, []);

    useEffect(() => {
        if (isBrazeIntialized) {
            return;
        }
        if (ready === true && isDisasterMode === false && CONFIG.BrazeActive) {
            if (
                !CONFIG.BrazeOnlyForLoggedInUserActive ||
                (CONFIG.BrazeOnlyForLoggedInUserActive && user)
            ) {
                const init = Braze.init({ onMessageShown, onMessageHidden });
                setIsBrazeIntialized(init);
            }
        }
    }, [user, isBrazeIntialized, ready, isDisasterMode, onMessageHidden, onMessageShown]);

    useEffect(() => {
        if (isBrazeIntialized) {
            if (user && user.analyticUserId) {
                // Loggedin and user details fetched
                Braze.setUser(user);
            } else if (!user) {
                // Anonymous User
                Braze.setUser();
            }
        }
    }, [user, isBrazeIntialized]);

    useEffect(() => {
        if (isOnLogout || isOnGlobalLogout) {
            Braze.logout();
            if (CONFIG.BrazeOnlyForLoggedInUserActive) {
                if (!isPS4()) {
                    // ON PS4/5, enable is not working so avoiding disable!
                    Braze.disable();
                }
            }
        }
    }, [isOnLogout, isOnGlobalLogout]);

    const { handleCCAction } = useCCAction();

    useEffect(() => {
        focusedBtnIndexRef.current = focusedBtnIndex;
    }, [focusedBtnIndex]);

    const handleBackButton = useCallback(() => {
        if (isMessageVisible) {
            Braze.hideMesage();
            return true;
        }
        return false;
    }, [isMessageVisible]);

    const handleKeydown = useCallback(
        (e: KeyboardEvent) => {
            if (isMessageVisible) {
                if (isKeyLeft(e)) {
                    setFocusedBtnIndex(0);
                } else if (isKeyRight(e)) {
                    // @ts-ignore
                    const nextButtonIndex = Braze.getButtons().length > 1 ? 1 : 0;
                    if (nextButtonIndex) {
                        setFocusedBtnIndex(nextButtonIndex);
                    }
                } else if (isKeyEnterButton(e)) {
                    const actionUri = Braze.performAction(focusedBtnIndexRef.current);
                    if (CONFIG.BrazeInAppMessagesHeadlessActive && actionUri) {
                        handleCCAction(actionUri);
                    }
                }
                return true;
            }
            return false;
        },
        [isMessageVisible, handleCCAction]
    );

    useKeyPress(handleKeydown);
    useBackButton({ callBack: handleBackButton, id: 'braze-cc-back', rank: 8 });

    return { message, focusedBtnIndex, isMessageVisible };
};

export default useBraze;
