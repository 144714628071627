import { CardLayoutType } from 'src/state/stores/dashboard/dashboard';
import { pxScale } from './styles';

import { BROWSE_PANEL_TYPE, EXPLORE_CARD_LAYOUT, EXPLORE_PANEL_TYPE } from './panel';
import { CONFIG } from 'src/config/config';

export const isLayoutHero = (layout: string) => layout === 'hero';

/**
 * Note: This is used for:
 * 1. {@link CardLayoutType}
 * 2. {@link PanelType}
 */
export const isLayoutExplore = (type: string) => {
    // type === EXPLORE_CARD_LAYOUT is particularly required as the param `type` can be of type CardLayoutType
    return (
        type === EXPLORE_PANEL_TYPE || type === BROWSE_PANEL_TYPE || type === EXPLORE_CARD_LAYOUT
    );
};

export const getLowest = (a: number, b: number) => {
    return Math.min(a, b);
};

export const getMaxAssetRenderCount = (
    cardLayout: CardLayoutType,
    assetLength: number,
    maxTileCount?: number
) => {
    if (isLayoutExplore(cardLayout)) {
        return getLowest(CONFIG.maxExploreAssetsToRender, assetLength);
    }
    if (isLayoutHero(cardLayout)) {
        return getLowest(CONFIG.maxHeroAssetsToRender, assetLength);
    } else {
        return maxTileCount || CONFIG.maxAssetsToRender;
    }
};

export const calcTranslateX = (
    width: number,
    margin: number,
    cardCount: number,
    focusedCardIndex: number,
    railLength: number = pxScale(1782)
) => {
    const cardWidth = width + margin * 2;
    const scrollRailLength = cardWidth * cardCount + pxScale(10);

    let translate = focusedCardIndex * cardWidth + cardWidth / 2;
    if (translate < railLength / 2 || scrollRailLength < railLength) {
        translate = 0;
    } else if (translate > scrollRailLength - railLength / 2) {
        translate = scrollRailLength - railLength + (cardWidth - width);
    } else {
        translate -= railLength / 2;
    }

    translate = translate * -1;
    return translate;
};

export const getFallbackLayout = (key: string) => {
    try {
        let fallback = require(`src/apis/fallback/${key.toLocaleLowerCase()}.json`);
        return fallback;
    } catch (error) {}
    return undefined;
};
