import CircularProgress from 'src/components/Common/CircularProgress';
import Typography from 'src/components/Common/Typography';
import styles from './userCode.module.scss';

interface userCodeProps {
    expireProgress: number;
    userCode: string | undefined;
}

const UserCode = (props: userCodeProps) => {
    const { expireProgress, userCode } = props;
    const codeArray = userCode ? userCode.match(/.{1,3}/g)?.join(' ') : ' ';
    const variant = userCode !== undefined ? 'determinate' : 'indeterminate';
    return (
        <div>
            <Typography className={styles.helpText}>When asked, enter this code</Typography>
            <div className={styles.container}>
                <Typography className={styles.userCode}>{codeArray || ''}</Typography>
                <CircularProgress variant={variant} value={expireProgress} className={styles.progress}></CircularProgress>
            </div>
        </div>
    );
};

export default UserCode;
