import { processImageUrl } from 'src/utilities/url';
import { getCardSize } from '../cardsizes';
import styles from '../Bodies/bodies.module.scss';
import useTheme from 'src/controllers/theme.controller';
import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { memo } from 'react';

interface BodyProps {
    asset?: AssetType;
    cardLayout: string;
    focused: boolean;
    onClick: () => void;
}
const ExploreCardBody = (props: BodyProps) => {
    const { asset, cardLayout, focused, onClick } = props;
    const { theme } = useTheme(asset);
    if (asset) {
        const { title } = asset;
        const contentClass = [styles.content];
        if (styles[cardLayout]) {
            contentClass.push(styles[cardLayout]);
        }
        const cardSize = getCardSize(cardLayout);
        const { imgHeight, imgWidth } = cardSize;
        const bgUrl = asset.backgroundImageUrl;
        const backgroundImage = processImageUrl(bgUrl || '', imgWidth, imgHeight);
        const bgStyle = {
            backgroundImage: `url(${backgroundImage})`,
        };

        const cardClasses = [styles.cardBody, styles.explore];
        if (styles[cardLayout]) {
            cardClasses.push(styles[cardLayout]);
        }
        if (theme === 'light') {
            cardClasses.push(styles.light);
        }

        if (focused) {
            cardClasses.push(styles.focused);
        }

        const titleIcon = {
            backgroundImage: `url(${asset.tileIconUrl})`,
        };

        const bgStyles =
            theme === 'dark' && asset.applyOverlay === true
                ? [styles.bg, styles.darkBg].join(' ')
                : styles.bg;

        return (
            <div className={cardClasses.join(' ')} onClick={onClick}>
                <div className={bgStyles} style={bgStyle}></div>
                <div className={styles.mask}></div>
                <div className={contentClass.join(' ')}>
                    <div className={styles.tileIcon} style={titleIcon}></div>
                    <span className={styles.exploreTitle}>{title}</span>
                </div>
            </div>
        );
    }
    return <></>;
};

export default memo(ExploreCardBody);
