import { memo, useEffect } from 'react';
import { CONFIG } from 'src/config/config';
import styles from './heroDots.module.scss';

type DotProps = {
    count?: number;
    activeIndex?: number;
    onAutoAnim: () => void;
};

const HeroDots = (props: DotProps) => {
    const { count, activeIndex, onAutoAnim } = props;
    const animation = {
        animationName: styles.animation,
        animationDuration: `${CONFIG.heroCarouselSlideInterval}ms`,
    };
    useEffect(() => {
        const i = setInterval(onAutoAnim, CONFIG.heroCarouselSlideInterval);
        return () => {
            clearInterval(i);
        };
    }, [onAutoAnim]);

    const dotStyle = (i: number) => {
        return styles.dot;
    };

    return (
        <div className={styles.dotsPanel}>
            {[...Array(count)].map((e, i) => (
                <div className={dotStyle(i)} key={i}>
                    <div
                        className={styles.progress}
                        style={activeIndex === i ? animation : undefined}
                    ></div>
                </div>
            ))}
        </div>
    );
};

export default memo(HeroDots);
