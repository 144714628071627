import { FocusNode, useSetFocus } from 'lal-lrud';
import { MoveEvent } from 'lal-lrud/dist/types';
import React, { useEffect, useState } from 'react';
import { AssetType, CardLayoutType } from 'src/state/stores/dashboard/dashboard';
import { getCardSize } from '../../Card/cardsizes';
import ShimmerPanel from '../../Dashboard/ShimmerPanel';
import HeroCard from '../HeroCard';
import HeroDots from '../HeroDots';
import styles from './cards.module.scss';

interface CardItemProps {
    assets?: AssetType[];
    cardLayout: CardLayoutType;
    panelId: string;
    panelIndex: number;
    focused: boolean;
}

const cardSize = getCardSize('hero');
const { height } = cardSize;

const HeroCards = (props: CardItemProps) => {
    const { assets, cardLayout, panelId, focused } = props;
    const [selectedHeroIndex, setSelectedHeroIndex] = useState(0);
    const trolleyStyle = {
        height,
    };
    const onMove = (e: MoveEvent) => {
        setSelectedHeroIndex(e.nextChildIndex);
    };

    const setFocus = useSetFocus();
    const onAutoAnim = () => {
        setSelectedHeroIndex((s) => {
            let ns = s + 1;
            if (assets && ns >= assets?.length) {
                ns = 0;
            }
            return ns;
        });
    };

    useEffect(() => {
        if (focused && assets?.length) {
            setTimeout(() => {
                setFocus(`hero_${selectedHeroIndex}`);
            });
        }
    }, [assets?.length, selectedHeroIndex, focused, setFocus]);

    if (assets?.length) {
        return (
            <FocusNode orientation="horizontal" className={styles.rail} wrapping onMove={onMove}>
                <div className={styles.trolley} style={trolleyStyle}>
                    {assets.map((asset: AssetType, index) => (
                        <FocusNode focusId={`hero_${index}`} key={asset.id}>
                            <HeroCard
                                asset={asset}
                                cardLayout={cardLayout}
                                panelId={panelId}
                                cardIndex={index}
                                selectedCardIndex={selectedHeroIndex}
                                cardsLen={assets.length}
                                focused={focused}
                            />
                        </FocusNode>
                    ))}
                </div>
                <HeroDots
                    count={assets.length}
                    activeIndex={selectedHeroIndex}
                    onAutoAnim={onAutoAnim}
                />
            </FocusNode>
        );
    }

    return (
        <div className={styles.rail}>
            <div className={styles.shimmer}>
                <ShimmerPanel cardLayout={cardLayout}></ShimmerPanel>
            </div>
        </div>
    );
};

export default React.memo(HeroCards);
