import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Analytics } from 'src/lib/Analytics';
import { ContentCardPositionType } from 'src/lib/Analytics/analytics';
import { ContentCard } from 'src/state/stores/dashboard/dashboard';

const useContentCardVisible = (
    contentCard: ContentCard | undefined,
    panelIndex: number,
    isRail: boolean,
    visible: boolean
) => {
    // const ccElmRef = useRef(null);
    const active = useRef(true);

    const cardPosition: ContentCardPositionType = useMemo(
        () => ({
            type: isRail ? 'BETWEEN_RAILS' : 'INSIDE_RAIL',
            indexV: panelIndex,
            indexH: 0,
            hostRailTitle: undefined,
        }),
        [panelIndex, isRail]
    );

    const onBannerVisible = useCallback(() => {
        if (contentCard && active.current) {
            active.current = false;
            Analytics.logContentCardViewed(contentCard, cardPosition);
        }
    }, [contentCard, cardPosition]);

    useEffect(() => {
        if (visible && contentCard) {
            onBannerVisible();
        }
    }, [visible, contentCard, onBannerVisible]);

    // useOnScreen(ccElmRef, onBannerVisible);

    // return { ccElmRef: contentCard ? ccElmRef : null };
};
export default useContentCardVisible;
