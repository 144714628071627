interface RouteTagsType {
    [index: string]: string;
}
// list of routes in the app
const RouteTags: RouteTagsType = {
    root: '/',
    splash: '/splash',
    onboarding: '/onboarding',
    login: '/login',
    logout: '/logout',
    globalLogout: '/globalLogout',
    userWelcome: '/login/welcome',
    dashboardCommon: '/dashboard',
    dashboardViewAll: '/dashboard/:dashboard/view-all/:bucketKey',
    dashboardExplore: '/dashboard/:dashboard/:explore',
    dashboardExploreViewAll: '/dashboard/:dashboard/:explore/view-all/:bucketKey',
    dashboard: '/dashboard/:dashboard',
    browseExplore: '/browse/:explore',
    browse: '/browse',
    vodPlayback: '/assets/:assetId/playback',
    vodDetail: '/assets/:dashboard/:assetId',
    livePlayback: '/live/:assetId',
    deviceManagement: '/settings/device-management',
    settings: '/settings/:setting',
    drplayer: '/drmode/:assetId',
};
// type RouteTagKeys = keyof typeof RouteTags;
export default RouteTags;

export const getRouteTags = (
    tag: string,
    key: string | string[],
    value: string | string[]
): string => {
    let route: string = RouteTags[tag] || tag;
    if (Array.isArray(value)) {
        value.forEach((v, i) => {
            route = route.replace(`:${key[i]}`, v);
        });
        return route;
    } else {
        return route.replace(`:${key}`, value);
    }
};
export const DASHBOARD_ROOT = getRouteTags('dashboard', 'dashboard', 'football');
