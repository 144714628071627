import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteTags } from 'src/routes/routeTags';
import { useStateValue } from 'src/state';
import { getLiveAssetEPGData } from 'src/state/stores/dashboard/dashboard.actions';
import { CONFIG } from 'src/config/config';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';

interface OptionsType {
    fetch: boolean;
}
const useMiniEPG = (options?: OptionsType) => {
    const { fetch = false } = options || {};
    const { state, dispatch } = useStateValue();
    const params = useParams();
    const [onNowI, setOnNowI] = useState<number | null>(null);
    const navigate = useNavigate();
    const { assetId } = params;
    const {
        dashboardData: { epgData, liveAssets },
    } = state;

    const channelEpg = useMemo(() => assetId && epgData[assetId], [assetId, epgData]);

    useEffect(() => {
        if (
            CONFIG.MEpgActive &&
            fetch &&
            assetId &&
            assetId !== ':assetId' &&
            channelEpg === undefined
        ) {
            dispatch(getLiveAssetEPGData(assetId)).catch(() => {});
        }
    }, [assetId, channelEpg, fetch, dispatch]);

    // const { auth } = useUser();
    // const { userId } = auth || {};

    useEffect(() => {
        let timer: any = null;
        if (assetId && channelEpg) {
            const update = () => {
                const now = new Date().getTime();
                const onNowIndex = channelEpg.findIndex(
                    (e: any) => e.epgStart <= now && e.epgStop >= now
                );
                if (onNowIndex >= 0) {
                    setOnNowI(onNowIndex);
                    const updateTime = channelEpg[onNowIndex].epgStop - Date.now();
                    timer = setTimeout(() => {
                        update();
                    }, updateTime + 2000);
                }
            };
            update();
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [assetId, channelEpg, dispatch]);

    const epgAvailable = onNowI !== null && onNowI >= 0 && channelEpg;
    const onNowProgram = epgAvailable ? channelEpg[onNowI] || null : channelEpg ? null : undefined;
    const upNextProgram = epgAvailable
        ? channelEpg[onNowI + 1] || null
        : channelEpg
        ? null
        : undefined;

    const changeChannel = (aId: string) => {
        if (assetId && assetId !== aId) {
            const Player = getActivePlayer();
            Player.unload().then(() => {
                navigate(getRouteTags('livePlayback', 'assetId', aId), { replace: true });
            });
        }
    };

    const selectedChannel = liveAssets && liveAssets.find((l) => l.id === assetId);
    return { changeChannel, liveAssets, selectedChannel, onNowProgram, upNextProgram };
};

export default useMiniEPG;
