import Storage from './storage';

export const maxOnboardingIndex = 2;

export const disableOnboarding = () => {
    Storage.appendItem('u', { hideOnboarding: true });
};

export const shouldShowUserOnboarding = () => {
    const { hideOnboarding } = Storage.getItem('u') || {};
    if (!hideOnboarding) {
        // Storage.appendItem('u', { hideOnboarding: true });
        // Setting this only after all user went through all slides.
        return true;
    }
};
