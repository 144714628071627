import { PlayerClass } from '../player';
import { BitmovinOptusPlayer } from '..';

export function destroy(this: PlayerClass) {
    if (this.playerState !== 'DESTROYED') {
        this.player = null;
        this.zombieKicked = false;
        this.playerState = 'DESTROYING';
        return BitmovinOptusPlayer.destroy();
    }
    return Promise.resolve();
}
