import { useEffect } from 'react';
import { useStateValue } from 'src/state';
import { getLayout } from 'src/state/stores/dashboard/dashboard.actions';
import useExplore from './exploreRail.controller';
import { useShimmer } from './shimmer.controller';
import useSidebar from './sidebar.controller';
import useContentCardFetch from './dashboard/contentCardsFetch.controller';
import { noop } from 'src/utilities/op';

const useDashboard = () => {
    const { state, dispatch } = useStateValue();
    const { selectedSidebarItem } = useSidebar(true);
    useExplore(true);
    const { setShowShimmer } = useShimmer();
    const {
        dashboardData: { layoutPending, layout },
    } = state;
    useContentCardFetch();
    useEffect(() => {
        const { href, layout: layoutUrl, layoutId } = selectedSidebarItem || {};
        if (href && layoutUrl && layoutId && layoutPending[layoutId] === undefined) {
            dispatch(getLayout(layoutUrl, layoutId)).catch(noop);
        }
    }, [dispatch, setShowShimmer, selectedSidebarItem, layoutPending]);

    return { layout };
};

export default useDashboard;
