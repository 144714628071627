import { CONFIG } from 'src/config/config';
import {
    getAnalytics,
    getBasicAppProperties,
    getContext,
    loadSegmentIO,
    setProperty,
} from './utils';

import { LOGGED_OUT_USER_TYPE, appProperties } from './constants';
import { UserType } from 'src/state/stores/user/user';
import { hidePii } from 'src/utilities/hidePii';
import { segmentDebugger } from './utils/debugger';
import { getDeviceId } from 'src/utilities/device';
import { getRefreshToken } from 'src/utilities/session';

/**
 * Collection of util functions to log segment events.
 */
class SegmentIOClass {
    user: UserType | null = null;
    isInitialized: boolean = false;

    eventsQueue: (() => void)[] = [];

    init = () => {
        if (!this.isInitialized) {
            // isSegmentClientInitalized = true;
            loadSegmentIO(CONFIG.segmentLibUrl, () => {
                this.isInitialized = true;
                this.processQueue();
            });
            const userId = getRefreshToken() ? null : getDeviceId();
            setProperty(appProperties.ENV, CONFIG.env, userId);
        }
    };

    destroy = () => {};

    processQueue = () => {
        while (this.eventsQueue.length) {
            const t = this.eventsQueue.pop();
            if (t) {
                t();
            }
        }
    };

    track = (name: string, category: string, type: string = 'application', params?: object) => {
        if (name === '') return;
        let eventParam: any = getBasicAppProperties(this.user, name, category, type);
        const context = getContext();
        const analytics = getAnalytics();

        if (params) {
            eventParam = { ...eventParam, ...hidePii(params) };
        }
        if (this.isInitialized && analytics) {
            segmentDebugger.log('tracking event', name, eventParam);
            analytics.track(name, eventParam, { context }, (err: any) => {
                if (err) {
                    segmentDebugger.log('tracked', err);
                }
            });
        } else {
            this.eventsQueue.push(() => {
                this.track(name, category, type, params);
            });
        }
    };

    trackPage = (pageName: string, params: any = {}) => {
        const analytics = getAnalytics();
        if (this.isInitialized && analytics) {
            segmentDebugger.log('tracking page', pageName, params);
            const context = getContext();
            analytics.page(pageName, params, { context }, (err: any) => {
                if (err) {
                    segmentDebugger.log('tracked', err);
                }
            });
        } else {
            this.eventsQueue.push(() => {
                this.trackPage(pageName, params);
            });
        }
    };

    setUserProperties = (user: UserType) => {
        this.user = user;
        const { analyticUserId = getDeviceId(), type = LOGGED_OUT_USER_TYPE } = user || {};
        const userID = analyticUserId;
        const userType = type || '';
        setProperty(appProperties.ANALYTICS_ID, userID, userID);
        setProperty(appProperties.TYPE, userType, userID);
    };

    resetAnalytics = () => {
        getAnalytics().reset();
    };
}

const SegmentIO = new SegmentIOClass();
export default SegmentIO;
