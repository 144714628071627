import { useMemo } from 'react';
import { useStateValue } from 'src/state';

import useExplore from './exploreRail.controller';
import useLayout from './layout.controller';
import usePage from 'src/lib/OLRUD/controllers/page.controller';

const usePanels = () => {
    const { layoutId } = useLayout();
    const { state } = useStateValue();
    const {
        dashboardData: { layoutFetchFailed, panelData, panelsToRender },
    } = state;
    const { isBrowse } = useExplore();
    const { pageId } = usePage();

    let noLayout = useMemo(() => {
        return layoutFetchFailed[layoutId];
    }, [layoutId, layoutFetchFailed]);

    const panels = panelsToRender[pageId] || [];

    return {
        panels,
        isBrowse,
        error: noLayout,
        panelData,
    };
};

export default usePanels;
