export const LOGIN_BUTTON = 'Login';
export const LOGGED_OUT_USER_TYPE = 'logged-out-user';

export enum eventCategories {
    API_USER = 'user',
    APP_USER = '',
    APP = '',
    OS_SETTINGS_PAGE = 'os_settings_page',
}

export enum appProperties {
    ENV = 'environment',
    TYPE = 'type', // basic or premium
    ANALYTICS_ID = 'vimondId',
}

export enum eventTypes {
    APP = 'application',
    API = 'api',
}

export enum screenViews {
    Login = 'Login',
    Logout = 'Signout',
}
