import { AssetType, CardLayoutType } from 'src/state/stores/dashboard/dashboard';
import CardBody from './CardBody';
import styles from './card.module.scss';
import React from 'react';
import { getCardSize } from './cardsizes';
import { isAssetPremium } from 'src/utilities/asset';
import useViewAll from 'src/controllers/viewAll.controller';

export interface CardProps {
    asset?: AssetType;
    cardLayout: CardLayoutType;
    isExploreCard?: boolean;
    panelId: string;
    cardIndex: number;
    panelIndex: number;
    focused: boolean;
}

const Card = (props: CardProps) => {
    const { asset, cardLayout, panelId, cardIndex, panelIndex, focused } = props;
    const { isViewAll } = useViewAll();

    // variables
    const cardSize = getCardSize(cardLayout);
    const { height, width, margin } = cardSize || {};
    const { accessGroups } = asset || {};

    const cardStyle = {
        width,
        height,
        transform: isViewAll
            ? undefined
            : `translate3d(${(width + margin * 2) * cardIndex}px, 0, 0)`,
    };
    const cardClasses = [styles.card];
    const isFreeAsset = !isAssetPremium(accessGroups || []);

    if (styles[cardLayout]) {
        cardClasses.push(styles[cardLayout]);
    }
    if (isViewAll) {
        cardClasses.push(styles.viewAll);
    }

    return (
        <div className={cardClasses.join(' ')} style={cardStyle} data-asset-type={asset?.type}>
            {asset ? (
                <CardBody
                    asset={asset}
                    cardIndex={cardIndex}
                    cardLayout={cardLayout}
                    isFreeAsset={isFreeAsset}
                    panelId={panelId}
                    panelIndex={panelIndex}
                    focused={focused}
                />
            ) : null}
        </div>
    );
};
export default React.memo(Card);
