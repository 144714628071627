import { memo, useCallback, useEffect, useState } from 'react';
import { FocusNode } from 'lal-lrud';
import Button from 'src/components/Common/Button';
import IconImg from 'src/components/Icons/IconImg';
import PlayerUISettingsTrolley from './common/UISettingsTrolley';
import Typography from 'src/components/Common/Typography';
import usePlayer from 'src/controllers/player.controller';
import { getLanguageLabel } from 'src/utilities/lang';
import { AudioTrackType } from 'src/lib/bitmovin/player.d';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';
import styles from './common/settings.module.scss';

const AudioOptions = () => {
    const [tracks, setTracks] = useState<AudioTrackType[]>([]);
    const { Player } = usePlayer();

    useEffect(() => {
        Player.ready().then(() => {
            setTracks(Player.getAudioTracks());
        });
    }, [Player]);

    useEffect(() => {
        const Player = getActivePlayer();
        const handlerChange = (e: any) => {
            setTracks(Player.getAudioTracks());
        };

        Player.ready().then(() => {
            Player.player.on('audiochanged', handlerChange);
        });

        return () => {
            Player.player.off('audiochanged', handlerChange);
        };
    }, []);

    const setAudio = useCallback(
        (track: string) => {
            Player.setAudioTrack(track);
            setTracks(Player.getAudioTracks());
        },
        [Player]
    );

    const selectedTrackIndex = tracks.findIndex((track) => track.selected);
    if (tracks.length <= 1) {
        return <FocusNode disabled></FocusNode>;
    }
    return (
        <div className={styles.section}>
            <Typography className={styles.title}>Audio</Typography>
            <PlayerUISettingsTrolley index={selectedTrackIndex}>
                {tracks.map((track) => (
                    <div
                        className={[
                            styles.track,
                            // @ts-ignore
                            track.selected ? styles.selected : undefined,
                        ].join(' ')}
                        key={track.id}
                    >
                        <IconImg icon={'tickSoloGreen'}></IconImg>
                        <Button
                            focusId={`audio-${track.id}`}
                            onClick={() => setAudio(track.id)}
                            open
                            className={styles.title}
                            classNameFocused={styles.focused}
                        >
                            {getLanguageLabel(track.lang) || track.label}
                        </Button>
                    </div>
                ))}
            </PlayerUISettingsTrolley>
        </div>
    );
};

export default memo(AudioOptions);
