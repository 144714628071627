import { useEffect, useMemo } from 'react';
import { getCardSize } from 'src/components/ui/Card/cardsizes';
import { CONFIG } from 'src/config/config';
import { useStateValue } from 'src/state';
import { AssetType, PanelType, PanelTypeType } from 'src/state/stores/dashboard/dashboard';
import { getPanel, getUserFavourites } from 'src/state/stores/dashboard/dashboard.actions';
import { cacheImages } from 'src/utilities/image';
import {
    BROWSE_PANEL_TYPE,
    CAROUSEL_PANEL_TYPE,
    CC_BANNER_PANEL_TYPE,
    CC_DYNAMIC_PANEL_TYPE,
    CC_FAVOURITE_DATASOURCE,
    EXPLORE_PANEL_TYPE,
} from 'src/utilities/panel';
import { processImageUrl } from 'src/utilities/url';
import useLayout from './layout.controller';
import { useShimmer } from './shimmer.controller';
import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';
import {
    validateCCBannerAsset,
    validateCCDynamicPanelAsset,
    validateCCFavPanelAsset,
    validateCCPanel,
} from 'src/utilities/contentCards';
import useContentCards from './dashboard/contentCards.controller';
import useFavouriteTeam from './favouriteTeam.controller';
import { noop } from 'src/utilities/op';

interface PanelDataProp {
    bucketKey?: string;
    focusedPanelIndex: number;
}

const panelTypes: PanelTypeType[] = [BROWSE_PANEL_TYPE, CAROUSEL_PANEL_TYPE, EXPLORE_PANEL_TYPE];
if (CONFIG.contentCardCMSActive) {
    panelTypes.push(CC_BANNER_PANEL_TYPE, CC_DYNAMIC_PANEL_TYPE);
}

// A controller to fetch the panel data
const usePanelsData = (prop: PanelDataProp) => {
    const { focusedPanelIndex, bucketKey } = prop;
    const { state, dispatch } = useStateValue();
    const { layout, layoutId } = useLayout();
    const { setShowShimmer } = useShimmer();
    const favouriteTeamId = useFavouriteTeam();

    const {
        dashboardData: { panelData, panelDataPending },
    } = state;
    const contentCards = useContentCards();

    const fetchCarousel: PanelType[] | null = useMemo(() => {
        let fc: PanelType[] = [];
        if (layout && layoutId) {
            const { panels: sPanels } = layout;
            const carousels = sPanels.filter((p) => {
                // TODO: Improve this logic
                if (p.type === CAROUSEL_PANEL_TYPE) {
                    return true;
                } else if (
                    CONFIG.contentCardCMSActive &&
                    p.type === CC_DYNAMIC_PANEL_TYPE &&
                    p.assetId &&
                    contentCards[p.assetId] &&
                    validateCCPanel(p, contentCards[p.assetId])
                ) {
                    if (
                        (favouriteTeamId && validateCCFavPanelAsset(contentCards[p.assetId])) ||
                        validateCCDynamicPanelAsset(contentCards[p.assetId]) ||
                        validateCCBannerAsset(contentCards[p.assetId])
                    ) {
                        return true;
                    }
                }

                return false;
            });

            if (bucketKey && contentCards[bucketKey]) {
                // View All, Dynamic/Fav Rail
                fc = carousels.filter((p) => {
                    if (p.assetId === bucketKey) {
                        return true;
                    }
                    return false;
                });
            } else if (bucketKey) {
                // View All
                fc = carousels.filter((p) => p.bucketKey === bucketKey);
            } else {
                const cStartIndex = 0;
                const cEndIndex = focusedPanelIndex + CONFIG.preFetchPanelsCount;
                fc = carousels.slice(cStartIndex, cEndIndex);
            }

            const fcn = fc.map((p) => {
                if (p.assetId && contentCards[p.assetId]) {
                    const ccAsset = contentCards[p.assetId];
                    if (validateCCDynamicPanelAsset(ccAsset) && ccAsset.contentCard) {
                        const { bucketKey: ccBucketKey } = ccAsset.contentCard;
                        if (panelDataPending[`${layoutId}_${ccBucketKey}`] === undefined) {
                            return {
                                ...p,
                                bucketKey: ccBucketKey,
                            };
                        }
                    } else if (validateCCFavPanelAsset(ccAsset) && favouriteTeamId) {
                        if (panelDataPending[`${favouriteTeamId}`] === undefined) {
                            return {
                                ...p,
                                bucketKey: favouriteTeamId,
                            };
                        }
                    }
                    return null;
                }

                if (panelDataPending[`${layoutId}_${p.bucketKey}`] === undefined) {
                    return p;
                }
                return null;
            });

            fc = fcn.filter((p) => p != null) as PanelType[];
        }
        // Null instead of empty array will prevent effects trigger
        return fc.length ? fc : null;
    }, [
        bucketKey,
        focusedPanelIndex,
        layout,
        layoutId,
        panelDataPending,
        contentCards,
        favouriteTeamId,
    ]);

    // Handle fetch for Favourite Panels
    useEffect(() => {
        if (
            favouriteTeamId &&
            panelDataPending[favouriteTeamId] === undefined &&
            fetchCarousel &&
            fetchCarousel.length
        ) {
            const anyValidFavPanel = fetchCarousel.some((c) => {
                return (
                    c.type === 'dynamic_rail' &&
                    c.assetId &&
                    contentCards[c.assetId] &&
                    contentCards[c.assetId].contentCard?.dataSource === CC_FAVOURITE_DATASOURCE
                );
            });
            if (anyValidFavPanel) {
                dispatch(getUserFavourites(favouriteTeamId));
            }
        }
    }, [fetchCarousel, favouriteTeamId, contentCards, panelData, panelDataPending, dispatch]);

    // Handle fetch for Non Favourite Panels
    useEffect(() => {
        const requests: any[] = [];
        fetchCarousel?.forEach((c) => {
            if (c.bucketKey && layoutId) {
                const s = dispatch(getPanel(c.bucketKey, layoutId));
                requests.push(s);
            }
        });

        if (!requests.length) {
            setTimeout(() => {
                setShowShimmer(false);
            }, 1000);
            return;
        }
        if (focusedPanelIndex === 0) {
            setShowShimmer(true);
        }
        Promise.all(requests)
            .then((pData) => {
                const imagesToCache: string[] = [];
                pData.forEach((p, i) => {
                    if (!(fetchCarousel && fetchCarousel[i])) {
                        return;
                    }
                    const cardLayout = fetchCarousel[i].layout;
                    const cardSize = getCardSize(cardLayout);
                    const { imageKey, imgHeight, imgWidth } = cardSize;
                    const assets = p.payload?.assets || [];
                    assets.slice(0, CONFIG.preFetchImagesOfNAssets).forEach((asset: AssetType) => {
                        const bgUrl = asset[imageKey];
                        const backgroundImage = bgUrl
                            ? processImageUrl(bgUrl, imgWidth, imgHeight)
                            : stockBackground;
                        imagesToCache.push(backgroundImage);
                    });
                });
                cacheImages(imagesToCache).then(() => {
                    setShowShimmer(false);
                });
            })
            .catch(noop);
    }, [contentCards, fetchCarousel, focusedPanelIndex, layoutId, setShowShimmer, dispatch]);
};

export default usePanelsData;
