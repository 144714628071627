import { mapObjIndexed, type, equals, toLower, map } from 'ramda';
import { includes } from 'lodash';
import { REDACTED_PERSONALLY_IDENTIFIABLE_INFORMATION } from 'src/state/stores/user/user.constants';

const typeIs = (expected: any) => (original: any) => equals(type(original), expected);
const isObjectLiteral = typeIs('Object');
const isArrayLiteral = typeIs('Array');
const isStringLiteral = typeIs('String');

const replaceNeedlesInHaystack = (haystack: any) => {
    // Make lowercase on needles array
    const lowerCaseNeedles = map(toLower, Object.keys(NEEDLES));

    const walkBackwards: any = mapObjIndexed((value, key) => {
        if (isArrayLiteral(value)) {
            return (value as []).map(walkBackwards);
        }

        if (isStringLiteral(value)) {
            try {
                const parsedJSON = JSON.parse(value as string);
                return walkBackwards(parsedJSON);
            } catch {}
        }

        const isNestedHaystack = isObjectLiteral(value);
        // Make key lowercase
        const isMatchingNeedle = includes(lowerCaseNeedles, toLower(key));

        if (isNestedHaystack) return walkBackwards(value);
        if (isMatchingNeedle) return REDACTED_PERSONALLY_IDENTIFIABLE_INFORMATION;
        return value;
    });
    return walkBackwards(haystack);
};

const NEEDLES = {
    userName: 'userName',
    password: 'password',
    AccessToken: 'AccessToken',
    RefreshToken: 'RefreshToken',
    IdToken: 'IdToken',
    Authorization: 'Authorization',
    AccessAuthorization: 'AccessAuthorization',
    license: 'license',
    drmData: 'drmData',
};

const hidePii = (jsonObject: any) => {
    return replaceNeedlesInHaystack(jsonObject);
};

export { hidePii };
