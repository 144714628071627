import { PlayerClass } from '../player';

export function qStatus(this: PlayerClass) {
    const qs = {
        isStalled: this.player.isStalled(),
        isPlaying: this.player.isPlaying(),
        isPaused: this.player.isPaused(),
    };
    // log(qs);
    return qs;
}
