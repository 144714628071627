import { Config } from '../config';

const config_debug: Partial<Config> = {
    // Debug Config
    debug: true,
    MUXDebug: true,
    SegmentDebug: true,
    showPlayerStatus: true,
    YoSpaceDebug: true,
};

export default config_debug;
