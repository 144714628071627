export const cacheImages = (images: string[]) => {
    const requests: any[] = images.map((src) => {
        return new Promise((resolve) => {
            const image = new Image();
            image.onload = resolve;
            image.onerror = resolve;
            image.src = src;
        });
    });
    return Promise.all(requests);
};
