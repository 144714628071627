import { FocusNode } from 'lal-lrud';
import { MoveEvent } from 'lal-lrud/dist/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './settings.module.scss';

type PlayerUISettingsTrolleyProps = {
    children: React.ReactNode;
    index: number;
};

const PlayerUISettingsTrolley = (props: PlayerUISettingsTrolleyProps) => {
    const { children, index: initialIndex } = props;
    const currentElRef = useRef(initialIndex);

    const [verticalStyle, setVerticalStyle] = useState({ transform: `translateY(0)` });
    const [anim, setAnim] = useState(false);

    const setTranslation = useCallback((currentEl: any, parentEl: any, index: number) => {
        const childHeight = currentEl.current ? outerHeight(currentEl.current) : 0;
        const parentHeight = parentEl.current?.offsetHeight ?? 0;

        const childCentrePosition = childHeight * index + childHeight / 2;
        const halfParentHeight = parentHeight / 2;
        if (childCentrePosition > halfParentHeight) {
            setVerticalStyle({
                transform: `translateY(${halfParentHeight - childCentrePosition}px)`,
            });
        } else {
            setVerticalStyle({
                transform: `translateY(0)`,
            });
        }
    }, []);

    const onMove = (e: MoveEvent) => {
        const currentEl = e.nextChildNode.elRef;
        const parentEl = e.node.elRef;
        currentElRef.current = e.nextChildIndex;
        setTranslation(currentEl, parentEl, e.nextChildIndex);
    };

    const onFocused = (e: any) => {
        setTranslation(e.focusNode.elRef, e.currentNode.elRef, currentElRef.current);
    };

    useEffect(() => {
        setTimeout(() => {
            setAnim(true);
        }, 500);
    }, []);

    const trollyClasses = [styles.settingsTrolley];
    if (anim) {
        trollyClasses.push(styles.anim);
    }
    return (
        <FocusNode
            orientation="vertical"
            onMove={onMove}
            onFocused={onFocused}
            className={styles.section}
            defaultFocusChild={currentElRef.current}
        >
            <div className={trollyClasses.join(' ')} style={verticalStyle}>
                {children}
            </div>
        </FocusNode>
    );
};

const outerHeight = (element: HTMLElement) => {
    const height = element.offsetHeight,
        style = window.getComputedStyle(element);

    return [style.marginTop, style.marginBottom]
        .map((side) => parseInt(side))
        .reduce((total, side) => total + side, height);
};

export default PlayerUISettingsTrolley;
