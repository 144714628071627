import { useEffect } from 'react';
import useUser from './user.controller';
import { useStateValue } from 'src/state';
import { getUserDetail } from 'src/state/stores/user/user.actions';
import { Analytics } from 'src/lib/Analytics';
import { noop } from 'src/utilities/op';

const useFetchUserDetail = () => {
    const { auth, userData } = useUser();
    const { dispatch } = useStateValue();

    useEffect(() => {
        if (auth && userData === undefined) {
            setTimeout(() => {
                dispatch(getUserDetail(auth.userId))
                    .then((response) => {
                        Analytics.logGetUserDetails(response.http);
                    })
                    .catch(noop);
            });
        }
    }, [auth, userData, dispatch]);
};

export default useFetchUserDetail;
