import styles from './banner.module.scss';
import focusIds from 'src/constants/focusIds';
import { FocusNode, useSetFocus } from 'lal-lrud';
import { useCallback, useEffect, useMemo } from 'react';
import { useStateValue } from 'src/state';
import { showOverlay } from 'src/state/stores/dashboard/dashboard.actions';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import useContentCardAsset from 'src/controllers/dashboard/contentCards.asset.controller';
import { processImageUrl } from 'src/utilities/url';

export const BannerOverlay = () => {
    const {
        dispatch,
        state: {
            dashboardData: { overlayAsset },
        },
    } = useStateValue();

    const { ccAsset: asset } = useContentCardAsset(overlayAsset || undefined);
    const { images, imageUrl: dImageUrl } = asset || {};

    const setFocus = useSetFocus();

    const onClose = useCallback(() => {
        dispatch(showOverlay(null));
    }, [dispatch]);

    let callBack = useCallback(() => {
        if (asset) {
            onClose();
            return true;
        }
        return false;
    }, [asset, onClose]);
    useBackButton({ callBack, id: 'cc overlay', rank: 5 });

    useEffect(() => {
        if (asset) {
            setFocus(focusIds.dashboardOverlay);
        } else {
            setFocus(focusIds.dashboardBody);
        }
    }, [asset, setFocus]);

    const bgStyle = useMemo(() => {
        const { overlay = { 'imageUrl_11:4': dImageUrl } } = images || {};

        const imageUrl = overlay['imageUrl_11:4']
            ? processImageUrl(overlay['imageUrl_11:4'], 1664, 625)
            : '';
        return {
            backgroundImage: `url(${imageUrl})`,
            color: '#FFF',
        };
    }, [dImageUrl, images]);

    if (!asset) {
        return <></>;
    }

    return (
        <FocusNode
            focusId={focusIds.dashboardOverlay}
            className={styles.backdrop}
            isTrap
            onClick={onClose}
        >
            <div className={styles.banner} style={bgStyle}></div>
        </FocusNode>
    );
};
