import { memo } from 'react';
import { PanelType } from 'src/state/stores/dashboard/dashboard';
import { stringToSlug } from 'src/utilities/string';
import Cards from '../Cards';
import styles from './panel.module.scss';
import { getRailHeight } from '../Card/cardsizes';
import usePanel from 'src/controllers/panel.controller';
import useOLRUDPanelTops from 'src/lib/OLRUD/controllers/oLRUDPanelTops.controller';
import useOLRUD from 'src/lib/OLRUD/controllers/oLRUD.controller';
import useContentCardVisible from 'src/controllers/dashboard/contentCards.log.visible.controller';

export interface PanelProps {
    panel: PanelType;
    index: number;
}

const Panel = (props: PanelProps) => {
    const { panel, index } = props;
    const { title, bucketKey, layout, id: panelId, maxTileCount, assetId } = panel;

    const railHeight = getRailHeight(layout);
    const { panelTops } = useOLRUDPanelTops();
    const { assets, isBrowse, ccAsset } = usePanel({
        assetId,
        bucketKey,
        layout,
        maxTileCount: +maxTileCount,
        panelId,
    });

    const panelClasses = [styles.panel];
    if (title) {
        panelClasses.push(stringToSlug(title));
    }

    if (isBrowse) {
        panelClasses.push(styles.browse);
    }
    const panelTop = isBrowse ? 0 : panelTops[index];
    // const panelStyle = {
    //     transform: `translateY(${panelTop}px)`,
    // };
    const panelStyle = {
        top: `${panelTop}px`,
    };

    const {
        focusedPanelIndex,
        focusedCardIndex = 0,
        panelTranslateX = 0,
        active,
    } = useOLRUD(index);

    const focused = active && focusedPanelIndex === index;

    useContentCardVisible(ccAsset?.contentCard, index, true, focused && !!assets.length);

    return (
        <div className={panelClasses.join(' ')} style={panelStyle}>
            <div style={{ height: railHeight }}>
                <Cards
                    title={title}
                    assets={assets}
                    cardLayout={layout}
                    panelId={panelId}
                    focused={focused}
                    focusedCardIndex={focusedCardIndex}
                    panelTranslateX={panelTranslateX}
                    panelIndex={index}
                />
            </div>
        </div>
    );
};

export default memo(Panel);
