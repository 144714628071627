import { FocusEvent } from 'lal-lrud/dist/types';
import { useCallback, useRef, useState } from 'react';
import { screenHeight } from 'src/utilities/device';

export const useVodDetailFocus = () => {
    const translateY = useRef(0);
    const [isRelatedPanelFocused, setIsRelatedPanelFocused] = useState(false);

    const onRelatedAssetsFocus = useCallback((e: FocusEvent) => {
        const el = e.currentNode?.elRef.current;
        const elHeight = el?.offsetHeight || 0;
        const parent = el?.parentElement;
        const parentHeight = parent?.offsetHeight || 0;
        const lastChildHeight = el?.lastElementChild?.clientHeight || 0;

        const y = el?.offsetTop || 0;

        const defaultBottomOffset = screenHeight - y;
        const offsetDifference = lastChildHeight - defaultBottomOffset;

        let translate = defaultBottomOffset - offsetDifference;

        if (
            parentHeight > screenHeight &&
            translate + elHeight >= parentHeight - screenHeight / 2
        ) {
            translate = parentHeight - screenHeight;
        }

        translateY.current = -translate;
        setIsRelatedPanelFocused(true);
    }, []);

    const onRelatedAssetsBlur = useCallback(() => {
        translateY.current = 0;
        setIsRelatedPanelFocused(false);
    }, []);

    return {
        isRelatedPanelFocused,
        translateY: translateY.current,
        onRelatedAssetsFocus,
        onRelatedAssetsBlur,
    };
};
