import styles from './competition.module.scss';
import { CompetitionIds, competitionsConfig } from 'src/config/competitionConfig';
import { memo } from 'react';

type IconProps = {
    competitionId: CompetitionIds;
    className?: string;
};

const CompetitionBg = (props: IconProps) => {
    const { competitionId, className } = props;
    const iconClasses = [styles.icon, className];

    if (!competitionsConfig[competitionId]) {
        return null;
    }
    const imgSrc = competitionsConfig[competitionId].tileBackgroundImage;
    const bgStyle = {
        backgroundImage: `url(${imgSrc})`,
    };

    return <div className={iconClasses.join(' ')} style={bgStyle} />;
};

export default memo(CompetitionBg);
