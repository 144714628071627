import { useProcessKey } from 'lal-lrud';
import { useEffect } from 'react';
import throttle from 'lodash.throttle';
import { CONFIG } from 'src/config/config';

export const useMagicRemote = () => {
    const processKey = useProcessKey();

    useEffect(() => {
        if (!CONFIG.MRActive) {
            return;
        }
        const handleOnWheel = throttle((e: any) => {
            if (e.deltaY > 0) {
                // 'down'
                processKey.down();
            } else {
                // 'up'
                processKey.up();
            }
        }, CONFIG.MRThrottleDelta);

        document.addEventListener('wheel', handleOnWheel);

        return () => {
            document.removeEventListener('wheel', handleOnWheel);
        };
    }, [processKey]);
};
