import { getExploreLayout } from 'src/state/stores/dashboard/dashboard.actions';
import { useStateValue } from 'src/state';
import { exploreLayoutMap } from 'src/config/exploreRailData';
import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import RouteTags, { getRouteTags } from 'src/routes/routeTags';
import { useCallback, useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useShimmer } from './shimmer.controller';

const useExplore = (fetch: boolean = false) => {
    const { state, dispatch } = useStateValue();
    const { dashboard, explore } = useParams();
    const isBrowse = !!useMatch(RouteTags.browse);
    const isDExplore = !!useMatch(RouteTags.dashboardExplore);
    const isBExplore = !!useMatch(RouteTags.browseExplore);
    const navigate = useNavigate();

    const { setShowShimmer } = useShimmer();

    const {
        dashboardData: { layoutPending, panelData },
    } = state;

    /**
     * This functions loads the explore landing pages of the assets in the rail, works for both explore rail in the fitness / football page and the rails in the browse page
     * @param asset selected asset card from the explore rail
     */
    const navigateToExplore = useCallback(
        (asset: AssetType) => {
            if (dashboard) {
                const route = getRouteTags(
                    'dashboardExplore',
                    ['dashboard', 'explore'],
                    [dashboard, asset.id]
                );
                setShowShimmer(false, () => {
                    navigate(route);
                });
            } else if (isBrowse) {
                const route = getRouteTags('browseExplore', 'explore', asset.id);
                navigate(route);
            }
        },
        [dashboard, isBrowse, navigate, setShowShimmer]
    );

    useEffect(() => {
        if (fetch && explore && layoutPending[explore] === undefined) {
            const bucketKey = exploreLayoutMap[explore];
            dispatch(getExploreLayout(bucketKey, explore));
        }
    }, [dispatch, explore, layoutPending, fetch]);

    let exploreAssets: AssetType[] = useMemo(() => {
        const eAs: AssetType[] = [];
        Object.keys(panelData).forEach((p) => {
            if (panelData[p]?.type === 'explore' || panelData[p]?.type === 'browse') {
                eAs.push(...(panelData[p].assets || []));
            }
        });
        return eAs;
    }, [panelData]);

    const asset = useMemo(() => {
        if (!explore) {
            return null;
        }

        const asset = exploreAssets.find((a) => a.id === explore);
        return asset;
    }, [explore, exploreAssets]);

    return {
        exploreAssets,
        navigateToExplore,
        asset,
        isBrowse,
        isExplore: isDExplore || isBExplore,
    };
};
export default useExplore;
