import Storage from './storage';
import { log } from './console';

function safeGet(fn: any) {
    try {
        var t = fn.call();
        return t;
    } catch (error: any) {
        if (error.message.indexOf('undefined') === -1) {
            log('=====> Error getting Vidaa APIs', JSON.stringify(error));
            // Error, such as a missing privilege
        } else {
            log('=====> Error getting Vidaa APIs - Older Firmware');
            // Undefined error
            // Older firmware and models do not support this method
            // Consider using a legacy method
        }
        return 'null';
    }
}

export const isVidaaOS = () => !!window.vidaatv;
console.log('Vidaa TV:', window.vidaatv);

export const getVidaaOSVersion = () => {
    const vidaaTv = window.vidaatv;

    if (vidaaTv) {
        const vidaaVersion = safeGet(vidaaTv.getOSVersion);
        const parsedVidaaVersion = vidaaVersion && vidaaVersion.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except for dot (.)
        return parseFloat(parsedVidaaVersion).toString() ?? '0';
    }

    return '0';
};

export const getVidaaDeviceModel = () => {
    const vidaaTv = window.vidaatv;
    let { deviceModel: vidaaModel } = Storage.getItem('d') ?? '';

    if (vidaaTv && !vidaaModel) {
        Storage.setItem('d', { deviceModel: '' });
        const modelValue = safeGet(vidaaTv.getModelName);
        if (modelValue) {
            log('====> getVidaaDeviceModel = ', modelValue);
            Storage.appendItem('d', { deviceModel: modelValue });
            vidaaModel = modelValue;
        }
    }
    return vidaaModel ?? null;
};

export const getVidaaDeviceMake = () => {
    const vidaaTv = window.vidaatv;
    let { deviceMake: vidaaMake } = Storage.getItem('d') ?? '';

    if (vidaaTv && !vidaaMake) {
        Storage.setItem('d', { deviceMake: '' });
        const brandName = safeGet(vidaaTv.getBrand);
        if (brandName) {
            log('====> getVidaaDeviceMake = ', brandName);
            Storage.appendItem('d', { deviceMake: brandName });
            vidaaMake = brandName;
        }
    }
    return vidaaMake ?? null;
};

// Uncomment this we need to use advanced Features for Hisense
// export const initVidaaSdk = () => {
//     const vidaaOS = window.vidaatv;
//     vidaaOS
//         .config({
//             debug: boolean, // If debugging mode is enabled, necessary logs are printed
//             client_id: string, // your client_id in VIDAAOS
//             apiList: [
//                 'ads',
//                 'pay',
//             ],
//         })
//         .then((pluginLoaded: any) => {
//             // Plugin loaded success
//             // do something
//             console.log(pluginLoaded);
//         })
//         .catch((pluginLoadFail: any) => {
//             // Plugin loaded fail
//             // do something
//             console.error(pluginLoadFail);
//         });
// };
