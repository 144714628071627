import { CONFIG } from 'src/config/config';

import { AssetType, ContentCard } from 'src/state/stores/dashboard/dashboard';

import SegmentIO from './Segment';
import { dataDog } from './datadog';
import { apiEvents, appEvents, pageEvents, playerEvents } from './Segment/events';

import Braze from '../braze';
import { safeClass } from 'src/utilities/decorators';
import { LOGIN_BUTTON, eventCategories, eventTypes, screenViews } from './Segment/constants';
import { formatName, getAssetSchema } from './Segment/utils';
import { UserType } from 'src/state/stores/user/user';
import { Segment } from 'src/lib/bitmovin/segment';
import {
    ContentCardActionPerfomedType,
    ContentCardPositionType,
    PanelPositionType,
} from './analytics';

const LOG_OUT = 'Logout';

@safeClass
class AnalyticsClass {
    logoutClicked: boolean = false;
    isFirstRender: boolean = true;
    currentPage: keyof typeof pageEvents | null = null;
    init() {
        if (CONFIG.isSegmentEnabled) {
            SegmentIO.init();
        }

        if (CONFIG.isDatadogRumEnabled) {
            dataDog.init();
        }
    }

    setUserProperties(user: UserType) {
        if (CONFIG.isSegmentEnabled) {
            SegmentIO.setUserProperties(user);
        }

        if (CONFIG.isDatadogRumEnabled) {
            dataDog.setUserProperties(user);
        }
    }

    // Page Navigation
    logPageNavigation(_pageKey: string, params: object = {}) {
        this.currentPage = null;
        const pageKey = _pageKey as keyof typeof pageEvents;
        const config = pageEvents[pageKey];
        if (config) {
            this.currentPage = pageKey;
            const { name, brazeActive, segmentActive: segmentPageActive } = config;
            if (CONFIG.isSegmentEnabled && segmentPageActive) {
                SegmentIO.trackPage(name, { ...params });
            }

            const { segmentActive } = appEvents.navigation;
            if (segmentActive) {
                const eventCategory = 'os_' + name.toLowerCase() + '_page';
                SegmentIO.track(appEvents.navigation.name, eventCategory, eventTypes.APP, params);
            }

            if (CONFIG.BrazeEventsActive && brazeActive) {
                Braze.track(name, { ...params });
            }
        }
    }

    // Settings Menu
    logSettingsSubMenu(title: string, isRight?: boolean) {
        if (CONFIG.isSegmentEnabled) {
            let extraParam = {
                name: formatName(title),
                screen: formatName(title),
            };

            const action = isRight || title === LOGIN_BUTTON ? 'Clicked' : 'Screen Viewed';
            const eventName = `${title} ${action}`;

            SegmentIO.track(
                eventName,
                eventCategories.OS_SETTINGS_PAGE,
                eventTypes.APP,
                extraParam
            );
        }
        if (title === LOG_OUT) {
            this.logSignOutClicked();
        } else {
            this.logSignOutCancelled();
        }
    }

    // Api Events
    logAuthInit(http: any) {
        const { name, category, type, brazeActive, segmentActive } = apiEvents.authInit;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, http);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, { ...http });
        }
    }

    logLoginSuccess(http: any) {
        const { name, category, type, brazeActive, segmentActive } = apiEvents.loginSuccess;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, http);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, { ...http });
        }
    }

    logLoginFailed(errParam: any) {
        const { name, category, type, brazeActive, segmentActive } = apiEvents.loginFailed;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, errParam);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, { ...errParam });
        }
    }

    logGetUserDetails(http: any) {
        const { name, category, type, brazeActive, segmentActive } = apiEvents.getUserDetails;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, http);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, { ...http });
        }
    }

    logLogoutSuccess(http: any) {
        const { name, category, type, brazeActive, segmentActive } = apiEvents.logoutSuccess;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, http);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, { ...http });
        }
    }

    logLogoutFailed(errParam: any) {
        const { name, category, type, brazeActive, segmentActive } = apiEvents.logoutFailed;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, errParam);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, { ...errParam });
        }
    }

    // App Events
    logAppLaunch() {
        const { name, category, type, brazeActive, segmentActive } = appEvents.launch;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type);
        }

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }
    logUserLoggedIn() {
        const { name, category, type, brazeActive, segmentActive } = appEvents.userLoggedIn;
        let extraParam = {
            screen_view: screenViews.Login,
        };

        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, extraParam);
        }

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, extraParam);
        }

        if (CONFIG.isDatadogRumEnabled) {
            dataDog.onUserLoggedIn();
        }
    }

    logDeviceManagementPageShown() {
        const { name, category, type, brazeActive, segmentActive } =
            appEvents.deviceManagementPageShown;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, {});
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, {});
        }
    }

    logUserLogOut() {
        const { name, category, type, brazeActive, segmentActive } = appEvents.userLoggedOut;
        let extraParam = {
            screen_view: screenViews.Login,
        };

        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, extraParam);
            SegmentIO.resetAnalytics();
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, extraParam);
        }

        if (CONFIG.isDatadogRumEnabled) {
            dataDog.onUserLoggedOut();
        }
    }

    logSignOutClicked() {
        const { name, category, type, brazeActive, segmentActive } = appEvents.userLoggedOutClicked;
        if (this.logoutClicked) {
            return;
        }

        this.logoutClicked = true;
        let extraParam = {
            screen_view: screenViews.Logout,
        };
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, extraParam);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, extraParam);
        }
    }

    logSignOutCancelled() {
        if (!this.logoutClicked) {
            return;
        }

        this.logoutClicked = false;

        const { name, category, type, brazeActive, segmentActive } =
            appEvents.userLoggedOutCancelled;
        let extraParam = {
            screen_view: screenViews.Logout,
        };

        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, extraParam);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, extraParam);
        }
    }

    logAppSuspended() {
        const { name, category, type, brazeActive, segmentActive } = appEvents.suspend;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }

    logAppResumed() {
        const { name, category, type, brazeActive, segmentActive } = appEvents.resume;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }

    logAppExit() {
        const { name, category, type, brazeActive, segmentActive } = appEvents.close;
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }

    logSideBarOpened() {
        if (this.isFirstRender) {
            return;
        }
        const { name, category, type, brazeActive, segmentActive } = appEvents.menuOpened;

        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type);
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }

    logSideBarClosed() {
        if (this.isFirstRender) {
            this.isFirstRender = false;
            return;
        }

        const { name, category, type, brazeActive, segmentActive } = appEvents.menuClosed;

        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type);
        }

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }

    logAssetSelection(
        asset: AssetType,
        cardPosition: number,
        panelId: string,
        panel: PanelPositionType,
        page: string = ''
    ) {
        const { name, category, type, brazeActive, segmentActive } = appEvents.assetClicked;
        const extraParam = {
            asset: getAssetSchema(asset),
            asset_category: asset.categoryTitle,
            asset_id: asset.id,
            asset_name: asset.title,
            asset_position: cardPosition,
            asset_type: asset.type,
            bucket_name: panelId,

            rail: panel,
            page: page,
        };
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, extraParam);
        }

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, extraParam);
        }
    }

    logContentCardClicked(
        asset: AssetType,
        action: ContentCardActionPerfomedType,
        panel: PanelPositionType,
        page: string = ''
    ) {
        const { contentCard, title, description, id } = asset;
        const { name, category, type, brazeActive, segmentActive } = appEvents.contentCardClicked;
        const ccExtras = {
            ...contentCard,
            assetId: id,
            contentCardId: contentCard!.id,
            title,
            description,
            badgeLabel: contentCard!.badgeLabel ?? '',

            targetPlatform: (contentCard?.targetPlatform || []).join(', '),
            targetUserSegment: (contentCard?.targetUserSegment || []).join(', '),

            targetPlatformArray: contentCard?.targetPlatform || [],
            targetUserSegmentArray: contentCard?.targetUserSegment || [],
        };
        delete ccExtras['id'];
        // @ts-ignore
        delete ccExtras['useAndConditionForTargetUserSegment'];

        const extraParam = {
            contentCard: ccExtras,
            clicked: action,
            rail: panel,
            page,
        };
        console.log('EVENT: ', name, extraParam);
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, extraParam);
        }

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, extraParam);
        }
    }

    logContentCardViewed(contentCard: ContentCard, cardPosition: ContentCardPositionType) {
        const { name, category, type, brazeActive, segmentActive } = appEvents.contentCardViewed;
        type ExtraParam = {
            contentCard: ContentCard;
            cardPosition: ContentCardPositionType;
        };
        const extraParam: ExtraParam = {
            contentCard,
            cardPosition: {
                ...cardPosition,
                page: this.currentPage!,
            },
        };
        console.log('EVENT: ', name, extraParam);
        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(name, category, type, extraParam);
        }

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name, extraParam);
        }
    }

    // Player
    logSelectedVideoQuality(id: string, screenView: string) {
        let extraParam = {
            screen_view: screenView,
        };

        const { name, category, type, brazeActive, segmentActive } =
            playerEvents.videoQualitySelected;

        const eventName = name.replace('<id>', id);

        if (CONFIG.isSegmentEnabled && segmentActive) {
            SegmentIO.track(eventName, category, type, extraParam);
        }

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(eventName, extraParam);
        }
    }

    logPlaybackRequested() {
        const { name, brazeActive, segmentActive } = playerEvents.videoPlaybackRequested;

        if (CONFIG.isSegmentEnabled && segmentActive) {
            Segment.trackVideoRequested();
        }
        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }

    logPlaybackInterrupted() {
        const { name, brazeActive } = playerEvents.videoPlaybackInterrupted;

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }

    logPlaybackCompleted() {
        const { name, brazeActive } = playerEvents.videoPlaybackCompleted;

        if (CONFIG.BrazeEventsActive && brazeActive) {
            Braze.track(name);
        }
    }
}

export const Analytics = new AnalyticsClass();
