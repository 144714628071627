import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FocusNode, useSetFocus } from 'lal-lrud';
import { QualityLabelType, QualityType } from 'src/lib/bitmovin/player.d';
import { Analytics } from 'src/lib/Analytics';

import Button from 'src/components/Common/Button';
import IconImg from 'src/components/Icons/IconImg';
import PlayerUISettingsTrolley from './common/UISettingsTrolley';
import Typography from 'src/components/Common/Typography';
import usePlayer from 'src/controllers/player.controller';

import focusIds from 'src/constants/focusIds';

import styles from './common/settings.module.scss';

interface QualityPropsType {
    onReady: () => void;
}

const QualityOptions = (props: QualityPropsType) => {
    const { onReady } = props;
    const [qualities, setQualities] = useState<QualityType[]>([]);

    const { Player } = usePlayer();
    const setFocus = useSetFocus();

    const selectedIndex = useMemo(() => qualities.findIndex((q) => q.selected), [qualities]);

    useEffect(() => {
        Player.ready().then(() => {
            const qs = Player.getQualities();
            setQualities(qs);
        });
        return () => {
            setQualities([]);
        };
    }, [Player, setQualities]);

    useEffect(() => {
        if (qualities.length) {
            onReady();
            setFocus(`quality-${selectedIndex}`);
        }
    }, [qualities, selectedIndex, onReady, setFocus]);

    const setQuality = useCallback(
        (label: QualityLabelType) => {
            Player.setQuality(label);
            const qs = Player.getQualities();
            const selected = qs.find((q) => q.selected);
            Analytics.logSelectedVideoQuality(selected!.id, 'Player');
            setQualities(qs);
        },
        [Player, setQualities]
    );

    if (!qualities.length) {
        return <FocusNode focusId={focusIds.videoQualityDummy}></FocusNode>;
    }

    return (
        <FocusNode
            orientation="vertical"
            className={styles.section}
            onMountAssignFocusTo={`quality-${selectedIndex}`}
        >
            <Typography className={styles.title}>Quality</Typography>
            <PlayerUISettingsTrolley index={selectedIndex}>
                {qualities.map((qual, index) => (
                    <div
                        className={[
                            styles.track,
                            index === selectedIndex ? styles.selected : undefined,
                        ].join(' ')}
                        key={qual.id}
                    >
                        <IconImg icon={'tickSoloGreen'}></IconImg>
                        <Button
                            onClick={() => setQuality(qual.label)}
                            open
                            className={styles.title}
                            classNameFocused={styles.focused}
                            focusId={`quality-${index}`}
                        >
                            <Typography>{qual.label}</Typography>
                        </Button>
                    </div>
                ))}
            </PlayerUISettingsTrolley>
        </FocusNode>
    );
};

export default memo(QualityOptions);
