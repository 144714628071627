import React, { useCallback } from 'react';
import { WatchModeLiveType, WatchModeType } from 'src/state/stores/dashboard/dashboard';
import styles from './heroCardActions.module.scss';
import HeroButton from '../HeroButton';

interface PropType {
    action: 'live' | 'past' | 'upcoming';
    assetId: string;
    isShow: boolean;
    showStartOver: boolean;
    leftAlign: boolean;
    focused: boolean;
    onClick: (watchMode?: WatchModeLiveType | WatchModeType | boolean) => void;
}

function HeroCardActions(props: PropType) {
    const { assetId, leftAlign, onClick, showStartOver, isShow, focused } = props;
    const getAlignment = () => {
        if (leftAlign) {
            return {
                justifyContent: 'left',
            };
        }
        return {
            justifyContent: 'center',
        };
    };

    const playLive = useCallback(() => {
        if (focused) {
            onClick('live');
        }
    }, [focused, onClick]);

    const playStartOver = useCallback(() => {
        if (focused) {
            onClick('startover');
        }
    }, [focused, onClick]);

    const VodDetail = useCallback(() => {
        if (focused) {
            onClick();
        }
    }, [focused, onClick]);

    const VodAutoPlay = useCallback(() => {
        if (focused) {
            onClick(true);
        }
    }, [focused, onClick]);

    return (
        <div className={styles.actions} style={getAlignment()}>
            {props.action === 'live' ? (
                <>
                    <HeroButton
                        focused={focused}
                        assetId={assetId}
                        focusId={`btn-${assetId}-first`}
                        onClick={playLive}
                    >
                        Watch Live
                    </HeroButton>
                    {showStartOver ? (
                        <HeroButton
                            focused={focused}
                            assetId={assetId}
                            focusId={`btn-${assetId}-start-over`}
                            onClick={playStartOver}
                        >
                            Start Over
                        </HeroButton>
                    ) : null}
                </>
            ) : null}
            {props.action === 'upcoming' ? (
                <HeroButton
                    focused={focused}
                    assetId={assetId}
                    focusId={`btn-${assetId}-first`}
                    onClick={VodDetail}
                >
                    {isShow ? 'View Details' : 'View Match Centre'}
                </HeroButton>
            ) : null}
            {props.action === 'past' ? (
                <HeroButton
                    focused={focused}
                    assetId={assetId}
                    focusId={`btn-${assetId}-first`}
                    onClick={VodAutoPlay}
                >
                    Watch now
                </HeroButton>
            ) : null}
        </div>
    );
}

export default React.memo(HeroCardActions);
