// Config for device if deviceID is in the whitelist/blacklist

import { Config } from '../config/config';

export const config_device: Partial<Config> = {
    isDatadogRumEnabled: true,
    playbackDrmChoice: 'playready',
    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['avc', 'hevc', 'av1'],
        },
    },
};

export const config_device_secondary: Partial<Config> = {
    playbackDrmChoice: 'playready',
    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['avc', 'hevc', 'av1'],
        },
    },
};
