import {
    ACTION_FETCH_DR_CHANNELS,
    ACTION_FETCH_SIDE_BAR,
} from 'src/state/stores/app/app.constants';

export const PENDING = 'PENDING';
export const FULLFILLED = 'FULLFILLED';
export const REJECTED = 'REJECTED';

export const nonApiCallingActions = [ACTION_FETCH_DR_CHANNELS, ACTION_FETCH_SIDE_BAR];

export const authErrorCodes = [401, 403];
