import { useEffect } from 'react';
import { isKeyEnterButton } from 'src/utilities/keys';

const useOLRUDonSelect = (focused: boolean, onClick: () => void) => {
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (isKeyEnterButton(e)) {
                onClick();
            }
        };
        if (focused) {
            document.addEventListener('keydown', handleKeyPress);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [focused, onClick]);
};

export default useOLRUDonSelect;
