import React, { useEffect, useState } from 'react';
import { getAppVersion, getBuildNumber } from 'src/utilities/app';
import { Player } from '../../player';
import styles from './playerStats.module.scss';
import Stat from './Stat';

const getStat = (p: any, isSSAIEnabled: boolean) => {
    return {
        getAppVersion: getAppVersion(),
        getBuildNumber: getBuildNumber(),

        getAspectRatio: p.getAspectRatio(),
        getAudio: p.getAudio(),
        getAudioBufferLength: p.getAudioBufferLength(),
        getAudioQuality: p.getAudioQuality(),
        getAvailableAudio: p.getAvailableAudio(),
        getAvailableAudioQualities: p.getAvailableAudioQualities(),
        // getAvailableSegments: p.getAvailableSegments(),
        getAvailableVideoQualities: p.getAvailableVideoQualities(),
        getBufferedRanges: p
            .getBufferedRanges()
            .map((r: any) => `${r.start.toFixed(0)} - ${r.end.toFixed(0)}`)
            .join(', '),
        getConfig: JSON.stringify(p.getConfig()),
        getContainer: p.getContainer(),
        getCurrentTime: p.getCurrentTime(),
        getDownloadedAudioData: p.getDownloadedAudioData(),
        getDownloadedVideoData: p.getDownloadedVideoData(),
        getDroppedVideoFrames: p.getDroppedVideoFrames(),
        getDuration: p.getDuration(),
        getManifest: p.getManifest(),
        getMaxTimeShift: p.getMaxTimeShift(),
        getPlaybackAudioData: p.getPlaybackAudioData(),
        getPlaybackSpeed: p.getPlaybackSpeed(),
        getPlaybackVideoData: p.getPlaybackVideoData(),
        getPlayerType: p.getPlayerType(),
        getSeekableRange: p.getSeekableRange(),
        getSnapshot: p.getSnapshot(),
        getSource: p.getSource()?.dash || p.getSource()?.hls,
        getDrm: JSON.stringify(p.getSource()?.drm),
        getStreamType: p.getStreamType(),
        getSupportedDRM: p.getSupportedDRM(),
        getSupportedTech: p.getSupportedTech(),
        getTimeShift: p.getTimeShift(),
        getTotalStalledTime: p.getTotalStalledTime(),
        getVideoBufferLength: p.getVideoBufferLength(),
        getVideoElement: p.getVideoElement(),
        getVideoQuality: p.getVideoQuality(),
        getViewMode: p.getViewMode(),
        getVolume: p.getVolume(),
        hasEnded: p.hasEnded(),
        isAirplayActive: p.isAirplayActive(),
        isAirplayAvailable: p.isAirplayAvailable(),
        isCastAvailable: p.isCastAvailable(),
        isCasting: p.isCasting(),
        // isDRMSupported: p.isDRMSupported(),
        isLive: p.isLive(),
        isMuted: p.isMuted(),
        isPaused: p.isPaused(),
        isPlaying: p.isPlaying(),
        isStalled: p.isStalled(),
        // isViewModeAvailable: p.isViewModeAvailable(),
        isYoSpacePlayer: p.bitmovinYospacePlayer ? true : false,
        isSsaiEnabled: isSSAIEnabled,
        adParts:
            p.bitmovinYospacePlayer && p.bitmovinYospacePlayer.adParts
                ? p.bitmovinYospacePlayer.adParts.map((ap: any) => ({ label: String(ap.start) }))
                : [],
    };
};

const getRenderable = (node: any) => {
    switch (typeof node) {
        case 'string':
            return `${node.substring(0, 100)}${node.length > 100 + 15
                    ? '[...]' + node.substring(node.length - 15, node.length)
                    : node.substring(15, node.length)
                }`;
        case 'number':
            return node.toFixed(0);
        case 'boolean':
            return node ? 'True' : 'False';
        case 'object':
            if (node && (node.id || node.label)) {
                return `${node.id} ${node.label || node.height || node.codec || ''}${!node.label && node.height ? 'p' : ''
                    }`;
            }
            if (Array.isArray(node) && node.length) {
                const r = node.reduce(
                    (acc, e) => `${acc}${e.label || e.id || '..'}, `,
                    `(${node.length})`
                );
                return r;
            }
            return 'NA';
        default:
            return '-';
    }
};

const AppStatsToRender = ['getAppVersion', 'getBuildNumber'];
const ConfigStatsToRender = ['getConfig', 'getSource', 'getDrm', 'getStreamType'];
const AudioStatsToRender = [
    'getAudio',
    // 'getAudioQuality',
    'getAvailableAudio',
    // 'getAvailableAudioQualities',
    'getPlaybackAudioData',
    'getVolume',
];
const VideoStatsToRender = [
    'getVideoQuality',
    'getAvailableVideoQualities',
    'getPlaybackVideoData',
    // 'getViewMode',
    'getPlaybackSpeed',
    // 'getPlayerType',
];
const TimeStatsToRender = [
    'getSeekableRange',
    'getTimeShift',
    'getMaxTimeShift',
    'getTotalStalledTime',
    'getCurrentTime',
    'getDuration',
    'getDroppedVideoFrames',
];
const BufferStatsToRender = ['getVideoBufferLength', 'getAudioBufferLength', 'getBufferedRanges'];
const PlayerStatsToRender = ['hasEnded', 'isLive', 'isMuted', 'isPaused', 'isPlaying', 'isStalled'];
const SSAIStatsToRender = ['isSsaiEnabled', 'isYoSpacePlayer', 'adParts'];

interface PlayStatsProps {
    isSSAIEnabled?: boolean;
}

const PlayerStats = (props: PlayStatsProps) => {
    const { isSSAIEnabled } = props;
    const [stat, setStat] = useState<any>({});

    useEffect(() => {
        const timer: any = setInterval(() => {
            Player.ready().then(() => {
                setStat(getStat(Player.player, isSSAIEnabled!));
            });
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [isSSAIEnabled]);

    return (
        <div className={styles.container}>
            {/* <h1 className={styles.title}>Player Status</h1> */}
            <div className={styles.statContainer}>
                <h2 className={styles.subTitle}>App</h2>
                {AppStatsToRender.map((s) => (
                    <Stat key={s} label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
                <h2 className={styles.subTitle}>Config</h2>
                {ConfigStatsToRender.map((s) => (
                    <Stat key={s} block label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
                <h2 className={styles.subTitle}>SSAI</h2>
                {SSAIStatsToRender.map((s) => (
                    <Stat key={s} label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
                <h2 className={styles.subTitle}>Audio</h2>
                {AudioStatsToRender.map((s) => (
                    <Stat key={s} label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
                <h2 className={styles.subTitle}>Video</h2>
                {VideoStatsToRender.map((s) => (
                    <Stat key={s} label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
                <h2 className={styles.subTitle}>Time</h2>
                {TimeStatsToRender.map((s) => (
                    <Stat key={s} label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
                <h2 className={styles.subTitle}>Buffer</h2>
                {BufferStatsToRender.map((s) => (
                    <Stat key={s} label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
                <h2 className={styles.subTitle}>Player</h2>
                {PlayerStatsToRender.map((s) => (
                    <Stat key={s} label={s} stat={getRenderable(stat[s])}></Stat>
                ))}
            </div>
        </div>
    );
};

export default PlayerStats;
