import React from 'react';
import Typography from 'src/components/Common/Typography';
import { AssetType, CardLayoutType } from 'src/state/stores/dashboard/dashboard';
import { screenHeight } from 'src/utilities/device';
import { pxScale } from 'src/utilities/styles';
import Card from '../Card';
import { getCardSize } from '../Card/cardsizes';
import ViewAllShimmer from '../ViewAllShimmer';
import styles from './cards.module.scss';

interface CardItemProps {
    assets: AssetType[];
    cardLayout: CardLayoutType;
    focused: boolean;
    focusedCardIndex: number;
    panelId: string;
    title: string;
}

const ViewAllCards = (props: CardItemProps) => {
    const { assets, cardLayout, focused, focusedCardIndex, panelId, title } = props;
    const cardSize = getCardSize(cardLayout);
    const { showPaneltitle, height, showAllRowMargin = 0 } = cardSize;

    const focusedRow = Math.floor((focusedCardIndex || 0) / 4);
    const totalRows = Math.ceil((assets?.length || 0) / 4);
    const titleHeight = pxScale(100);
    const trolleyHeight = (height + (showAllRowMargin || 0)) * totalRows + titleHeight;
    const viewPortHeight = screenHeight; // -title height
    let translateY = titleHeight + (height + (showAllRowMargin || 0)) * focusedRow;
    if (translateY < viewPortHeight / 2) {
        translateY = 0;
    } else if (translateY > trolleyHeight - viewPortHeight / 2) {
        translateY = trolleyHeight - viewPortHeight + titleHeight + pxScale(50);
    } else {
        translateY = translateY + titleHeight - viewPortHeight / 2;
    }

    translateY = -translateY;
    const trolleyStyle = {
        transform: `translate3d(0, ${translateY}px, 0)`,
    };

    const showTitle = showPaneltitle && title;

    if (assets?.length) {
        return (
            <>
                <div className={styles.cards}>
                    <div className={styles.trolley} style={trolleyStyle}>
                        {showTitle ? (
                            <Typography className={styles.title}>{title}</Typography>
                        ) : null}
                        {assets.map((asset: AssetType, index) => (
                            <Card
                                key={asset.id}
                                asset={asset}
                                cardLayout={cardLayout}
                                panelId={panelId}
                                cardIndex={index}
                                panelIndex={0}
                                focused={focused && focusedCardIndex === index}
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    }

    return <ViewAllShimmer cardLayout={cardLayout}></ViewAllShimmer>;
};

export default React.memo(ViewAllCards);
