import React from 'react';
import Typography from 'src/components/Common/Typography';

import { MatchType } from 'src/state/stores/dashboard/dashboard';
import CoverageInTime from '../../CoverageInTime';
import Team from '../../Match/Team';
import MatchTime from '../../Match/Time';

import styles from './vodInfoMatch.module.scss';

interface VodInfoMatchProps {
    isLive: boolean;
    broadcastStartTime: string;
    broadcastEndTime: string;
    match: MatchType;
    setIsPastOrUpcoming?: (str: string) => void;
    isPastOrUpcoming?: string;
}

const VodInfoMatch = (props: VodInfoMatchProps) => {
    const {
        isLive,
        broadcastStartTime,
        broadcastEndTime,
        match,
        setIsPastOrUpcoming,
        isPastOrUpcoming,
    } = props;

    const { awayTeam, homeTeam } = match || {};
    const { logoUrl: awayTeamLogo, shortName: aTshortName } = awayTeam || {};
    const { logoUrl: homeTeamLogo, shortName: hTshortName } = homeTeam || {};

    const isPrematch = isPastOrUpcoming === 'upcoming';
    const matchTimeClass = [styles.matchTime];
    if (isLive) {
        matchTimeClass.push(styles.live);
    }

    return (
        <div className={styles.vodinfo}>
            <div className={styles.teams}>
                <div className={styles.team}>
                    <Team logoUrl={homeTeamLogo} className={styles.teamFlag} />
                    <Typography className={styles.teamTitle}>{hTshortName}</Typography>
                </div>
                <MatchTime
                    broadcastStartTime={broadcastStartTime}
                    broadcastEndTime={broadcastEndTime}
                    isLive={isLive}
                    isMatch={!!match.id}
                />
                <div className={styles.team}>
                    <Team logoUrl={awayTeamLogo} className={styles.teamFlag} />
                    <Typography className={styles.teamTitle}>{aTshortName}</Typography>
                </div>
            </div>

            {isPrematch ? (
                <CoverageInTime
                    broadcastStartTime={broadcastStartTime}
                    className={styles.coverageInTime}
                    setIsPastOrUpcoming={setIsPastOrUpcoming}
                />
            ) : null}
        </div>
    );
};

export default React.memo(VodInfoMatch);
