import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Analytics } from 'src/lib/Analytics';

import { useStateValue } from 'src/state';
import { postAuthInit, postAuthPoll } from 'src/state/stores/user/user.actions';
import { errorCodes } from 'src/constants/errorCodes';
import RouteTags from 'src/routes/routeTags';

import { noop } from 'src/utilities/op';

export interface locationStateType {
    redirectOnSuccess: {
        pathname: string;
        state: any;
    };
}

const useLogin = () => {
    const { dispatch, state } = useStateValue();
    const {
        userData: { init, auth },
    } = state;
    const { userCode, deviceCode, interval, expiresIn } = init || {};
    const navigate = useNavigate();
    const location = useLocation();
    const { state: locationState } = location;
    const { redirectOnSuccess } = (locationState as locationStateType) || {};
    const [expireRemaining, setExpireRemaining] = useState<number | null>(null);
    const [showGenericError, setShowGenericError] = useState(false);
    const retryCount = useRef(0);
    const allowRetry = retryCount.current < 5;
    const expireProgress =
        expiresIn && expireRemaining ? 100 - (expireRemaining / expiresIn) * 100 : 0;
    useEffect(() => {
        if (
            !auth &&
            !showGenericError &&
            deviceCode &&
            expireRemaining &&
            interval &&
            allowRetry &&
            expireRemaining % interval === 0
        ) {
            dispatch(postAuthPoll(deviceCode))
                .then((response) => {
                    Analytics.logLoginSuccess(response.http);
                })
                .catch((d) => {
                    const { code } = d.error || {};

                    if (code === errorCodes.PENDING_AUTHORIZATION) {
                        noop();
                    } else if (code === errorCodes.SESSION_EXPIRED) {
                        setExpireRemaining(0);
                    } else if (code === errorCodes.INVALID_DEVICE_CODE) {
                        // Show generic error, only for INVALID_DEVICE_CODE
                        setShowGenericError(true);
                    } else {
                        let errParam = {
                            http: d.http,
                            error: d.error,
                        };
                        Analytics.logLoginFailed(errParam);
                    }
                });
        } else if (auth) {
            setTimeout(() => {
                navigate(RouteTags.userWelcome, {
                    replace: true,
                    state: { redirectOnSuccess },
                });
            }, 3000);
        }
    }, [
        expireRemaining,
        deviceCode,
        interval,
        auth,
        showGenericError,
        allowRetry,
        redirectOnSuccess,
        dispatch,
        navigate,
    ]);

    useEffect(() => {
        let timerRef: any = null;
        const tick = 1; //1s
        if ((auth || showGenericError || !allowRetry) && timerRef) {
            clearInterval(timerRef);
        }

        if (expiresIn && userCode && !auth) {
            timerRef = setInterval(() => {
                setExpireRemaining((e) => {
                    if (e === 1 || e === 0) {
                        clearInterval(timerRef);
                        return 0;
                    } else if (e) {
                        return e - 1;
                    } else {
                        return expiresIn;
                    }
                });
            }, tick * 1000);
        } else {
            setExpireRemaining(null);
        }

        return () => {
            if (timerRef !== null) {
                clearInterval(timerRef);
            }
        };
    }, [userCode, expiresIn, auth, showGenericError, allowRetry]);

    useEffect(() => {
        dispatch(postAuthInit())
            .then((response) => {
                Analytics.logAuthInit(response.http);
            })
            .catch(() => {
                setShowGenericError(true);
            });
    }, [dispatch]);

    const retry = () => {
        setExpireRemaining(null);
        setShowGenericError(false);
        if (allowRetry) {
            dispatch(postAuthInit()).catch(() => {
                setShowGenericError(true);
            });
            retryCount.current += 1;
        }
    };

    return {
        expireProgress,
        userCode: allowRetry ? userCode : '------',
        retry,
        codeExpired: expireRemaining === 0,
        auth,
        showGenericError,
    };
};

export const useCheckAuthNRedirect = () => {
    const { state } = useStateValue();
    const {
        userData: { user },
    } = state;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user) {
            navigate(RouteTags.root, { replace: true });
        }
    }, [user, navigate]);
};

export default useLogin;
