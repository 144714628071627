import { memo, useCallback, useEffect, useState } from 'react';
import { FocusNode } from 'lal-lrud';
import Button from 'src/components/Common/Button';
import IconImg from 'src/components/Icons/IconImg';
import PlayerUISettingsTrolley from './common/UISettingsTrolley';
import Typography from 'src/components/Common/Typography';
import usePlayer from 'src/controllers/player.controller';
import { TextTrackType } from 'src/lib/bitmovin/player.d';
import Storage from 'src/utilities/storage';
import styles from './common/settings.module.scss';
import { getLanguageLabel } from 'src/utilities/lang';

const SubtitleOptions = () => {
    const [tracks, _setTracks] = useState<TextTrackType[]>([]);
    const { Player } = usePlayer();

    const setTracks = useCallback(() => {
        _setTracks([
            {
                enabled: false,
                id: 'off',
                isFragmented: true,
                kind: 'subtitle',
                label: 'Off',
                lang: 'off',
                url: '',
            },
            ...Player.getSubtitleTracks(),
        ]);
    }, [Player]);

    useEffect(() => {
        Player.ready().then(() => {
            setTracks();
        });
    }, [Player, setTracks]);

    const setSubtitleTrack = useCallback(
        (track: string) => {
            Storage.setItem('vsub', track);
            Player.setSubtitleTrack(track);
            setTimeout(() => {
                setTracks();
            }, 500);
        },
        [Player, setTracks]
    );

    let selectedTrackIndex = tracks.findIndex((track) => track.id === Storage.getItem('vsub'));
    if (selectedTrackIndex === -1) {
        selectedTrackIndex = 0;
    }

    if (tracks.length <= 1) {
        return <FocusNode disabled></FocusNode>;
    }
    return (
        <div className={styles.section}>
            <Typography className={styles.title}>Subtitle</Typography>
            <PlayerUISettingsTrolley index={selectedTrackIndex}>
                {tracks.map((track, i) => (
                    <div
                        className={[
                            styles.track,
                            selectedTrackIndex === i ? styles.selected : undefined,
                        ].join(' ')}
                        key={track.id}
                    >
                        <IconImg icon={'tickSoloGreen'}></IconImg>
                        <Button
                            focusId={`subtitle-${track.id}`}
                            onClick={() => setSubtitleTrack(track.id)}
                            open
                            className={styles.title}
                            classNameFocused={styles.focused}
                        >
                            {getLanguageLabel(track.lang) || track.label}
                        </Button>
                    </div>
                ))}
            </PlayerUISettingsTrolley>
        </div>
    );
};

export default memo(SubtitleOptions);
