import { log } from 'src/utilities/console';
import { BitmovinOptusPlayer, OSPlayerEvent } from '..';
import { PlayerClass } from '../player';
import { CONFIG } from 'src/config/config';
import { Segment } from '../segment';
import { Analytics } from '../../Analytics';

export function listenForPlayerEvents(this: PlayerClass) {
    BitmovinOptusPlayer.on(OSPlayerEvent.ERROR, (errorObj) => {
        log('Player ERROR', { errorObj });
        Analytics.logPlaybackInterrupted();
        if (
            !(
                errorObj &&
                (errorObj.code === 'ZOMBIE_STREAM_KICK' || errorObj.code === 'CHUCK_NORRIS')
            )
        ) {
            this.setError(errorObj.code || '0000');
            Segment.handleAppKill();
        }
    });
    BitmovinOptusPlayer.on(OSPlayerEvent.WARNING, (warnObj) => {
        log('Player WARNING', { warnObj });
    });
    BitmovinOptusPlayer.on(OSPlayerEvent.YOSPACE_ERROR, (errorObj) => {
        // set yoSpaceError flag
        this.setYospaceError();
        // set retryCountYospaceError value
        this.retryCountYospaceError = CONFIG.YoSpaceRetryCount;
        // call the yospace callback. This callback will be supplied by the component/controller. When this is executed, component/controller updates its state and retrys the playback url with yspSdk=false
        if (this.yospaceCallback) {
            this.yospaceCallback();
        }
        log('Player YOSPACE_ERROR', { errorObj });
    });
    BitmovinOptusPlayer.on(OSPlayerEvent.PLAYBACK_FINISHED, (data) => {
        log('Player PLAYBACK_FINISHED', { data });
        this.playerState = 'FINISHED';
        Analytics.logPlaybackCompleted();
        // Player about to auto destroy, Prevent any future api calls.
        // this.player = null;
    });

    BitmovinOptusPlayer.on(OSPlayerEvent.DESTROY, (data) => {
        // if there is an yospace error then do not destroy the player since we retry playback endpoint with yspSdk=false option to play the video without ads
        this.player = null;
        if (!(this.assetId && this.isYoSpaceError(this.assetId))) {
            this.playerState = 'DESTROYED';
            log('Player DESTROYED', { data });
            this.resetSource();
        }

        // Init the player again when destryoed, Mostly on yospace error, so the normal player is reinitialized
        // this.init(); // not needed as player is init on load only
    });
}
