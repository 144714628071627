import { DateTime } from 'luxon';
import moment from 'moment';

export const findDifferenceFromCurrentTime = (time: string) => {
    const date = new Date(time);
    return Date.now() - date.getTime();
};

export const checkifLive = (broadcastStart: string, broadcastEnd: string) => {
    const bS = new Date(broadcastStart);
    const bE = new Date(broadcastEnd);
    return bS.getTime() <= Date.now() && bE.getTime() >= Date.now();
};

export const checkifPastOrUpcoming = (broadcastStartTime: string) => {
    if (DateTime.now() < DateTime.fromISO(broadcastStartTime)) {
        return 'upcoming';
    }
    return 'past';
};

export const checkifUpcomingLiveOrPast = (broadcastStartTime: string, broadcastEndTime: string) => {
    const now = DateTime.now();
    if (now < DateTime.fromISO(broadcastStartTime)) {
        return 'upcoming';
    } else if (now < DateTime.fromISO(broadcastEndTime)) {
        return 'live';
    }
    return 'past';
};

const getLocalTimeString = (broadcastStartTime: string) => {
    return moment(broadcastStartTime).format('DD MMM h:mma');
};

function pad(num: number, size = 2) {
    let numS = num.toString();
    while (numS.length < size) numS = '0' + numS;
    return numS;
}

export const getDurationHrs = (broadcastStartTime: string) => {
    const duration = Math.abs(moment().diff(moment(broadcastStartTime), 'seconds'));
    const hrs = ~~(duration / (60 * 60)) | 0;
    return hrs;
};
export const getDurationString = (broadcastStartTime: string) => {
    const duration = Math.abs(moment().diff(moment(broadcastStartTime), 'seconds'));
    const hrs = ~~(duration / (60 * 60)) | 0;
    if (hrs >= 12) {
        return getLocalTimeString(broadcastStartTime);
    } else {
        return formatDuration(duration);
    }
};

export const formatDuration = (duration: number) => {
    const hrs = ~~(duration / (60 * 60)) | 0;
    const minutes = ~~((duration % 3600) / 60);
    const seconds = ~~duration % 60;
    return duration
        ? `${hrs ? pad(hrs) : ''}${hrs ? ':' : ''}${pad(minutes)}:${pad(seconds)}`
        : '00:00';
};

export const formatTime = (t: number) => {
    return moment(t).format('hh:mma');
};

export const fibonacciDelay: (n: number) => number = (n) => {
    return n < 1 ? 0 : n <= 1 ? 1 : fibonacciDelay(n - 1) + fibonacciDelay(n - 2);
};
