import { FocusNode } from 'lal-lrud';
import { memo, useCallback, useRef, useState } from 'react';
import { useFocusOnMouseOver } from 'src/hooks/useFocusOnMouseOver';
import styles from './scrubber.module.scss';
import { MouseEvent } from 'react';
import TimeBox from './TimeBox';
type ScrubberProps = {
    visible: boolean;
    isLive: boolean;
    width: number;
    bufferWidth: number;
    duration: number;
    currentTime: number;
    onScrubberLRKey: (t: any) => void;
    onClick: (t: any) => void;
    hide?: boolean;
};

const Scrubber = (props: ScrubberProps) => {
    const {
        isLive,
        width,
        bufferWidth,
        duration,
        currentTime,
        onScrubberLRKey,
        onClick,
        hide = false,
    } = props;

    const [mousePosition, setMousePosition] = useState<null | number>(null);

    const focusId = 'Scrubber';

    const { onMouseOver } = useFocusOnMouseOver(focusId);

    const outTimer = useRef<any>(null);

    const onMouseMove = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const target = e.currentTarget as Element;
        const { width } = target.getBoundingClientRect();
        const pos = e.nativeEvent.offsetX / width;
        setMousePosition(pos <= 1 ? pos : 1);
        clearTimeout(outTimer.current);
    };
    const onBlurred = useCallback(() => {
        setMousePosition(null);
    }, []);

    const onMouseOut = useCallback(() => {
        outTimer.current = setTimeout(() => {
            setMousePosition(null);
        }, 1000);
    }, []);

    const onRailClick = () => {
        onClick(mousePosition);
    };

    const seekToEnd = () => {
        setMousePosition(1);
        onClick(1);
    };

    const seekToStart = () => {
        setMousePosition(0);
        onClick(0);
    };

    const barWidth = mousePosition ? `${mousePosition * 100}%` : `${width * 100}%`;
    const bufferBarWidth = `${bufferWidth * 100}%`;

    return (
        <FocusNode
            focusId={focusId}
            className={[styles.container, hide ? styles.hide : ''].join(' ')}
            focusedClass={styles.focused}
            onKey={onScrubberLRKey}
            onMouseOver={onMouseOver}
            onBlurred={onBlurred}
            disabled={hide}
        >
            {isLive ? null : <TimeBox time={currentTime} onClick={seekToStart} />}
            <div
                className={styles.rail}
                onMouseOut={onMouseOut}
                onMouseMove={onMouseMove}
                onClick={onRailClick}
            >
                <div className={styles.bar} style={{ width: barWidth }} />
                <div className={styles.bufferBar} style={{ width: bufferBarWidth }} />
            </div>
            {isLive ? (
                <TimeBox isLive tail time={currentTime} onClick={seekToEnd} />
            ) : (
                <TimeBox tail time={duration} onClick={seekToEnd} />
            )}
        </FocusNode>
    );
};

export default memo(Scrubber);
