import React from 'react';
import styles from './playerStats.module.scss';

interface StatPropType {
    label: string;
    stat: any;
    block?: boolean;
}

const Stat = (props: StatPropType) => {
    const { label, stat, block } = props;
    let labelClean = label
        // .replace(/^is+/, '')
        .replace(/^get+/, '')
        .replace(/([A-Z])/g, ' $1');
    const classes = [styles.stat];
    if (block) {
        classes.push(styles.block);
    }
    return (
        <div className={classes.join(' ')}>
            <span>{labelClean}:</span>
            <strong>{stat}</strong>
        </div>
    );
};

export default React.memo(Stat);
