import { AccessGroupType, AuthType, UserDataType, UserType } from 'src/state/stores/user/user';
import { getJWTPayload } from './jwt';
import { AnonymousUserSegments } from 'src/constants/contentCards';

export const getProcessIdToken = (idToken: string) => {
    const { sub, email, phone_number, name } = (idToken && getJWTPayload(idToken)) || {};

    let user: UserType = {
        userId: sub,
        email,
        phone_number,
        name,
        accessGroups: null,
        type: null,
        analyticUserId: null,
    };
    return user;
};

export const isUserInSegments = (
    segments: string[],
    userSegments: string[] = AnonymousUserSegments
) => {
    return segments.some(function (s) {
        return userSegments.indexOf(s) > -1;
    });
};

export const getUser = (idToken: string, userData?: UserDataType) => {
    const user: UserType = getProcessIdToken(idToken);

    const { accessGroups: _accessGroups, type, analyticUserId, userSegmentTags } = userData || {};
    const [segmentTag] = userSegmentTags || [];

    const accessGroups = _accessGroups?.map((a) => a.toUpperCase()) as AccessGroupType[];

    user.accessGroups = user.accessGroups || accessGroups || null;
    user.type = user.type || (type?.toUpperCase() as AccessGroupType) || null;
    user.analyticUserId = user.analyticUserId || analyticUserId || null;
    user.segmentTag = segmentTag;

    return user;
};

export const getAuth = (data: AuthType) => {
    if (data && data.IdToken) {
        const { userId }: UserType = getProcessIdToken(data.IdToken);
        const auth: AuthType = {
            AccessToken: data.AccessToken,
            ExpiresIn: data.ExpiresIn,
            IdToken: data.IdToken,
            TokenType: data.TokenType,
            userId: userId,
        };
        return auth;
    }
    return undefined;
};
