import { FocusNode } from 'lal-lrud';
import React, { useState } from 'react';
import { LiveAssetType } from 'src/state/stores/dashboard/dashboard';
import { calcTranslateX } from 'src/utilities/dashboard';
import ChannelButton from '../ChannelButton/ChannelButton';
import styles from './channels.module.scss';
import { pxScale } from 'src/utilities/styles';

interface ChannelProps {
    assets: LiveAssetType[];
    onChange: (ch: string) => void;
    selectedChannel?: LiveAssetType | null;
}

const Channels = (props: ChannelProps) => {
    const { assets, onChange, selectedChannel } = props;

    const [selectedChIndex, setSelectedChIndex] = useState(0);
    const onMove = (e: any) => {
        setSelectedChIndex(e.nextChildIndex);
    };

    const selectedChannelIndex = assets?.findIndex((a) => a?.id === selectedChannel?.id);

    /**
     * The associated CSS has the value of `min-width: pxToVh(378px)` and ideally
     * pxScale(374) should have the same pixel value of 378. However when visually observed on device
     * 374 gives a better UI.
     * TODO As part of pxToVh tech debt 
     */
    const translateX = calcTranslateX(pxScale(374), pxScale(20), assets.length, selectedChIndex);
    const trolleyStyle = {
        height: 'auto',
        transform: `translateX(${translateX}px)`,
    };
    return (
        <FocusNode
            elementType="div"
            className={styles.rail}
            onMove={onMove}
            defaultFocusChild={selectedChannelIndex}
            onMountAssignFocusTo={assets[0].id}
        >
            <div className={styles.trolley} style={trolleyStyle}>
                {assets.map((la) => (
                    <ChannelButton
                        key={la.id}
                        onChange={onChange}
                        id={la.id}
                        logo={la.logo}
                    ></ChannelButton>
                ))}
            </div>
        </FocusNode>
    );
};

export default React.memo(Channels);
