import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    accountSettingsLoggedIn,
    accountSettingsLoggedOut,
    settingsData,
} from 'src/config/settings';
import { useStateValue } from 'src/state';
import { useNavigate, useParams } from 'react-router-dom';
import RouteTags, { getRouteTags } from 'src/routes/routeTags';
import { settingsPageQRCodes } from 'src/config/imageAssets';

const useSettings = (fetch?: boolean) => {
    const { state } = useStateValue();
    const [showLogOutAll, setShowLogOutAll] = useState(false);
    const navigate = useNavigate();
    const {
        userData: { user },
    } = state;

    const { setting: selectedSettingItem = 'customer-support' } = useParams();
    const { t } = useTranslation(undefined, { keyPrefix: 'settings' });
    let rightSideData = useMemo(() => {
        switch (selectedSettingItem) {
            case 'customer-support': {
                return {
                    primaryHeading: t('customerSupport.primaryHeading'),
                    primaryDesc: t('customerSupport.primaryDesc'),
                    secondaryHeading: t('customerSupport.secondaryHeading'),
                    secondaryDesc: t('customerSupport.secondaryDesc'),
                    qrCode: settingsPageQRCodes.customerSupport,
                    showVersion: true,
                };
            }
            case 'privacy-policy': {
                return {
                    primaryHeading: t('privacyPolicy.primaryHeading'),
                    primaryDesc: t('privacyPolicy.primaryDesc'),
                    secondaryHeading: t('privacyPolicy.secondaryHeading'),
                    secondaryDesc: t('privacyPolicy.secondaryDesc'),
                    qrCode: settingsPageQRCodes.privacyPolicy,
                    showVersion: false,
                };
            }
            case 'account-profile': {
                return {
                    primaryHeading: t('myProfile.primaryHeading'),
                    emailId: user ? user.email : '',
                    primaryDesc: t('myProfile.primaryDesc'),
                    secondaryHeading: t('myProfile.secondaryHeading'),
                    secondaryDesc: t('myProfile.secondaryDesc'),
                    qrCode: settingsPageQRCodes.myProfile,
                    showVersion: false,
                };
            }
            case 'help-faq': {
                return {
                    primaryHeading: t('helpFaq.primaryHeading'),
                    primaryDesc: t('helpFaq.primaryDesc'),
                    secondaryHeading: t('helpFaq.secondaryHeading'),
                    secondaryDesc: t('helpFaq.secondaryDesc'),
                    qrCode: settingsPageQRCodes.helpnFaq,
                    showVersion: false,
                };
            }
        }
    }, [selectedSettingItem, t, user]);

    let rightActionData = useMemo(() => {
        switch (selectedSettingItem) {
            case 'account-logout':
                return {
                    primaryHeading: t('logout.primaryHeading'),
                    primaryDesc: t('logout.primaryDesc'),
                    buttons: [
                        {
                            title: 'Log out',
                            buttonId: 'logout-action',
                        },
                    ],
                };
            case 'device-management': {
                return {
                    primaryHeading: t('deviceManagement.primaryHeading'),
                    primaryDesc: t('deviceManagement.primaryDesc'),
                    secondaryDesc: t('deviceManagement.secondaryDesc'),
                    qrCode: settingsPageQRCodes.devices,
                    showVersion: false,
                    buttons: [
                        {
                            title: 'Log out of all devices',
                            buttonId: 'logout-global-action',
                        },
                    ],
                };
            }
        }
    }, [selectedSettingItem, t]);
    let menuCategories = useMemo(() => {
        let settingsMenu = settingsData.slice();
        if (user) {
            settingsMenu.push(accountSettingsLoggedIn);
        } else {
            settingsMenu.push(accountSettingsLoggedOut);
        }
        return settingsMenu;
    }, [user]);

    const setSelectedSettingsItem = (pageId: string) => {
        if (pageId === 'account-login') {
            navigate(getRouteTags('login', '', ''));
        } else if (pageId === 'logout-action') {
            navigate(getRouteTags('logout', '', ''));
        } else if (pageId === 'logout-global-action') {
            setShowLogOutAll(true);
        } else {
            navigate(getRouteTags(RouteTags.settings, 'setting', pageId));
        }
    };

    return {
        menuCategories,
        setSelectedSettingsItem,
        selectedSettingItem,
        rightSideData,
        rightActionData,
        setShowLogOutAll,
        showLogOutAll,
    };
};
export default useSettings;
