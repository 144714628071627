import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteTags, { DASHBOARD_ROOT } from 'src/routes/routeTags';
import { shouldShowUserOnboarding } from 'src/utilities/onboarding';
import { getRefreshToken } from 'src/utilities/session';
import useDisasterMode from './disasterRecovery.controller';
import useAppReady from './app/app.ready.controller';

/** This function checks if we need to show user the on boarding page even after the first load.
 * TODO : if the config data is not available here, we can change the hideOnboarding flag in the local storage on config load
 */
// const checkifConfigOverrides = () => {
//     return false;
// };

const useSplashScreen = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isDisasterMode } = useDisasterMode(true);
    const { ready } = useAppReady();

    useEffect(() => {
        const isActiveSession = !!getRefreshToken();
        if (ready && pathname === RouteTags.root) {
            setTimeout(() => {
                if (!isActiveSession && shouldShowUserOnboarding()) {
                    navigate(RouteTags.onboarding, { replace: true });
                } else if (isDisasterMode) {
                    navigate(RouteTags.drmode, { replace: true });
                } else {
                    navigate(DASHBOARD_ROOT, { replace: true });
                }
            }, 0);
        }
    }, [ready, pathname, isDisasterMode, navigate]);
};

export default useSplashScreen;
