import React, { useEffect } from 'react';
import { useLiveTime } from 'src/hooks/liveTime';
import { checkifPastOrUpcoming, getDurationHrs } from 'src/utilities/time';
import styles from './coverageInTime.module.scss';

interface CoverageInTimeProps {
    className?: string;
    broadcastStartTime: string;
    setIsPastOrUpcoming?: (str: string) => void;
}

const CoverageInTime = (props: CoverageInTimeProps) => {
    const { className, broadcastStartTime, setIsPastOrUpcoming } = props;
    const timeClass = [styles.coverateInTime];
    if (className) {
        timeClass.push(className);
    }

    const fDuration = useLiveTime(broadcastStartTime);
    const hrs = getDurationHrs(broadcastStartTime);
    useEffect(() => {
        if (setIsPastOrUpcoming) {
            setIsPastOrUpcoming(checkifPastOrUpcoming(broadcastStartTime));
        }
    }, [fDuration]);
    return (
        <div className={timeClass.join(' ')}>
            Coverage {hrs >= 12 ? 'from' : 'in'} <span className={styles.time}>{fDuration}</span>
        </div>
    );
};

export default React.memo(CoverageInTime);
