import { FocusNode } from 'lal-lrud';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Common/Logo';
import Typography from 'src/components/Common/Typography';
import styles from './slides.module.scss';

// Welcome
const WelcomeOB = React.memo(() => {
    const { t } = useTranslation(undefined, { keyPrefix: 'userOnBoarding' });
    return (
        <div className={styles.slide}>
            <Logo className={styles.logo}></Logo>
            <Typography className={styles.title}> {t('welcomePageTitle')}</Typography>
            <Typography className={styles.description}>{t('wecomePageDesc')}</Typography>
        </div>
    );
});

//MatchCenter

const MatchCenterOB = React.memo(() => {
    const { t } = useTranslation(undefined, { keyPrefix: 'userOnBoarding' });
    return (
        <div className={[styles.slide, styles.matchCenter].join(' ')}>
            <Typography className={styles.title}> {t('matchCenterTitle')}</Typography>
            <Typography className={styles.description}>{t('matchCenterDesc')}</Typography>
        </div>
    );
});

//FootballnFitness

const FootballnFitnessOB = React.memo(() => {
    const { t } = useTranslation(undefined, { keyPrefix: 'userOnBoarding' });
    return (
        <div className={[styles.slide, styles.football].join(' ')}>
            <Typography className={styles.title}> {t('footballnFitnessTitle')}</Typography>
            <Typography className={styles.description}>{t('footballnFitnessDesc1')}</Typography>
            <Typography className={styles.description}>{t('footballnFitnessDesc2')}</Typography>
        </div>
    );
});

//ExittoLogin
const ExitPageOB = React.memo(() => {
    const { t } = useTranslation(undefined, { keyPrefix: 'userOnBoarding' });
    return (
        <div className={[styles.slide, styles.exitPage].join(' ')}>
            <Logo className={styles.logo}></Logo>
            <Typography className={styles.title}> {t('exitPageTitle')}</Typography>
            <Typography className={styles.description}>{t('exitPageDesc1')}</Typography>
            <Typography className={styles.description}>{t('exitPageDesc2')}</Typography>
            <Typography className={styles.description}>{t('exitPageDesc3')}</Typography>
        </div>
    );
});
const Slides = { WelcomeOB, MatchCenterOB, FootballnFitnessOB, ExitPageOB };
export default Slides;
