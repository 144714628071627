// Wraps all prototype functions with a try...catch block
export function safeClass(target: any) {
    for (const propertyName of Reflect.ownKeys(target.prototype).filter(
        (prop) => prop !== 'constructor'
    )) {
        const desc = Object.getOwnPropertyDescriptor(target.prototype, propertyName)!;
        const isMethod = desc.value instanceof Function;
        if (!isMethod) continue;
        const originalMethod = desc.value;
        desc.value = function (...args: any[]) {
            try {
                const result = originalMethod.apply(this, args);
                return result;
            } catch (error) {
                console.log(error);
            }
        };

        Object.defineProperty(target.prototype, propertyName, desc);
    }
}
