import Logo from 'src/components/Common/Logo';
import Spinner from 'src/components/Common/Spinner';
import { CONFIG } from 'src/config/config';
import useSplashScreen from 'src/controllers/splashScreen.controller';
import { getAppVersion } from 'src/utilities/app';
import styles from './splash.module.scss';

const Splash = () => {
    useSplashScreen();

    return (
        <div className={styles.splash}>
            <Logo className={styles.logo}></Logo>
            <Spinner></Spinner>
            {CONFIG.debug ? (
                <div className={styles.env}>
                    {getAppVersion()} {CONFIG.env}
                </div>
            ) : null}
        </div>
    );
};

export default Splash;
