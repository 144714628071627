// import PlayerUI from 'src/components/Player/PlayerUI';
import FullscreenPopup from 'src/components/ui/FullscreenPopup';
import PlaybackError from 'src/components/ui/PlaybackError';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import useDisasterMode from 'src/controllers/disasterRecovery.controller';
import usePlayer from 'src/controllers/player.controller';
import usePlaybackError from 'src/controllers/playbackError.controller';
import styles from './drPlayer.module.scss';
import { useEffect, useState } from 'react';
import Spinner from 'src/components/Common/Spinner';

const DisasterRecoveryPlayer = () => {
    const { drAsset, handleBack } = useDisasterMode();
    // const { title, isLive } = drAsset || {};
    const { Player } = usePlayer();
    const [playerReady, setPlayerReady] = useState(false);

    useBackButton({ callBack: handleBack, id: 'disaster player', rank: 3 });
    const retry = () => {
        window.location.reload();
    };

    const { playerError, retryOnError } = usePlaybackError(retry);

    useEffect(() => {
        Player.ready().then(() => {
            Player.player.on('playing', () => {
                setPlayerReady(true);
            });
        });
    }, [Player]);

    return drAsset ? (
        <>
            <div className={styles.playback}>
                {/* NOTE: We are hiding the PlayerUI when in Simplified App Mode */}
                {/* <PlayerUI
                    title={title}
                    isLive={!!isLive}
                    isShowMiniEPG={!!isLive}
                    isPlayerError={!!playerError}
                    isKicked={false}
                    isMiniUI={true}
                /> */}
            </div>
            {playerError ? (
                <PlaybackError message={playerError} onRetry={retryOnError} onExit={handleBack} />
            ) : null}

            {!playerReady && <Spinner fullScreen />}
        </>
    ) : (
        <FullscreenPopup
            id={'player.genericError'}
            onClick={() => {}}
            onExit={() => {}}
        ></FullscreenPopup>
    );
};
export default DisasterRecoveryPlayer;
