import { DateTime } from "luxon";
import Typography from 'src/components/Common/Typography';

export const calculateDateTimeDisplay = (broadcastStartTime: string, broadcastEndTime: string, isMatch: boolean) => {
    if (!broadcastStartTime) {
        return ''
    }

    const now = DateTime.local()
    const startTime = DateTime.fromISO(broadcastStartTime)
    const endTime = DateTime.fromISO(broadcastEndTime)
    
    if (now < startTime) { //if current time is earlier than broadcast start
        return (
            <Typography>
                {startTime.toFormat('d MMM')}
                <br/>
                {startTime.toFormat('h:mma').toLocaleLowerCase()}
            </Typography>
        )
    }

    if (now.year !== endTime.year) {
        return startTime.toFormat('d MMM yy')
    }

    const hoursDifference = Math.floor(now.diff(endTime, 'hours').toObject().hours!)
    if (hoursDifference < 1) { // if current time is within an hour of broadcast end
        return 'Just now'
    }

    if (hoursDifference < 24) { // if current time is within a day of broadcast end
        return hoursDifference + (hoursDifference === 1 ? ' hour ago' : ' hours ago')
    }

    const daysDifference = Math.floor(now.diff(endTime, 'days').toObject().days!)
    if (daysDifference < 7) {// if current time is within 7 days of broadcast end
        if (!isMatch) { // if it's a not a match
            return daysDifference + (daysDifference === 1 ? ' day ago' : ' days ago')
        }
        return endTime.toFormat('EEE d MMM')
    }

    return startTime.toFormat('d MMM')
}