export const backButtons = [
    // '`',
    // 'XF86Back', // SAMSUNG
    // 'Escape', // Code
    // 'RCUBack',
    // 'GoBack', // WebOS key
    // 'Back', // Foxtel??
    // 'Backspace', // Key for keyCode `8`
    461, // LG
    10009, // Samsung
    192, // ` for testing
    196, // Xbox
    27, // 'Escape' key
    8, // Foxtel, Hisense
];

export const enterButtons = [
    13,
    195, // Xbox
];

export const leftButtons = [
    37,
    // Xbox
    205, 218, 214, 200, 201,
];

export const rightButtons = [
    39,
    // Xbox
    217, 206, 213, 199, 202,
];

export const upButtons = [
    38,
    // Xbox
    211, 215, 203,
];

export const downButtons = [
    40,
    // Xbox
    204, 212, 216,
];

export const isKeyEnterButton = (e: KeyboardEvent) => enterButtons.indexOf(e.keyCode) !== -1;
export const isKeyBackButton = (e: KeyboardEvent) => backButtons.indexOf(e.keyCode) !== -1;

export const isKeyLeft = (e: KeyboardEvent) => leftButtons.indexOf(e.keyCode) !== -1;
export const isKeyRight = (e: KeyboardEvent) => rightButtons.indexOf(e.keyCode) !== -1;
export const isKeyUp = (e: KeyboardEvent) => upButtons.indexOf(e.keyCode) !== -1;
export const isKeyDown = (e: KeyboardEvent) => downButtons.indexOf(e.keyCode) !== -1;
export const isKeyLeftOrRight = (e: KeyboardEvent) => isKeyLeft(e) || isKeyRight(e);
export const isKeyArrow = (e: KeyboardEvent) =>
    isKeyLeft(e) || isKeyRight(e) || isKeyUp(e) || isKeyDown(e);

/** ---Player keys---------- */

// Play: p
// Pause: s
// PlayPause: a
// FF: f
// RE: d
// Stop: x

const playButtons = [
    415, // Samsung, LG, Hisense
    71,
    80, // p
    250, // Foxtel
];
const pauseButtons = [
    19, // Foxtel, Samsung, LG, Hisense
    74,
    83, // s
];
const playPauseButtons = [
    10252, // Samsung
    463, // Hisense
    65, // a
    179, // Foxtel
];
const fastForwardButtons = [
    417, // Samsung, LG, Hisense
    72,
    70, // f
    129, // Foxtel
    117, // PS4
];
const fasterForwardButtons = [
    119, // PS4
];
const rewindButtons = [
    412, // Samsung, LG, Hisense
    69,
    68, // d
    128, // Foxtel
    116, // PS
];
const fasterRewindButtons = [
    118, // PS4
];
const stopButtons = [
    413, // Samsung, LG, Hisense
    70,
    88, // x
    178, // Foxtel
];

export const isPlayButton = (e: KeyboardEvent) => playButtons.indexOf(e.keyCode) !== -1;
export const isPauseButton = (e: KeyboardEvent) => pauseButtons.indexOf(e.keyCode) !== -1;
export const isPlayPauseButton = (e: KeyboardEvent) => playPauseButtons.indexOf(e.keyCode) !== -1;
export const isForwardSeekButton = (e: KeyboardEvent) =>
    fastForwardButtons.indexOf(e.keyCode) !== -1;
export const isFasterForwardSeekButton = (e: KeyboardEvent) =>
    fasterForwardButtons.indexOf(e.keyCode) !== -1;
export const isRewindSeekButton = (e: KeyboardEvent) => rewindButtons.indexOf(e.keyCode) !== -1;
export const isFasterRewindSeekButton = (e: KeyboardEvent) =>
    fasterRewindButtons.indexOf(e.keyCode) !== -1;
export const isStopButton = (e: KeyboardEvent) => stopButtons.indexOf(e.keyCode) !== -1;
