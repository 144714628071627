const focusIds = {
    sidebar: 'sidebar',
    dashboardBody: 'dashboardBody',
    dashboardOverlay: 'dashboardOverlay',
    setttingsBody: 'setttingsBody',
    playPauseBtn: 'playPauseBtn',
    plbSettingsBtn: 'plbSettingsBtn',
    miniEPG: 'miniEPG',
    videoQualityDummy: 'videoQualityDummy',
    vodDetail: {
        startover: 'startover',
        play: 'play',
    },
    matchActionPrefix: {
        watchlive: 'btn-match-watchlive-',
        startover: 'btn-match-startover-',
        game: 'btn-match-game-',
        mega: 'btn-match-mega-',
        minimatch: 'btn-match-minimatch-',
        extended: 'btn-match-extended-',
        highlights: 'btn-match-highlights-',
    },
};

export default focusIds;
