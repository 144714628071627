import { FocusEvent } from 'lal-lrud/dist/types';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

let lrudFocusCache: {
    [index: string]: string | null;
} = {};

export const clearNavigationPosition = () => (lrudFocusCache = {});

export const useFocusCache = (panelId?: string, panelIndex?: number) => {
    const { pathname } = useLocation();

    const setFocusCache = useCallback(
        (e: FocusEvent | string) => {
            const focusId = (typeof e === 'string' ? e : e.focusNode?.focusId) || null;
            lrudFocusCache[pathname] = focusId;
        },
        [pathname]
    );
    const getFocusCache = useCallback(() => {
        return lrudFocusCache[pathname];
    }, [pathname]);

    return {
        setFocusCache,
        getFocusCache,
    };
};
