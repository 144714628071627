import { useEffect } from 'react';
import { Buffer } from 'buffer';
import { Firebolt } from 'src/lib/firebolt';
import useAppReady from '../../controllers/app/app.ready.controller';
import { useNavigate } from 'react-router-dom';
import RouteTags, { getRouteTags } from 'src/routes/routeTags';
import { log } from 'src/utilities/console';

/**
 * This is a common Firebolt SDK module which sends certain events and methods for both the App and Player lifecycle
 * @returns
 */

const useFirebolt = () => {
    const { ready } = useAppReady();
    const navigate = useNavigate();
    // init analytics on the first render
    useEffect(() => {
        if (ready) {
            log('### APP IS READY >> Lets Firebolt!');
            Firebolt.setReady();
            Firebolt.navigateToCb((value: any) => {
                log('### Firebolt >> Discovery.navigateTo()', value);
                if (value) {
                    const deeplinkIntent = Firebolt.parseNavIntent(JSON.stringify(value));
                    if (deeplinkIntent) {
                        log('### Firebolt >> Discovery Navigate (The Intent):', deeplinkIntent);
                        const assetId = String(Buffer.from(deeplinkIntent, 'base64'));
                        const vodPath = getRouteTags(
                            RouteTags.vodDetail,
                            ['dashboard', 'assetId'],
                            ['football', assetId]
                        );
                        navigate(vodPath, { state: { autoPlay: '' } });

                        // const route = link.split('#')[1];
                        // navigate(route);
                    }
                }
            });
        }
    }, [ready]);
};

export default useFirebolt;
