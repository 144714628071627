import useSidebar from './sidebar.controller';
import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { competitionsConfig } from 'src/config/competitionConfig';

const useTheme = (asset?: AssetType) => {
    const { selectedSidebarItem } = useSidebar();
    let { theme = 'dark' } = selectedSidebarItem || {};

    const { match, type } = asset || {};
    const { competition } = match || {};
    const { id } = competition || {};

    if (type === 'fitness') {
        theme = 'light';
    }

    if (id && id in competitionsConfig) {
        theme = competitionsConfig[id].theme;
    }

    return { theme };
};

export default useTheme;
