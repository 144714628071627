import { memo } from 'react';
import Typography from 'src/components/Common/Typography';
import useMiniEPG from 'src/controllers/miniEPG.controller';
import styles from './programNowTitle.module.scss';

interface ProgramNowTitlePropsType {
    title?: string;
    className?: string;
}
const ProgramNowTitle = (props: ProgramNowTitlePropsType) => {
    const { title: defaultTitle } = props;
    const { onNowProgram } = useMiniEPG();
    const { title = defaultTitle } = onNowProgram || {};
    return <Typography className={styles.title}>{title}</Typography>;
};

export default memo(ProgramNowTitle);
