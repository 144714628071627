import { eventCategories, eventTypes } from './constants';

export const pageEvents = {
    dashboard: {
        name: 'Home',
        segmentActive: true,
        brazeActive: true,
    },

    explore: {
        name: 'Competition',
        segmentActive: true,
        brazeActive: true,
    },

    browse: {
        name: 'Browse',
        segmentActive: true,
        brazeActive: true,
    },

    live: {
        name: 'Live Channel',
        segmentActive: true,
        brazeActive: true,
    },

    faq: {
        name: 'Faq',
        segmentActive: true,
        brazeActive: true,
    },

    'device-management': {
        name: 'Device Management',
        segmentActive: true,
        brazeActive: true,
    },

    'match-upcoming': {
        name: 'Match Prematch',
        segmentActive: true,
        brazeActive: true,
    },

    'match-live': {
        name: 'Match Live',
        segmentActive: true,
        brazeActive: true,
    },

    'match-past': {
        name: 'Match Postmatch',
        segmentActive: true,
        brazeActive: true,
    },

    vodDetail: {
        name: 'VOD Detail',
        segmentActive: true,
        brazeActive: true,
    },

    'customer-support': {
        name: 'Support',
        segmentActive: true,
        brazeActive: true,
    },

    'privacy-policy': {
        name: 'Privacy Policy',
        segmentActive: true,
        brazeActive: false,
    },

    'account-profile': {
        name: 'My Profile',
        segmentActive: true,
        brazeActive: true,
    },

    login: {
        name: 'Login',
        segmentActive: true,
        brazeActive: false,
    },

    welcome: {
        name: 'Welcome',
        segmentActive: true,
        brazeActive: false,
    },
};

export const apiEvents = {
    authInit: {
        name: 'Auth Initiate',
        category: eventCategories.API_USER,
        type: eventTypes.API,
        segmentActive: true,
        brazeActive: false,
    },
    loginSuccess: {
        name: 'Login Success',
        category: eventCategories.API_USER,
        type: eventTypes.API,
        segmentActive: true,
        brazeActive: false,
    },
    loginFailed: {
        name: 'Login Errored',
        category: eventCategories.API_USER,
        type: eventTypes.API,
        segmentActive: true,
        brazeActive: false,
    },
    getUserDetails: {
        name: 'Get User Details',
        category: eventCategories.API_USER,
        type: eventTypes.API,
        segmentActive: true,
        brazeActive: false,
    },
    logoutSuccess: {
        name: 'Logout API Success',
        category: eventCategories.API_USER,
        type: eventTypes.API,
        segmentActive: true,
        brazeActive: false,
    },
    logoutFailed: {
        name: 'Logout API Errored',
        category: eventCategories.API_USER,
        type: eventTypes.API,
        segmentActive: true,
        brazeActive: false,
    },
};

export const appEvents = {
    userLoggedOut: {
        name: 'User Logged Out',
        category: eventCategories.APP_USER,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    userLoggedIn: {
        name: 'User Logged In',
        category: eventCategories.APP_USER,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    userLoggedOutCancelled: {
        name: 'Signout Cancelled',
        category: eventCategories.APP_USER,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    userLoggedOutClicked: {
        name: 'Signout Clicked',
        category: eventCategories.APP_USER,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    launch: {
        name: 'App Launch',
        category: eventCategories.APP_USER,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    close: {
        name: 'App Close',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    suspend: {
        name: 'App Suspend',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    resume: {
        name: 'App Resume',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    navigation: {
        name: 'App Navigation',
        category: eventCategories.APP_USER,
        type: eventTypes.APP,
        segmentActive: false,
        brazeActive: false,
    },
    menuOpened: {
        name: 'Menu Opened',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: false,
        brazeActive: false,
    },
    menuClosed: {
        name: 'Menu Closed',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: false,
        brazeActive: false,
    },
    assetClicked: {
        name: 'Asset Clicked',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    contentCardClicked: {
        name: 'Content Card Clicked',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },

    contentCardViewed: {
        name: 'Content Card Viewed',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: false,
        brazeActive: false,
    },

    deviceManagementPageShown: {
        name: 'Device Management Page Shown',
        category: eventCategories.APP_USER,
        type: eventTypes.APP,
        segmentActive: false,
        brazeActive: false,
    },

    // settingsLoginScreen: {
    //     name: 'Login Screen Viewed',
    //     category: eventCategories.OS_SETTINGS_PAGE,
    //     type: eventTypes.APP,
    //     segmentActive: true,
    //     brazeActive: true,
    // },

    // settingsMenuClicked: {
    //     name: '<title>',
    //     category: eventCategories.OS_SETTINGS_PAGE,
    //     type: eventTypes.APP,
    //     segmentActive: true,
    //     brazeActive: true,
    // },
};

export const playerEvents = {
    videoQualitySelected: {
        name: 'Video Settings <id> Clicked',
        category: eventCategories.APP,
        type: eventTypes.APP,
        segmentActive: true,
        brazeActive: false,
    },
    videoPlaybackRequested: {
        name: 'Video Playback Requested',
        segmentActive: true,
        brazeActive: true,
    },
    videoPlaybackInterrupted: {
        name: 'Video Playback Interrupted',
        segmentActive: true,
        brazeActive: true,
    },
    videoPlaybackCompleted: {
        name: 'Video Playback Completed',
        segmentActive: true,
        brazeActive: true,
    },
};
