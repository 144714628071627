import { FocusNode } from 'lal-lrud';
import styles from './playerAdUI.module.scss';
import { formatDuration } from 'src/utilities/time';
import usePlayer from 'src/controllers/player.controller';

interface PlayerAdUIPropsType {
    timeRemaining: number | undefined;
    showAdUI: boolean;
}

const PlayerAdUI = (props: PlayerAdUIPropsType) => {
    const { timeRemaining, showAdUI } = props;

    const { Player } = usePlayer();
    const onClick = () => {
        if (Player.isPaused()) {
            Player.play();
        } else {
            Player.pause();
        }
    };
    return timeRemaining ? (
        <FocusNode
            onClick={onClick}
            onSelected={onClick}
            className={styles.adUI}
            disabled={showAdUI}
        >
            {`Ad • ${formatDuration(timeRemaining)}`}
        </FocusNode>
    ) : null;
};

export default PlayerAdUI;
