import { getRefreshToken } from 'src/utilities/session';
import { fibonacciDelay } from 'src/utilities/time';
import { getHttpParams } from 'src/utilities/http';
import { isAuthError, isRetryable } from './checkers';
import { getAccessToken } from './getAccessToken';
import getNetworkStatusInstance from 'src/utilities/network';

const networkStatus = getNetworkStatusInstance();
export const actionRunner = (action: Function, retryCount: number, payload: any) => {
    return new Promise((resolve, reject) => {
        const delayNRun = () => {
            const delay = fibonacciDelay(retryCount + 2) * 1000;
            setTimeout(() => {
                actionRunner(action, retryCount + 1, payload)
                    .then(resolve)
                    .catch(reject);
            }, delay);
        };

        const retry = (refreshTokenOnRetry = false) => {
            const refreshToken = getRefreshToken();
            if (refreshTokenOnRetry && refreshToken) {
                getAccessToken().then(delayNRun).catch(reject);
            } else {
                delayNRun();
            }
        };

        action()
            .then((response: any) => {
                let payloadForDispatch = null;
                const { data, error } = response || {};
                let p = payload.fullfilled || data || error || response;

                if (payload.meta) {
                    payloadForDispatch = {
                        payload: p,
                        meta: payload.meta,
                    };
                } else {
                    payloadForDispatch = { ...p, ...getHttpParams(response) };
                }

                if (data && (data.error || data.success === false)) {
                    if (isRetryable(response, retryCount, payload.options)) {
                        retry();
                    } else {
                        reject(payloadForDispatch);
                    }
                } else {
                    resolve(payloadForDispatch);
                }
            })
            .catch((reason: any) => {
                networkStatus.checkIfOffline().then(() => {
                    if (isRetryable(reason.response, retryCount, payload.options)) {
                        const refreshTokenOnRetry =
                            reason.response && isAuthError(reason.response.status);
                        retry(refreshTokenOnRetry);
                        return;
                    } else if (payload.fallback) {
                        resolve(payload.fallback);
                        return;
                    } else if (reason) {
                        let payloadForDispatch = null;
                        const { response } = reason;
                        const error = response && (response.data || response.error);
                        let p = error || reason;
                        if (payload.meta) {
                            payloadForDispatch = {
                                payload: p,
                                meta: payload.meta,
                                reason: reason,
                            };
                        } else {
                            payloadForDispatch = p;
                        }
                        reject({ reason, payload: payloadForDispatch });
                    }
                    reject({ reason, payload: null });
                });
            });
    });
};
