import PlayerConfig from '@optussport/fe-bitmovin-player/lib/types/PlayerConfig';
import { FeaturesType } from '@optussport/fe-bitmovin-player/lib/types/Features';
import { SpeedType } from '../player.d';
import { CONFIG } from 'src/config/config';
import { getActivePlayer } from '../utilities/player';

export const featuresConfig: FeaturesType = {
    enableSaveUserSettings: false, // Enable/disable saving and applying the player volume/mute and video quality across user sessions and for all assets played.
    yspPlaybackPolicy: {
        enabled: true, // Enable/disable the use of custom playback policy for skipping ads and seeking behaviour. Needs to be enabled for ad holiday to work.
        allowSeekPastAds: false, // Allow user to seek forward so that the ads in between the current position and seek position will not be played after seeking.
    },
    adHoliday: {
        enabled: CONFIG.adHolidayEnabled, // Enable/disable ad holiday countdown calculation
        duration: CONFIG.adHolidayDuration, // 180 seconds = 3 mins
        allowSkipButton: false, // Show/hide skip button during an ad break if user is inside the ad holiday period
    },
};

export const bPlayerConfig: PlayerConfig = {
    key: '091F8084-B544-4DAB-B090-94ECF8506D12',
    features: featuresConfig,
    ui: false,
    playback: {
        autoplay: false,
    },
    adaptation: {
        maxStartupBitrate: '2000kbps',

        onVideoAdaptation: ({ representations, suggested }) => {
            const player = getActivePlayer();
            const { bitrate: userBitrate = Number.POSITIVE_INFINITY } =
                player.getQualities().find((q) => q.selected) || {};
            const { bandwidth: suggestedBitrate = Number.POSITIVE_INFINITY } =
                representations.find((r) => r.id === suggested) || {};

            const maxBitrate = Math.min(userBitrate, suggestedBitrate);

            const { id = suggested } =
                [...representations.sort((a, b) => b.bandwidth - a.bandwidth)].find(
                    (r) => r.bandwidth <= maxBitrate
                ) || {};

            return id;
        },
    },
    buffer: {
        video: {
            forwardduration: 16,
            backwardduration: 12,
        },
        audio: {
            forwardduration: 16,
            backwardduration: 12,
        },
    },
    tweaks: {
        startup_threshold: 0.5,
    },
};

export const speeds: SpeedType[] = [
    {
        id: '0',
        label: '50%',
        speed: 0.5,
        selected: false,
    },
    {
        id: '1',
        label: 'Normal',
        speed: 1,
        selected: false,
    },
    {
        id: '2',
        label: '150%',
        speed: 1.5,
        selected: false,
    },
    {
        id: '3',
        label: '200%',
        speed: 2,
        selected: false,
    },
];
