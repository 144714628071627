import { FULLFILLED, PENDING, REJECTED } from 'src/state/middlewares/promise/constants';
import { translateDrData } from 'src/utilities/drmode';
import { Action } from '../../reducers.d';
import { AppStateType, DrChannelItem } from './app';
import * as ACTIONS from './app.constants';
import { CONFIG } from 'src/config/config';

export const INITIAL_STATE_APP: AppStateType = {
    sidebar: null,
    isDisasterMode: CONFIG.drModeCheckActive ? null : false,
    ready: false,
    drChannelList: undefined,
    drChannelPlaybackData: [],
    playerPreference: {
        playbackQuality: 0,
    },
};

export function appReducer(state: AppStateType, action: Action) {
    switch (action.type) {
        case `${ACTIONS.ACTION_APP_STATUS_READY}`: {
            return {
                ...state,
                ready: true,
            };
        }
        case `${ACTIONS.ACTION_APP_SET_PLAYER_QUALITY}`: {
            return {
                ...state,
                playerPreference: { playbackQuality: action.payload },
            };
        }

        case `${ACTIONS.ACTION_FETCH_SIDE_BAR}_${PENDING}`: {
            return {
                ...state,
                sidebar: [],
            };
        }
        case `${ACTIONS.ACTION_FETCH_SIDE_BAR}_${FULLFILLED}`: {
            return {
                ...state,
                sidebar: action.payload,
            };
        }

        case `${ACTIONS.ACTION_FETCH_DR_CHANNELS}_${PENDING}`: {
            return {
                ...state,
                drChannelList: [],
            };
        }
        case `${ACTIONS.ACTION_FETCH_DR_CHANNELS}_${FULLFILLED}`: {
            delete action.payload.http;
            let drChannels: DrChannelItem[] = Object.values(action.payload) || [];
            let drChannelList = drChannels.map((dr) => translateDrData(dr).asset);
            let drChannelPlaybackData = drChannels.map((dr) => translateDrData(dr).playbackData);
            return {
                ...state,
                isDisasterMode: Array.isArray(drChannels) && drChannels.length > 0,
                drChannelList,
                drChannelPlaybackData,
            };
        }

        case `${ACTIONS.ACTION_FETCH_DR_CHANNELS}_${REJECTED}`: {
            return {
                ...state,
                isDisasterMode: false,
                drChannelList: [],
            };
        }

        default:
            break;
    }
    return state;
}
