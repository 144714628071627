export const exploreLayoutMap: any = {
    explore_deeplink_copa_america: 'COPA-AMERICA-HOME-CTV',
    explore_deeplink_dfb: 'DFB-POKAL-HOME-CTV',
    explore_deeplink_epl: 'EPL-HOME-CTV',
    explore_deeplink_euro: 'EURO-HOME-CTV',
    explore_deeplink_euro_2024: 'EURO-HOME-CTV',
    explore_deeplink_internationals: 'INTERNATIONALS-HOME-CTV',
    explore_deeplink_j_league: 'J-LEAGUE-HOME-CTV',
    explore_deeplink_k_league: 'K-LEAGUE-HOME-CTV',
    explore_deeplink_laliga: 'LALIGA-HOME-CTV',
    explore_deeplink_nwsl: 'NWSL-HOME-CTV',
    explore_deeplink_uwc: 'UWC-HOME-CTV',
    explore_deeplink_womens: 'WOMENS-HOME-CTV',
    explore_deeplink_wwc: 'WWC-HOME-CTV',
    explore_deeplink_resistance: 'FITNESS-RESISTANCE-CTV',
    explore_deeplink_cardio: 'FITNESS-CARDIO-CTV',
    explore_deeplink_yoga: 'FITNESS-YOGA-CTV',
    explore_deeplink_sport: 'FITNESS-SPORT-SKILLS-CTV',
    explore_deeplink_pilates: 'FITNESS-PILATES-CTV',
    explore_deeplink_recovery: 'FITNESS-RECOVERY-CTV',
    explore_deeplink_meditation: 'FITNESS-MEDITATION-CTV',
    explore_deeplink_pregnancy: 'FITNESS-PREGNANCY-CTV',
    explore_deeplink_post_pregnancy: 'FITNESS-POST-PREGNANCY-CTV',
    explore_deeplink_family_fit: 'FITNESS-FAMILY-FIT-CTV',
};
