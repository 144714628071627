import { memo } from 'react';
import Router from './routes';
import styles from './app.module.scss';
import useApp from './controllers/app/app.controller';
import XboxApp from './platforms/xbox';

import { initWebosChild, isWebos } from 'src/utilities/webos';

import URLSearchParams from '@ungap/url-search-params';
import Storage from 'src/utilities/storage';
import { isXbox } from './utilities/xbox';

import { CONFIG } from './config/config';
import { debugInit } from './utilities/console';

const urlParams = new URLSearchParams(window.location.search);
const resQuery = urlParams.get('res');

const App = () => {
    useApp();

    if (CONFIG.debug) {
        debugInit();
    }

    const hasWebOSDetails = Storage.getItem('p');
    if (isWebos() && !hasWebOSDetails?.sdkVersion) {
        initWebosChild();
    }

    const appClass = [styles.app];
    if (resQuery === 'hd') {
        appClass.push(styles.hd);
    }

    if (isXbox()) {
        return (
            <div className={appClass.join(' ')}>
                <XboxApp></XboxApp>
            </div>
        );
    }

    return (
        <div className={appClass.join(' ')}>
            <Router></Router>
        </div>
    );
};

export default memo(App);
