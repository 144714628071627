import Storage from './storage';
import platform from 'platform';
import { log } from './console';

function safeGet(fn: any) {
    try {
        var t = fn.call();
        return t;
    } catch (error: any) {
        if (error.message.indexOf('undefined') === -1) {
            log('=====> Error getting Tizen APIs', JSON.stringify(error));
            // Error, such as a missing privilege
        } else {
            log('=====> Error getting Tizen APIs - Older Firmware');
            // Undefined error
            // Older firmware and models do not support this method
            // Consider using a legacy method
        }
        return 'null';
    }
}

export const getTIFA = () => {
    const webapis = window.webapis;
    let tizenAdId = sessionStorage.getItem('adid');

    if (webapis && webapis.adinfo && !tizenAdId) {
        const value = safeGet(webapis.adinfo.getTIFA);
        if (value) {
            log('======> getTIFA value = ' + value);
            sessionStorage.setItem('adid', value);
            tizenAdId = value;
        }
    }

    return tizenAdId !== 'null' ? tizenAdId : null;
};

export const getTizenDevice = () => {
    const webapis = window.webapis;
    let { deviceModel: tizenModel } = Storage.getItem('d') ?? '';
    if (webapis && webapis.productinfo && !tizenModel) {
        Storage.setItem('d', { deviceModel: '' });
        const modelValue = safeGet(webapis.productinfo.getModel);
        if (modelValue) {
            log('====> getTizenDevice = ', modelValue);
            Storage.appendItem('d', { deviceModel: modelValue });
            tizenModel = modelValue;
        }
    }
    return tizenModel ?? null;
};

export let isTizen = () => platform.os?.family?.toLowerCase() === 'tizen';
export let getTizenVersion = () => (platform.os?.version || 0).toString();
