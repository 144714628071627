import Logo from 'src/components/Common/Logo';
import Spinner from 'src/components/Common/Spinner';
import styles from './loader.module.scss';

function Loader() {
    return (
        <div className={styles.splash}>
            <Logo className={styles.logo}></Logo>
            <Spinner></Spinner>
        </div>
    );
}

export default Loader;
