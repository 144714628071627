import { CONFIG } from 'src/config/config';
import { BitmovinOptusPlayer } from '.';
import {
    CallBackFn,
    EventCallbacks,
    PlayerClassSpec,
    PlayerState,
    SourceConfigType,
} from './player.d';
import { init } from './Prototypes/init';
import { load } from './Prototypes/load';
import { isAdPlaying, isLive, isPaused, isPlaying, isStalled } from './Prototypes/is';
import { listenForPlayerEvents } from './Prototypes/listenForPlayerEvents';
import { listenForPlaybackEvents } from './Prototypes/listenForPlaybackEvents';
import {
    getAssetUrl,
    getAudioTracks,
    getBufferLength,
    getCurrentTime,
    getDuration,
    getQualities,
    getSpeeds,
    getSubtitleTracks,
    getVersion,
} from './Prototypes/get';
import {
    setAudioTrack,
    setError,
    setPlayerContainerElm,
    setQuality,
    setSpeed,
    setSubtitleTrack,
    setYospaceErrorCallback,
} from './Prototypes/set';

import { checkQuality } from './Prototypes/checkQuality';
import { destroy } from './Prototypes/destroy';
import { ff } from './Prototypes/ff';
import { generateSessionId } from './Prototypes/generateSessionId';
import { initTracking } from './Prototypes/initTracking';
import { on } from './Prototypes/on';
import { onDestroy } from './Prototypes/onDestroy';
import { onError } from './Prototypes/onError';
import { pause } from './Prototypes/pause';
import { play } from './Prototypes/play';
import { qStatus } from './Prototypes/qStatus';
import { ready } from './Prototypes/ready';
import { readyForInit } from './Prototypes/readyForInit';
import { reset, resetSource } from './Prototypes/reset';
import { rw } from './Prototypes/rw';
import { seek } from './Prototypes/seek';
import { unload } from './Prototypes/unload';
import { isYoSpaceError, setYospaceError } from './Prototypes/yospace';

export class PlayerClass implements PlayerClassSpec {
    playerSession = BitmovinOptusPlayer.playerSession;

    constructor() {
        this.assetId = null;
        this.assetUrl = null;
        this.containerElm = null;
        this.ebps = true;
        this.zombieKicked = false;
        this.error = null;
        this.errorCbs = [];
        this.eventCbs = {};
        this.isDatadogRumEnabled = CONFIG.isDatadogRumEnabled;
        this.isMUXEnabled = CONFIG.MUXActive;
        this.isSegmentEnabled = CONFIG.isSegmentEnabled;
        this.isYoSpaceActive = CONFIG.YoSpaceActiveOnLive && CONFIG.YoSpaceActiveOnVod;
        this.isYoSpaceErrorAssetIds = [];
        this.listenForPlayerEvents();
        this.player = null;
        this.playerState = 'READY_FOR_INIT';
        this.retryCountYospaceError = undefined;
        this.sourceConfig = null;
        this.videoElm = null;
        this.yospaceCallback = undefined;
    }
    assetId: string | null;
    assetUrl: string | null;
    containerElm: HTMLDivElement | null;
    ebps: boolean;
    error: string | number | null;
    errorCbs: CallBackFn[];
    eventCbs: EventCallbacks;
    isDatadogRumEnabled: boolean;
    isMUXEnabled: boolean;
    isSegmentEnabled: boolean;
    isYoSpaceActive: boolean;
    isYoSpaceErrorAssetIds: string[];
    player: any;
    playerState: PlayerState;
    retryCountYospaceError: any;
    sourceConfig: SourceConfigType | null;
    videoElm: HTMLVideoElement | null;
    yospaceCallback: any;
    zombieKicked: boolean;

    initTracking = initTracking;
    init = init;
    load = load;
    unload = unload;

    getBufferLength = getBufferLength;
    getCurrentTime = getCurrentTime;
    getDuration = getDuration;
    getVersion = getVersion;

    getQualities = getQualities;
    getSpeeds = getSpeeds;
    getAudioTracks = getAudioTracks;
    getSubtitleTracks = getSubtitleTracks;

    setError = setError;
    setPlayerContainerElm = setPlayerContainerElm;

    setQuality = setQuality;
    setSpeed = setSpeed;
    setAudioTrack = setAudioTrack;
    setSubtitleTrack = setSubtitleTrack;

    isYoSpaceError = isYoSpaceError;
    setYospaceError = setYospaceError;
    setYospaceErrorCallback = setYospaceErrorCallback;

    isLive = isLive;
    isPaused = isPaused;
    isPlaying = isPlaying;
    isStalled = isStalled;
    isAdPlaying = isAdPlaying;

    checkQuality = checkQuality;
    destroy = destroy;
    ff = ff;
    generateSessionId = generateSessionId;
    getAssetUrl = getAssetUrl;
    listenForPlaybackEvents = listenForPlaybackEvents;
    listenForPlayerEvents = listenForPlayerEvents;
    on = on;
    onDestroy = onDestroy;
    onError = onError;
    pause = pause;
    play = play;
    qStatus = qStatus;
    ready = ready;
    readyForInit = readyForInit;
    reset = reset;
    resetSource = resetSource;
    rw = rw;
    seek = seek;
}

export const Player = new PlayerClass();
