import React, { useMemo } from 'react';
import Typography from 'src/components/Common/Typography';
import styles from './loginOptions.module.scss';
import { getPlatformReferral } from 'src/utilities/device';

const LoginOptions = () => {
    const name = useMemo(() => getPlatformReferral(), []);

    return (
        <div className={styles.options}>
            <div className={styles.option1}>
                <Typography className={styles.title}>Visit our website</Typography>
                <Typography className={styles.subTitle}>sport.optus.com.au/{name}</Typography>
                <Typography className={styles.helpText}>
                    To scan, use your camera app on your device
                </Typography>
                <div className={[styles.connect, styles[name]].join(' ')}></div>
            </div>
            <div className={styles.or}>
                <span>OR</span>
                <span className={styles.line} />
            </div>
            <div className={styles.option2}>
                <Typography className={styles.title}>Use the Optus Sport app</Typography>
                <Typography className={styles.helpText}>
                    Open the Optus Sport app, head to the settings page and press{' '}
                    <strong>Log in on TV app</strong>
                </Typography>
                <div className={styles.phone}></div>
            </div>
        </div>
    );
};

export default LoginOptions;
