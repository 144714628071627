import { LayoutIdType } from 'src/state/stores/app/app';
import { CardLayoutType, PanelTypeType } from 'src/state/stores/dashboard/dashboard';

// Page Layouts
export const BROWSE_LAYOUT: LayoutIdType = 'browse';
export const EXPLORE_LAYOUT: LayoutIdType = 'explore';
export const FOOTBALL_LAYOUT: LayoutIdType = 'football';
export const FITNESS_LAYOUT: LayoutIdType = 'fitness';

// Card Layouts
export const RECTANGULAR_CARD: CardLayoutType = 'rectangular_tile';

// Assets
export const VIEW_ALL_CARD: string = 'viewAll';
