import Apis from 'src/apis';
import { Action } from '../../reducers.d';
import * as ACTIONS from './dashboard.constants';
import { LayoutIdType } from '../app/app';
import { PanelType, PlaybackSessionType, WatchModeLiveType, WatchModeType } from './dashboard';
import { getFallbackLayout } from 'src/utilities/dashboard';

export const getExploreLayout = (bucketKey: string, layout: string): Action => {
    const key = bucketKey.split('/').pop();
    let fallback = key ? getFallbackLayout(key) : undefined;
    return {
        type: ACTIONS.ACTION_FETCH_HOME_LAYOUT,
        payload: {
            action: Apis.dashboard.getExploreLayout(bucketKey),
            meta: { layout },
            fallback,
        },
    };
};

export const getLayout = (url: string, layout: string): Action => {
    const key = url.split('/').pop();
    let fallback = key ? getFallbackLayout(key) : undefined;
    return {
        type: ACTIONS.ACTION_FETCH_HOME_LAYOUT,
        payload: {
            action: Apis.dashboard.getLayout(url),
            meta: { layout },
            fallback: fallback,
        },
    };
};

export const resetLayout = (): Action => ({
    type: ACTIONS.ACTION_RESET_HOME_LAYOUT,
});

export const getPanel = (q: string, layoutId: LayoutIdType): Action => ({
    type: ACTIONS.ACTION_FETCH_HOME_PANEL,
    payload: { action: Apis.dashboard.getPanel(q), meta: { bucketKey: q, layoutId } },
});

export const getPanels = (qs: string[], layoutId: LayoutIdType): Action => ({
    type: ACTIONS.ACTION_FETCH_HOME_PANELS,
    payload: {
        action: Apis.dashboard.getPanels(qs),
        meta: { bucketKeys: qs, layoutId },
    },
});

export const getAsset = (assetId: string): Action => ({
    type: ACTIONS.ACTION_FETCH_ASSET,
    payload: {
        action: Apis.dashboard.getAsset(assetId),
        meta: { assetId },
    },
});

export const clearAssetError = (assetId: string): Action => ({
    type: ACTIONS.ACTION_CLEAR_ASSET_ERROR,
    payload: {
        assetId,
    },
});

export const getLiveAssets = (): Action => ({
    type: ACTIONS.ACTION_FETCH_LIVE_ASSETS,
    payload: { action: Apis.dashboard.getLiveAssets() },
});

export const getAssetMetaData = (assetId: string): Action => ({
    type: ACTIONS.ACTION_FETCH_ASSET_METADATA,
    payload: { action: Apis.dashboard.getAssetMetaData(assetId), meta: { assetId } },
});

export const getAssetPlaybackLatestSession = (assetId: string): Action => ({
    type: ACTIONS.ACTION_FETCH_ASSET_PB_SESSION_LATEST,
    payload: { action: Apis.dashboard.getAssetPlaybackLatestSession(assetId) },
});

export const postAssetPlaybackSession = (sessionData: PlaybackSessionType): Action => ({
    type: ACTIONS.ACTION_POST_ASSET_PLAYBACK_SESSION,
    payload: {
        action: Apis.dashboard.postAssetPlaybackSession(sessionData),
        options: { retry: true, maxAttempts: 1 },
    },
});

export const getFreeAssetPlaybackData = (
    assetId: string,
    watchMode: WatchModeType | WatchModeLiveType = 'beginning',
    prohibitTargeting: boolean = false,
    start?: number,
    end?: number,
    options?: { yspSdk: boolean }
): Action => ({
    type: ACTIONS.ACTION_FETCH_ASSET_PLAYBACK,
    payload: {
        action: Apis.dashboard.getFreeAssetPlaybackData(
            assetId,
            watchMode,
            prohibitTargeting,
            start,
            end,
            options
        ),
        retry: false,
    },
});

export const getGeneralAssetPlaybackData = (
    assetId: string,
    userId: string,
    watchMode: WatchModeType | WatchModeLiveType = 'beginning',
    prohibitTargeting: boolean = false,
    start?: number,
    end?: number,
    options?: { yspSdk: boolean }
): Action => ({
    type: ACTIONS.ACTION_FETCH_ASSET_PLAYBACK,
    payload: {
        action: Apis.dashboard.getGeneralAssetPlaybackData(
            assetId,
            userId,
            watchMode,
            prohibitTargeting,
            start,
            end,
            options
        ),
        retry: false,
    },
});

export const getLiveAssetPlaybackData = (
    assetData: any,
    userId: string,
    prohibitTargeting: boolean = false,
    watchmode: WatchModeLiveType = 'live'
): Action => ({
    type: ACTIONS.ACTION_FETCH_LIVE_ASSET_PLAYBACK,
    payload: {
        action: Apis.dashboard.getLiveAssetPlaybackData(
            assetData,
            userId,
            prohibitTargeting,
            watchmode
        ),
        retry: false,
    },
});

export const getLiveAssetEPGData = (assetId: string): Action => ({
    type: ACTIONS.ACTION_FETCH_LIVE_ASSET_EPG,
    payload: { action: Apis.dashboard.getLiveAssetEPGData(assetId), meta: { assetId } },
});

export const getRelatedAssets = (assetId: string): Action => ({
    type: ACTIONS.ACTION_FETCH_RELATED_ASSETS,
    payload: { action: Apis.dashboard.getRelatedAssets(assetId), meta: { assetId } },
});

export const focusAsset = (panelId: string, assetId: string): Action => ({
    type: ACTIONS.ACTION_FOCUS_ASSET,
    payload: { panelId, assetId },
});

export const showShimmer = (show: boolean): Action => {
    return {
        type: ACTIONS.ACTION_HOME_SHOW_HIDE_SHIMMER,
        payload: show,
    };
};

export const showOverlay = (assetId: null | string): Action => {
    return {
        type: ACTIONS.ACTION_HOME_SHOW_HIDE_OVERLAY,
        payload: assetId,
    };
};

export const setPanelsToRender = (page: string, panels: PanelType[]): Action => {
    return {
        type: ACTIONS.ACTION_HOME_SET_PANELS_TO_RENDER,
        payload: { panels, page },
    };
};

export const getContentCardsPlatform = (): Action => {
    return {
        type: ACTIONS.ACTION_FETCH_CONTENTCARDS_PLATFORM,
        payload: Apis.dashboard.getContentCardsPlatform(),
    };
};

export const getContentCardsUser = (userId: string): Action => {
    return {
        type: ACTIONS.ACTION_FETCH_CONTENTCARDS_USER,
        payload: Apis.dashboard.getContentCardsUser(userId),
    };
};

export const getContentCardsBrazeAll = (): Action => {
    return {
        type: ACTIONS.ACTION_FETCH_CONTENTCARDS_BRAZE_ALL,
        payload: Apis.dashboard.getContentCardsBrazeAll(),
    };
};

export const getUserFavourites = (optusTeamCode: string): Action => {
    return {
        type: ACTIONS.ACTION_FETCH_USER_FAVOURITES,
        payload: {
            action: Apis.dashboard.getUserFavourites(optusTeamCode),
            meta: { bucketKey: optusTeamCode },
        },
    };
};
