import { CONFIG } from 'src/config/config';
import BrazeRoot from './BrazeRoot';
import BrazeHeadless from './BrazeHeadless';

const Braze = () => {
    if (CONFIG.BrazeInAppMessagesHeadlessActive) {
        return <BrazeHeadless />;
    }
    return <BrazeRoot />;
};

export default Braze;
