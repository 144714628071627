import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useMatch } from 'react-router-dom';

import RouteTags, { getRouteTags } from 'src/routes/routeTags';

import { useStateValue } from 'src/state';
import { getDrChannels } from 'src/state/stores/app/app.actions';

import useUser from './auth/user.controller';

import { getActivePlayer, getBitmovinSourceConfig } from 'src/lib/bitmovin/utilities/player';
import { formatAssetPlaybackData } from 'src/utilities/asset';
import { getAppData } from 'src/lib/Analytics/Segment/utils';

import { CONFIG } from 'src/config/config';

const useDisasterMode = (fetch: boolean = false) => {
    const navigate = useNavigate();
    const isAtDRPlayerPage = useMatch(RouteTags.drplayer);
    const { state, dispatch } = useStateValue();

    const {
        appData: { isDisasterMode, drChannelList, drChannelPlaybackData },
    } = state;

    const { user } = useUser();

    const { assetId } = useParams();

    useEffect(() => {
        if (CONFIG.drModeCheckActive && fetch && drChannelList === undefined) {
            dispatch(getDrChannels());
        }
    }, [dispatch, fetch, drChannelList]);

    useEffect(() => {
        if (isAtDRPlayerPage && drChannelList !== undefined && !drChannelList.length) {
            navigate(RouteTags.root);
        }
    }, [navigate, drChannelList, isAtDRPlayerPage]);

    const selectDRStream = useCallback(
        (streamId: string) => {
            navigate(getRouteTags(RouteTags.drplayer, 'assetId', streamId));
        },
        [navigate]
    );

    const handleBack = useCallback(() => {
        const Player = getActivePlayer();
        Player.unload();

        navigate(-1);
        return true;
    }, [navigate]);

    let panelData = useMemo(() => {
        return {
            assets: drChannelList,
            displayName: '',
            id: '',
            bucketKey: '',
        };
    }, [drChannelList]);

    const [drAsset, playbackData] = useMemo(() => {
        if (!drChannelList) {
            return [undefined, undefined];
        }

        const Player = getActivePlayer();

        const channelI = drChannelList.findIndex((dr) => dr.id === assetId);
        if (channelI === -1 || !drChannelList) {
            return [undefined, undefined];
        }
        const channel = drChannelList && drChannelList[channelI];
        const playBackData = formatAssetPlaybackData(drChannelPlaybackData[channelI]);
        const { sourceConfig } = getBitmovinSourceConfig(playBackData, 0);

        const userData = { ...user, loggedIn: !!user };
        const segmentData = {
            appData: getAppData(),
            userData: userData,
            assetData: channel,
            playbackData: drChannelPlaybackData[channelI],
        };
        Player.load(channel.id, sourceConfig, false, segmentData, '');

        return [channel, playBackData];
    }, [drChannelList, assetId, user, drChannelPlaybackData]);

    return {
        isDisasterMode,
        drChannelList,
        panelData,
        playbackData,
        selectDRStream,
        drAsset,
        handleBack,
    };
};
export default useDisasterMode;
