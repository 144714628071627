import { FULLFILLED, PENDING, REJECTED } from 'src/state/middlewares/promise/constants';
import { getAuth, getProcessIdToken, getUser } from 'src/utilities/user';
import { Action } from '../../reducers.d';
import { AuthType, UserDataType, UserStateType, UserType } from './user.d';
import * as ACTIONS from './user.constants';
import getNetworkStatusInstance from 'src/utilities/network';
import { setRefreshToken } from 'src/utilities/session';

export const INITIAL_STATE_USER: UserStateType = {
    init: undefined,
    auth: undefined,
    user: undefined,
    userData: undefined,
};

const networkStatus = getNetworkStatusInstance();

export function userReducer(state: UserStateType, action: Action): UserStateType {
    if (networkStatus.isError()) {
        return state;
    }

    switch (action.type) {
        case `${ACTIONS.ACTION_FETCH_USER}_${PENDING}`:
        case `${ACTIONS.ACTION_FETCH_USER}_${REJECTED}`: {
            let userData: UserDataType | undefined | null = null;
            let user: UserType | undefined = undefined;
            if (state.auth) {
                const { userId = '', IdToken } = state.auth;
                const accessGroups = ['PREMIUM'];
                const type = 'PREMIUM';
                const userType = 'PREMIUM';
                userData = {
                    accessGroups,
                    analyticUserId: '',
                    isIntlUser: false,
                    subscriptions: [],
                    type,
                    userId,
                    userSegmentTags: [],
                    userType,
                    ...(state.userData || {}),
                };

                const { email, name, phone_number, analyticUserId } = getProcessIdToken(IdToken);

                user = {
                    userId,
                    email,
                    name,
                    phone_number,
                    accessGroups: [],
                    type,
                    analyticUserId,
                };
            }
            return {
                ...state,
                user,
                userData,
            };
        }
        case `${ACTIONS.ACTION_FETCH_USER}_${FULLFILLED}`: {
            const {
                data: { item: userData },
            } = action.payload;

            const { IdToken } = state.auth!;
            const user = getUser(IdToken, userData);
            return {
                ...state,
                user: user ? { ...user } : null,
                userData: {
                    ...userData,
                    userType: userData.type, // FIXME: Needed for Segment Data logs - can be improved.
                },
            };
        }

        // case `${ACTIONS.ACTION_TOKEN_REFRESH}_${REJECTED}`: {
        //     return {
        //         ...state,
        //         auth: null,
        //         user: null,
        //         userData: null,
        //     };
        // }

        case `${ACTIONS.ACTION_TOKEN_REFRESH}_${FULLFILLED}`: {
            const { AuthenticationResult: data } = action.payload;
            const auth = getAuth(data);
            return {
                ...state,
                auth,
            };
        }

        case `${ACTIONS.ACTION_LOGIN_POLL}_${FULLFILLED}`: {
            const { success, data } = action.payload;
            let auth: AuthType | null = null;
            let user: UserType | null = null;

            if (success && data && data.RefreshToken) {
                setRefreshToken(data.RefreshToken);
                try {
                    auth = getAuth(data) || null;
                    user = auth ? getUser(auth.IdToken) : null;
                } catch (error) {}
            }
            return {
                ...state,
                auth,
                user,
            };
        }

        case `${ACTIONS.ACTION_LOGIN_INIT}_${PENDING}`: {
            return {
                ...state,
                init: null,
            };
        }

        case `${ACTIONS.ACTION_LOGIN_INIT}_${FULLFILLED}`: {
            return {
                ...state,
                init: action.payload.data,
            };
        }

        default:
            break;
    }
    return state;
}
