import { ReactChild, createContext, useContext, useMemo, useReducer, useRef } from 'react';
import promiseMiddleware from './middlewares/promise';
import reducers, { GLOBAL_INITIAL_STATE, State } from './reducers';
import { Action } from './reducers.d';

type dispatchType = (action: Action) => Promise<any>;

type ContextType = {
    state: State;
    dispatch: dispatchType;
};

type StateProviderProps = {
    children: ReactChild;
};

let D: (actiont: Action) => Promise<unknown>;

export const StateContext = createContext<ContextType>({
    state: GLOBAL_INITIAL_STATE,
    dispatch: () => Promise.resolve(),
});

export function StateProvider(props: StateProviderProps) {
    const { children } = props;
    const [state, dispatch] = useReducer(reducers, GLOBAL_INITIAL_STATE);
    const dispatchPromiseRef = useRef(promiseMiddleware(dispatch));
    D = dispatchPromiseRef.current;
    const value = useMemo(() => ({ state, dispatch: dispatchPromiseRef.current }), [state]);
    return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
}

export const useStateValue = (): ContextType => useContext(StateContext);
export const getDispatch = () => D;
