import { useTranslation } from 'react-i18next';
import DRBody from 'src/components/ui/Disaster';
import styles from './disasterRecover.module.scss';

const DisasterRecovery = () => {
    const { t } = useTranslation(undefined, { keyPrefix: 'disasterMode' });

    return (
        <>
            <div className={styles.drLogo}></div>
            <div className={styles.drTextBox}>
                <div className={styles.drTextIcon}></div>
                <div className={styles.drText}>{t('drText')}</div>
            </div>
            <DRBody />
        </>
    );
};

export default DisasterRecovery;
