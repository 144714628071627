import { useEffect, useState } from 'react';
import { PlaybackEvent } from 'src/lib/bitmovin/player.d';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';
import Storage from 'src/utilities/storage';

const useSubtitle = () => {
    const [text, setText] = useState('');

    useEffect(() => {
        const Player = getActivePlayer();

        const enableSubtitle = (e: any) => {
            const track = Storage.getItem('vsub');
            if (track) {
                setTimeout(() => {
                    Player.setSubtitleTrack(track);
                }, 500);
            }
        };

        const disableSubtitle = (e: any) => {
            Player.setSubtitleTrack(undefined);
        };

        const handlerEnter = (e: any) => {
            setText(e.text);
        };

        const handlerExit = (e: any) => {
            setText('');
        };

        Player.ready().then(() => {
            Player.on('playing', enableSubtitle);
            Player.on('adbreakfinished', enableSubtitle);
            Player.on('adbreakstarted', disableSubtitle);

            Player.on('cueenter', handlerEnter);

            const onExitEvents: PlaybackEvent[] = [
                'cueexit',
                'audiochanged',
                'subtitleenabled',
                'seek',
                'timeshift',
                'adbreakstarted',
            ];
            onExitEvents.forEach((e) => {
                Player.player.on(e, handlerExit);
            });
        });
    }, []);
    return { text };
};
export default useSubtitle;
