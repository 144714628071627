import useBrazeHeadLess from '../../controllers/brazeHeadless.controller';
import BannerMessage from '../Messages/Banner';

const BrazeHeadless = () => {
    const { message, focusedBtnIndex } = useBrazeHeadLess();
    if (message) {
        return <BannerMessage message={message} focusedBtnIndex={focusedBtnIndex} />;
    }
    return <></>;
};

export default BrazeHeadless;
