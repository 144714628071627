import React, { useMemo } from 'react';
import useDashboardBody from 'src/controllers/dashboardBody.controller';
import HeroBanner from '../../Hero/HeroBanner';
import HeroPanel from '../../Hero/HeroPanel';
import Panel from '../../Panel';
import PanelViewAll from '../../PanelViewAll';
import DashboardError from '../DashboardError';
import styles from './dashboardBody.module.scss';

const DashboardBody = () => {
    const { panelSlice, heroPanel, isBrowse, showErrorPopup, indexOffset, railStyle, isViewAll } =
        useDashboardBody();

    const railClasses = useMemo(() => {
        const c = [styles.panelRail];
        if (isBrowse) {
            c.push(styles.browse);
        }
        return c;
    }, [isBrowse]);

    if (showErrorPopup) {
        return <DashboardError></DashboardError>;
    }
    const viewAllPanel = isViewAll && panelSlice.length && panelSlice[0];
    return (
        <div className={railClasses.join(' ')} style={railStyle}>
            <HeroBanner />
            {heroPanel ? <HeroPanel panel={heroPanel} /> : null}
            {viewAllPanel ? (
                <PanelViewAll panel={viewAllPanel} />
            ) : (
                panelSlice.map((p, i) => (
                    <Panel key={`${p.id}`} index={i + indexOffset} panel={p}></Panel>
                ))
            )}
        </div>
    );
};

export default React.memo(DashboardBody);
