import { LRUDEvent } from 'lal-lrud/dist/types';
import { useNavigate } from 'react-router-dom';

import Button from 'src/components/Common/Button';
import { disableOnboarding, maxOnboardingIndex } from 'src/utilities/onboarding';
import RouteTags, { DASHBOARD_ROOT } from 'src/routes/routeTags';
import styles from './controls.module.scss';
import { FocusNode } from 'lal-lrud';

interface OnboardingControlsProps {
    slideIndex: number;
    onNext: (e: LRUDEvent) => void;
}

const OnboardingControls = (props: OnboardingControlsProps) => {
    const { slideIndex, onNext } = props;
    const navigate = useNavigate();

    const onLogin = () => {
        disableOnboarding();
        navigate(RouteTags.login, { replace: true });
    };
    const onWithoutLogin = () => {
        disableOnboarding();
        navigate(DASHBOARD_ROOT, { replace: true });
    };

    return slideIndex < maxOnboardingIndex ? (
        <Button className={styles.button} onClick={onNext}>
            Next
        </Button>
    ) : (
        <FocusNode className={styles.container} orientation="horizontal">
            <Button className={styles.primaryButton} onClick={onLogin}>
                Log in
            </Button>
            <Button
                className={styles.primaryButton}
                onClick={onWithoutLogin}
                focusId="startbrowsing"
            >
                Start browsing
            </Button>
        </FocusNode>
    );
};

export default OnboardingControls;
