import { CardPosCache } from '../olrud';

let cardPosCache: CardPosCache = {};

export const clearAll = () => {
    cardPosCache = {};
};

export const getPageCardPos = (key: string) => {
    let initialPanel = 0;
    let initialCard = 0;

    if (!cardPosCache[key]) {
        cardPosCache[key] = {
            selectedPanel: 0,
        };
    }

    ({ selectedPanel: initialPanel = 0 } = cardPosCache[key]);

    if (!cardPosCache[key][initialPanel]) {
        cardPosCache[key][initialPanel] = { c: 0 };
    }

    initialCard = cardPosCache[key][initialPanel].c;
    return { focusedPanelIndex: initialPanel, focusedCardIndex: initialCard };
};

export const resetCardPos = (key: string) => {
    if (cardPosCache && cardPosCache[key]) {
        delete cardPosCache[key];
    }
};

export const getCardPos = (key: string, panelIndex: number) => {
    return (cardPosCache[key] && cardPosCache[key][panelIndex]) || { c: 0 };
};

export const setCardPos = (key: string, panelIndex = 0, cardIndex?: number, transform?: number) => {
    cardPosCache[key] = cardPosCache[key] || {
        selectedPanel: panelIndex,
    };
    cardPosCache[key].selectedPanel = panelIndex;
    if (cardIndex !== undefined) {
        if (!cardPosCache[key][panelIndex]) {
            cardPosCache[key][panelIndex] = { c: cardIndex };
        } else {
            cardPosCache[key][panelIndex].c = cardIndex;
        }
        if (transform !== undefined) {
            cardPosCache[key][panelIndex].t = transform;
        }
    }
};
