import { useCallback, useEffect, useState } from 'react';
import usePlayer from './player.controller';

const usePlaybackError = (retry: () => void) => {
    const { Player } = usePlayer();
    const [playerError, setPlayerError] = useState(null);

    useEffect(() => {
        const off = Player.onError((error) => {
            setPlayerError(error);
        });
        return off;
    }, [Player]);

    const retryOnError = useCallback(() => {
        retry();
        setPlayerError(null);
    }, [retry]);

    return {
        playerError,
        retryOnError,
    };
};

export default usePlaybackError;
