import * as B from '@braze/web-sdk';
import styles from './button.module.scss';
import { memo } from 'react';

interface ButtonProps {
    button: B.InAppMessageButton;
    focused: boolean;
}

const Button = (props: ButtonProps) => {
    const { button, focused } = props;
    const { text } = button;

    const classes = [styles.button];
    if (focused) {
        classes.push(styles.focused);
    }
    return <div className={classes.join(' ')}>{text}</div>;
};

export default memo(Button);
