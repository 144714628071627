import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { checkifUpcomingLiveOrPast } from 'src/utilities/time';
import { processImageUrl } from 'src/utilities/url';
import {
    shouldDisableStartover,
    showOverlayAndLeftAlignActionsBasedOnTags,
} from 'src/utilities/asset';
import HeroCardActions from '../HeroCardActions';
import styles from './heroBodies.module.scss';
import useTheme from 'src/controllers/theme.controller';
import { getCardSize } from 'src/components/ui/Card/cardsizes';
import { FocusNode } from 'lal-lrud';
import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';
import HeroContentCardActions from '../HeroContentCardActions';
import useContentCardAsset from 'src/controllers/dashboard/contentCards.asset.controller';
import useContentCardVisible from 'src/controllers/dashboard/contentCards.log.visible.controller';

interface HeroShowBodyProps {
    asset: AssetType;
    focused: boolean;
    onClick: () => void;
}

const cardSize = getCardSize('hero');
const { imageKey, imgHeight, imgWidth } = cardSize;

const HeroShowBody = (props: HeroShowBodyProps) => {
    const { asset, focused, onClick } = props;
    const { ccAsset } = useContentCardAsset(asset.id);
    const { broadcastEndTime, broadcastStartTime, description, title, type, contentCard } =
        ccAsset || asset;

    const { theme } = useTheme(asset);

    const bgUrl = asset[imageKey];
    const backgroundImage = processImageUrl(bgUrl || '', imgWidth, imgHeight);
    const bgStyle = {
        backgroundImage: `url(${backgroundImage}), url(${stockBackground})`,
    };

    const showTitle = showOverlayAndLeftAlignActionsBasedOnTags(asset);
    const hideStartOverButton = shouldDisableStartover(asset);

    const titleClasses = [styles.title, theme === 'light' && styles.light].join(' ');
    const descClasses = [styles.description, theme === 'light' && styles.light].join(' ');
    const contentClasses = [styles.content];
    if (contentCard) {
        contentClasses.push(styles.contentCard);
    }

    useContentCardVisible(contentCard, 0, false, focused);

    return (
        <FocusNode className={styles.cardBody}>
            <div className={styles.bg} style={bgStyle}></div>
            <div className={styles.mask}></div>
            <div className={contentClasses.join(' ')}>
                {showTitle ? <span className={titleClasses}>{title}</span> : null}
                {showTitle ? <span className={descClasses}>{description}</span> : null}

                {contentCard ? (
                    <HeroContentCardActions
                        assetId={asset.id}
                        contentCard={contentCard}
                        focused={focused}
                    />
                ) : (
                    <HeroCardActions
                        action={checkifUpcomingLiveOrPast(broadcastStartTime, broadcastEndTime)}
                        onClick={onClick}
                        assetId={asset.id}
                        leftAlign={showTitle}
                        showStartOver={!hideStartOverButton}
                        isShow={type === 'show'}
                        focused={focused}
                    />
                )}
            </div>
        </FocusNode>
    );
};

export default HeroShowBody;
