import { CONFIG } from 'src/config/config';

const { baseURL, baseMetadataURL } = CONFIG;

const urls = {
    // Home Pages
    football_home: `${baseURL}/api/appmanager/v2/apps/ctv/pages/football-home-page-ctv`,
    fitness_home: `${baseURL}/api/appmanager/v2/apps/ctv/pages/fitness-home-page-ctv`,
    explore_home: `${baseURL}/api/appmanager/v2/apps/ctv/pages/<bucketKey>`,
    browse_home: `${baseURL}/api/appmanager/v2/apps/ctv/pages/browse-page-ctv`,

    // Content Cards
    content_cards_platform: `${baseURL}/api1/contentcard/assets/ctv?platform=<platform>`,
    content_cards_user: `${baseURL}/api1/contentcard/assets/users/<userId>/ctv?platform=<platform>`,
    content_cards_braze_all: `${baseMetadataURL}/api/metadata/editorials/v2/content-cards/ctv`,

    // Auth
    login_inititate: `${baseURL}/api/auth/devicelogin/ctv/initiate`,
    login_poll: `${baseURL}/api/auth/devicelogin/ctv/poll`,
    logout_user: `${baseURL}/api/userauth/logout/users/<userId>?platform=<platform>&deviceId=<deviceId>&appVersion=<appVersion>`,
    global_logout_user: `${baseURL}/api/userauth/globalLogout/users/<userId>?appVersion=<appVersion>`,
    token_refresh: `${baseURL}/api/userauth/refreshToken/ctv`,
    user_detail: `${baseURL}/api/userauth/details/users/<userId>`,

    // Favourites
    favourites: `${baseURL}/api/metadata/search/v2/ctv/assets?optusTeamCode=<optusTeamCode>`,

    // Playback
    asset_free_playback_data_url: `${baseURL}/api/playback/freePlayback/ctv/assets/<assetId>`,
    asset_general_playback_data_url: `${baseURL}/api/playback/generalPlayback/ctv/users/<userId>/assets/<assetId>`,
    asset_playback_session: `${baseURL}/api1/sessionmanager/sessions`,
    asset_playback_session_latest: `${baseURL}/api1/sessionmanager/assets/<assetId>/history/latest`,
    live_asset_playback_data_url: `${baseURL}/api/playback/generalPlayback/ctv/users/<userId>/assets/<assetId>`,

    // Asset
    asset_url: `${baseMetadataURL}/api/metadata/assets/v2/<assetId>/ctv?includeMatchRelated=true`,
    asset_metadata_url: `${baseMetadataURL}/api/metadata/assets/v2/<assetId>/ctv`,
    assets_related_assets: `${baseMetadataURL}/api/metadata/search/v2/ctv/assets?relatedAssetId=<assetId>`,
    linear_assets: `${baseMetadataURL}/api/metadata/editorials/v2/home_linear_channels/ctv`,
    live_asset_EPG_data_url: `https://epg.optusvideo.tv/api/assets/<assetId>?mergeAttrs=true&days=2`,

    // Editorials
    panel_url: `${baseMetadataURL}/api/metadata/editorials/v2/<bucketKey>/ctv`,

    // Disaster URL
    drModeCheckURL: CONFIG.drModeCheckURL,
};

export default urls;
