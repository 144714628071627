import { log } from 'src/utilities/console';
import { PlayerClass } from '../player';

export function onDestroy(this: PlayerClass, cb: () => void): void {
    // @ts-ignore
    BitmovinOptusPlayer.on(OSPlayerEvent.DESTROY, (errorObj) => {
        log('ON DESTROY');
        cb();
    });
}
