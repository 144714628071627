import { PlayerClass } from '../player';

export function readyForInit(this: PlayerClass) {
    return new Promise<void>((resolve, reject) => {
        if (this.playerState === 'DESTROYED') {
            this.playerState = 'READY_FOR_INIT';
            resolve();
        } else if (this.playerState === 'DESTROYING') {
            this.onDestroy(() => {
                resolve();
            });
        } else {
            resolve();
        }
    });
}
