import Braze from '..';
import { useEffect, useState } from 'react';

const useBrazeProvider = () => {
    const [ccCardIds, setCCCardIds] = useState<string[]>(Braze.getContentCardIds());
    useEffect(() => {
        Braze.on('onContentCard', (ids) => {
            setCCCardIds(Array.from(ids));
        });
    }, []);
    return ccCardIds;
};

export default useBrazeProvider;
