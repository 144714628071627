import { useStateValue } from 'src/state';

const useAppReady = () => {
    const { state } = useStateValue();

    const {
        appData: { ready, isDisasterMode },
    } = state;

    return { ready, isDisasterMode };
};

export default useAppReady;
