import { useSetFocus } from 'lal-lrud';
import { useCallback, useEffect, useRef } from 'react';
import { useStateValue } from 'src/state';
import { showShimmer } from 'src/state/stores/dashboard/dashboard.actions';
import { useFocusCache } from './focusCache.controller';

export const useShimmer = () => {
    const {
        dispatch,
        state: {
            dashboardData: { showShimmer: shimmerState },
        },
    } = useStateValue();
    const shimmerRef = useRef(shimmerState);
    useEffect(() => {
        shimmerRef.current = shimmerState;
    }, [shimmerState]);

    const { getFocusCache } = useFocusCache();
    const setFocus = useSetFocus();
    const setShowShimmer = useCallback(
        (value: boolean, callback?: () => void, timer: number = 300) => {
            if (shimmerRef.current !== value) {
                dispatch(showShimmer(value));
            }
            const focusId = getFocusCache();
            if (value === false && focusId) {
                setFocus(focusId);
            }
            if (callback) {
                setTimeout(() => {
                    callback();
                }, timer);
            }
        },
        [getFocusCache, setFocus, dispatch]
    );

    return { showShimmer: shimmerState, setShowShimmer };
};
