import { memo } from 'react';
import styles from './team.module.scss';

interface TeamProps {
    logoUrl: string;
    className?: string;
}

const Team = (props: TeamProps) => {
    const { logoUrl, className } = props;
    const bg = {
        backgroundImage: `url(${logoUrl})`,
    };
    const teamClasses = [styles.team, className];
    return <div style={bg} className={teamClasses.join(' ')} />;
};

export default memo(Team);
