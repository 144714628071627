import { BitmovinOptusPlayer } from '..';
import { PlayerClass } from '../player';

export function seek(this: PlayerClass, seekTime: number) {
    try {
        BitmovinOptusPlayer.seek(seekTime);
    } catch (error) {
        this.setError('0007');
    }
}
