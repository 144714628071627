import { PlayerClass } from '../player';
import { BitmovinOptusPlayer } from '..';
import { Segment } from '../segment';
import { Firebolt } from '../../firebolt';
import { DateTime } from 'luxon';
import { CONFIG } from 'src/config/config';
import { log } from 'src/utilities/console';

function handleSegment(this: PlayerClass) {
    if (this.zombieKicked) {
        Segment.trackZombieKickSessionFromClient();
    } else if (this.isLive()) {
        // this is to make sure to log when user has changed the channel
        Segment.handleAppKill({
            type: 'user',
            message: 'user changed linear channel',
        });
    } else {
        Segment.handleAppKill({
            type: 'user',
            message: 'user closed the player',
        });
    }
}

export function unload(this: PlayerClass, trackEvents: boolean = true) {
    // this.eventCbs = {};
    this.setError(null);
    if (this.player) {
        if (trackEvents) {
            const isPlaybackComplete =
                this.getDuration() && this.getCurrentTime() === this.getDuration();

            if (!isPlaybackComplete) {
                // No Events needed on playback Complete, As it is Hanlded by the FE lib
                handleSegment.call(this);
            }

            // Do Firebolt Event sendWatched
            if (CONFIG.isFireboltEnabled) {
                let viewProgress = this.isLive()
                    ? this.player?.getCurrentTime()
                    : (this.getCurrentTime() / this.getDuration()).toFixed(2);
                viewProgress = !isNaN(viewProgress) ? viewProgress : 0;
                const watchedOn = DateTime.now().toISO() || undefined;

                log(
                    '### Firebolt >> sendWatched()',
                    this.assetId!,
                    viewProgress,
                    isPlaybackComplete,
                    watchedOn
                );
                Firebolt.sendWatched(this.assetId!, viewProgress, isPlaybackComplete, watchedOn);
            }
        }
        this.playerState = 'UNLOADING';
        this.assetUrl = null;

        return new Promise((resolve, reject) => {
            const onUnload = () => {
                this.playerState = 'UNLOADED';
                this.zombieKicked = false;
                this.videoElm = null;
                resolve(null);
            };

            try {
                BitmovinOptusPlayer.unload().then(onUnload);
            } catch (error) {
                onUnload();
            }
        });
    }
    this.assetUrl = null;
    this.assetId = null;

    return Promise.resolve();
}
