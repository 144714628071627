export const ACTION_FETCH_HOME_LAYOUT = 'ACTION_FETCH_HOME_LAYOUT';
export const ACTION_FETCH_HOME_PANEL = 'ACTION_FETCH_HOME_PANEL';
export const ACTION_FETCH_HOME_PANELS = 'ACTION_FETCH_HOME_PANELS';
export const ACTION_FETCH_CONTENTCARDS_PLATFORM = 'ACTION_FETCH_CONTENTCSARD_PLATFORM';
export const ACTION_FETCH_CONTENTCARDS_USER = 'ACTION_FETCH_CONTENTCARDS_USER';
export const ACTION_FETCH_CONTENTCARDS_BRAZE_ALL = 'ACTION_FETCH_CONTENTCARDS_BRAZE_ALL';
export const ACTION_HOME_SET_PANELS_TO_RENDER = 'ACTION_HOME_SET_PANELS_TO_RENDER';
// export const ACTION_SET_ALL_PANELS_LOADED_STATUS = 'ACTION_SET_ALL_PANELS_LOADED_STATUS';
export const ACTION_HOME_SHOW_HIDE_SHIMMER = 'ACTION_HOME_SHOW_HIDE_SHIMMER';
export const ACTION_HOME_SHOW_HIDE_OVERLAY = 'ACTION_HOME_SHOW_HIDE_OVERLAY';
export const ACTION_RESET_HOME_LAYOUT = 'ACTION_RESET_HOME_LAYOUT';

export const ACTION_FETCH_ASSET = 'ACTION_FETCH_ASSET';
export const ACTION_FETCH_LIVE_ASSETS = 'ACTION_FETCH_LIVE_ASSETS';
export const ACTION_FETCH_USER_FAVOURITES = 'ACTION_FETCH_USER_FAVOURITES';
export const ACTION_FETCH_ASSET_METADATA = 'ACTION_FETCH_ASSET_METADATA';
export const ACTION_FETCH_ASSET_PLAYBACK = 'ACTION_FETCH_ASSET_PLAYBACK';
export const ACTION_FETCH_LIVE_ASSET_PLAYBACK = 'ACTION_FETCH_LIVE_ASSET_PLAYBACK';
export const ACTION_CLEAR_ASSET_ERROR = 'ACTION_CLEAR_ASSET_ERROR';
export const ACTION_POST_ASSET_PLAYBACK_SESSION = 'ACTION_POST_ASSET_PLAYBACK_SESSION';
export const ACTION_FETCH_ASSET_PB_SESSION_LATEST = 'ACTION_FETCH_ASSET_PB_SESSION_LATEST';
export const ACTION_FETCH_LIVE_ASSET_EPG = 'ACTION_FETCH_LIVE_ASSET_EPG';
export const ACTION_FOCUS_ASSET = 'ACTION_FOCUS_ASSET';

export const ACTION_FETCH_RELATED_ASSETS = 'ACTION_FETCH_RELATED_ASSETS';
export const ACTION_SET_DR_CHANNEL = 'ACTION_SET_DR_CHANNEL';
