import { useEffect } from 'react';
import { Analytics } from 'src/lib/Analytics';

import { useStateValue } from 'src/state';
import { logoutUser } from 'src/state/stores/user/user.actions';
import useUser from 'src/controllers/auth/user.controller';

import Logo from 'src/components/Common/Logo';
import Spinner from 'src/components/Common/Spinner';

import { clearLastPlayedLiveAsset } from 'src/utilities/live';
import { clearSession } from 'src/utilities/session';

import styles from './logout.module.scss';

const logout = () => {
    clearSession();
    clearLastPlayedLiveAsset();
    Analytics.logUserLogOut();
};

const Logout = () => {
    const { auth } = useUser();
    const { userId } = auth || {};
    const { dispatch } = useStateValue();

    useEffect(() => {
        if (userId) {
            dispatch(logoutUser(userId))
                .then((response) => {
                    Analytics.logLogoutSuccess(response.http);
                })
                .catch((d) => {
                    // Do nothing
                    Analytics.logLogoutFailed(d);
                })
                .finally(() => {
                    logout();
                    window.location.href = '/';
                });
        } else {
            logout();
            window.location.href = '/';
        }
    }, [dispatch, userId]);

    return (
        <div className={styles.splash}>
            <Logo></Logo>
            <Spinner></Spinner>
        </div>
    );
};

export default Logout;
