import { FocusNode } from 'lal-lrud';
import React from 'react';
import useSidebar from 'src/controllers/sidebar.controller';
import SidebarItem from './SidebarItem';
import styles from './sidebar.module.scss';
import { useLocation, useMatch } from 'react-router-dom';
import RouteTags from 'src/routes/routeTags';
import { Analytics } from 'src/lib/Analytics';

const Sidebar = () => {
    const { sidebar } = useSidebar();
    const { items = [] } = sidebar || {};
    const { pathname } = useLocation();
    const sideBarClasses = ['nav-menu', styles.sidebar];
    const defFocusedItemIndex = items.findIndex((i) => pathname.includes(i.href || ''));
    if (useMatch(RouteTags.deviceManagement)) {
        Analytics.logDeviceManagementPageShown();
    }
    return (
        <FocusNode
            focusedClass={styles.focused}
            className={sideBarClasses.join(' ')}
            orientation="vertical"
            defaultFocusChild={defFocusedItemIndex}
        >
            {items.map((s, i) => (
                <SidebarItem
                    key={s.title}
                    item={s}
                    selected={i === defFocusedItemIndex}
                ></SidebarItem>
            ))}
        </FocusNode>
    );
};

export default React.memo(Sidebar);
