interface liveAssetsType {
    [index: string]: {
        id: string;
        logo: string;
        logo_controls: string;
        title: string;
    };
}
const liveAssets: liveAssetsType = {
    v42463: {
        id: 'v42463',
        logo: '/images/logo/channels/os1_logo_tv.png',
        logo_controls: '/images/logo/channels/os1_tv_player_controls.png',
        title: 'Optus Sports 01',
    },
    os6772: {
        id: 'os6772',
        // id: 'os22204', // (PP) OS90 PLAIN CMAF Disabled
        // id: 'os20279', // (PP) OS88 CMAF SSAI
        logo: '/images/logo/channels/os2_logo_tv.png',
        logo_controls: '/images/logo/channels/os2_tv_player_controls.png',
        title: 'Optus Sports 02',
    },
    os45991: {
        id: 'os45991',
        logo: '/images/logo/channels/os12_logo_tv_v2.png',
        logo_controls: '/images/logo/channels/os12_tv_player_controls_v2.png',
        title: 'LaLiga Channel',
    },
    os45990: {
        id: 'os45990',
        logo: '/images/logo/channels/os11_logo_tv_v2.png',
        logo_controls: '/images/logo/channels/os11_tv_player_controls_v2.png',
        title: 'Premier League Channel',
    },
};

export default liveAssets;
