import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usePlaybackState from './playerPlaybackState.controller';

const usePlaybackComplete = () => {
    const navigate = useNavigate();

    const { finished } = usePlaybackState();
    useEffect(() => {
        if (finished) {
            setTimeout(() => {
                navigate(-1);
            }, 200);
        }
    }, [finished, navigate]);
};

export default usePlaybackComplete;
