import { competitionsConfig } from 'src/config/competitionConfig';

export const getExploreTitle = (id: string) => {
    const cId = Object.keys(competitionsConfig).find((i) => {
        const c = competitionsConfig[i];
        return c.deepLinkNames.indexOf(id) !== -1;
    });
    const title = cId ? competitionsConfig[cId].title : null;
    return title;
};
