import React, { useCallback } from 'react';
import { ContentCard } from 'src/state/stores/dashboard/dashboard.d';
import styles from './heroCardActions.module.scss';
import HeroButton from '../HeroButton';
import useContentCardActions from 'src/controllers/dashboard/contentCardActions.controller';
import { FocusNode } from 'lal-lrud';

interface PropType {
    contentCard: ContentCard;
    focused: boolean;
    assetId: string;
}

function HeroContentCardActions(props: PropType) {
    const { focused, assetId, contentCard } = props;
    const { id, primaryButtonLabel, secondaryButtonLabel } = contentCard;
    const {
        showPrimaryButton,
        showSecondaryButton,
        onClickPrimary,
        onClickSecondary,
        onClickCard,
    } = useContentCardActions(assetId, contentCard, 0);

    const onClick = useCallback(() => {
        if (focused) {
            onClickCard();
        }
    }, [focused, onClickCard]);

    return (
        <FocusNode className={styles.actions} onSelected={onClick}>
            {showPrimaryButton ? (
                <HeroButton
                    focused={focused}
                    assetId={id}
                    focusId={`btn-${assetId}-first`}
                    onClick={onClickPrimary}
                >
                    {primaryButtonLabel}
                </HeroButton>
            ) : null}
            {showSecondaryButton ? (
                <HeroButton
                    focused={focused}
                    assetId={id}
                    focusId={`btn-${assetId}-second`}
                    onClick={onClickSecondary}
                >
                    {secondaryButtonLabel}
                </HeroButton>
            ) : null}
        </FocusNode>
    );
}

export default React.memo(HeroContentCardActions);
