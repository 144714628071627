import { memo } from 'react';
import { formatDuration } from 'src/utilities/time';
import styles from './scrubber.module.scss';

interface TimeBoxProps {
    time: number;
    onClick: () => void;
    tail?: boolean;
    isLive?: boolean;
}

const TimeBox = (props: TimeBoxProps) => {
    const { time, tail, isLive, onClick } = props;
    const classes = [styles.timeBox];
    if (tail) {
        classes.push(styles.tail);
    } else {
        classes.push(styles.head);
    }
    if (time > 3599) {
        classes.push(styles.longDuration);
    }
    if (isLive && !time) {
        return null;
    }
    return (
        <span onClick={onClick} className={classes.join(' ')}>
            {`${isLive ? '-' : ''}${formatDuration(Math.abs(time))}`}
        </span>
    );
};

export default memo(TimeBox);
