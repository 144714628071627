import { useCallback, useEffect, useRef } from 'react';
import { CONFIG } from 'src/config/config';
import { useStateValue } from 'src/state';
import { postRefreshToken } from 'src/state/stores/user/user.actions';
// import { ApiEventsLogger } from 'src/utilities/analytics/apiEventLoggerUtils';
import { log } from 'src/utilities/console';
import { getRefreshToken } from 'src/utilities/session';
import useAppReady from '../app/app.ready.controller';
import useUser from './user.controller';

const useTokenRefresh = () => {
    // Controller which will run a timer to refresh access token before its expiry time.
    const { dispatch } = useStateValue();
    const { ready, setReady } = useAppReady();
    const { auth, userData } = useUser();

    const getAccessToken = useCallback(
        (refreshToken: string) => dispatch(postRefreshToken(refreshToken)),
        [dispatch]
    );

    // On App Launch
    useEffect(() => {
        const refreshToken = getRefreshToken();
        if (!ready) {
            if (refreshToken && userData === undefined) {
                getAccessToken(refreshToken).catch(() => {
                    setReady();
                });
                // TODO: Shall we clear the token when the token refresh fails?
                // Check for the correct message and clear, do not clear on nw error or other errors.
            } else {
                setReady();
            }
        }
    }, [ready, userData, setReady, getAccessToken]);

    // Refresh Timer Logic
    const timerRef = useRef<ReturnType<typeof setInterval>>();
    useEffect(() => {
        // refresh token use case - as soon as page is refreshed i.e tv-app is loaded, refresh the token...
        // ...use the tokens etc from the response (not from state/userData) and update localStorate and interceptor

        if (auth && auth.ExpiresIn) {
            try {
                const { ExpiresIn } = auth;
                const expiry = ExpiresIn - CONFIG.tokenExpiryThrottle;
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = undefined;
                }
                timerRef.current = setTimeout(() => {
                    const refreshToken = getRefreshToken();
                    if (refreshToken) {
                        getAccessToken(refreshToken).catch((e) => {
                            log('===> handleAuthTokenRefresh error', e);
                            // 400 and !retryable
                            // No need to Clear Session during token refresh as the fail may be temporary
                            // clearSession();
                        });
                    }
                }, expiry * 1000);

                // TODO: Why do we need this? Refreshing token when user.accessgroup is not there!
                // else if (auth && user && !user?.accessGroups) {
                //     await refreshTokenAndSetSession(auth);
                // }
            } catch (e) {
                log('===> handleAuthTokenRefresh error', e);
                // 400 and !retryable
                // No need to Clear Session during token refresh as the fail may be temporary
                // clearSession();
            }
        }
    }, [auth, getAccessToken, dispatch]);
};

export default useTokenRefresh;
