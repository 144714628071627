import React from 'react';
import useSettings from 'src/controllers/settings.controller';
import RightSideSettingsCard from './RightSideSettingsCard';
import styles from './rightContainer.module.scss';
import RightSideActionCard from './RightSideActionCard';

const RightSideSettingsContainer = () => {
    const { rightSideData, rightActionData } = useSettings();
    if (rightSideData) {
        return (
            <div className={styles.body}>
                <RightSideSettingsCard cardData={rightSideData}></RightSideSettingsCard>
            </div>
        );
    } else if (rightActionData) {
        return (
            <div className={styles.body}>
                <RightSideActionCard cardData={rightActionData}></RightSideActionCard>
            </div>
        );
    } else {
        return <></>;
    }
};

export default React.memo(RightSideSettingsContainer);
