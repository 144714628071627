import * as dashboard from './dashboard';
import * as app from './app';
import * as user from './user';

const Apis = {
    dashboard,
    app,
    user,
};

export default Apis;
