import { useEffect, useRef, useState } from 'react';
import usePlayer from './player.controller';

const useAdUI = () => {
    const { Player } = usePlayer();
    const [timeRemaining, setTimeRemaining] = useState<number | undefined>(undefined);
    const startTime = useRef<number | undefined>(undefined);
    const adDuration = useRef<number | undefined>(undefined);
    const pauseStart = useRef<number | undefined>(undefined);
    const timeRemainingInterval = useRef<ReturnType<typeof setInterval> | undefined>();
    const adBreakTriggered = useRef<boolean>(false);

    const clearAdTimes = () => {
        if (timeRemainingInterval.current) {
            clearInterval(timeRemainingInterval.current);
        }
        setTimeRemaining(undefined);
        startTime.current = undefined;
        adDuration.current = undefined;
        pauseStart.current = undefined;
        adBreakTriggered.current = false;
    };

    useEffect(() => {
        const timeRemainingCallback = () => {
            if (!!adDuration.current && !!startTime.current) {
                if (Player.isPaused() || Player.isStalled()) {
                    const pausedRemaining =
                        adDuration.current - (pauseStart.current! - startTime.current) / 1000;
                    setTimeRemaining(pausedRemaining);
                } else {
                    const remaining = adDuration.current - (Date.now() - startTime.current) / 1000;

                    if (remaining > 0) {
                        setTimeRemaining(remaining);
                        if (remaining < 1 && timeRemainingInterval.current) {
                            clearInterval(timeRemainingInterval.current);
                            adBreakTriggered.current = false;
                        }
                    } else {
                        clearAdTimes();
                    }
                }
            }
        };

        const adBreakStarted = (event: any) => {
            // If AdBreakStarted event called, use the entire ad break duration as timer.
            adBreakTriggered.current = true;
            startTime.current = Date.now();
            adDuration.current = event.adBreak?.duration;
        };

        const adStarted = (event: any) => {
            // If AdBreakStarted event didnt get called, use the AdStarted event
            // to get the individual ad duration.
            if (!adBreakTriggered.current) {
                startTime.current = Date.now();
                adDuration.current = event.ad.duration;
            }

            // Call the callback once beforehand else the setInterval timer will start after
            // useEffect completes causing ad timer to show with slight delay
            timeRemainingCallback();
            timeRemainingInterval.current = setInterval(timeRemainingCallback, 1000);
        };

        const adBreakFinished = () => {
            adBreakTriggered.current = false;
            clearAdTimes();
        };

        const play = () => {
            if (!!startTime.current && !!pauseStart.current) {
                startTime.current = startTime.current + Date.now() - pauseStart.current;
                pauseStart.current = undefined;
            }
        };

        const paused = () => {
            pauseStart.current = Date.now();
            // CTV-685 - TODO: check if it works and also check pr as to why
            // clearInterval(timeRemainingInterval.current); is added in here.
        };

        const stallStarted = () => {
            pauseStart.current = Date.now();
        };

        Player.ready().then(() => {
            Player.on('adstarted', adStarted);
            // CTV-926 - Clearing the Timeout is no longer handled in adFinished.
            // @NOTE: Sometimes the AdFinished gets invoked too early from YS thus causing issues.
            // Keeping it here for reference when working on any refactoring.
            // Player.on('adfinished', adFinished);
            Player.on('adbreakstarted', adBreakStarted);
            Player.on('adbreakfinished', adBreakFinished);
            Player.on('play', play);
            Player.on('paused', paused);
            Player.on('stallstarted', stallStarted);
            Player.on('stallended', play);
        });
    }, [Player]);
    return { showAdUI: !!timeRemaining, timeRemaining };
};

export default useAdUI;
