import { useSetFocus } from 'lal-lrud';
import { LRUDEvent } from 'lal-lrud/dist/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CONFIG } from 'src/config/config';
import focusIds from 'src/constants/focusIds';
import { useFirstRender } from 'src/hooks/common';
import { isKeyBackButton } from 'src/utilities/keys';
import useAreYouStillWatching from './areYouStillWatching.controller';
import useBackButton from './keyPress/backButton.controller';
import usePlaybackState from './playerPlaybackState.controller';
import useLiveAsset from './liveAsset.controller';
import useAdUI from './adUI.controller';

const usePlayerUI = (
    isLive: boolean,
    isKicked: boolean,
    isPlayerError: boolean,
    isMiniUI: boolean
) => {
    const [show, setShow] = useState(false);
    const { asset } = useLiveAsset();

    const timer = useRef<ReturnType<typeof setInterval>>();

    const isFirstRender = useFirstRender();
    const { buffering, finished } = usePlaybackState();
    const { showAdUI, timeRemaining } = useAdUI();

    const setFocus = useSetFocus();

    const defFocusId = useMemo(
        () => (isMiniUI ? focusIds.plbSettingsBtn : focusIds.playPauseBtn),
        [isMiniUI]
    );

    const {
        userWatchingState,
        onKeyPress: ayswOnKeyPress,
        ayswAutoExitTime,
    } = useAreYouStillWatching();

    useEffect(() => {
        if (asset) {
            setTimeout(() => {
                setFocus(defFocusId);
                setShow(false);
            });
        }
    }, [asset, defFocusId, setFocus]);

    // TODO: Improve this logic
    const visible =
        show &&
        (show || buffering || isPlayerError || finished) &&
        userWatchingState === 'WATCHING';

    const wakeUI = useCallback(
        (e?: LRUDEvent) => {
            ayswOnKeyPress();
            if (
                userWatchingState === 'WATCHING' &&
                // @ts-ignore - type will be fixed bit later
                !(e && isKeyBackButton(e)) &&
                // @ts-ignore - type will be fixed bit later
                !['back', 'Escape'].includes(e?.key) &&
                !isFirstRender
            ) {
                setShow(true);

                if (!show && !isKicked && !isPlayerError) {
                    if (e) {
                        if (!isLive) {
                            // Focus play button for non-live assets
                            setFocus(defFocusId);
                            e.preventDefault();
                            e.stopPropagation();
                        } else if (isLive && !['down', 'ArrowDown'].includes(e?.key)) {
                            setFocus(defFocusId);
                            // for live assets, open player controls upon hitting any key other than down
                            e.preventDefault();
                            e.stopPropagation();
                        } else if (isLive && ['down', 'ArrowDown'].includes(e?.key)) {
                            // for live assets, upon hitting down, show channels list
                            setFocus(focusIds.miniEPG);
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }
                }
                if (timer.current) {
                    clearTimeout(timer.current);
                }
                if (!isPlayerError) {
                    // Set a timeout to hide the player UI after a specific duration
                    timer.current = setTimeout(() => {
                        setShow(false);
                    }, CONFIG.playerUITimeout);
                }
            }
            return null;
        },
        [
            ayswOnKeyPress,
            defFocusId,
            isFirstRender,
            isKicked,
            isLive,
            isPlayerError,
            setFocus,
            show,
            userWatchingState,
        ]
    );
    // Callback function for the back button controller
    const callBack = useCallback(() => {
        ayswOnKeyPress();
        if (show) {
            // Animate hiding the player UI
            setShow(false);
            if (timer.current) {
                clearTimeout(timer.current);
            }
            return true;
        }
        return false;
    }, [ayswOnKeyPress, show]);
    useBackButton({ callBack, id: 'player Controls', rank: 2 });

    useEffect(() => {
        if (isKicked && !show) {
            // this is executed ONLY when Fullscreen overlay(eg: device limit) exists and if user presses any key.
            // The line of code `setFocus(focusIds.playPauseBtn);` in another use effect tries to focus oon play/pause button which is hidden and it makes user to click twice to focus on any button say -Try again.
            // This useEffect helps user to foucs on overlay buttons upon very first keydown(instead of hitting twice)
            wakeUI();
        }
    }, [isKicked, show, wakeUI]);

    useEffect(() => {
        clearTimeout(timer.current);
        if (!isPlayerError) {
            // Set a timeout to hide the player UI after a specific duration
            timer.current = setTimeout(() => {
                setShow(false);
            }, CONFIG.playerUITimeout);
        }
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [isPlayerError]);

    return {
        visible,
        ayswOnKeyPress,
        ayswAutoExitTime,
        userWatchingState,
        timeRemaining,
        showAdUI,
        wakeUI,
        buffering,
    };
};

export default usePlayerUI;
