import { memo } from 'react';
import Button from 'src/components/Common/Button';
import { useSeekButtonActiveClass } from 'src/hooks/common';
import styles from './fastForward.module.scss';
import { CONFIG } from 'src/config/config';

interface FastForwardProps {
    seek: (isFF: boolean, step?: number) => void;
    hidden: boolean;
}

const FastForward = (props: FastForwardProps) => {
    const { seek, hidden } = props;
    const { activeClass, setActiveClass } = useSeekButtonActiveClass();
    const seekJumpTime = CONFIG.seekJumpTime
    const onClick = () => {
        setActiveClass(styles.buttonActive);
        seek(true, seekJumpTime);
    };
    return (
        <Button
            focusId="btn-ff"
            iconOnly
            iconPre="ff"
            onClick={onClick}
            className={styles.button}
            classNameActive={activeClass}
            hidden={hidden}
            disabled={hidden}   
        >
            {seekJumpTime}
        </Button>
    );
};

export default memo(FastForward);
