import { useCallback } from 'react';
import Button from 'src/components/Common/Button';
import { AssetType, MediaAssetType, WatchModeLiveType } from 'src/state/stores/dashboard/dashboard';
import { AccessGroupType } from 'src/state/stores/user/user';
import { checkifPastOrUpcoming } from 'src/utilities/time';
import styles from './detail.module.scss';
import { shouldDisableStartover } from 'src/utilities/asset';
import focusIds from 'src/constants/focusIds';

interface MatchActionsProps {
    asset: AssetType;
    onPlayClick: (
        id: string,
        watchMode?: WatchModeLiveType,
        assetAccessGroup?: AccessGroupType[]
    ) => void;
}

const MatchActions = (props: MatchActionsProps) => {
    const { asset, onPlayClick } = props;
    const {
        isLive,
        fullAssets,
        megaAssets,
        miniAssets,
        extendedAssets,
        tinyAssets,
        type,
        broadcastStartTime,
        publishStartDate,
        isInTransition,
    } = asset;

    const onActionButtonClick = useCallback(
        (
            id: string,
            watchMode: WatchModeLiveType | undefined,
            assetAccessGroup: AccessGroupType[]
        ) => {
            onPlayClick(id, watchMode, assetAccessGroup);
        },
        [onPlayClick]
    );

    const isUpcoming =
        checkifPastOrUpcoming(broadcastStartTime ? broadcastStartTime : publishStartDate) ===
        'upcoming';

    const showStartoverButton = !shouldDisableStartover(asset);

    const subAssets =
        fullAssets?.length ||
        megaAssets?.length ||
        miniAssets?.length ||
        extendedAssets?.length ||
        tinyAssets?.length;

    const playAsset = useCallback(
        (assets: MediaAssetType[] | undefined, watchMode?: WatchModeLiveType) => {
            const { id, accessGroups } = assets?.length ? assets[0] : asset;
            onActionButtonClick(id, watchMode, accessGroups);
        },
        [asset, onActionButtonClick]
    );

    // The following render logic should be in sync with the logic for `getOnMountFocusIds`
    if (isLive) {
        if (!isUpcoming) {
            return (
                <>
                    <Button
                        onClick={() => playAsset(fullAssets, 'live')}
                        className={styles.actionButton}
                        focusId={`${focusIds.matchActionPrefix.watchlive}${asset.id}`}
                        trackFocus
                    >
                        Watch Live
                    </Button>
                    {showStartoverButton ? (
                        <Button
                            onClick={() => playAsset(fullAssets, 'startover')}
                            className={styles.actionButton}
                            focusId={`${focusIds.matchActionPrefix.startover}${asset.id}`}
                            trackFocus
                        >
                            Start Over
                        </Button>
                    ) : null}
                    {/* NOTE: Hide Show Scores until Feature development commences. */}
                    {/* <Button onClick={showScores} className={styles.actionButton} iconPost={'spoilerOff'}>
                    Show Scores
                </Button> */}
                </>
            );
        }
    } else if (
        fullAssets?.length ||
        megaAssets?.length ||
        miniAssets?.length ||
        extendedAssets?.length ||
        tinyAssets?.length
    ) {
        return (
            <>
                {type === 'game' || fullAssets?.length ? (
                    <Button
                        onClick={() => playAsset(fullAssets)}
                        className={styles.actionButton}
                        iconPre={'replayLength90'}
                        focusId={`${focusIds.matchActionPrefix.game}${asset.id}`}
                        trackFocus
                        largeSvg
                    >
                        Full
                    </Button>
                ) : null}
                {type === 'mega' || megaAssets?.length ? (
                    <Button
                        onClick={() => playAsset(megaAssets)}
                        className={styles.actionButton}
                        iconPre={'replayLength45'}
                        focusId={`${focusIds.matchActionPrefix.mega}${asset.id}`}
                        trackFocus
                        largeSvg
                    >
                        Mega
                    </Button>
                ) : null}
                {type === 'minimatch' || miniAssets?.length ? (
                    <Button
                        onClick={() => playAsset(miniAssets)}
                        className={styles.actionButton}
                        iconPre={'replayLength25'}
                        focusId={`${focusIds.matchActionPrefix.minimatch}${asset.id}`}
                        trackFocus
                        largeSvg
                    >
                        Mini
                    </Button>
                ) : null}
                {type === 'extended' || extendedAssets?.length ? (
                    <Button
                        onClick={() => playAsset(extendedAssets)}
                        className={styles.actionButton}
                        iconPre={'replayLength9'}
                        focusId={`${focusIds.matchActionPrefix.extended}${asset.id}`}
                        trackFocus
                        largeSvg
                    >
                        Short
                    </Button>
                ) : null}
                {type === 'highlights' || tinyAssets?.length ? (
                    <Button
                        onClick={() => playAsset(tinyAssets)}
                        className={styles.actionButton}
                        iconPre={'replayLength3'}
                        focusId={`${focusIds.matchActionPrefix.highlights}${asset.id}`}
                        trackFocus
                        largeSvg
                    >
                        Quick
                    </Button>
                ) : null}
                {/* NOTE: Hide Show Scores until Feature development commences. */}
                {/* <Button onClick={showScores} className={styles.actionButton} iconPost={'spoilerOff'}>
                    Show Scores
                </Button> */}
                {/* {fullAssets?.length ? <Button onClick={() => { }} className={styles.actionButton} iconPre={'replayLength90'} >Highlight</Button> : null} */}
                {/* <Button onClick={() => { }} className={styles.actionButton} iconPre={'replayLength30'} > Mini</Button> */}
                {/* <Button onClick={() => { }} className={styles.actionButton} iconPre={'replayLength10'} > Show Scores</Button> */}
                {/* <Button onClick={() => { }} className={styles.actionButton} iconPre={'replayLength15'} > Show Scores</Button> */}
                {/* <Button onClick={() => { }} className={styles.actionButton} iconPre={'replayLength3'} > Show Scores</Button> */}
            </>
        );
    } else if (isInTransition || subAssets === 0) {
        return (
            <Button
                onClick={() => playAsset(fullAssets, 'startover')}
                className={styles.actionButton}
                iconPre={'replayLength90'}
                focusId={`${focusIds.matchActionPrefix.game}${asset.id}`}
                trackFocus
                largeSvg
            >
                Full
            </Button>
        );
    }
    return null;
};

export default MatchActions;
