import React from 'react';
import Typography from 'src/components/Common/Typography';
import MenuItem from '../MenuItem';
import styles from './menuCategory.module.scss';

interface MenuItems {
    title: string;
    pageId: string;
}

interface MenuCategoryProps {
    title: string;
    menuItems: MenuItems[];
}

const MenuCategory = (props: MenuCategoryProps) => {
    const { title, menuItems } = props;

    return (
        <div className={styles.menuCategory}>
            <Typography className={styles.title}> {title}</Typography>
            {menuItems.map((item, i) => (
                <MenuItem key={item.pageId} title={item.title} pageId={item.pageId}></MenuItem>
            ))}
        </div>
    );
};

export default React.memo(MenuCategory);
