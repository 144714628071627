import { memo } from 'react';
import { CompetitionIds, competitionsConfig } from 'src/config/competitionConfig';
import styles from './competition.module.scss';

type IconProps = {
    competitionId: CompetitionIds;
    className?: string;
};

const CompetitionIcon = (props: IconProps) => {
    const { competitionId, className } = props;
    const iconClasses = [styles.icon, className];
    if (!competitionsConfig[competitionId]) {
        return null;
    }
    const imgSrc = competitionsConfig[competitionId].iconImage;
    return <img className={iconClasses.join(' ')} src={imgSrc} alt="" />;
};

export default memo(CompetitionIcon);
