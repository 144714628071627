import React from 'react';
import Typography from 'src/components/Common/Typography';
import { CONFIG } from 'src/config/config';
import { AssetType, CardLayoutType } from 'src/state/stores/dashboard/dashboard';
import Card from '../Card';
import { getCardSize } from '../Card/cardsizes';
import ShimmerPanel from '../Dashboard/ShimmerPanel';
import styles from './cards.module.scss';

interface CardItemProps {
    assets?: AssetType[];
    cardLayout: CardLayoutType;
    panelId: string;
    focusedCardIndex: number;
    panelTranslateX: number;
    title?: string;
    wrap?: boolean;
    focused: boolean;
    panelIndex: number;
}

const Cards = (props: CardItemProps) => {
    const {
        assets,
        cardLayout,
        panelId,
        title,
        focused,
        focusedCardIndex,
        panelTranslateX,
        panelIndex,
    } = props;
    const cardSize = getCardSize(cardLayout);
    const { height, showPaneltitle } = cardSize || {};

    const trolleyStyle = {
        height,
        transform: `translate3d(${panelTranslateX}px, 0, 0)`,
    };

    let sliceStart = focusedCardIndex - Math.floor(CONFIG.dashboardWindowMaxCard / 2) - 1;
    sliceStart = sliceStart < 0 ? 0 : sliceStart;
    const sliceEnd = sliceStart + CONFIG.dashboardWindowMaxCard;
    const assetsSlice = assets ? assets.slice(sliceStart, sliceEnd) : [];

    if (assetsSlice.length) {
        return (
            <>
                {showPaneltitle && title ? (
                    <Typography className={styles.title}>{title}</Typography>
                ) : null}
                <div className={styles.cards}>
                    <div className={styles.trolley} style={trolleyStyle}>
                        {assetsSlice.map((asset: AssetType, index) => (
                            <Card
                                key={asset.id}
                                asset={asset}
                                cardLayout={cardLayout}
                                panelId={panelId}
                                cardIndex={sliceStart + index}
                                panelIndex={panelIndex}
                                focused={focused && focusedCardIndex === sliceStart + index}
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className={styles.cards}>
            <ShimmerPanel cardLayout={cardLayout}></ShimmerPanel>
        </div>
    );
};

export default React.memo(Cards);
