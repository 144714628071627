import { CallBackFn } from '../player.d';
import { PlayerClass } from '../player';

// Register onError Call backs
export function onError(this: PlayerClass, callback: CallBackFn) {
    this.errorCbs.push(callback);
    return () => {
        this.errorCbs = this.errorCbs.filter((cb) => cb !== callback);
    };
}
