import { PanelType } from 'src/state/stores/dashboard/dashboard';
import { stringToSlug } from 'src/utilities/string';
import styles from './panelViewAll.module.scss';
import usePanel from 'src/controllers/panel.controller';
import ViewAllCards from '../ViewAllCards';
import useOLRUD from 'src/lib/OLRUD/controllers/oLRUD.controller';

export interface PanelViewAllProps {
    panel: PanelType;
}

const PanelViewAll = (props: PanelViewAllProps) => {
    const { panel } = props;
    const { title, bucketKey, layout, id: panelId, assetId } = panel;

    const { assets } = usePanel({
        assetId,
        bucketKey,
        layout,
        panelId,
    });

    const { focusedCardIndex = 0, active } = useOLRUD(0);
    const panelClasses = [styles.panel];
    if (title) {
        panelClasses.push(stringToSlug(title));
    }

    return (
        <div className={panelClasses.join(' ')}>
            <ViewAllCards
                assets={assets}
                cardLayout={layout}
                panelId={panelId}
                title={title}
                focused={active}
                focusedCardIndex={focusedCardIndex}
            />
        </div>
    );
};

export default PanelViewAll;
