import { AdEvent, PlaybackEvent, VideoElmEvent } from '../player.d';
import { PlayerClass } from '../player';
import { isPS4 } from 'src/utilities/ps4';

const OFFs: any[] = [];
export function listenForPlaybackEvents(this: PlayerClass) {
    const adEvents: AdEvent[] = this.isYoSpaceActive
        ? ['adstarted', 'adfinished', 'adbreakstarted', 'adbreakfinished']
        : [];

    // https://cdn.bitmovin.com/player/web/8/docs/enums/core_events.playerevent.html
    const playbackEvents: PlaybackEvent[] = [
        'paused',
        'play',
        'playbackfinished',
        'playing',
        'ready',
        'seeked',
        'seek',
        'stallended',
        'stallstarted',
        'timechanged',
        'timeshifted',
        'timeshift',

        'cueenter',
        'cueexit',

        'audiochanged',
        'subtitleenabled',
    ];

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#events
    const videoElmEvents: VideoElmEvent[] = [
        'loadeddata',
        'ended',
        'pause',
        'playing',
        'seeked',
        'stalled',
        'timeupdate',
        'waiting',
    ];

    const eventHandler = (e: Event) => {
        const handlers = this.eventCbs[e.type] || [];
        handlers.forEach((h: any) => {
            h(e);
        });
    };
    const eventHandlerPS4 = (e: Event) => {
        const handlers = this.eventCbs[e.type] || [];
        handlers.forEach((h: any) => {
            h(e);
        });
    };

    OFFs.forEach((off) => {
        try {
            off();
        } catch (error) {}
    });
    OFFs.length = 0;

    if (isPS4()) {
        const video = document.querySelector('video');
        if (video) {
            videoElmEvents.forEach((e) => {
                video.addEventListener(e, eventHandlerPS4);
                OFFs.push(() => {
                    video.addEventListener(e, eventHandlerPS4);
                });
            });
        }
    }

    [...adEvents, ...playbackEvents].forEach((e) => {
        this.player.on(e, eventHandler);
        OFFs.push(() => {
            this.player.off(e, eventHandler);
        });
    });
}
