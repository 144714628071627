import React from 'react'
import moment from 'moment';
import styles from './durationPill.module.scss'


type DurationPillProps = {
    duration: number
}

const DurationPill = (props: DurationPillProps) => {
    const { duration } = props

    const formatting = duration < 3600 ? "mm:ss" : "HH:mm:ss"

    return (
        <div className={styles.duration}>
            {moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format(formatting)}
        </div>
    )
}

export default React.memo(DurationPill)