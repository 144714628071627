import React from 'react';
import styles from './publishTime.module.scss';
import { calculateDateTimeDisplay } from 'src/utilities/dateTime';

type PublishTimeProps = {
    className?: string;
    publishStartDate: string;
    isMatch: boolean;
};

function PublishTime(props: PublishTimeProps) {
    const { className, publishStartDate, isMatch } = props;
    const livePillClasses = [styles.publishTime];
    if (className) {
        livePillClasses.push(className);
    }

    return <div className={livePillClasses.join(' ')}>{calculateDateTimeDisplay(publishStartDate, publishStartDate, isMatch)}</div>;
}

export default React.memo(PublishTime);
