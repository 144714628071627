import { memo } from 'react';
import useExplore from 'src/controllers/exploreRail.controller';
import useSidebar from 'src/controllers/sidebar.controller';
import bgGenFitness from 'src/assets/images/backgrounds/bgGeneralFitness.png';
import useViewAll from 'src/controllers/viewAll.controller';
import styles from './heroBanner.module.scss';
import { getExploreTitle } from 'src/utilities/explore';

const HeroBanner = () => {
    const { asset } = useExplore();
    const { isViewAll } = useViewAll();
    const { selectedSidebarItem } = useSidebar();
    const isFitnessLayout = selectedSidebarItem?.layoutId === 'fitness';
    let overlay = styles.overlay;

    if (isFitnessLayout) {
        overlay = styles.fitnessOverlay;
    }

    if (asset && !isViewAll) {
        const bgStyle = isFitnessLayout
            ? { backgroundImage: `url(${bgGenFitness})` }
            : { backgroundImage: `url(${asset.backgroundImageUrl})` };

        const tileIcon = {
            backgroundImage: `url(${asset.tileIconUrl})`,
        };

        const title = getExploreTitle(asset.id) || asset.title;

        return (
            <div className={styles.heroBanner} style={bgStyle}>
                <div className={overlay}></div>
                <div className={styles.tileIcon} style={tileIcon}></div>
                <div className={styles.tileTitle}>{title}</div>
            </div>
        );
    } else {
        return null;
    }
};

export default memo(HeroBanner);
