import { useStateValue } from 'src/state';

const useUser = () => {
    const { state } = useStateValue();
    const {
        userData: { user, auth, userData },
    } = state;

    return {
        auth,
        user,
        userData,
    };
};

export default useUser;
