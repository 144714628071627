import { LRUDEvent } from 'lal-lrud/dist/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import useMountedRef from 'src/hooks/mounted';
import useMediaButtons from './mediaButtons.controller';
import usePlayer from './player.controller';
import usePlayerTimeStat from './playerTimeStat.controller';
import { CONFIG } from 'src/config/config';

export const useScrubber = (visible: boolean, onKey: (e?: any) => void) => {
    const { Player } = usePlayer();
    const [longSeekTime, setLongSeekTime] = useState(0);
    const longSeekTimeRef = useRef(0);
    const isLive = Player.isLive();

    const longPressTimer = useRef<any>();
    const isLongPress = useRef<boolean>(false);

    const onDoneSeeking = useCallback(() => {
        isLongPress.current = false;
    }, []);

    const {
        duration = 0,
        currentTime = 0,
        bufferLength = 0,
    } = usePlayerTimeStat({ seekedCb: onDoneSeeking });

    useEffect(() => {
        longSeekTimeRef.current = longSeekTime;
    }, [longSeekTime]);

    const onSelected = useCallback(
        (seekTime = longSeekTimeRef.current) => {
            if (isLongPress.current) {
                Player.seek(seekTime);
            }
        },
        [Player]
    );

    const handleSeek = useCallback(
        (isFF: boolean, step = Math.max(1, Math.abs(duration * CONFIG.scrubSeekPercentage))) => {
            onKey();
            const getNextJump = (cj: number) => {
                let nj = 0;
                if (isLive) {
                    nj = isFF ? cj + step : cj - step;
                    nj = nj < duration ? duration : nj;
                    nj = nj > 0 ? 0 : nj;
                } else {
                    nj = isFF ? cj + step : cj - step;
                    nj = nj <= 0 ? 1 : nj;
                    nj = nj > duration ? duration - 1 : nj;
                }
                return Math.floor(nj);
            };
            if (isLongPress.current) {
                setLongSeekTime((currentTime) => getNextJump(currentTime));
                clearTimeout(longPressTimer.current);
            } else {
                const nextJumpTime = getNextJump(currentTime);
                setLongSeekTime(nextJumpTime);
                isLongPress.current = true;
            }

            clearTimeout(longPressTimer.current);
            longPressTimer.current = setTimeout(() => {
                onSelected();
                longPressTimer.current = null;
            }, 1000);
        },
        [currentTime, duration, isLive, onKey, onSelected]
    );

    const mediaKeyHandlers = {
        onKeyPress: onKey,
        onForwardSeekPress: () => {
            if (visible) {
                handleSeek(true, CONFIG.seekJumpTime);
            }
        },
        onFasterForwardSeekPress: () => {
            if (visible) {
                handleSeek(true, CONFIG.seekJumpTime * 2);
            }
        },
        onRewindSeekPress: () => {
            if (visible) {
                handleSeek(false, CONFIG.seekJumpTime);
            }
        },
        onFasterRewindSeekPress: () => {
            if (visible) {
                handleSeek(false, CONFIG.seekJumpTime * 2);
            }
        },
    };
    useMediaButtons(mediaKeyHandlers);

    const mounted = useMountedRef();

    useEffect(() => {
        setTimeout(() => {
            if (mounted.current) {
                setLongSeekTime(0);
            }
            clearTimeout(longPressTimer.current);
            isLongPress.current = false;
        }, 1000);
    }, [visible, mounted]);

    const onScrubberLRKey = (e: LRUDEvent) => {
        const isFF = e.key === 'right';
        const isRW = e.key === 'left';

        if (!isFF && !isRW) {
            return;
        }
        handleSeek(isFF);
    };

    const onClick = (seekTimePercent: number) => {
        if (isLive) {
            Player.seek(duration * (1 - seekTimePercent));
        } else {
            Player.seek(duration * seekTimePercent);
        }
        // Player.play();
    };
    let longSeekValue = (longSeekTime / duration) * (isLive ? -1 : 1);
    let value = !isLongPress.current ? currentTime / duration : longSeekValue;
    let bufferValue = (currentTime + bufferLength) / duration;

    if (duration < 0) {
        // Live
        value = !isLongPress.current ? (duration - currentTime) / duration : 1 + longSeekValue;
        bufferValue = (duration - currentTime - bufferLength) / duration;
    }
    bufferValue = bufferValue > 1 ? 1 : bufferValue;

    const width = value;
    const bufferWidth = bufferValue;
    const scrubberDuration = duration || 0;
    let scrubberCurrentTime = currentTime;

    if (isLongPress.current) {
        scrubberCurrentTime = width * duration;
        if (isLive) {
            scrubberCurrentTime = scrubberCurrentTime - scrubberDuration;
        }
    }

    return {
        width,
        bufferWidth,
        duration: scrubberDuration,
        currentTime: scrubberCurrentTime,
        onScrubberLRKey,
        onClick,
        seek: handleSeek,
    };
};
