import React from 'react';
import styles from './logo.module.scss';

type LogoProps = {
    className?: string;
};

const Logo = (props: LogoProps) => {
    const { className } = props;
    const classes = [styles.logo, className];
    return <div className={classes.join(' ')}></div>;
};

export default React.memo(Logo);
