import { Config, StringSet, URLSet } from '../config';
import { env } from './config.all';

const segmentPS5URLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/yTHRGIffzA9DdRc30nwDiZT33wHZkXgq/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/v2ZAyEioxDVj8uT8QomHTf8TVkFsW8J3/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/v2ZAyEioxDVj8uT8QomHTf8TVkFsW8J3/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/v2ZAyEioxDVj8uT8QomHTf8TVkFsW8J3/analytics.min.js',
};

const drModePS5URLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-ps4.json',
    PP: 'https://dr.optussport.info/assets-ps4.json',
    STAGING: 'https://dr.optussport.info/assets-ps4.json',
    LOCAL: 'https://dr.optussport.info/assets-ps4.json',
};

const brazePS5Keys: StringSet = {
    PROD: '9fdb8954-fa74-4163-ab9d-094dfe78c61c',
    PP: '806da137-0a10-4b34-aeb8-e3aeb98ff91d',
    STAGING: '806da137-0a10-4b34-aeb8-e3aeb98ff91d',
    LOCAL: '806da137-0a10-4b34-aeb8-e3aeb98ff91d',
};

const config_ps5: Partial<Config> = {
    platform: 'PlayStation',
    segmentLibUrl: segmentPS5URLs[env],
    drModeCheckURL: drModePS5URLs[env],

    playbackDrmChoice: 'playready',
    drModeCheckActive: true,
    playbackSettingsSpeedActive: false,
    playbackSupportsCmafLive: false,
    playbackSupportsCmafVod: false,
    YoSpaceActiveOnLive: true,
    YoSpaceActiveOnVod: true,
    adUIEnabledOnLive: false,
    adUIEnabledOnVod: true,

    BrazeActive: true, // All users
    BrazeApiKey: brazePS5Keys[env],

    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['avc', 'hevc', 'av1'],
        },
        adaptation: {
            resolution: {
                maxSelectableVideoHeight: 720,
            },
            exclude: true,
        },
        tweaks: {
            playstation_5: {
                playmode: '2K',
                esvm: false,
            },
        },
    },
};

export default config_ps5;
