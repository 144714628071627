import useSubtitle from 'src/controllers/player.subtitle.controller';
import styles from './subtitle.module.scss';
import { memo } from 'react';

interface SubtitleProps {
    top: boolean;
}

const Subtitle = (props: SubtitleProps) => {
    const { top } = props;
    const { text } = useSubtitle();
    const classes = [styles.text];
    if (top) {
        classes.push(styles.top);
    }
    return <div className={classes.join(' ')}>{text}</div>;
};

export default memo(Subtitle);
