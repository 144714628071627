import { UAParser } from 'ua-parser-js';
import { log } from 'src/utilities/console';

export const foxtelDeviceModels = {
    IQ3: 'iQ3',
    IQ4: 'iQ4',
    IQ5: 'iQ5',
    HUBBL_PUCK: 'Hubbl',
    HUBBL_TV: 'Hubbl Glass',
} as const;

const foxtelOSs = [
    [/(WPE ARRIS_Foxtel_STB_\S+[\s]*)\/([\w.]+)/i],
    [UAParser.OS.NAME, UAParser.OS.VERSION],
    [/(Sky_\S+[\s]*)\/([\w.]+)/i],
    [UAParser.OS.NAME, UAParser.OS.VERSION],
];

const foxtelDevices = [
    //Mozilla/5.0 (ARRIS_Foxtel_STB_DGX7000NF; Linux mipsel) AppleWebKit/605.1.15 (KHTML, like Gecko) WPE ARRIS_Foxtel_STB_DGX7000NF /1.21.0.11 (Foxtel,DGX7000NF ,wireless)
    [/(WPE ARRIS_Foxtel_STB_DGX7000NF[\s]?)\/([\w.]+)/i],
    [
        [UAParser.DEVICE.MODEL, foxtelDeviceModels.IQ3],
        [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV],
        [UAParser.DEVICE.VENDOR, 'Foxtel'],
    ],

    //Mozilla/5.0 (ARRIS_Foxtel_STB_MX6505NF; Linux armv7l) AppleWebKit/605.1.15 (KHTML, like Gecko) WPE ARRIS_Foxtel_STB_MX6505NF/1.21.1.10 (Foxtel,MX6505NF,wireless)
    [/(WPE ARRIS_Foxtel_STB_MX6505NF)\/([\w.]+)/i],
    [
        [UAParser.DEVICE.MODEL, foxtelDeviceModels.IQ4],
        [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV],
        [UAParser.DEVICE.VENDOR, 'Foxtel'],
    ],

    //Mozilla/5.0 (ARRIS_Foxtel_STB_QH5515ZF; Linux armv7l) AppleWebKit/605.1.15 (KHTML, like Gecko) WPE ARRIS_Foxtel_STB_QH5515ZF/1.21.2.10 (Foxtel,QH5515ZF,wireless)
    [/(WPE ARRIS_Foxtel_STB_QH5515ZF)\/([\w.]+)/i],
    [
        [UAParser.DEVICE.MODEL, foxtelDeviceModels.IQ5],
        [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV],
        [UAParser.DEVICE.VENDOR, 'Foxtel'],
    ],

    //Mozilla/5.0 (X11; Linux armv7l) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0 Safari/605.1.15 WPE Sky_OTT_RTD1319_2020/1.0.0 (Sky, XiOneAU, Wired)
    [/(Sky_OTT_RTD1319_2020)\/([\w.]+)/i],
    [
        [UAParser.DEVICE.MODEL, foxtelDeviceModels.HUBBL_PUCK],
        [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV],
        [UAParser.DEVICE.VENDOR, 'Foxtel'],
    ],

    //Mozilla/5.0 (X11; Linux armv7l) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 Safari/605.1.15 WPE/1.0 Sky_TV_T962X3_2020/1.0.0 (Sky, LlamaAU, Wired)
    [/(Sky_TV_T962X3_2020)\/([\w.]+)/i],
    [
        [UAParser.DEVICE.MODEL, foxtelDeviceModels.HUBBL_TV],
        [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV],
        [UAParser.DEVICE.VENDOR, 'Foxtel'],
    ],
];

const deviceInfo = UAParser(navigator.userAgent, { os: foxtelOSs, device: foxtelDevices });
export const isFoxtel = () => {
    return deviceInfo.device.vendor === 'Foxtel';
};
export const isHubbl = () => {
    return deviceInfo.device.model?.startsWith('Hubbl') || false;
};

export const getFoxtelModel = () => {
    return deviceInfo.device.model || foxtelDeviceModels.IQ4;
};

// ***
// * Hubbl Specific Methods.
// ***

//@ts-ignore
const address = document.ethan_as_ip_address || undefined; // || 'stb'
//@ts-ignore
const port = document.ethan_as_port || 9005;
const asAddress = 'http://'.concat(address, ':').concat(port, '/as/');
// @NOTE (FOR DEV PURPOSES) : Get System Information (Firmware, Model, etc)
const getHubblSystemInfo = (method: string) => {
    let fetchMethod = fetch(asAddress + method);
    let modelNumber;
    fetchMethod
        .then((response) => {
            if (!response.ok) {
                log('[Hubbl] Device Details Response - FAILED');
                throw new Error('[Hubbl] Device Details does not exist');
            }
            const jsonRes = response.json();
            log('#### Hubbl Device: ', jsonRes);
            return jsonRes;
        })
        .then((data) => {
            log('[Hubbl] Device Details Response - DATA', data.modelNumber);
            modelNumber = data.modelNumber;
            return modelNumber;
        })
        .catch((error) => {
            log('#### Hubbl System Details Error:', error);
        });

    return modelNumber;
};

// const getHubblModel = () => {
//     const hubblDeviceModel = getHubblSystemInfo('system/information');
//     console.log('##### hubblDeviceModel', hubblDeviceModel);
//     return '27'; // Minimum Firmware Version since release.
// };

export const getFoxtelOSVersion = () => {
    return deviceInfo.os.version;
};
