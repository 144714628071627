import { getRailHeight } from 'src/components/ui/Card/cardsizes';
import {
    CardLayoutType,
    DataSourceType,
    PanelType,
    PanelTypeType,
} from 'src/state/stores/dashboard/dashboard';
import { isLayoutHero } from './dashboard';
import { screenHeight } from './device';
import { CardLayouts, DEFAULT_CARD_LAYOUT } from './card';

export const BROWSE_PANEL_TYPE: PanelTypeType = 'browse';
export const EXPLORE_PANEL_TYPE: PanelTypeType = 'explore';
export const CAROUSEL_PANEL_TYPE: PanelTypeType = 'carousel';

export const CC_BANNER_PANEL_TYPE: PanelTypeType = 'banner';
export const CC_DYNAMIC_PANEL_TYPE: PanelTypeType = 'dynamic_rail';

export const CC_BUCKET_DATASOURCE: DataSourceType = 'BUCKET';
export const CC_FAVOURITE_DATASOURCE: DataSourceType = 'FAVOURITE';

export const RECTANGULAR_CARD_LAYOUT: CardLayoutType = 'rectangular_tile';
export const EXPLORE_CARD_LAYOUT: CardLayoutType = 'explore_tile';

export const getPanelLayout = (panel: PanelType, panelType: string) => {
    return panelType === EXPLORE_PANEL_TYPE || panelType === BROWSE_PANEL_TYPE
        ? RECTANGULAR_CARD_LAYOUT
        : panel.layout;
};

export const isHeroThere = (panels: PanelType[]) => {
    return panels.length ? isLayoutHero(panels[0].layout) : false;
};

export const isBanner = (panel?: PanelType) => {
    return panel && panel.type === CC_BANNER_PANEL_TYPE;
};

/**
 * This function finds the panel tops of a panels in a layout
 * @param panelType this can be browse, explore or the normal carousel panels
 * @param panels an array of panels
 * @returns array of available panels of resppective paneltype in the selected layout
 */
export const getPanelsTops = (panels: PanelType[]) => {
    const offset = isHeroThere(panels) ? 0 : screenHeight / 2;
    const panelTops: number[] = [offset];
    const panelHeights: number[] = [];
    let lastPanelTop = offset;
    panels.forEach((p, i) => {
        const railHeight = getRailHeight(getPanelLayout(p, p.layout), true);
        const panelTop = lastPanelTop + railHeight;
        lastPanelTop = panelTop;
        panelTops.push(lastPanelTop);
        panelHeights.push(railHeight);
    });
    return panelTops;
};

export const getPanelsOfType = (panelTypes: PanelTypeType[], panels: PanelType[]) => {
    const availablePanels = panels.filter((p) => panelTypes.includes(p.type));

    if (panelTypes.includes(EXPLORE_PANEL_TYPE)) {
        // Data is now being taken from the app manager
        // @TODO :layout attribute is missing for the explore panel - need to get this added by cms team
        availablePanels.forEach((panel, i) => {
            availablePanels[i].layout =
                availablePanels[i].layout ||
                (availablePanels[i].type === 'explore' && EXPLORE_CARD_LAYOUT) ||
                availablePanels[i].type ||
                EXPLORE_CARD_LAYOUT;
        });
    }
    if (panelTypes.includes(BROWSE_PANEL_TYPE)) {
        // Data is now being taken from the app manager
        // @TODO :layout attribute is missing for the browse panel - need to get this added by cms team
        availablePanels.forEach((panel, i) => {
            availablePanels[i].layout = availablePanels[i].layout || RECTANGULAR_CARD_LAYOUT;
        });
    }
    return availablePanels;
};

export const cleanPanels = (panels: PanelType[]) => {
    return panels
        .filter((p, i) => !(i && isLayoutHero(p.layout)))
        .map((p) => {
            return {
                ...p,
                layout: CardLayouts.includes(p.layout) ? p.layout : DEFAULT_CARD_LAYOUT,
            };
        });
};
