import { FocusNode } from 'lal-lrud';
import React from 'react';
import { useFocusOnMouseOver } from 'src/hooks/useFocusOnMouseOver';
import styles from './button.module.scss';
import { LRUDEvent } from 'lal-lrud/dist/types';

interface HeroButtonProps {
    assetId: string;
    focusId: string;
    focused: boolean;
    onClick: () => any;
    children: React.ReactChild;
}
const HeroButton = (props: HeroButtonProps) => {
    const { assetId, children, focusId, focused, onClick } = props;
    const onSelect = (e: LRUDEvent) => {
        e.stopPropagation();
        if (onClick) {
            return onClick();
        }
        return undefined;
    };

    const { onMouseOver } = useFocusOnMouseOver(focusId);
    return (
        <FocusNode
            className={styles.button}
            focusedClass={focused ? styles.focused : undefined}
            activeClass={styles.active}
            onClick={onSelect}
            onSelected={focused ? onSelect : undefined}
            focusId={focusId}
            onMouseOver={onMouseOver}
            data-asset-id={assetId}
        >
            {children || null}
        </FocusNode>
    );
};

export default HeroButton;
