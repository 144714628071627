import { useMemo } from 'react';
import { VIEW_ALL_CARD } from 'src/constants/assetTypes';
import { useStateValue } from 'src/state';
import { CardLayoutType } from 'src/state/stores/dashboard/dashboard';
import { getMaxAssetRenderCount, isLayoutHero } from 'src/utilities/dashboard';
import useExplore from './exploreRail.controller';
import useLayout from './layout.controller';
import useViewAll from './viewAll.controller';
import useContentCardAsset from './dashboard/contentCards.asset.controller';
import {
    validateCCBannerAsset,
    validateCCDynamicPanelAsset,
    validateCCFavPanelAsset,
} from 'src/utilities/contentCards';
import { CONFIG } from 'src/config/config';
import useContentCards from './dashboard/contentCards.controller';
import useFavouriteTeam from './favouriteTeam.controller';

type usePanelProps = {
    bucketKey: string;
    panelId?: string;
    maxTileCount?: number;
    layout: CardLayoutType;
    assetId?: string;
};

const usePanel = (props: usePanelProps) => {
    const { bucketKey, panelId, maxTileCount, layout: layoutType, assetId } = props;
    const { state } = useStateValue();
    const { isBrowse } = useExplore();
    const { isViewAll } = useViewAll();
    const { layoutId } = useLayout();
    const {
        dashboardData: { panelData },
    } = state;

    const contentCards = useContentCards();
    const { ccAsset } = useContentCardAsset(assetId);
    const favouriteTeamId = useFavouriteTeam();

    const panel = useMemo(() => {
        if (panelId && panelId in panelData) {
            return panelData[panelId];
        } else if (ccAsset && validateCCDynamicPanelAsset(ccAsset)) {
            return panelData[`${layoutId}_${ccAsset.contentCard?.bucketKey}`];
        } else if (ccAsset && validateCCFavPanelAsset(ccAsset) && favouriteTeamId) {
            return panelData[favouriteTeamId];
        } else {
            return panelData[`${layoutId}_${bucketKey}`];
        }
    }, [bucketKey, layoutId, panelData, panelId, ccAsset, favouriteTeamId]);

    const assets = useMemo(() => {
        if (panel) {
            // TODO: Correct when API Data is fixed
            // @ts-ignore
            let filteredAssets: AssetType[] = panel.asset || panel.assets || [];
            filteredAssets = filteredAssets.filter((a) =>
                a.contentCard
                    ? CONFIG.contentCardCMSActive && contentCards[a.id] && validateCCBannerAsset(a)
                    : true
            );

            const maxRenderCount = getMaxAssetRenderCount(
                layoutType,
                filteredAssets.length,
                isViewAll ? filteredAssets.length : maxTileCount
            );
            const assets = filteredAssets.slice(0, maxRenderCount);

            if (filteredAssets.length > maxRenderCount && !isViewAll && !isLayoutHero(layoutType)) {
                let id = bucketKey;
                if (ccAsset && ccAsset.contentCard) {
                    if (
                        (favouriteTeamId && validateCCFavPanelAsset(ccAsset)) ||
                        validateCCDynamicPanelAsset(ccAsset)
                    ) {
                        id = ccAsset.id;
                    }
                }

                const viewAllAsset = {
                    id,
                    cmsTags: [],
                    type: VIEW_ALL_CARD,
                    match: '',
                    imageKey: '',
                    title: 'View all',
                    description: 'View All',
                    cardLayout: 'view-all',
                };
                // @ts-ignore
                assets.push(viewAllAsset);
            }
            return assets;
        } else if (ccAsset && validateCCBannerAsset(ccAsset)) {
            // CC Banner
            return [ccAsset];
        }
        return [];
    }, [
        panel,
        bucketKey,
        layoutType,
        maxTileCount,
        isViewAll,
        ccAsset,
        contentCards,
        favouriteTeamId,
    ]);
    return { panel, ccAsset, assets, isBrowse };
};

export default usePanel;
