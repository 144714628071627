import { CONFIG } from 'src/config/config';
import { PlayerClass } from '../player';

export function rw(this: PlayerClass) {
    if (this.player?.isLive()) {
        const maxTimeShift = this.player?.getMaxTimeShift();
        let timeShift = this.getCurrentTime() - CONFIG.seekJumpTime;
        if (timeShift > maxTimeShift) {
            this.seek(timeShift);
        } else {
            this.seek(maxTimeShift);
        }
    } else {
        const seekTime = this.getCurrentTime() - CONFIG.seekJumpTime;
        if (this.videoElm) {
            if (seekTime <= 0) {
                this.seek(0.5);
            } else {
                this.seek(seekTime);
            }
        }
    }
}
