import React from 'react';
import Typography from 'src/components/Common/Typography';
import styles from './rightSideCard.module.scss';
import MenuItem from '../../MenuItem';
import { Trans } from 'react-i18next';
interface ActionButton {
    title: string;
    buttonId: string;
}
interface RightSideData {
    primaryHeading: string;
    primaryDesc: string;
    secondaryDesc?: string;
    buttons: ActionButton[];
    qrCode?: string;
}
interface RightSideCard {
    cardData: RightSideData;
}
const RightSideActionCard = (props: RightSideCard) => {
    const { cardData } = props;
    const qrCode = {
        backgroundImage: `url(${cardData.qrCode})`,
    };
    return (
        <>
            <Typography className={styles.primaryHeading}>{cardData.primaryHeading}</Typography>
            <Typography className={styles.primaryDesc}><Trans>{cardData.primaryDesc}</Trans></Typography>
            {cardData.qrCode && <div className={styles.qrCode} style={qrCode}/>}
            <Typography className={styles.primaryDesc}>{cardData.secondaryDesc}</Typography>
            {cardData &&
                cardData.buttons.map((item, i) => (
                    <MenuItem key={item.buttonId} title={item.title} pageId={item.buttonId} isRight={true}></MenuItem>
                ))}
        </>
    );
};

export default React.memo(RightSideActionCard);
