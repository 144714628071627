import { v4 as uuidv4 } from 'uuid';
export const getUID = () => {
    return uuidv4().toUpperCase();
};

export function sleep(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
