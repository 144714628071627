import Storage from './storage';

export const setRefreshToken = (refreshToken: string) => {
    Storage.setItem('rt', refreshToken);
};

export const getRefreshToken = (): string | undefined => {
    // TODO: Remove this migration logic when done. Rel?: 2.7.0
    const session = Storage.getItem('s');
    if (session && session.RefreshToken) {
        setRefreshToken(session.RefreshToken);
        Storage.removeItem('s');
    }

    return Storage.getItem('rt');
};

// export const setSession = (s: SessionType) => {
//     console.log('SET SESSION');
//     Storage.setItem('s', s);
// };

// export const getSession = (): string | undefined => {
//     return Storage.getItem('s');
// };

export const clearSession = () => {
    // Storage.removeItem('s');
    Storage.removeItem('rt');
};
