/* eslint-disable no-restricted-globals */
import { CONFIG } from 'src/config/config';
const debug: boolean = process.env.REACT_APP_DEBUG?.toLocaleLowerCase() === 'true';
type MessageColor = 'red' | 'yellow' | 'blue' | 'green' | undefined;

const logsMaxEntries = 65;
const logsPurgeAmount = 10;
// eslint-disable-next-line no-control-regex
const reControlSequence = /\[\d+m/g;
const que: [string | object, MessageColor][] = [];
// const oldOnUnhandledRejection = window.onunhandledrejection;

let wrapper: HTMLDivElement;
let inner: HTMLDivElement;
let loaded = false;
let ready = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(...msgs: any[]): void {
    // eslint-disable-next-line no-console
    if (debug || (CONFIG && CONFIG.debug)) {
        console.debug(...msgs);
    }
}

export const errorLog = (...message: any) => {
    console.error(...message);
};

function purgeOldLogs(): void {
    if (inner.childElementCount > logsMaxEntries) {
        const newInner = inner.cloneNode(true) as HTMLDivElement;

        for (let index = 0; index < logsPurgeAmount; index += 1) {
            if (newInner.firstChild) {
                newInner.firstChild.remove();
            }
        }

        inner.parentNode!.replaceChild(newInner, inner);
        inner = newInner;
        inner.lastElementChild!.scrollIntoView();
    }
}

function printLog(msg: string | object | any, color?: MessageColor): void {
    if (!ready) {
        que.push([msg, color]);
        return;
    }
    msg = typeof msg === 'object' ? JSON.stringify(msg) : msg;
    // Remove console control sequences (used to add colour etc.) because they
    // show up as tofu (missing character; �)
    msg = (msg || '').replace(reControlSequence, '');

    const el = document.createElement('div');
    if (color) el.className = `osc-${color}`;
    el.innerText = msg;
    inner.appendChild(el);
    el.scrollIntoView();
    purgeOldLogs();
}

// window.onunhandledrejection = function (event) {
//     let { reason } = event;
//     // Will be an object if `reason` is an instance of `Error`
//     if (typeof reason === 'object') {
//         reason = `${event.reason.message}; ${event.reason.filename}; ${event.reason.lineNumber}:${event.reason.columnNumber}`;
//     }
//     printLog(`onunhandledrejection: $reason}; ${event}`, 'red');
//     if (oldOnUnhandledRejection) {
//         oldOnUnhandledRejection.call(window, event);
//     }
// };

function fnDecorator(fn: Function, fnName: string): () => void {
    // Use `function` keyword for access to `arguments`
    return function () {
        // prettier-ignore
        // eslint-disable-next-line no-nested-ternary
        const color = fnName === 'error' ? 'red' : fnName === 'warn' ? 'yellow' : fnName === 'debug' ? 'blue' : fnName === 'info' ? 'green' : undefined;
        // Commenting this out because of a circular json error.
        const args = JSON.stringify(Array.prototype.slice.call(arguments, 0));
        printLog(`${fnName}: ${args}`, color);
        Function.prototype.apply.call(fn, console, arguments);
    };
}

function fnConsoleInit() {
    const globalObject =
        typeof globalThis !== 'undefined'
            ? globalThis
            : typeof self !== 'undefined'
            ? self
            : typeof window !== 'undefined'
            ? window
            : typeof global !== 'undefined'
            ? global
            : {};

    if ('console' in globalObject) {
        const fnNames = [
            'assert',
            'debug',
            'dir',
            'error',
            'info',
            'log',
            'trace',
            'warn',
        ] as const;

        for (let index = 0; index < fnNames.length; index += 1) {
            const fnName: (typeof fnNames)[number] = fnNames[index];

            if (fnName in console) {
                const fn = console[fnName];
                console[fnName] = fnDecorator(fn, fnName);
            }
        }
    }
}

export const onScreenConsole = (panel: HTMLDivElement): void => {
    if (typeof window !== 'undefined') {
        wrapper = document.createElement('div');
        inner = document.createElement('div');
        wrapper.id = 'osc';
        inner.id = 'osc-inner';

        wrapper.appendChild(inner);

        const load = (): void => {
            ready = true;
            panel.appendChild(wrapper);
            for (let index = 0; index < que.length; index += 1) {
                const [msg, color] = que[index];
                printLog(msg, color);
            }
        };

        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', load);
        } else {
            load();
        }
    }
};

/**
 * Initialise debug mode.
 */
export function debugInit(): void {
    if (loaded) return;
    loaded = true;

    fnConsoleInit();

    const panel = document.createElement('div');
    const styles = document.createElement('style');
    panel.id = 'debug-panel';

    let css = `
      #debug-panel {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 999999;
        line-height: 0;
      }

      #osc {
        position: fixed;
        top: 0;
        left: 0;
        padding: 20px 10px;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        height: 40vh;
        color: #eee;
        font-family: 'Roboto Mono', monospace;
        font-size: 1.852vh;
        line-height: 1.2;
        text-align: initial;
        background: #000;
        opacity: 0.85;
      }

      #osc.short { height: 28vh; }
      #osc.long { height: 60vh; }
      #osc.full { height: 100vh; }
      #osc.hide { display: none; }

      #osc-inner {
        height: 100%;
        overflow-y: scroll;
        white-space: pre-wrap;
      }

      #osc-input,
      #osc-inner > div {
        padding: 2px 8px;
        word-break: break-all;
      }

      #osc-inner > div:nth-child(2n + 1) {
        background: #111;
      }

      .osc-blue { color: #07f; }
      .osc-red { color: #f05; }
      .osc-yellow { color: #fc0; }
      .osc-green { color: #00bf00; }
    `;
    styles.innerHTML = css;
    document.head.appendChild(styles);
    document.body.appendChild(panel);

    onScreenConsole(panel);
    // Show debug start up message
    log(window.navigator.userAgent);

    // Nested Classes
    // TODO: add ability to hide / extend / shorten the OSC panel using remote Keys.
    const osc = document.getElementById('osc') as HTMLDivElement;
    // full | short | hide
    osc.className = 'long'; //
}
