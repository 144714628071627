import Storage from './storage';

export const getLastPlayedLiveAsset = () => {
    return Storage.getItem('lpla');
};

export const setLastPlayedLiveAsset = (assetId: string) => {
    return Storage.setItem('lpla', assetId);
};

export const clearLastPlayedLiveAsset = () => {
    return Storage.removeItem('lpla');
};
