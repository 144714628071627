import { datadogRum } from '@datadog/browser-rum';

import { UserType } from 'src/state/stores/user/user';

import { getAppVersion } from 'src/utilities/app';
import { getDeviceId } from 'src/utilities/device';

import { CONFIG } from 'src/config/config';
import { REDACTED_PERSONALLY_IDENTIFIABLE_INFORMATION } from 'src/state/stores/user/user.constants';
import { LOGGED_OUT_USER_TYPE } from '../Segment/constants';

export const dataDog = {
    init: () => {
        datadogRum.init({
            applicationId: CONFIG.dataDogApplicationId,
            clientToken: CONFIG.dataDogClientId,
            site: 'datadoghq.com',
            service: CONFIG.platform.concat('-', CONFIG.env).toLowerCase(),
            env: CONFIG.env,
            version: getAppVersion(), //we may have to prepend with 'v' as per https://docs.datadoghq.com/getting_started/tagging/#define-tags
            sampleRate: CONFIG.dataDogSampleRate,
            sessionReplaySampleRate: CONFIG.dataDogReplayRate,
            trackInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            trackFrustrations: true,
            defaultPrivacyLevel: 'mask-user-input',
            beforeSend: (event) => {
                // Remove pkgV from view url & Hash for cleaner View analytics.
                event.view.url = event.view.url
                    .replace(/index.html/, '')
                    .replace(/pkgV=[^&]*/, '')
                    .replace(/\/#/, '');
            },
        });

        datadogRum.setUser({
            ...datadogRum.getUser(),
            device: getDeviceId(),
        });
    },

    onUserLoggedIn: () => {
        datadogRum.startSessionReplayRecording();
    },

    onUserLoggedOut: () => {
        datadogRum.stopSessionReplayRecording();
    },

    setUserProperties: (user?: UserType) => {
        const {
            analyticUserId = getDeviceId(),
            userId: username = '',
            accessGroups = [],
            type = LOGGED_OUT_USER_TYPE,
        } = user || {};
        datadogRum.setUser({
            ...datadogRum.getUser(),

            id: analyticUserId,
            username: username,
            accessGroups: accessGroups,
            type: type,
            email: REDACTED_PERSONALLY_IDENTIFIABLE_INFORMATION,
            name: REDACTED_PERSONALLY_IDENTIFIABLE_INFORMATION,
            phone_number: REDACTED_PERSONALLY_IDENTIFIABLE_INFORMATION,
        });
    },
};
