import Storage from './storage';

export const getSuspendedTime = () => {
    return Storage.getItem('ast');
};

export const setSuspendedTime = () => {
    Storage.setItem('ast', Date.now());
};

export const clearSuspendedTime = () => {
    Storage.removeItem('ast');
};
