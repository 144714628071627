import { Config, StringSet, URLSet } from '../config';
import { env } from '../configs/config.all';

const segmentXboxURLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/9HQmk0C80IChRv6QwvybBCnEY37UfiYL/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/yg0A93W4GfedaDerg6TvDsfhfn3ReAfi/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/yg0A93W4GfedaDerg6TvDsfhfn3ReAfi/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/yg0A93W4GfedaDerg6TvDsfhfn3ReAfi/analytics.min.js',
};

const drModeXboxURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-xbox.json',
    PP: 'https://dr.optussport.info/assets-xbox.json',
    STAGING: 'https://dr.optussport.info/assets-xbox.json',
    LOCAL: 'https://dr.optussport.info/assets-xbox.json',
};

const brazeXboxKeys: StringSet = {
    PROD: 'b696c1fa-1ffe-4ac7-b699-f2a1551345ed',
    PP: '9aa531c4-aaf6-4d8a-8aeb-579eed00c89c',
    STAGING: '9aa531c4-aaf6-4d8a-8aeb-579eed00c89c',
    LOCAL: '9aa531c4-aaf6-4d8a-8aeb-579eed00c89c',
};

const config_xbox: Partial<Config> = {
    segmentLibUrl: segmentXboxURLs[env],
    drModeCheckURL: drModeXboxURLs[env],
    platform: 'Xbox',
    exitAppPopupEnabled: true,
    playbackDrmChoice: 'playready',
    playbackSupportsCmafLive: true,
    playbackSupportsCmafVod: true,
    YoSpaceActiveOnLive: true,
    YoSpaceActiveOnVod: true,
    adUIEnabledOnLive: false,
    adUIEnabledOnVod: true,

    BrazeActive: true, // All users
    BrazeApiKey: brazeXboxKeys[env],

    // bitmovinPlayerConfig: {
    //     logs: {
    //         level: LogLevel.WARN,
    //     },
    // },
};

export default config_xbox;
