import tb3 from '../assets/icons/competition/tbgLeagueEuro2024.svg';
import tb8 from '../assets/icons/competition/tbgLeaguePremier_v2.svg';
import tb20 from '../assets/icons/competition/tbgLeagueJleague.svg';
import tb21 from '../assets/icons/competition/tbgLeagueKLeague.svg';
import tb23 from '../assets/icons/competition/tbgLeagueLaLiga_v2.svg';
import tb88 from '../assets/icons/competition/tbgLeagueFriendlies.svg';
import tb128 from '../assets/icons/competition/tbgLeagueCopaAmerica24.svg';
import tb231 from '../assets/icons/competition/tbgLeagueDFBPokal.svg';
import tb235 from '../assets/icons/competition/tbgLeagueEuroQualifiers.svg';
import tb408 from '../assets/icons/competition/tbgLeagueWwc23.svg';
import tb550 from '../assets/icons/competition/tbgLeagueWsl.svg';
import tb832 from '../assets/icons/competition/tbgLeagueNWSL.svg';
import tb941 from '../assets/icons/competition/tbgLeagueNations.svg';

import tb3Png from '../assets/icons/competition/tbgLeagueEuro2024.png';
import tb8Png from '../assets/icons/competition/tbgLeaguePremier_v2.png';
import tb20Png from '../assets/icons/competition/tbgLeagueJleague.png';
import tb21Png from '../assets/icons/competition/tbgLeagueKLeague.png';
import tb23Png from '../assets/icons/competition/tbgLeagueLaLiga_v2.png';
import tb88Png from '../assets/icons/competition/tbgLeagueFriendlies.png';
import tb128Png from '../assets/icons/competition/tbgLeagueCopaAmerica24.png';
import tb231Png from '../assets/icons/competition/tbgLeagueDFBPokal.png';
import tb336Png from '../assets/icons/competition/tbgLeagueEuroQualifiers.png';
import tb408Png from '../assets/icons/competition/tbgLeagueWwc23.png';
import tb550Png from '../assets/icons/competition/tbgLeagueWsl.png';
import tb832Png from '../assets/icons/competition/tbgLeagueNWSL.png';
import tb941Png from '../assets/icons/competition/tbgLeagueNations.png';

import i3 from '../assets/icons/competition/iconLeagueEuro2024.svg';
import i8 from '../assets/icons/competition/iconLeaguePremier_v2.svg';
import i20 from '../assets/icons/competition/iconLeagueJleague.svg';
import i21 from '../assets/icons/competition/iconLeagueKLeague.svg';
import i23 from '../assets/icons/competition/iconLeagueLaLiga_v2.svg';
import i88 from '../assets/icons/competition/iconLeagueFriendlies.svg';
import i128 from '../assets/icons/competition/iconLeagueCopaAmerica24.svg';
import i231 from '../assets/icons/competition/iconLeagueDFBPokal.svg';
import i235 from '../assets/icons/competition/iconLeagueEuroQualifiers.svg';
import i408 from '../assets/icons/competition/iconLeagueWwc23.svg';
import i550 from '../assets/icons/competition/iconLeagueWsl.svg';
import i832 from '../assets/icons/competition/iconLeagueNWSL.svg';
import i941 from '../assets/icons/competition/iconLeagueNations.svg';

import bg3 from '../assets/images/backgrounds/competition/bgLeagueEuro2024.png';
import bg8 from '../assets/images/backgrounds/competition/bgLeaguePremier_v2.png';
import bg20 from '../assets/images/backgrounds/competition/bgLeagueJleague.png';
import bg21 from '../assets/images/backgrounds/competition/bgLeagueKLeague.png';
import bg23 from '../assets/images/backgrounds/competition/bgLeagueLaLiga_v3.png';
import bg88 from '../assets/images/backgrounds/competition/bgLeagueInternationals.png';
import bg128 from '../assets/images/backgrounds/competition/bgLeagueCopaAmerica24.png';
import bg231 from '../assets/images/backgrounds/competition/bgLeagueDFBPokal.png';
import bg235 from '../assets/images/backgrounds/competition/bgLeagueInternationals.png';
import bg408 from '../assets/images/backgrounds/competition/bgLeagueWwc23.png';
import bg550 from '../assets/images/backgrounds/competition/bgLeagueWsl.png';
import bg832 from '../assets/images/backgrounds/competition/bgLeagueNWSL.png';
import bg941 from '../assets/images/backgrounds/competition/bgLeagueInternationals.png';

import { ThemeType } from 'src/state/stores/app/app';
import { isPS4 } from 'src/utilities/ps4';

export type CompetitionIds =
    | '0'
    | '3'
    | '8'
    | '20'
    | '21'
    | '23'
    | '88'
    | '128'
    | '231'
    | '235'
    | '408'
    | '550'
    | '832'
    | '941';

interface CompetitionType {
    title: string;
    deepLinkNames: string[];
    tileBackgroundImage: string;
    vodBackgroundImage: string;
    iconImage: string;
    titleTextColor: string;
    theme: ThemeType;
}

type CompetitionsConfigType = {
    [key: string]: CompetitionType;
};

//
// 3    EURO 2024
// 8    Premier League
// 20   J.League
// 21   K League (Placeholder ID for now)
// 23   LaLiga
// 88   Friendlies
// 128  Copa America 2024
// 231  DFP Pokal
// 235  WC Qualification Europe
// 408  Women's World Cup 2023
// 550  Women's Super League
// 642  Women's WC Qualification Europe
// 832  National Women's Soccer League
// 941  UEFA Nations League

const usePng = isPS4();

export const competitionsConfig: CompetitionsConfigType = {
    0: {
        title: 'Premier League',
        deepLinkNames: ['explore_deeplink_epl', 'epl'],
        tileBackgroundImage: usePng ? tb8Png : tb8,
        vodBackgroundImage: bg8,
        iconImage: i8,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    3: {
        title: 'EURO 2024',
        deepLinkNames: ['explore_deeplink_euro_2024', 'uefa-euro-2024'],
        tileBackgroundImage: usePng ? tb3Png : tb3,
        vodBackgroundImage: bg3,
        iconImage: i3,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    8: {
        title: 'Premier League',
        deepLinkNames: ['epl'],
        tileBackgroundImage: usePng ? tb8Png : tb8,
        vodBackgroundImage: bg8,
        iconImage: i8,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    20: {
        title: 'J.League',
        deepLinkNames: ['explore_deeplink_j_league', 'j-league'],
        tileBackgroundImage: usePng ? tb20Png : tb20,
        vodBackgroundImage: bg20,
        iconImage: i20,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    21: {
        title: 'K League',
        deepLinkNames: ['explore_deeplink_k_league', 'k-league'],
        tileBackgroundImage: usePng ? tb21Png : tb21,
        vodBackgroundImage: bg21,
        iconImage: i21,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    23: {
        title: 'LaLiga',
        deepLinkNames: ['explore_deeplink_laliga', 'Laliga'],
        tileBackgroundImage: usePng ? tb23Png : tb23,
        vodBackgroundImage: bg23,
        iconImage: i23,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    88: {
        title: 'Friendlies',
        deepLinkNames: ['Friendlies'],
        tileBackgroundImage: usePng ? tb88Png : tb88,
        vodBackgroundImage: bg88,
        iconImage: i88,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    235: {
        title: 'UEFA European Championship Qualifiers',
        deepLinkNames: ['WC-Qualification-Europe'],
        tileBackgroundImage: usePng ? tb336Png : tb235,
        vodBackgroundImage: bg235,
        iconImage: i235,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    550: {
        title: "Women's Super League",
        deepLinkNames: ['explore_deeplink_womens', 'womens'],
        tileBackgroundImage: usePng ? tb550Png : tb550,
        vodBackgroundImage: bg550,
        iconImage: i550,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    941: {
        title: 'UEFA Nations League',
        deepLinkNames: ['UEFA-Nations-League'],
        tileBackgroundImage: usePng ? tb941Png : tb941,
        vodBackgroundImage: bg941,
        iconImage: i941,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    231: {
        title: 'DFB Pokal',
        deepLinkNames: ['explore_deeplink_dfb', 'dfb-pokal'],
        tileBackgroundImage: usePng ? tb231Png : tb231,
        vodBackgroundImage: bg231,
        iconImage: i231,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    408: {
        title: "FIFA Women's World Cup 2023",
        deepLinkNames: ['womens-world-cup-2023'],
        tileBackgroundImage: usePng ? tb408Png : tb408,
        vodBackgroundImage: bg408,
        iconImage: i408,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    832: {
        title: "National Women's Soccer League",
        deepLinkNames: ['explore_deeplink_nwsl', 'nwsl'],
        tileBackgroundImage: usePng ? tb832Png : tb832,
        vodBackgroundImage: bg832,
        iconImage: i832,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
    128: {
        title: 'Copa America USA 2024™',
        deepLinkNames: ['explore_deeplink_copa_america', 'copa-america-2024'],
        tileBackgroundImage: usePng ? tb128Png : tb128,
        vodBackgroundImage: bg128,
        iconImage: i128,
        titleTextColor: '#FFFFFF',
        theme: 'dark',
    },
};
