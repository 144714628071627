import { FocusNode, useProcessKey, useSetFocus } from 'lal-lrud';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from 'src/components/Common/Typography';
import Icon from 'src/components/Icons';
import { clearNavigationPosition } from 'src/controllers/focusCache.controller';
import { SidebarItemType } from 'src/state/stores/app/app';
import styles from './sidebar.module.scss';

interface SidebarItemProps {
    item: SidebarItemType;
    selected: boolean;
}

const SidebarItem = (props: SidebarItemProps) => {
    const navigate = useNavigate();
    const {
        item: { layoutId, icon, title, href },
        selected,
    } = props;
    const setFocus = useSetFocus();
    const processKey = useProcessKey();
    const focusId = `sidebar-item-${href}`;
    const onClick = () => {
        processKey.right();
        if (href) {
            clearNavigationPosition();
            navigate(href);
        }
    };
    const itemClasses = [`nav-menu-${layoutId}`, styles.sidebarItem];
    if (selected) {
        itemClasses.push(styles.selected);
    }

    const handleMouseOver = () => {
        setFocus(focusId);
    };

    return (
        <FocusNode
            className={itemClasses.join(' ')}
            focusedClass={styles.focused}
            onSelected={onClick}
            onClick={onClick}
            onMouseOver={handleMouseOver}
            focusId={focusId}
        >
            <div className={styles.content}>
                <Icon icon={icon} className={styles.icon}></Icon>
                <Typography className={styles.title}>{title}</Typography>
            </div>
            <div className={styles.focusDrop}></div>
        </FocusNode>
    );
};

export default React.memo(SidebarItem);
