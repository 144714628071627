import { useCallback } from 'react';
import { useStateValue } from 'src/state';
import { setAppReady } from 'src/state/stores/app/app.actions';

const useAppReady = () => {
    const { state, dispatch } = useStateValue();
    const {
        appData: { ready },
    } = state;

    const setReady = useCallback(() => {
        dispatch(setAppReady());
    }, [dispatch]);

    return { ready, setReady };
};

export default useAppReady;
