import { useEffect } from 'react';
import { exitApp } from 'src/utilities/device';

export const useForceExit = () => {
    useEffect(() => {
        const codes = ['1', '2', '3', '4', 'Unidentified']; // 1, 2, 3, 4, RED Button

        let index = 0;

        const handleKeyQueue = (event: KeyboardEvent) => {
            const key = event.key;
            // console.log('THE COLOURED BUTTONS', event.key, event.keyCode);
            if (key === codes[index]) {
                index++;
                if (index === codes.length) {
                    exitApp(true);
                    index = 0;
                }
            } else {
                index = 0;
            }
        };

        document.addEventListener('keyup', handleKeyQueue);

        return () => {
            document.removeEventListener('keyup', handleKeyQueue);
        };
    }, []);
};
