import { useEffect, useState } from 'react';
import { VideoElmEvent, PlaybackEvent } from 'src/lib/bitmovin/player.d';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';

const events: PlaybackEvent[] = [
    'playing',
    'paused',
    'stallstarted',
    'stallended',
    'playbackfinished',
];
const eventsPs4: VideoElmEvent[] = ['playing', 'pause', 'waiting', 'stalled', 'ended'];

const usePlaybackState = () => {
    // Not in use so commenting
    const [playing, setPlaying] = useState(false);
    const [paused, setPaused] = useState(false);
    const [buffering, setBuffering] = useState(true);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        const eventHandler = (e: Event) => {
            switch (e.type) {
                case 'playing':
                    setPlaying(true);
                    setPaused(false);
                    setBuffering(false);
                    setFinished(false);
                    break;

                case 'pause':
                case 'paused':
                    setPaused(true);
                    break;

                case 'waiting':
                case 'stalled':
                case 'stallstarted':
                    setBuffering(true);
                    break;

                case 'stallended':
                    setBuffering(false);
                    break;

                case 'ended':
                case 'playbackfinished':
                    setFinished(true);
                    setBuffering(false);
                    setPlaying(false);
                    setPaused(false);
                    break;

                default:
                    break;
            }
        };

        const Player = getActivePlayer();
        const offs: (() => void)[] = [];

        const subscribe = () => {
            if (Player.player) {
                [...events, ...eventsPs4].forEach((e) => {
                    offs.push(Player.on(e, eventHandler));
                });
            }
        };

        const unSubscribe = () => {
            offs.forEach((off) => off());
        };

        document.addEventListener('player-ready', subscribe);

        return unSubscribe;
    }, []);

    return { buffering, finished, paused, playing };
};

export default usePlaybackState;
