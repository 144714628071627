import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RouteTags, { getRouteTags } from 'src/routes/routeTags';
import { useStateValue } from 'src/state';
import { WatchModeLiveType } from 'src/state/stores/dashboard/dashboard';

import useAsset from './asset.controller';
import useBackButton from './keyPress/backButton.controller';
import { useShimmer } from './shimmer.controller';
import useUser from './auth/user.controller';

export interface locationStateType {
    autoPlay: WatchModeLiveType | boolean;
}

const useVodDetail = () => {
    const { assetId, dashboard } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useUser();
    const [showSubPendingNotification, setShowSubPendingNotification] = useState<boolean>(false);
    const { accessGroups: userAGs, type: userType } = user || {};
    const { asset } = useAsset({ detailed: true, refetchFullAsset: true });
    const { accessGroups: assetAGs = [] } = asset || {};
    const [canPlay, setCanPlay] = useState<boolean | undefined>(undefined);
    const { state } = useStateValue();
    const {
        dashboardData: { assetFetchFailed },
    } = state;

    // TODO: this piece of code/logic can be improved/simpliied. We can remove useState and use useMemo. See commit ac8a40c3e478dec47cc42017c21652f840aa10cb and refactor later
    const canPlayAsset = useCallback(
        (accessGroup: any) => {
            return accessGroup.some(
                (ag: any) => ag === 'FREE_WITHOUT_LOGIN' || ag === userType || userAGs?.includes(ag)
            );
        },
        [userAGs, userType]
    );

    useEffect(() => {
        if (!asset) return;
        const canPlayVar = canPlayAsset(assetAGs);
        setCanPlay(canPlayVar);
    }, [asset, assetAGs, canPlayAsset]);

    const handleAssetFetchError = useMemo(() => {
        if (assetId) {
            return assetFetchFailed[assetId];
        }
    }, [assetId, assetFetchFailed]);

    const { state: locationState, pathname } = location;
    const { autoPlay } = (locationState as locationStateType) || {};

    const { setShowShimmer } = useShimmer();

    useBackButton({
        callBack: () => {
            setShowShimmer(false, () => {
                navigate(-1);
            });
            return true;
        },
        id: 'vod-controller-unselectAsset',
        rank: 2,
    });

    const onPlayClick = useCallback(
        (sAssetId = assetId, watchMode?: WatchModeLiveType, assetAccessGroup = assetAGs) => {
            if (sAssetId) {
                if (canPlayAsset(assetAccessGroup)) {
                    navigate(getRouteTags('vodPlayback', 'assetId', sAssetId), {
                        state: { watchMode },
                    });
                } else if (user) {
                    // Subscription Pending
                    setShowSubPendingNotification(true);
                } else {
                    // login Pending
                    navigate(RouteTags.login, {
                        state: {
                            redirectOnSuccess: {
                                pathname: getRouteTags(
                                    'vodDetail',
                                    ['dashboard', 'assetId'],
                                    [dashboard || '', sAssetId]
                                ),
                                state: { autoPlay: watchMode || true },
                            },
                        },
                    });
                }
            }
        },
        [assetId, assetAGs, canPlayAsset, user, navigate, dashboard]
    );

    useEffect(() => {
        if (canPlay === undefined) return;
        if (autoPlay) {
            navigate(pathname, { replace: true });
        }
        if (autoPlay === true) {
            onPlayClick(assetId);
        } else if (autoPlay) {
            onPlayClick(assetId, autoPlay);
        }
    }, [assetId, autoPlay, pathname, onPlayClick, navigate, canPlay]);

    const hideNotification = () => {
        setShowSubPendingNotification(false);
    };
    return {
        asset,
        canPlay,
        showSubPendingNotification,
        hideNotification,
        onPlayClick,
        handleAssetFetchError,
    };
};

export default useVodDetail;
