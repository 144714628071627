import { createContext, memo } from 'react';
import { ContextType, StateProviderProps } from '../braze';
import useBrazeProvider from '../controllers/brazeProvider.controller';

const INITIAL_STATE: ContextType = {
    brazeContentCards: [],
};

export const BrazeContext = createContext<ContextType>(INITIAL_STATE);

const BrazeProvider = (props: StateProviderProps) => {
    const { children } = props;
    const brazeContentCards = useBrazeProvider();
    const value = {
        brazeContentCards,
    };
    return <BrazeContext.Provider value={value}>{children}</BrazeContext.Provider>;
};

export default memo(BrazeProvider);
