// @ts-nocheck
import { useEffect, useRef } from 'react';

const useGamepadInputEmulation = () => {
    const navigationThrottle = useRef(null);
    // controller key - event mappings
    useEffect(() => {
        navigator.gamepadInputEmulation = 'keyboard';
        const initiateNavigationByGameController = (e) => {
            const { keyCode } = e;
            if (keyCode === 196) {
                e.preventDefault();
            }

            if (!navigationThrottle.current) {
                navigationThrottle.current = setTimeout(() => {
                    navigationThrottle.current = null;
                }, 200);
                const controllerKeyMappings = {
                    211: 'up',
                    215: 'up',
                    203: 'up',

                    204: 'down',
                    212: 'down',
                    216: 'down',

                    205: 'left',
                    218: 'left',
                    214: 'left',
                    200: 'left',
                    201: 'left',

                    217: 'right',
                    206: 'right',
                    213: 'right',
                    // 32: 'right',
                    199: 'right',
                    202: 'right',

                    195: 'enter',
                    196: 'back',
                };

                const eventMappings = {
                    up: {
                        isTrusted: true,
                        key: 'ArrowUp',
                        charCode: 'ArrowUp',
                        code: 38,
                        keyCode: 38,
                    },
                    down: {
                        isTrusted: true,
                        key: 'ArrowDown',
                        code: 40,
                        keyCode: 40,
                    },
                    left: {
                        isTrusted: true,
                        key: 'ArrowLeft',
                        code: 37,
                        keyCode: 37,
                    },
                    right: {
                        isTrusted: true,
                        key: 'ArrowRight',
                        code: 39,
                        keyCode: 39,
                    },
                    enter: {
                        isTrusted: true,
                        key: 'Enter',
                        code: 13,
                        keyCode: 13,
                    },
                    // back: {
                    //     isTrusted: true,
                    //     key: 'Back',
                    //     code: 196,
                    //     keyCode: 196,
                    // },
                };
                const direction = controllerKeyMappings[keyCode];
                if (direction) {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    e.preventDefault();

                    const { keyCode: kc, key: k } = eventMappings[direction];

                    // TODO: Remove this!
                    // const keyboardEvent = document.createEvent('Event');
                    // keyboardEvent.initEvent(
                    //     'keydown', // event type: keydown, keyup, keypress
                    //     true, // bubbles
                    //     true // cancelable
                    // );

                    // keyboardEvent.view = window; // view: should be window
                    // keyboardEvent.keyCode = kc; // keyCode: unsigned long - the virtual key code, else 0
                    // keyboardEvent.key = k; // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
                    // window.dispatchEvent(keyboardEvent);
                    // var keyboardEvent = document.createEvent('KeyboardEvent');
                    // var initMethod =
                    //     typeof keyboardEvent.initKeyboardEvent !== 'undefined'
                    //         ? 'initKeyboardEvent'
                    //         : 'initKeyEvent';

                    // keyboardEvent[initMethod](
                    //     'keydown', // event type: keydown, keyup, keypress
                    //     true, // bubbles
                    //     true, // cancelable
                    //     window, // view: should be window
                    //     false, // ctrlKey
                    //     false, // altKey
                    //     false, // shiftKey
                    //     false, // metaKey
                    //     kc, // keyCode: unsigned long - the virtual key code, else 0
                    //     k // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
                    // );
                    // document.dispatchEvent(keyboardEvent);

                    const keyboardEvent = new Event('keydown', {
                        bubbles: true,
                        cancelable: true,
                    });
                    keyboardEvent.keyCode = kc;
                    keyboardEvent.key = k;
                    document.dispatchEvent(keyboardEvent);
                    console.log(kc, k);
                }
            }
        };
        document.addEventListener('keydown', initiateNavigationByGameController);
        return () => {
            document.removeEventListener('keydown', initiateNavigationByGameController);
        };
    }, []);
};

export default useGamepadInputEmulation;
