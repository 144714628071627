import bgGenDark from '../assets/images/backgrounds/bgGeneralDark.png';
import bgGenLight from '../assets/images/backgrounds/bgGeneralLight.png';
import bgGenFitness from '../assets/images/backgrounds/bgGeneralFitness.png';
import { CompetitionIds, competitionsConfig } from 'src/config/competitionConfig';

interface bgMappingType {
    [index: string]: string;
}

const bgMapping: bgMappingType = {
    def: bgGenLight,

    article: bgGenDark,
    classic: bgGenDark,
    extended: bgGenDark,
    feature: bgGenDark,
    fitness: bgGenFitness,
    'game-nostats': bgGenDark,
    game: bgGenDark,
    interview: bgGenDark,
    'linear-channel': bgGenDark,
    news: bgGenDark,
    pressconference: bgGenDark,
    short: bgGenDark,
    show: bgGenDark,
};

export const getBackgroundImage = (bg: keyof typeof bgMapping, competitionId?: CompetitionIds) => {
    if (competitionId && competitionsConfig[competitionId]) {
        return competitionsConfig[competitionId].vodBackgroundImage;
    }
    if (bg in bgMapping) {
        return bgMapping[bg];
    }
    return bgMapping.def;
};
