import { log } from 'src/utilities/console';
import { BitmovinOptusPlayer } from '..';
import { PlayerClass } from '../player';

export function generateSessionId(this: PlayerClass) {
    BitmovinOptusPlayer.playerSession.generateViewingSessionId();
    const session = BitmovinOptusPlayer.playerSession.getViewingSessionId();
    log('New Player Session Id:', session);
    return session;
}
