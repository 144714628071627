import axios from 'axios';
import {
    PlaybackSessionType,
    WatchModeLiveType,
    WatchModeType,
} from 'src/state/stores/dashboard/dashboard';
import { getAppVersion } from 'src/utilities/app';
import {
    getAdvertParams,
    getDeviceDetails,
    getDeviceId,
    matchByUserDevice,
} from 'src/utilities/device';
import urls from './urls';

import { disabledCmafDeviceIds, disabledCmafDeviceModel } from 'src/config/user/deviceList';
import { BitmovinOptusPlayer } from 'src/lib/bitmovin';
import { Segment } from 'src/lib/bitmovin/segment';
import { CONFIG } from 'src/config/config';
import { featuresConfig } from 'src/lib/bitmovin/config/bPlayerConfig';
import { REQUIRE_AUTH } from 'src/utilities/http';
import { Player } from 'src/lib/bitmovin/player';
import { hidePii } from 'src/utilities/hidePii';

export const getLayout = (layoutUrl: string) => {
    return () => axios.get(layoutUrl);
};

export const getExploreLayout = (bucketKey: string) => {
    const url = urls.explore_home.replace('<bucketKey>', bucketKey);
    return () => axios.get(url);
};

export const getPanel = (bucketKey: string) => {
    const url = urls.panel_url.replace('<bucketKey>', bucketKey);
    return () => axios.get(url);
};

export const getPanels = (bucketKeys: string[]) => {
    return () => Promise.all(bucketKeys.map((bucketKey) => getPanel(bucketKey)()));
};

export const getAsset = (assetId: string) => {
    const url = urls.asset_url.replace('<assetId>', assetId);
    return () => axios.get(url);
};

export const getLiveAssets = () => {
    const url = urls.linear_assets;
    return () => axios.get(url);
};

export const getAssetMetaData = (assetId: string) => {
    const url = urls.asset_metadata_url.replace('<assetId>', assetId);
    return () => axios.get(url);
};

export const getAssetPlaybackLatestSession = (assetId: string) => {
    const url = urls.asset_playback_session_latest.replace('<assetId>', assetId);
    return () => axios.get(url, REQUIRE_AUTH);
};

export const postAssetPlaybackSession = (data: PlaybackSessionType) => {
    const url = urls.asset_playback_session;
    return () => axios.post(url, data, REQUIRE_AUTH);
};

export const getLiveAssetEPGData = (assetId: string) => {
    const url = urls.live_asset_EPG_data_url.replace('<assetId>', assetId);

    // Create new Axios instance to ensure header is only removed from this call.
    const customRequest = axios.create();
    delete customRequest.defaults.headers.common['Authorization'];

    return () => customRequest.get(url);
};

export const getRelatedAssets = (assetId: string) => {
    const url = urls.assets_related_assets.replace('<assetId>', assetId);
    return () => axios.get(url);
};

export const isYspEnabled = (watchMode?: WatchModeType | WatchModeLiveType) => {
    const isLive = watchMode === 'live' || watchMode === 'startover';
    return isLive ? CONFIG.YoSpaceActiveOnLive : CONFIG.YoSpaceActiveOnVod;
};

const isInAdHoliday = (): boolean => {
    // Use the value for `isInAdHoliday` to be sent to Playback API as the value for `adHol` parameter
    if (featuresConfig.adHoliday?.enabled) {
        return BitmovinOptusPlayer.isInAdHoliday(featuresConfig);
    }
    return false;
};

const yspVersion = (watchMode?: WatchModeType | WatchModeLiveType) =>
    isYspEnabled(watchMode) ? CONFIG.YoSpaceVersion : false;

const hasCmafSupport = (
    watchMode: WatchModeType | WatchModeLiveType,
    deviceId: string,
    deviceModel: string
) => {
    const isLive = watchMode === 'live' || watchMode === 'startover';

    if (deviceId && matchByUserDevice(disabledCmafDeviceIds, deviceId)) return false;
    if (deviceModel && matchByUserDevice(disabledCmafDeviceModel, deviceModel)) return false;

    return isLive ? CONFIG.playbackSupportsCmafLive : CONFIG.playbackSupportsCmafVod;
};

const playbackParams = {
    type: CONFIG.playbackStreamType,
    drm: CONFIG.playbackDrmChoice,
    deviceId: getDeviceId(),
    appVersion: getAppVersion(),
    platform: CONFIG.platform,
    vendor: getDeviceDetails().deviceMake,
    model: getDeviceDetails().deviceModel,
    deviceMake: getDeviceDetails().deviceMake,
    deviceModel: getDeviceDetails().deviceModel,
    osName: getDeviceDetails().osName,
    osVersion: getDeviceDetails().osVersion,
    playerName: BitmovinOptusPlayer.getName(),
    playerVersion: BitmovinOptusPlayer.getVersion(),
    advertConsent: getAdvertParams().advertConsent,
    advertId: getAdvertParams().advertId,
    advertIdType: getAdvertParams().advertIdType,
};

export const getLiveAssetPlaybackData = (
    assetData: any,
    userId: string,
    prohibitTargeting: boolean,
    watchMode: WatchModeLiveType
) => {
    const url = urls.live_asset_playback_data_url
        .replace('<assetId>', assetData.id)
        .replace('<userId>', userId)
        .replace('<deviceId>', getDeviceId());

    const advertParams = getAdvertParams();
    const supportsCmaf = hasCmafSupport(
        watchMode,
        playbackParams.deviceId,
        playbackParams.deviceModel
    );
    const yspSdk = yspVersion(watchMode);

    return () => {
        const params = {
            ...playbackParams,
            supportsCmaf,
            yspSdk,
            ...advertParams,
            watchMode,
            prohibitTargeting,
        };
        const req = axios.get(url, { params, ...REQUIRE_AUTH });
        req.then((response: any) => {
            const {
                request: { responseURL },
            } = response;
            Player.initTracking();
            Segment.trackApiEvent({
                apiUrl: responseURL || url,
                segmentEventName: 'apiMlGeneralPlayback',
                requestParams: params,
                response: hidePii(response),
            });

            return response;
        });
        return req;
    };
};

export const getFreeAssetPlaybackData = (
    assetId: string,
    watchMode: WatchModeType | WatchModeLiveType,
    prohibitTargeting: boolean,
    start?: number,
    end?: number,
    options?: { yspSdk: boolean }
) => {
    const url = urls.asset_free_playback_data_url.replace('<assetId>', assetId);
    const supportsCmaf = hasCmafSupport(
        watchMode,
        playbackParams.deviceId,
        playbackParams.deviceModel
    );
    const yspSdk = yspVersion(watchMode);
    const adHol = CONFIG.adHolidayEnabled ? isInAdHoliday() : null;

    return () => {
        const params = {
            ...playbackParams,
            supportsCmaf,
            yspSdk,
            adHol,
            watchMode,
            prohibitTargeting,
            start,
            end,
            ...options,
        };
        const req = axios.get(url, { params });
        req.then((response: any) => {
            const {
                request: { responseURL },
            } = response;
            Player.initTracking();
            Segment.trackApiEvent({
                apiUrl: responseURL || url,
                segmentEventName: 'apiMlFreePlayback',
                requestParams: params,
                response: hidePii(response),
            });

            return response;
        }).catch((e) => e);
        return req;
    };
};
export const getGeneralAssetPlaybackData = (
    assetId: string,
    userId: string,
    watchMode: WatchModeType | WatchModeLiveType,
    prohibitTargeting: boolean,
    start?: number,
    end?: number,
    options?: { yspSdk: boolean }
) => {
    const url = urls.asset_general_playback_data_url
        .replace('<assetId>', assetId)
        .replace('<userId>', userId);
    const advertParams = getAdvertParams();
    const supportsCmaf = hasCmafSupport(
        watchMode,
        playbackParams.deviceId,
        playbackParams.deviceModel
    );
    const yspSdk = yspVersion(watchMode);
    const adHol = CONFIG.adHolidayEnabled ? isInAdHoliday() : null;

    return () => {
        const params = {
            ...playbackParams,
            supportsCmaf,
            yspSdk,
            ...advertParams,
            adHol,
            watchMode,
            prohibitTargeting,
            start,
            end,
            ...options,
        };

        const req = axios.get(url, { params, ...REQUIRE_AUTH });
        req.then((response: any) => {
            const {
                request: { responseURL },
            } = response;
            Player.initTracking();
            Segment.trackApiEvent({
                apiUrl: responseURL || url,
                segmentEventName: 'apiMlGeneralPlayback',
                requestParams: params,
                response: hidePii(response),
            });
            return response;
        }).catch((e) => e);
        return req;
    };
};

const contentCardsParams = {
    appVersion: getAppVersion(),
    platform: CONFIG.platform,
    osVersion: getDeviceDetails().osVersion,
    osName: getDeviceDetails().osName,
    os: `${getDeviceDetails().osName} ${getDeviceDetails().osVersion}`,
    model: getDeviceDetails().deviceModel,
    vendor: getDeviceDetails().deviceMake,
};
export const getContentCardsPlatform = () => {
    const url = urls.content_cards_platform.replace(
        new RegExp('<platform>', 'g'),
        CONFIG.platform.toLowerCase()
    );

    const params = { ...contentCardsParams };
    return () => axios.get(url, { params });
};

export const getContentCardsUser = (userId: string) => {
    const url = urls.content_cards_user
        .replace(new RegExp('<platform>', 'g'), CONFIG.platform.toLowerCase())
        .replace('<userId>', userId);
    const params = { ...contentCardsParams };
    return () => axios.get(url, { params, ...REQUIRE_AUTH });
};

export const getContentCardsBrazeAll = () => {
    const url = urls.content_cards_braze_all;
    const params = { ...contentCardsParams };
    return () => axios.get(url, { params });
};

export const getUserFavourites = (optusTeamCode: string) => {
    const url = urls.favourites.replace('<optusTeamCode>', optusTeamCode);
    return () => axios.get(url);
};
