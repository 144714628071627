import packageInfo from '../../package.json';

const { name, version, build, id } = packageInfo;
export const getAppVersion = () => {
    return version;
};

export const getBuildNumber = () => {
    return build;
};

console.log('App Version:', getAppVersion(), getBuildNumber());

export const getAppName = () => {
    return name;
};

export const getAppId = () => {
    return id;
};

export const reloadApp = () => {
    window.location.href = window.location.pathname;
};
