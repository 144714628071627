import { FocusNode } from 'lal-lrud';
import { LRUDEvent } from 'lal-lrud/dist/types';
import { createContext, memo, useContext } from 'react';
import useOLRUDProvider from '../controllers/oLRUDProvider.controller';
import { ContextType, StateProviderProps } from '../olrud';

const INITIAL_STATE: ContextType = {
    onLRUDKey: (e: LRUDEvent) => null,
    onFocus: () => null,
    onBlur: () => null,
    active: false,
    focusedPanelIndex: 0,
    focusedCardIndex: 0,
    panelTranslateX: 0,
};

export const OLRUDContext = createContext<ContextType>(INITIAL_STATE);

const OLRUDProvider = (props: StateProviderProps) => {
    const { children, className } = props;
    const value = useOLRUDProvider();
    return (
        <OLRUDContext.Provider value={value}>
            <FocusNode
                elementType="div"
                orientation="vertical"
                className={className}
                onArrow={value.onLRUDKey}
                onFocused={value.onFocus}
                onBlurred={value.onBlur}
            >
                {children}
            </FocusNode>
        </OLRUDContext.Provider>
    );
};
export default memo(OLRUDProvider);
export const useOLRUDConsumer = (): ContextType => useContext(OLRUDContext);
