import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/components/Common/Button';
import Typography from 'src/components/Common/Typography';
import RouteTags, { DASHBOARD_ROOT } from 'src/routes/routeTags';
import { locationStateType } from 'src/controllers/auth/auth.login.controller';
import useUser from 'src/controllers/auth/user.controller';
import styles from './login.module.scss';

const UserWelcome = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useUser();

    useEffect(() => {
        if (!user) {
            navigate(RouteTags.root, { replace: true });
        }
    }, [user, navigate]);

    const onLoginClick = useCallback(() => {
        const { state: locationState } = location;
        const { redirectOnSuccess } = (locationState as locationStateType) || {};
        const { pathname = '', state = {} } = redirectOnSuccess || {};
        navigate(pathname || DASHBOARD_ROOT, {
            replace: true,
            state: { ...state, ...(locationState as Object) },
        });
    }, [location, navigate]);

    const { name } = user || {};

    return (
        <div className={styles.loginContainer}>
            <Typography className={styles.title}>You are now logged in</Typography>
            <Typography className={styles.subTitle}>
                Welcome {name}, enjoy watching Optus Sport
            </Typography>
            <Button className={styles.loginButton} onClick={onLoginClick}>
                Continue to Optus Sport
            </Button>
        </div>
    );
};

export default UserWelcome;
