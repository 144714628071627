import { useState } from 'react';
import useDashboard from 'src/controllers/dashboard.controller';
import Slides from '../../components/ui/Onboarding/Slides';
import styles from './onboarding.module.scss';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import { exitApp } from 'src/utilities/device';
import { disableOnboarding, maxOnboardingIndex } from 'src/utilities/onboarding';
import { FocusNode } from 'lal-lrud';
import { LRUDEvent } from 'lal-lrud/dist/types';
import OnboardingDots from 'src/components/ui/Onboarding/Dots';
import OnboardingControls from 'src/components/ui/Onboarding/Controls';

const Onboarding = () => {
    useDashboard();

    const [slideIndex, setSlideIndex] = useState(0);

    if (slideIndex === maxOnboardingIndex) {
        disableOnboarding();
    }
    const onNext = (e: LRUDEvent) => {
        // the last slide has two buttons so if user is on last slide an if focus is on startbrowsing button then dont slide
        if (
            e.key === 'left' &&
            slideIndex > 0 &&
            e.targetNode.focusId !== 'startbrowsing' &&
            e.node.focusId !== 'startbrowsing'
        ) {
            setSlideIndex(slideIndex - 1);
        } else if (e.key !== 'left' && slideIndex < maxOnboardingIndex) {
            setSlideIndex(slideIndex + 1);
        }
    };

    useBackButton({
        callBack: () => {
            if (slideIndex > 0) {
                setSlideIndex(slideIndex - 1);
            } else {
                exitApp();
            }
            return true;
        },
        id: 'slide',
        rank: 3,
    });

    return (
        <FocusNode className={styles.onboarding} onLeft={onNext} onRight={onNext}>
            <div className={styles.overlay} />
            {slideIndex === 0 && <Slides.WelcomeOB />}
            {slideIndex === 1 && <Slides.MatchCenterOB />}
            {slideIndex === 2 && <Slides.ExitPageOB />}
            <div className={styles.controlBox}>
                <OnboardingDots
                    count={maxOnboardingIndex + 1}
                    activeIndex={slideIndex}
                ></OnboardingDots>
                <OnboardingControls onNext={onNext} slideIndex={slideIndex} />
            </div>
        </FocusNode>
    );
};

export default Onboarding;
