import Button from 'src/components/Common/Button';
import focusIds from 'src/constants/focusIds';
import usePlayer from 'src/controllers/player.controller';
import usePlaybackState from 'src/controllers/playerPlaybackState.controller';
import styles from './playPause.module.scss';
import { memo, useEffect, useState } from 'react';

interface PlayPauseProps {
    hidden: boolean;
}

const PlayPause = (props: PlayPauseProps) => {
    const { hidden } = props;
    const { Player } = usePlayer();
    const { paused } = usePlaybackState();

    const [btnPaused, setBtnPaused] = useState(paused)
    useEffect(() => {
        setBtnPaused(paused)
    }, [paused])

    const onClick = () => {
        if (btnPaused) {
            Player.play();
            setBtnPaused(false)
        } else {
            Player.pause();
            setBtnPaused(true)
        }
    };

    const icon = btnPaused ? 'play' : 'pause';

    return (
        <Button
            focusId={focusIds.playPauseBtn}
            iconOnly
            iconPre={icon}
            onClick={onClick}
            hidden={hidden}
            className={styles.button}
        />
    );
};

export default memo(PlayPause);
