import useTheme from 'src/controllers/theme.controller';
import { AssetType, WatchModeLiveType } from 'src/state/stores/dashboard/dashboard';
import { processImageUrl } from 'src/utilities/url';
import { getBackgroundImage } from 'src/utilities/vod';
import PlayButton from '../PlayButton';
import Poster from '../Poster';
import VodInfo from '../VodInfo';
import { posterImageSize } from './constants';
import styles from './detail.module.scss';
import { useFocusCache } from 'src/controllers/focusCache.controller';
import { FocusNode } from 'lal-lrud';
import { useVodDetailFocus } from 'src/controllers/vodDetail.focus.controller';
import { shouldDisableStartover } from 'src/utilities/asset';
import { checkifPastOrUpcoming } from 'src/utilities/time';
import focusIds from 'src/constants/focusIds';

interface ShowDetailProps {
    asset: AssetType;
    onPlayClick: (watchMode?: WatchModeLiveType) => void;
    isPastOrUpcoming?: string;
    setIsPastOrUpcoming?: (str: string) => void;
}

const ShowDetail = (props: ShowDetailProps) => {
    const { asset, onPlayClick, isPastOrUpcoming, setIsPastOrUpcoming } = props;
    const { theme } = useTheme(asset);
    const { setFocusCache, getFocusCache } = useFocusCache();
    // @TEMP: Re-enable Related Assets Component
    // const { translateY, isRelatedPanelFocused, onRelatedAssetsFocus, onRelatedAssetsBlur } =
    // useVodDetailFocus();
    const { translateY } = useVodDetailFocus();
    const buttonFocusId = getFocusCache();

    const {
        imageUrl,
        title,
        description,
        type,
        match,
        tags,
        cmsTags,
        broadcastStartTime,
        publishStartDate,
        isLive,
    } = asset || {};

    const posterImageUrl = processImageUrl(
        asset['imageUrl_16:9'] || imageUrl || '',
        posterImageSize.width,
        posterImageSize.height
    );
    const { competition } = match || {};
    const { id: competitionId } = competition || {};

    const vodDetailClasses = [styles.container];

    if (theme === 'dark') {
        vodDetailClasses.push(styles.dark);
    }

    const bg = getBackgroundImage(type, competitionId);
    const bgStyle = {
        transform: `translateY(${translateY}px)`,
        backgroundImage: `url(${bg})`,
    };

    const isUpcoming =
        checkifPastOrUpcoming(broadcastStartTime ? broadcastStartTime : publishStartDate) ===
        'upcoming';
    const showPlayButton = !isUpcoming;
    const showStartoverButton = !shouldDisableStartover(asset);

    const onActionButtonClick = (playType: WatchModeLiveType, focusId: string) => {
        setFocusCache(focusId);
        onPlayClick(playType);
    };

    let onMountProp: { onMountAssignFocusTo?: string } = { onMountAssignFocusTo: undefined };
    if (buttonFocusId && showPlayButton) {
        onMountProp.onMountAssignFocusTo = buttonFocusId;
        if (
            !showStartoverButton &&
            onMountProp.onMountAssignFocusTo === focusIds.vodDetail.startover
        ) {
            onMountProp.onMountAssignFocusTo = undefined;
        }
    }

    return (
        <div className={vodDetailClasses.join(' ')} style={bgStyle}>
            <div className={styles.mask}></div>
            <div className={styles.body}>
                <div className={styles.topSection}>
                    <VodInfo
                        title={title}
                        description={description}
                        tags={tags}
                        cmsTags={cmsTags}
                        broadcastStartTime={broadcastStartTime}
                        setIsPastOrUpcoming={setIsPastOrUpcoming}
                        isPastOrUpcoming={isPastOrUpcoming}
                    />
                    <Poster url={posterImageUrl} />
                </div>
                <FocusNode orientation="vertical">
                    <FocusNode className={styles.actions} {...onMountProp}>
                        {isLive ? (
                            // live
                            showPlayButton ? (
                                <>
                                    <PlayButton
                                        buttonText={'Watch Live'}
                                        focusId={focusIds.vodDetail.play}
                                        onClick={() =>
                                            onActionButtonClick('live', focusIds.vodDetail.play)
                                        }
                                    ></PlayButton>
                                    {showStartoverButton && (
                                        <PlayButton
                                            buttonText={'Start Over'}
                                            focusId={focusIds.vodDetail.startover}
                                            onClick={() =>
                                                onActionButtonClick(
                                                    'startover',
                                                    focusIds.vodDetail.startover
                                                )
                                            }
                                        ></PlayButton>
                                    )}
                                </>
                            ) : null
                        ) : showPlayButton ? (
                            <PlayButton
                                buttonText={'Play'}
                                focusId={focusIds.vodDetail.play}
                                onClick={() =>
                                    onActionButtonClick('startover', focusIds.vodDetail.play)
                                }
                            ></PlayButton>
                        ) : null}
                    </FocusNode>
                    {/* <RelatedAssetsPanel
                        assetId={asset.id}
                        className={styles.relatedAssets}
                        focused={isRelatedPanelFocused}
                        onFocus={onRelatedAssetsFocus}
                        onBlur={onRelatedAssetsBlur}
                    /> */}
                </FocusNode>
            </div>
        </div>
    );
};

export default ShowDetail;
