import { useEffect, useRef } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useStateValue } from 'src/state';
import { resetLayout } from 'src/state/stores/dashboard/dashboard.actions';
import RouteTags from 'src/routes/routeTags';
import { CONFIG } from 'src/config/config';

// TODO: Not safe!!! Check and fix
const useDataRefreshOnNavigation = () => {
    const prevLocation = useRef<string | null>(null);
    const location = useLocation();
    const { dispatch } = useStateValue();
    const isDashboard = useMatch(RouteTags.dashboard);
    const isDashboardExplore = useMatch(RouteTags.dashboardExplore);
    const isDashboardExploreViewAll = useMatch(RouteTags.dashboardExploreViewAll);

    useEffect(() => {
        //store the previous location with the current timestamp in local storage
        if (prevLocation.current) {
            try {
                //Attempting to get timestamp for previously stored location
                const prev = localStorage.getItem(prevLocation.current);

                //Store timestamp if it doesn't exist
                if (!prev) {
                    localStorage.setItem(prevLocation.current, DateTime.now().toISO() || '');
                }
            } catch (error) {
                //Store timestamp if localStorage.getItem errors
                localStorage.setItem(prevLocation.current, DateTime.now().toISO() || '');
            }
        }

        if (isDashboard || isDashboardExplore || isDashboardExploreViewAll) {
            //get the current location and the time it was stored from localStorage
            try {
                const potentialDateTime = localStorage.getItem(location.pathname);
                if (potentialDateTime) {
                    const retreivedDateTime: DateTime = DateTime.fromISO(potentialDateTime);

                    const diffInMins = DateTime.now().diff(retreivedDateTime, 'minutes').toObject()
                        .minutes!;

                    //if the value is more than 5 mins refresh the page and remove page from localStorage
                    if (diffInMins > CONFIG.PageExpiryPeriodMinutes) {
                        localStorage.removeItem(location.pathname);
                        // TODO: NOT Safe!!
                        dispatch(resetLayout());
                    }
                }
            } catch (error) {}

            //set prevLocation to location.pathname
            prevLocation.current = location.pathname;
        }
    }, [dispatch, isDashboard, isDashboardExplore, isDashboardExploreViewAll, location.pathname]);
};

export default useDataRefreshOnNavigation;
