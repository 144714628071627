import { CONFIG } from './config';

export const settingsData = [
    {
        categoryTitle: 'Support',
        categoryId: '1_Support',
        menuItems: [
            {
                title: 'Help & Support',
                pageId: 'customer-support',
            },
            // {
            //     title: 'Help & FAQ',
            //     pageId: 'help-faq',
            // },
            {
                title: 'Privacy Policy',
                pageId: 'privacy-policy',
            },
        ],
    },
];

const deviceManagementMenu = CONFIG.deviceManagementEnabled
    ? [
          {
              title: 'Device management',
              pageId: 'device-management',
          },
      ]
    : [];

export const accountSettingsLoggedIn = {
    categoryTitle: 'Account',
    categoryId: '2_Account',
    menuItems: [
        {
            title: 'My profile',
            pageId: 'account-profile',
        },
        ...deviceManagementMenu,
        {
            title: 'Log out',
            pageId: 'account-logout',
        },
    ],
};

export const accountSettingsLoggedOut = {
    categoryTitle: 'Account',
    categoryId: '2_Account',
    menuItems: [
        {
            title: 'Log in',
            pageId: 'account-login',
        },
    ],
};
