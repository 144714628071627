import { DrChannelItem } from 'src/state/stores/app/app';
import { AssetType, DRVideoStreams } from 'src/state/stores/dashboard/dashboard';

const hasStream = (drStream: DRVideoStreams | undefined, protocol: 'hls' | 'dash') => {
    return (
        drStream &&
        drStream[protocol] &&
        (drStream[protocol] !== '' ||
            drStream[protocol] !== null ||
            drStream[protocol] !== 'undefined' ||
            drStream[protocol] !== undefined)
    );
};

export const getStreamData = (drStream: DRVideoStreams) => {
    if (hasStream(drStream, 'dash')) {
        return {
            url: drStream.dash,
            protocol: 'DASH',
            licenseUrl: '',
        };
    } else if (hasStream(drStream, 'hls')) {
        return {
            url: drStream.hls,
            protocol: 'HLS',
            licenseUrl: '',
        };
    } else {
        return { url: '' };
    }
};

export const translateDrData = (drAsset: DrChannelItem) => {
    const { channel, description, poster, video } = drAsset;
    const { hls } = video;
    const url = hls; // DR is always HLS
    const broadcastStartTime = '2018-07-30T01:05:56Z';
    const publishStartDate = '2019-09-29T19:00:34Z';
    const createdAt = '2018-07-30T01:05:56Z';
    const updatedAt = '2018-07-30T01:05:56Z';
    const broadcastEndTime = '2018-07-30T01:05:56Z';

    const asset: AssetType = {
        id: channel.id,
        externalId: '',
        source: '',
        type: 'linear-channel',
        title: channel.name,
        description: description,
        createdAt,
        publishStartDate,
        isLive: true,
        broadcastStartTime,
        broadcastEndTime,
        channel: {
            id: channel.id,
            name: channel.name,
        },
        categoryTitle: 'Live TV',
        categorySlug: 'live-tv',
        updatedAt,
        slug: 'optus-sport-1',
        seoTitle: channel.name,
        accessGroups: ['PREMIUM'],
        imageUrl: poster,
        imageSource: 'NA',
        'imageUrl_1:1': poster,
        'imageUrl_16:9': poster,
        'imageUrl_9:16': poster,
        duration: 0,
        isSSAIEnabled: true,
        isChannelSSAIEnabled: true,
        drmProtected: true,
        isCMAFEnabled: true,
        logo: '',
        logo_controls: '',
        tileIconUrl: '',
        backgroundImageUrl: '',
        isInTransition: false,
    };

    const playbackData = {
        '@assetId': channel.id,
        title: channel.name,
        channelId: channel.id,
        channelName: channel.name,
        liveBroadcastTime: broadcastStartTime,
        live: true,
        drmProtected: true,
        playbackStatus: 'OK',
        hasItems: true,
        isSSAIEnabled: false,
        streamType: 'live',
        items: {
            item: [
                {
                    bitrate: 0,
                    fileSize: 0,
                    mediaFormat: 'smil',
                    scheme: 'https',
                    server: '',
                    base: '',
                    // protocol: CONFIG.playbackStreamType.toUpperCase(),
                    protocol: 'HLS',
                    url,
                    streamsProvider: 'infinit',
                    flag: 'elemental multi',
                    videoJobID: 'N/A',
                    encodingType: 'x265',
                    videoLanguage: 'en-au',
                    selectedCDN: '',
                    drmProxyType: '',
                    license: null,
                },
            ],
        },
        streamRule: {
            maxSubscriptionStreams: 2,
        },
    };

    return { asset, playbackData };
};
