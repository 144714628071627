import { useCallback, useEffect, useMemo } from 'react';
import { CONFIG } from 'src/config/config';
import { useStateValue } from 'src/state';
import { PanelType, PanelTypeType } from 'src/state/stores/dashboard/dashboard';
import { setPanelsToRender } from 'src/state/stores/dashboard/dashboard.actions';

import {
    BROWSE_PANEL_TYPE,
    CAROUSEL_PANEL_TYPE,
    CC_BANNER_PANEL_TYPE,
    CC_DYNAMIC_PANEL_TYPE,
    EXPLORE_PANEL_TYPE,
    cleanPanels,
    getPanelsOfType,
} from 'src/utilities/panel';

import useLayout from './layout.controller';
import {
    validateCCBanner,
    validateCCDynamicPanelAsset,
    validateCCFavPanelAsset,
    validateCCPanel,
} from 'src/utilities/contentCards';
import useViewAll from './viewAll.controller';
import useContentCards from './dashboard/contentCards.controller';
import usePage from 'src/lib/OLRUD/controllers/page.controller';
import useFavouriteTeam from './favouriteTeam.controller';

interface PanelDataProp {
    bucketKey?: string;
}

const panelTypes: PanelTypeType[] = [BROWSE_PANEL_TYPE, CAROUSEL_PANEL_TYPE, EXPLORE_PANEL_TYPE];
if (CONFIG.contentCardCMSActive) {
    panelTypes.push(CC_BANNER_PANEL_TYPE, CC_DYNAMIC_PANEL_TYPE);
}

// A controller to set the Panels to be rendered
const usePanelsToRender = (prop: PanelDataProp) => {
    const { bucketKey } = prop;
    const { state, dispatch } = useStateValue();
    const { layout, layoutId } = useLayout();
    const { isViewAll } = useViewAll();
    const favouriteTeamId = useFavouriteTeam();

    const {
        dashboardData: { panelData, panelDataPending },
    } = state;

    const contentCards = useContentCards();

    const allPanels = useMemo(
        () => (layout ? cleanPanels(getPanelsOfType(panelTypes, layout.panels)) : []),
        [layout]
    );

    let getViewAllPanels = useCallback(() => {
        return allPanels.filter((panel) => {
            if (bucketKey === panel.bucketKey) {
                return true;
            } else if (
                CONFIG.contentCardCMSActive &&
                panel.type === CC_DYNAMIC_PANEL_TYPE &&
                panel.assetId &&
                panel.assetId === bucketKey &&
                contentCards[panel.assetId] &&
                validateCCPanel(panel, contentCards[panel.assetId])
            ) {
                // Content Card
                if (validateCCDynamicPanelAsset(contentCards[panel.assetId])) {
                    // Dynamic Panel
                    return true;
                } else if (
                    favouriteTeamId &&
                    validateCCFavPanelAsset(contentCards[panel.assetId])
                ) {
                    // Favourite Panel
                    return true;
                }
            }
            return false;
        });
    }, [allPanels, bucketKey, contentCards, favouriteTeamId]);

    let getPanels = useCallback(() => {
        const panelsWithData: PanelType[] = [];
        for (let i = 0; i < allPanels.length; i++) {
            if (panelsWithData.length >= CONFIG.maxPanelsToRender) {
                break;
            }

            const p = allPanels[i];

            const dKey = `${layoutId}_${p.bucketKey}`;
            const pData = panelData[dKey];

            const eAssets = p.id in panelData;
            const pAssets = !!(pData && pData.assets.length);

            const isCCBanner =
                CONFIG.contentCardCMSActive &&
                p.type === CC_BANNER_PANEL_TYPE &&
                p.assetId &&
                contentCards[p.assetId] &&
                validateCCBanner(p, contentCards[p.assetId]);

            const isCCPanel =
                CONFIG.contentCardCMSActive &&
                p.type === CC_DYNAMIC_PANEL_TYPE &&
                p.assetId &&
                contentCards[p.assetId] &&
                validateCCPanel(p, contentCards[p.assetId!]);

            const isCCDynamicPanel =
                isCCPanel && validateCCDynamicPanelAsset(contentCards[p.assetId!]);

            const isCCFavPanel =
                isCCPanel && favouriteTeamId && validateCCFavPanelAsset(contentCards[p.assetId!]);

            const hasCCPanelFavAssets = !!(
                isCCFavPanel &&
                favouriteTeamId &&
                !!panelData[favouriteTeamId]
            );

            const isCCPanelFavAssetsPending = !!(isCCFavPanel && panelDataPending[favouriteTeamId]);

            const ccPanelDKey =
                isCCDynamicPanel &&
                `${layoutId}_${contentCards[p.assetId!].contentCard?.bucketKey}`;

            const hasCCPanelAssets = !!(ccPanelDKey && panelData[ccPanelDKey]?.assets?.length);

            const fetching =
                panelDataPending[dKey] ||
                (ccPanelDKey && panelDataPending[ccPanelDKey]) ||
                eAssets === null ||
                isCCPanelFavAssetsPending;

            if (
                eAssets ||
                pAssets ||
                isCCBanner ||
                hasCCPanelAssets ||
                hasCCPanelFavAssets ||
                fetching
            ) {
                panelsWithData.push({
                    ...p,
                    // TODO: Remove debug data
                    // @ts-ignore
                    c: {
                        eAssets,
                        pAssets,
                        isCCBanner,
                        hasCCPanelAssets,
                        hasCCPanelFavAssets,
                        fetching,
                    },
                });
            }
        }

        return panelsWithData;
    }, [allPanels, contentCards, favouriteTeamId, layoutId, panelData, panelDataPending]);

    const { pageId } = usePage();

    useEffect(() => {
        const panelsToRender = isViewAll ? getViewAllPanels() : getPanels();
        dispatch(setPanelsToRender(pageId, panelsToRender));
    }, [isViewAll, pageId, getPanels, getViewAllPanels, dispatch]);

    return {};
};

export default usePanelsToRender;
