import { useEffect } from 'react';
import { Analytics } from 'src/lib/Analytics';
import { useBitmovinSegmentData } from './segment.bitmovin.controller';
import useUser from '../auth/user.controller';

/**
 * This is a common analytics module which recieves app events and send to respective analtics platforms like loggly, Segment, etc
 * @returns
 */

const useAnalytics = () => {
    useBitmovinSegmentData();
    const { user } = useUser();
    const { analyticUserId } = user || {};

    // init analytics on the first render
    useEffect(() => {
        Analytics.init();
    }, []);

    // login and logout
    useEffect(() => {
        if (user) {
            Analytics.setUserProperties(user);
        }
    }, [user]);

    useEffect(() => {
        if (analyticUserId) {
            Analytics.logUserLoggedIn();
        }
    }, [analyticUserId]);
};

export default useAnalytics;
