import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { StateProvider } from './state';
import { FocusRoot } from 'lal-lrud';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';
import Loader from './pages/Loader';
import { CONFIG } from './config/config';

ReactDOM.render(
    <React.StrictMode>
        <StateProvider>
            <FocusRoot throttle={CONFIG.DPADThrottleDelta}>
                <Suspense fallback={<Loader />}>
                    <App />
                </Suspense>
            </FocusRoot>
        </StateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

if (CONFIG.noAnimations) {
    document.getElementById('root')!.className += ' noAnim ';
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// serviceWorkerRegistration.register();
// reportWebVitals();
