import { useEffect } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';

/**
 * NOTE: CTV-543: The solution below is for when we shift to using BrowserRouter
 * instead of HashRouter. When we do, please remove the useDeepLinking currently in use
 */
// export const useDeepLinking = () => {
//     const [searchParams, setSearchParams] = useSearchParams();
//     const navigate = useNavigate()

//     useEffect(() => {
//         if (searchParams.has('deeplink')) {
//             const deeplinkToken = searchParams.get("deeplink");
//             if (deeplinkToken) {
//                 searchParams.delete('deeplink')

//                 setSearchParams(searchParams)
//                 const link = String(Buffer.from(deeplinkToken, 'base64'))
//                 const route = link.split('#')[1]
//                 navigate(route)
//             }
//         }
//     }, [navigate, searchParams, setSearchParams])
// }

export const useDeepLinking = (deeplinkValue?: string) => {
    const navigate = useNavigate();
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const hasDeeplink = searchParams.has('deeplink');
        const deeplinkToken = hasDeeplink ? searchParams.get('deeplink') : deeplinkValue;
        if (deeplinkToken) {
            const link = String(Buffer.from(deeplinkToken, 'base64'));
            const route = link.split('#')[1];
            navigate(route);
        }
    }, [deeplinkValue, navigate]);
};
