import { useSetFocus } from 'lal-lrud';

export function useFocusOnMouseOver(focusId?: string) {
    const setFocus = useSetFocus();
    const onMouseOver = (e: any) => {
        if (focusId) {
            setFocus(focusId);
        }
    };
    return { onMouseOver };
}
