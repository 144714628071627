import useBrazeRoot from '../../controllers/brazeRoot.controller';
import styles from './brazeRoot.module.scss';

const BrazeRoot = () => {
    const { focusedBtnIndex, isMessageVisible } = useBrazeRoot();
    const containerClasses = [styles.container, styles[`focused-btn-${focusedBtnIndex}`]];
    if (isMessageVisible) {
        containerClasses.push(styles.show);
    }
    return <div id="braze-root" className={containerClasses.join(' ')}></div>;
};

export default BrazeRoot;
