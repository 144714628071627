import { useStateValue } from 'src/state';
import useSidebar from '../sidebar.controller';
import { getExploreTitle } from 'src/utilities/explore';
import useExplore from '../exploreRail.controller';

const usePageTitle = () => {
    const { state } = useStateValue();
    const { selectedSidebarItem } = useSidebar(false);
    const {
        dashboardData: { layout },
    } = state;
    const { asset } = useExplore();
    const exploreTitle = asset ? getExploreTitle(asset.id) || asset.title : null;
    const { layoutId } = selectedSidebarItem || {};

    const title = exploreTitle || (layoutId && layout[layoutId]?.title);

    return title;
};

export default usePageTitle;
