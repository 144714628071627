import { useEffect } from 'react';
import { Player } from '../player';
import { errorLog } from 'src/utilities/console';

const useBitmovinInit = (elmID: string) => {
    useEffect(() => {
        try {
            var container = document.getElementById(elmID) as HTMLDivElement;
            Player.setPlayerContainerElm(container);
        } catch (error) {
            errorLog(error);
        }
    }, [elmID]);

    return { Player };
};

export default useBitmovinInit;
