import { CONFIG } from 'src/config/config';
import useGamepadInputEmulation from 'src/controllers/platforms/xbox/gamepadInputEmulation.controller';
import Router from 'src/routes';

console.info({ CONFIG });
const XboxApp = () => {
    useGamepadInputEmulation();
    return <Router></Router>;
};

export default XboxApp;
