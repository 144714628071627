import useContentCards from './contentCards.controller';

const useContentCardAsset = (assetId?: string) => {
    const contentCards = useContentCards();
    let ccAsset = null;
    if (assetId) {
        ccAsset = contentCards[assetId];
    }
    return { ccAsset };
};

export default useContentCardAsset;
