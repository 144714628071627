import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStateValue } from 'src/state';
import { getSidebarItems } from 'src/state/stores/app/app.actions';

const useSidebar = (fetch?: boolean) => {
    const { state, dispatch } = useStateValue();
    const {
        appData: { sidebar },
    } = state;
    const { pathname } = useLocation();
    const selectedSidebarItem = sidebar?.items?.find((s) => s.href && pathname.startsWith(s.href));
    useEffect(() => {
        if (!sidebar && fetch) {
            dispatch(getSidebarItems());
        }
    }, [dispatch, sidebar, fetch]);

    return { sidebar, selectedSidebarItem };
};
export default useSidebar;
