import React, { useCallback } from 'react';
import useAsset from 'src/controllers/asset.controller';
import useDisasterMode from 'src/controllers/disasterRecovery.controller';
import useExplore from 'src/controllers/exploreRail.controller';
import useUser from 'src/controllers/auth/user.controller';
import useOLRUDonSelect from 'src/lib/OLRUD/controllers/oLRUDonSelect.controller';
import { AssetType, CardLayoutType, WatchModeLiveType } from 'src/state/stores/dashboard/dashboard';
import { showFreeBadge } from 'src/utilities/asset';
import { isLayoutExplore } from 'src/utilities/dashboard';
import Body from './Bodies';
import ExploreCardBody from './Bodies/ExploreCardBody';
import MatchBody from './Bodies/MatchBody';
import Banner from './Bodies/Banner';
import { Analytics } from 'src/lib/Analytics';
import { PanelPositionType } from 'src/lib/Analytics/analytics';
import usePanels from 'src/controllers/panels.controller';
import usePageTitle from 'src/controllers/dashboard/page.title.controller';

export interface CardBodyProps {
    asset: AssetType;
    isFreeAsset: boolean;
    cardLayout: CardLayoutType;
    panelId: string;
    cardIndex: number;
    panelIndex: number;
    focused: boolean;
}

const cardsWithActions: CardLayoutType[] = ['hero'];

const CardBody = (props: CardBodyProps) => {
    const { cardLayout, asset, panelId, cardIndex, panelIndex, focused } = props;
    const { id, type, accessGroups } = asset;
    const actionsNeeded = cardsWithActions.indexOf(cardLayout) !== -1;
    const { selectAsset } = useAsset();
    const { user } = useUser();
    const { panels } = usePanels();
    const pageTitle = usePageTitle();
    const { isDisasterMode, selectDRStream } = useDisasterMode();
    const { navigateToExplore } = useExplore();
    const onClick = useCallback(
        (watchMode?: WatchModeLiveType) => {
            if (isLayoutExplore(cardLayout)) {
                navigateToExplore(asset);
            } else if (isDisasterMode) {
                selectDRStream(id);
            } else {
                selectAsset(id, asset.type, watchMode);

                const { title, type } = panels[panelIndex];
                const panel: PanelPositionType = {
                    index: panelIndex,
                    title,
                    type,
                };
                Analytics.logAssetSelection(asset, cardIndex + 1, panelId, panel, pageTitle);
            }
        },
        [
            asset,
            cardIndex,
            cardLayout,
            id,
            isDisasterMode,
            pageTitle,
            panelId,
            panelIndex,
            panels,

            navigateToExplore,
            selectAsset,
            selectDRStream,
        ]
    );
    const isBanner = cardLayout === 'banner' && asset.contentCard;
    useOLRUDonSelect(!isBanner && focused, onClick);

    const showBadge = showFreeBadge(type, accessGroups || [], user, cardLayout);

    if (cardLayout === 'banner' && asset.contentCard) {
        return <Banner panelIndex={panelIndex} asset={asset} focused={focused} />;
    }
    if (type === 'game') {
        return (
            <MatchBody
                actionsNeeded={actionsNeeded}
                asset={asset}
                cardLayout={cardLayout}
                focused={focused}
                isFreeAsset={showBadge}
                onClick={onClick}
            />
        );
    }
    if (isLayoutExplore(cardLayout)) {
        return (
            <ExploreCardBody
                asset={asset}
                cardLayout={cardLayout}
                focused={focused}
                onClick={onClick}
            />
        );
    }
    return (
        <Body
            actionsNeeded={actionsNeeded}
            asset={asset}
            cardLayout={cardLayout}
            focused={focused}
            isFreeAsset={showBadge}
            onClick={onClick}
        />
    );
};

export default React.memo(CardBody);
