import useTheme from 'src/controllers/theme.controller';
import { AssetType } from 'src/state/stores/dashboard/dashboard';
import { getBackgroundImage } from 'src/utilities/vod';
import Poster from '../Poster';
import VodInfoMatch from '../VodInfoMatch';
import MatchActions from './MatchActions';
import styles from './detail.module.scss';
import themeStyle from '../../../Theme/theme.module.scss';
import { processImageUrl } from 'src/utilities/url';
import { posterImageSize } from './constants';
import { FocusNode } from 'lal-lrud';
import { useFocusCache } from 'src/controllers/focusCache.controller';
import { useStateValue } from 'src/state';
import { useVodDetailFocus } from 'src/controllers/vodDetail.focus.controller';
import { getOnMountFocusIds } from 'src/utilities/matchActionsFocus';

interface MatchDetailProps {
    asset: AssetType;
    onPlayClick: (id: string) => void;
    isPastOrUpcoming?: string;
    setIsPastOrUpcoming?: (str: string) => void;
}
const MatchDetail = (props: MatchDetailProps) => {
    const { asset, onPlayClick, setIsPastOrUpcoming, isPastOrUpcoming } = props;
    const { theme } = useTheme(asset);
    const { state } = useStateValue();

    const { getFocusCache } = useFocusCache();
    // @TEMP: Re-enable Related Assets Component
    // const { translateY, isRelatedPanelFocused, onRelatedAssetsFocus, onRelatedAssetsBlur } =
    // useVodDetailFocus();
    const { translateY } = useVodDetailFocus();

    const {
        imageUrl,
        type,
        match,
        isLive,
        broadcastStartTime,
        broadcastEndTime,
        fullAssets,
        tinyAssets,
        miniAssets,
        megaAssets,
        extendedAssets,
    } = asset || {};
    const { competition } = match || {};
    const { id: competitionId } = competition || {};
    const buttonFocusId = getFocusCache();

    const {
        dashboardData: { detailedAssets },
    } = state;
    const fullAssetId = fullAssets?.[0]?.id;
    const mainMatchAsset = fullAssetId ? detailedAssets[fullAssetId] : null;

    const vodDetailClasses = [styles.container];

    if (theme === 'dark') {
        vodDetailClasses.push(styles.dark);
    } else {
        vodDetailClasses.push(themeStyle.light);
    }

    const bg = getBackgroundImage(type, competitionId);
    const bgStyle = {
        transform: `translateY(${translateY}px)`,
        backgroundImage: `url(${bg})`,
    };
    const posterImageUrl = processImageUrl(
        asset['imageUrl_16:9'] || imageUrl || '',
        posterImageSize.width,
        posterImageSize.height
    );
    const subAssets =
        fullAssets?.length ||
        megaAssets?.length ||
        miniAssets?.length ||
        extendedAssets?.length ||
        tinyAssets?.length;
    let onMountProp: { onMountAssignFocusTo?: string } = {};

    // CTV-731 - There are some instances where Production team have published sub assets
    // while the game is still live.
    // This causes focus assignment issues for the MatchActions component.
    // Adding is !isLive check to resolve this.
    if (!isLive && subAssets && subAssets > 0) {
        const focusId = buttonFocusId || `btn-match-${asset.type}-${asset.id}`;
        if (getOnMountFocusIds(asset).includes(focusId)) {
            onMountProp.onMountAssignFocusTo = focusId;
        }
    }

    return (
        <>
            <div className={vodDetailClasses.join(' ')} style={bgStyle}>
                <div className={styles.mask}></div>
                <div className={styles.body}>
                    <div className={styles.topSection}>
                        {match && (
                            <VodInfoMatch
                                isLive={isLive}
                                broadcastStartTime={
                                    broadcastStartTime ?? mainMatchAsset?.broadcastStartTime
                                }
                                broadcastEndTime={
                                    broadcastEndTime ?? mainMatchAsset?.broadcastEndTime
                                }
                                match={match}
                                setIsPastOrUpcoming={setIsPastOrUpcoming}
                                isPastOrUpcoming={isPastOrUpcoming}
                            />
                        )}
                        <Poster url={posterImageUrl} />
                    </div>
                    <FocusNode elementType="div" orientation="vertical" {...onMountProp}>
                        <FocusNode
                            elementType="div"
                            orientation="horizontal"
                            defaultFocusChild={0}
                            className={styles.actions}
                        >
                            <MatchActions asset={asset} onPlayClick={onPlayClick} />
                        </FocusNode>
                        {/* <RelatedAssetsPanel
                            assetId={asset.id}
                            focused={isRelatedPanelFocused}
                            className={styles.relatedAssets}
                            onFocus={onRelatedAssetsFocus}
                            onBlur={onRelatedAssetsBlur}
                        /> */}
                    </FocusNode>
                </div>
            </div>
        </>
    );
};

export default MatchDetail;
