import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VIEW_ALL_CARD } from 'src/constants/assetTypes';
import RouteTags, { getRouteTags } from 'src/routes/routeTags';
import { useStateValue } from 'src/state';
import { WatchModeLiveType } from 'src/state/stores/dashboard/dashboard';
import { clearAssetError, getAsset } from 'src/state/stores/dashboard/dashboard.actions';
import { useShimmer } from './shimmer.controller';
import { noop } from 'src/utilities/op';
import useNetworkError from './networkErrorController';

interface useAssetPropType {
    assetId?: string;
    detailed?: boolean;
    refetchFullAsset?: boolean;
}
const useAsset = (props?: useAssetPropType) => {
    const { assetId: pAssetId, detailed = false, refetchFullAsset = false } = props || {};
    const navigate = useNavigate();
    const { state, dispatch } = useStateValue();
    const { setShowShimmer } = useShimmer();
    const { networkError } = useNetworkError();
    const { assetId: qAssetId, dashboard, explore } = useParams();
    const {
        dashboardData: { detailedAssets, assetFetchFailed },
    } = state;

    const assetId = pAssetId || qAssetId;

    const detailedAsset = assetId ? detailedAssets[assetId] : null;

    useEffect(() => {
        if (assetId && networkError && assetFetchFailed && assetFetchFailed[assetId]) {
            // Asset Error is because of no NW, So clearing asset error to avoid showing double error.
            dispatch(clearAssetError(assetId));
        }
    }, [dispatch, assetId, assetFetchFailed, networkError]);

    useEffect(() => {
        if (assetId && detailed && detailedAsset === undefined) {
            dispatch(getAsset(assetId)).catch(noop);
        }
    }, [assetId, detailedAsset, detailed, dispatch]);

    useEffect(() => {
        if (
            !detailedAsset?.broadcastStartTime &&
            detailedAsset?.fullAssets?.[0] &&
            refetchFullAsset
        ) {
            // Refetch asset if required:- If detailedAsset has a full game associated, but no broadcast times then fetch the asset.
            dispatch(getAsset(detailedAsset?.fullAssets?.[0].id));
        }
    }, [dispatch, detailedAsset, refetchFullAsset]);

    const selectAsset = useCallback(
        (assetId: string, assetType: string, watchMode?: WatchModeLiveType) => {
            const dDashboard = dashboard || (assetType === 'fitness' ? 'fitness' : 'football');
            setShowShimmer(true);
            setTimeout(() => {
                if (assetType === VIEW_ALL_CARD) {
                    const vodPath = explore
                        ? getRouteTags(
                              RouteTags.dashboardExploreViewAll,
                              ['dashboard', 'explore', 'bucketKey'],
                              [dDashboard, explore, assetId]
                          )
                        : getRouteTags(
                              RouteTags.dashboardViewAll,
                              ['dashboard', 'bucketKey'],
                              [dDashboard, assetId]
                          );
                    navigate(vodPath, { state: { autoPlay: watchMode } });
                } else if (assetId && assetType !== VIEW_ALL_CARD) {
                    const vodPath = getRouteTags(
                        RouteTags.vodDetail,
                        ['dashboard', 'assetId'],
                        [dDashboard, assetId]
                    );
                    navigate(vodPath, { state: { autoPlay: watchMode } });
                }
            });
        },
        [dashboard, explore, navigate, setShowShimmer]
    );

    return { selectAsset, asset: detailedAsset };
};

export default useAsset;
