import { useCallback, useEffect, useRef, useState } from 'react';
import usePlayer from './player.controller';
import { useStateValue } from 'src/state';
import { postAssetPlaybackSession } from 'src/state/stores/dashboard/dashboard.actions';
import { OSPlayerEvent } from 'src/lib/bitmovin';
import { Segment } from 'src/lib/bitmovin/segment';
import useBackButton from './keyPress/backButton.controller';
import useMountedRef from 'src/hooks/mounted';
import { log } from 'src/utilities/console';
import { CONFIG } from 'src/config/config';
import { Firebolt } from '../lib/firebolt';

const usePlaybackSession = () => {
    const [isKicked, setIsKicked] = useState<boolean>(false);
    const { Player } = usePlayer();
    const { dispatch } = useStateValue();
    const requestParamsRef = useRef<any>(null);

    const mounted = useMountedRef();

    const postSession = useCallback(
        (sessionData: any = requestParamsRef.current) => {
            const viewProgressSec = Player.getCurrentTime() || 0;
            const viewProgressMs = viewProgressSec * 1000;
            if (!sessionData || isKicked || Player.isAdPlaying()) {
                return;
            }
            const params = {
                ...sessionData,
                asset: { ...sessionData.asset, viewProgress: viewProgressMs },
            };
            requestParamsRef.current = params;

            Firebolt.sendMediaProgress(sessionData.asset.id, Math.floor(viewProgressSec));

            dispatch(postAssetPlaybackSession(params))
                .then(({ data }) => {
                    if (mounted.current) {
                        Player.playerSession.setProgressUpdaterTimeout(data);
                        if (data.item.isKicked) {
                            Player.pause(false);
                            setIsKicked(true);
                            Segment.trackChuckNorrisKick();
                            Segment.trackPlaybackErrorDisplayed({
                                errorType: 'DEVICE_LIMIT_EXCEEDED',
                            });
                        } else {
                            setIsKicked(false);
                        }
                    }
                })
                .catch((e) => {
                    log(e);
                    Player.playerSession.setProgressUpdaterTimeout();
                });
        },
        [Player, isKicked, dispatch, mounted]
    );

    useEffect(() => {
        Player.playerSession.on(OSPlayerEvent.SAVE_STREAM_SESSION, postSession);
    }, [Player.playerSession, postSession]);

    useEffect(() => {
        return () => {
            // since we reload the player when isKicked is true, we dont need to call postSession when we unmoun the component
            if (!isKicked) {
                return postSession();
            }
        };
    }, [isKicked, postSession]);

    const callBack = () => (isKicked ? true : false);
    useBackButton({ callBack, id: 'player Session', rank: 6 });

    const recheckIsKicked = useCallback(() => {
        if (isKicked) {
            setIsKicked(false);
            return;
        } else {
            const sessionId = Player.generateSessionId();
            requestParamsRef.current = { ...requestParamsRef.current, sessionId };
            Player.play();
            setIsKicked(false);
            setTimeout(() => {
                postSession();
            }, 1000);
        }
    }, [Player, isKicked, postSession]);

    return { isKicked, recheckIsKicked, setIsKicked };
};

export default usePlaybackSession;
