import styles from './onboardingDots.module.scss';
import React from 'react';
interface DotProps {
    count: Number;
    activeIndex: Number;
}

const generateClasslist = (index: Number, props: DotProps) => {
    if (index === props.activeIndex) {
        return [styles.dot, styles.currentDot].join(' ');
    } else if (index < props.activeIndex) {
        return [styles.dot, styles.previousDot].join(' ');
    } else {
        return styles.dot;
    }
};

const OnboardingDots = (props: DotProps) => {
    return (
        <div className={styles.dotsPanel}>
            <span className={generateClasslist(0, props)}></span>
            <span className={generateClasslist(1, props)}></span>
            <span className={generateClasslist(2, props)}></span>
        </div>
    );
};

export default React.memo(OnboardingDots);
