import { log } from 'src/utilities/console';
import { PlayerClass } from '../player';
import { commonBitmovinModules, platformBitmovinModules } from '../utilities/platformModules';
import { CONFIG } from 'src/config/config';
import { BitmovinOptusPlayer } from '..';
import merge from 'lodash.merge';
import { bPlayerConfig } from '../config/bPlayerConfig';
import { CombSegmentDataType } from '../player.d';

type InitSegmentData = Pick<CombSegmentDataType, 'appData' | 'userData'>;

function _init(this: PlayerClass, segmentData: InitSegmentData) {
    platformBitmovinModules[CONFIG.platform]?.forEach((mod) => {
        BitmovinOptusPlayer.addModule(mod);
    });

    commonBitmovinModules.forEach((mod) => {
        BitmovinOptusPlayer.addModule(mod);
    });

    log(
        'Loading Player Modules Complete',
        platformBitmovinModules[CONFIG.platform],
        commonBitmovinModules,
        BitmovinOptusPlayer
    );

    BitmovinOptusPlayer.setAppDetails(segmentData.appData);
    BitmovinOptusPlayer.setUserDetails(segmentData.userData);

    this.player = BitmovinOptusPlayer.init(
        this.containerElm!,
        merge(bPlayerConfig, CONFIG.bitmovinPlayerConfig ?? {}),
        this.isYoSpaceActive,
        CONFIG.YoSpaceDebug,
        this.isSegmentEnabled,
        this.isMUXEnabled,
        // destryOnComplete: Prevents the player getting destroyed on playback complete.
        false
    );
    window.player = this.player;

    return this.player;
}

export function init(
    this: PlayerClass,
    assetId: string,
    isSSAIEnabled: boolean,
    segmentData: InitSegmentData
) {
    this.initTracking();
    const initNeeded = !this.player;
    const isCurrentPlayerYospace = !!(this.player && this.player.bitmovinYospacePlayer);
    const _isYoSpaceError = this.isYoSpaceError(assetId);
    this.isYoSpaceActive = isSSAIEnabled && !_isYoSpaceError;

    const switchNeeded =
        (isCurrentPlayerYospace && (_isYoSpaceError || !isSSAIEnabled)) ||
        (!isCurrentPlayerYospace && this.isYoSpaceActive);

    if (initNeeded || switchNeeded) {
        return new Promise<void>((resolve) => {
            const onDestroy = () => {
                console.log(`Player INIT  ${this.isYoSpaceActive ? 'YoSpace' : 'BitMovin'}`);
                _init.call(this, segmentData);
                resolve();
            };
            this.destroy().then(onDestroy).catch(onDestroy);
        });
    } else {
        console.log(
            `Player using last instance: `,
            `${this.isYoSpaceActive ? 'YoSpace' : 'BitMovin'}`
        );
    }
    if (!this.player) {
        return Promise.reject();
    }
    return Promise.resolve();
}
