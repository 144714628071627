import styles from './poster.module.scss';
import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';

type PosterProps = {
    url: string;
};
function Poster(props: PosterProps) {
    const { url } = props;

    const bg = {
        backgroundImage: `url(${url}), url(${stockBackground})`,
    };
    return <div className={styles.poster} style={bg}></div>;
}

export default Poster;
