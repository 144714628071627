import { useEffect, useState } from 'react';
import Typography from 'src/components/Common/Typography';
import { useLiveTime } from 'src/hooks/liveTime';
import { checkifPastOrUpcoming } from 'src/utilities/time';
import styles from './matchTime.module.scss';
import { calculateDateTimeDisplay } from 'src/utilities/dateTime';
import { DateTime } from 'luxon';

interface MatchTimeProps {
    isLive: boolean;
    broadcastStartTime: string;
    broadcastEndTime: string;
    isMatch: boolean;
}

const MatchTime = (props: MatchTimeProps) => {
    const [broadcastEnded, setBroadcastEnded] = useState<boolean>(false);
    const { isLive, broadcastStartTime, broadcastEndTime, isMatch } = props;
    const lTime = useLiveTime(broadcastStartTime, broadcastEndTime);

    const isUpcoming = checkifPastOrUpcoming(broadcastStartTime) === 'upcoming';

    useEffect(() => {
        // CTV-964 - Hide the Timer when time elapses broadcastEndTime
        if (broadcastEndTime && DateTime.now() > DateTime.fromISO(broadcastEndTime)) {
            setBroadcastEnded(true);
        }
    }, [broadcastEndTime, setBroadcastEnded]);

    return (
        <div className={styles.container}>
            {isLive && !isUpcoming ? (
                <div className={!broadcastEnded ? styles.live : ''}>
                    <span>{lTime}</span>
                </div>
            ) : (
                <div className={styles.matchTime}>
                    <Typography className={styles.title}>
                        {calculateDateTimeDisplay(broadcastStartTime, broadcastEndTime, isMatch)}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default MatchTime;
