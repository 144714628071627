import { PlayerClass } from '../player';

export function setYospaceError(this: PlayerClass) {
    if (this.assetId) {
        this.isYoSpaceErrorAssetIds.push(this.assetId);
    }
}

export function isYoSpaceError(this: PlayerClass, assetId: string) {
    return this.isYoSpaceErrorAssetIds.includes(assetId);
}
