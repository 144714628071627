import { memo } from 'react';
import styles from './button.module.scss';

interface BannerButtonProps {
    label: string;
    focused: boolean;
    onClick: () => void;
}
const BannerButton = (props: BannerButtonProps) => {
    const { label, focused, onClick } = props;
    const classes = [styles.button];
    if (focused) {
        classes.push(styles.focused);
    }
    return (
        <div onClick={onClick} className={classes.join(' ')}>
            {label}
        </div>
    );
};

export default memo(BannerButton);
