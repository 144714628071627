import { useEffect } from 'react';

import { Segment } from 'src/lib/bitmovin/segment';
import { Analytics } from 'src/lib/Analytics';

import { useStateValue } from 'src/state';
import { globalLogoutUser } from 'src/state/stores/user/user.actions';
import useUser from 'src/controllers/auth/user.controller';

import FullscreenPopup from 'src/components/ui/FullscreenPopup';

import { clearLastPlayedLiveAsset } from 'src/utilities/live';
import { clearSession } from 'src/utilities/session';
import { log } from 'src/utilities/console';

const GlobalLogout = () => {
    const { dispatch } = useStateValue();
    const { auth } = useUser();
    const { userId, AccessToken, IdToken, TokenType } = auth || {};

    useEffect(() => {
        const globalLogout = () => {
            clearSession();
            clearLastPlayedLiveAsset();
            Analytics.logUserLogOut();
            setTimeout(() => {
                window.location.href = '/';
            }, 5000);
        };

        if (userId && AccessToken && IdToken && TokenType) {
            dispatch(globalLogoutUser(userId, AccessToken, IdToken, TokenType))
                .then((response) => {
                    Segment.trackApiEvent({
                        segmentEventName: 'Logout All Devices Performed',
                    });
                    Analytics.logLogoutSuccess(response.http);
                })
                .catch((d) => {
                    Analytics.logLogoutFailed(d);
                })
                .finally(() => {
                    globalLogout();
                });
        } else {
            // A user should not be able to get here without being logged in
            log('GlobalLogout - Impossible code reached');
            globalLogout();
        }
    }, [dispatch, userId, AccessToken, IdToken, TokenType]);

    return <FullscreenPopup fullyOpaque id={'deviceManagement.logOutAllSuccess'} />;
};

export default GlobalLogout;
