import { CONFIG } from 'src/config/config';

let instance: ReturnType<typeof makeSingleton>;

const makeSingleton = () => {
    let _networkError = false;
    let _isChecking = false;

    const setError = () => {
        _networkError = true;
    };

    const clearError = () => {
        _networkError = false;
    };

    const isError = () => {
        return _networkError;
    };

    const checkIfOffline = async (status?: number) => {
        if (status && status < 500) {
            clearError();
            return false;
        }

        if (_isChecking) {
            return null;
        }

        _isChecking = true;

        const areWeOnline = async () => {
            return new Promise<boolean>((resolve, reject) => {
                var xhr = new XMLHttpRequest();

                const onError = () => {
                    setError();
                    reject(false);
                };

                const onLoad = () => {
                    if (xhr.status === 200) {
                        clearError();
                        resolve(true);
                    } else {
                        onError();
                    }
                };

                xhr.open('GET', CONFIG.onlineCheckURL);
                xhr.onerror = onError;
                xhr.onload = onLoad;
                xhr.send();
            });
        };

        let isOnline = false;
        try {
            isOnline = await areWeOnline();
        } catch (error) {
            isOnline = false;
        }

        _isChecking = false;

        if (isOnline) {
            clearError();
            return false;
        } else {
            setError();
            return true;
        }
    };

    return {
        setError,
        clearError,
        isError,
        checkIfOffline,
    };
};

const getNetworkStatusInstance = () => {
    if (!instance) {
        instance = makeSingleton();
    }

    return instance;
};

export default getNetworkStatusInstance;
