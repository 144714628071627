import React from 'react'
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player'

const VideoQuality = () => {
    const player = getActivePlayer()
    // @ts-ignore
    const qualities = player?.getQualities().map(q => q.height).filter(x => x)
    const { height } = player.player?.getPlaybackVideoData() || { height: 0 }
    return (
        <>
            <div style={{ fontSize: 30 }}>
                VideoQualities: {qualities.join(', ')}
            </div>
            <div style={{ fontSize: 30 }}>
                Current Video Quality: {height}p
            </div>
        </>
    )
}

export default VideoQuality