import { useEffect, useState } from 'react';
import { bannerTypes } from 'src/state/stores/dashboard/dashboard.d';
import { isKeyEnterButton, isKeyLeft, isKeyLeftOrRight, isKeyRight } from 'src/utilities/keys';
import { useSetFocus } from 'lal-lrud';
import focusIds from 'src/constants/focusIds';
import useContentCardActions from './contentCardActions.controller';
import useContentCardAsset from './contentCards.asset.controller';

const useContentCardBanner = (assetId: string, focused: boolean, panelIndex: number) => {
    const [focusedButton, setFocusedButton] = useState(0);

    const { ccAsset } = useContentCardAsset(assetId);
    const setFocus = useSetFocus();
    const { contentCard } = ccAsset || {};

    const { primaryButtonLabel, secondaryButtonLabel, bannerType } = contentCard || {};

    const {
        showPrimaryButton,
        showSecondaryButton,
        onClickPrimary,
        onClickSecondary,
        onClickCard,
    } = useContentCardActions(assetId, contentCard!, panelIndex);

    const action =
        (bannerType === bannerTypes.noButton && onClickCard) ||
        (focusedButton === 0 && onClickPrimary) ||
        (focusedButton === 1 && onClickSecondary);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const stopPropagation = () => {
                event.stopPropagation();
                event.preventDefault();
            };
            if (isKeyLeftOrRight(event)) {
                stopPropagation();
                setFocusedButton((k) => {
                    if (isKeyLeft(event) && k === 0) {
                        setFocus(focusIds.sidebar);
                    }
                    return isKeyRight(event) && showSecondaryButton ? 1 : 0;
                });
            }
            if (isKeyEnterButton(event) && action) {
                action();
            }
        };
        if (focused) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [focused, action, showSecondaryButton, setFocus]);

    return {
        focusedButton,
        showPrimaryButton,
        showSecondaryButton,
        primaryButtonLabel,
        secondaryButtonLabel,
        onClickPrimary,
        onClickSecondary,
        onClickCard,
    };
};

export default useContentCardBanner;
