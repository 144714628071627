import { useMatch, useParams } from 'react-router-dom';
import RouteTags from 'src/routes/routeTags';
const usePage = () => {
    const { dashboard, explore, bucketKey } = useParams();
    const isBrowse = !!useMatch(RouteTags.browse);
    const isDExplore = !!useMatch(RouteTags.dashboardExplore);
    const isBExplore = !!useMatch(RouteTags.browseExplore);

    const page =
        dashboard ||
        (isBrowse && 'browse') ||
        (isBExplore && 'bExplore') ||
        (isDExplore && 'dExplore') ||
        'dashboard';
    const pageId = `${page}_${explore}_${bucketKey}`;
    return { pageId: pageId };
};

export default usePage;
