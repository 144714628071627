import Apis from 'src/apis';
import { Action } from '../../reducers.d';
import * as ACTIONS from './app.constants';

export const getSidebarItems = (): Action => ({
    type: ACTIONS.ACTION_FETCH_SIDE_BAR,
    payload: Apis.app.getSidebarItems,
});

export const getDrChannels = (): Action => ({
    type: ACTIONS.ACTION_FETCH_DR_CHANNELS,
    payload: {
        action: Apis.app.getDrChannels,
        retry: false,
    },
});

export const setAppReady = (): Action => ({
    type: ACTIONS.ACTION_APP_STATUS_READY,
});

export const setPlayerQuality = (playerQuality: number): Action => ({
    type: ACTIONS.ACTION_APP_SET_PLAYER_QUALITY,
    payload: playerQuality,
});
