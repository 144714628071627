import { useEffect } from 'react';
import { BitmovinOptusPlayer } from 'src/lib/bitmovin';
import { getAppData } from 'src/lib/Analytics/Segment/utils';
import useUser from '../auth/user.controller';

export const useBitmovinSegmentData = () => {
    const { user, userData } = useUser();
    // TODO: Define the missing data structures
    useEffect(() => {
        // @ts-ignore
        BitmovinOptusPlayer.setAppDetails(getAppData());
    }, []);

    useEffect(() => {
        const userSegmentData = { ...userData, loggedIn: !!user };
        // @ts-ignore
        BitmovinOptusPlayer.setUserDetails(userSegmentData);
    }, [user, userData]);
};
