import { Action } from './reducers.d';
import { AppStateType } from './stores/app/app';
import { appReducer, INITIAL_STATE_APP } from './stores/app/app.reducer';
import { DashboardStateType } from './stores/dashboard/dashboard';
import { dashboardReducer, INITIAL_STATE_DASHBOARD } from './stores/dashboard/dashboard.reducer';
import { UserStateType } from './stores/user/user';
import { INITIAL_STATE_USER, userReducer } from './stores/user/user.reducer';

export type State = {
    dashboardData: DashboardStateType;
    appData: AppStateType;
    userData: UserStateType;
};

export const GLOBAL_INITIAL_STATE: State = {
    dashboardData: {
        ...INITIAL_STATE_DASHBOARD,
    },
    appData: {
        ...INITIAL_STATE_APP,
    },
    userData: {
        ...INITIAL_STATE_USER,
    },
};

const reducer = ({ dashboardData, appData, userData }: State, action: Action): State => ({
    dashboardData: dashboardReducer(dashboardData, action),
    appData: appReducer(appData, action),
    userData: userReducer(userData, action),
});

export default reducer;
