import React from 'react';
import Typography from 'src/components/Common/Typography';
import { CmsTagType } from 'src/state/stores/dashboard/dashboard';
import CoverageInTime from '../../CoverageInTime';
import styles from './vodInfo.module.scss';

type VodInfoProps = {
    title: string;
    description: string;
    cmsTags?: CmsTagType[];
    tags?: string;
    broadcastStartTime: string;
    setIsPastOrUpcoming?: (str: string) => void;
    isPastOrUpcoming?: string;
};
function VodInfo(props: VodInfoProps) {
    const {
        title,
        description,
        broadcastStartTime,
        setIsPastOrUpcoming,
        isPastOrUpcoming,
    } = props;
    const isUpcoming = isPastOrUpcoming === 'upcoming';
    return (
        <div className={styles.vodinfo}>
            <Typography className={styles.title}> {title} </Typography>
            <Typography className={styles.description}>{description}</Typography>
            {/* @TEMP: Uncomment when required, as per designs. */}
            {/* <CMSTags cmsTags={cmsTags} tags={tags} /> */}
            {broadcastStartTime && isUpcoming ? (
                <CoverageInTime
                    className={styles.time}
                    broadcastStartTime={broadcastStartTime}
                    setIsPastOrUpcoming={setIsPastOrUpcoming}
                ></CoverageInTime>
            ) : null}
        </div>
    );
}

export default React.memo(VodInfo);
