import React from 'react';
import { useLocation } from 'react-router-dom';
import useSidebar from 'src/controllers/sidebar.controller';
import styles from './theme.module.scss';
import { isVidaaOS } from 'src/utilities/vidaa';

interface ThemeProps {
    children: React.ReactNode;
}
const Theme = (props: ThemeProps) => {
    const { children } = props;
    const { selectedSidebarItem } = useSidebar();
    const { theme = 'dark' } = selectedSidebarItem || {};
    const { pathname } = useLocation();
    const isFitnessDash = pathname.includes('/fitness/');
    const themeClasses = [styles.app];
    if (theme === 'light' || isFitnessDash) {
        themeClasses.push('light-theme');
    }

    if (isVidaaOS()) {
        themeClasses.push('vidaa-theme');
    }

    return <div className={themeClasses.join(' ')}>{children}</div>;
};

export default React.memo(Theme);
