import { DateTime, Duration } from 'luxon';
import React, { useEffect, useState } from 'react';
import { calculateDateTimeDisplay } from 'src/utilities/dateTime';
import LiveTag from '../LiveTag';
import styles from './livePill.module.scss';

type LivePillProps = {
    className?: string;
    duration?: number;
    matchID?: string;
    broadcastStartTime: string;
    broadcastEndTime: string;
};

const useTimerDisplay = (broadcastStartTime: string, broadcastEndTime: string, matchID?: string) => {
    const [timeNow, setTime] = useState(DateTime.now());

    const isBeforeBroadcast = timeNow < DateTime.fromISO(broadcastStartTime)

    useEffect(() => {
        if (isBeforeBroadcast) {
            const interval = setInterval(() => setTime(() => (DateTime.now())), 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [isBeforeBroadcast]);

    if (isBeforeBroadcast) { //If before
        const startTime = DateTime.fromISO(broadcastStartTime)
        const duration = startTime.diffNow(['hours', 'minutes', 'seconds'])
        if (duration.hours > 11) {
            return startTime.toFormat('d MMM h:mm') + startTime.toFormat('a').toLocaleLowerCase()
        }
        return 'Coverage in ' + Duration.fromObject({hours: duration.hours, minutes: duration.minutes, seconds: duration.seconds}).toFormat(duration.hours? 'hh:mm:ss' : 'mm:ss')
    } else if (timeNow > DateTime.fromISO(broadcastEndTime)) { //If after
        return calculateDateTimeDisplay(broadcastStartTime, broadcastEndTime, !!matchID)
    }
    
    // If during
    return <LiveTag />
}

const LivePill = (props: LivePillProps) => {
    const { className, broadcastStartTime, broadcastEndTime, matchID } = props;
    const display = useTimerDisplay(broadcastStartTime, broadcastEndTime, matchID)

    const livePillClasses = [styles.coverage];
    if (className) {
        livePillClasses.push(className);
    }

    return (
        <div className={livePillClasses.join(' ')}>
            {display}
        </div>
    );
};

export default React.memo(LivePill);
