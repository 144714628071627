import { VersionSpecificConfig } from 'src/config/config/config';

export const compareSymVersions = (a: string, b: string) => {
    // https://stackoverflow.com/a/6832706/1256197

    if (a === b) {
        return 0;
    }

    var a_components = a.split('.');
    var b_components = b.split('.');

    var len = Math.min(a_components.length, b_components.length);

    // loop while the components are equal
    for (var i = 0; i < len; i++) {
        // A bigger than B
        if (parseInt(a_components[i]) > parseInt(b_components[i])) {
            return 1;
        }

        // B bigger than A
        if (parseInt(a_components[i]) < parseInt(b_components[i])) {
            return -1;
        }
    }

    // If one's a prefix of the other, the longer one is greater.
    if (a_components.length > b_components.length) {
        return 1;
    }

    if (a_components.length < b_components.length) {
        return -1;
    }

    // Otherwise they are the same.
    return 0;
};

export const combineConfig = (config: VersionSpecificConfig, version: string) => {
    // returns a config by combining config up to the given version

    const configVersions = Object.keys(config).filter((x) => {
        return compareSymVersions(version, x) >= 0;
    });
    const sortedVersionArray = configVersions.sort(compareSymVersions);
    let configCombination = config[sortedVersionArray[0]];
    sortedVersionArray.forEach((v) => {
        configCombination = { ...configCombination, ...config[v] };
    });

    return configCombination;
};
