import React, { useCallback, useEffect, useState } from 'react';
import { FocusNode, useSetFocus } from 'lal-lrud';
import Button from 'src/components/Common/Button';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import { useMagicRemote } from 'src/controllers/magicRemote.controller';
import usePlayer from 'src/controllers/player.controller';
import focusIds from 'src/constants/focusIds';
import styles from './common/settings.module.scss';

import AudioOptions from './AudioOptions';
import QualityOptions from './QualityOptions';
import SpeedOptions from './SpeedOptions';
import SubtitleOptions from './SubtitleOptions';

import Spinner from 'src/components/Common/Spinner';
import { CONFIG } from 'src/config/config';

interface SettingsProps {
    visible: boolean;
    hidden: boolean;
}
const Settings = (props: SettingsProps) => {
    const { visible, hidden = false } = props;
    const { Player } = usePlayer();

    const [open, setOpen] = useState(false);
    const [ready, setReady] = useState(false);

    useMagicRemote();
    const { playbackSettingsSpeedActive } = CONFIG;

    let callBack = () => {
        if (open) {
            setOpen(false);
            return true;
        }
        return false;
    };
    useBackButton({ callBack, id: 'player Settings', rank: 7 });

    const onClick = useCallback(() => {
        setOpen(true);
    }, []);

    const onReady = useCallback(() => {
        setReady(true);
    }, []);

    useEffect(() => {
        if (!visible) {
            setOpen(false);
        }
    }, [visible]);

    const containerClasses = [styles.container];
    if (open) {
        containerClasses.push(styles.open);
    }

    const setFocus = useSetFocus();
    useEffect(() => {
        if (!ready && open) {
            setTimeout(() => {
                setFocus(focusIds.videoQualityDummy);
            });
        }
    }, [ready, open, setFocus]);

    const isLive = Player.isLive();

    const showSpeedSetting = !isLive && playbackSettingsSpeedActive;

    return (
        <>
            {open ? (
                <FocusNode
                    isTrap
                    className={containerClasses.join(' ')}
                    // onMountAssignFocusTo={'sContiner'}
                    defaultFocusChild={0}
                >
                    <FocusNode
                        orientation="horizontal"
                        className={styles.sectionContainer}
                        focusId="sContiner"
                        // onMountAssignFocusTo="qContiner"
                    >
                        <QualityOptions onReady={onReady} />
                        {ready ? (
                            <>
                                {showSpeedSetting && <SpeedOptions />}
                                {CONFIG.playbackSettingsAudioActive && <AudioOptions />}
                                {CONFIG.playbackSettingsTextActive && <SubtitleOptions />}
                            </>
                        ) : null}
                    </FocusNode>
                    {ready ? null : <Spinner fullScreen />}
                </FocusNode>
            ) : null}
            <Button
                focusId={focusIds.plbSettingsBtn}
                iconOnly
                noBg
                open
                iconPre="settingsWhite"
                onClick={onClick}
                className={styles.button}
                hidden={hidden}
                disabled={hidden}
            />
        </>
    );
};

export default React.memo(Settings);
