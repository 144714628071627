import { useEffect, useMemo } from 'react';
import { useBitmovinSegmentData } from './segment.bitmovin.controller';
import { useLocation } from 'react-router-dom';
import { Analytics } from 'src/lib/Analytics';
import useExplore from '../exploreRail.controller';
import useAsset from '../asset.controller';
import { checkifUpcomingLiveOrPast } from 'src/utilities/time';
import { isMatchAsset } from 'src/utilities/asset';

/**
 * This is a common analytics module which logs route change events
 */

const useRouteAnalytics = () => {
    useBitmovinSegmentData();
    const { exploreAssets } = useExplore();
    const location = useLocation();

    const { pathname } = location;
    const [, root, extension, extensionId] = pathname.split('/');
    const exploreId = (root === 'browse' && extension) || extensionId;

    // Explore/Competition Pages
    const exploreTitle = useMemo(() => {
        const exploreAsset = exploreAssets.find((e) => e.id === exploreId);
        return exploreAsset?.title;
    }, [exploreAssets, exploreId]);
    useEffect(() => {
        if (exploreTitle) {
            Analytics.logPageNavigation('explore', { comp_name: exploreTitle });
        }
    }, [exploreTitle]);

    // VOD and Match
    const assetId = root === 'assets' ? extensionId : undefined;
    const { asset } = useAsset({ assetId: assetId });
    const isAsset = !!asset?.id;
    const isMatch = asset && isMatchAsset(asset);

    const matchState =
        isMatch && checkifUpcomingLiveOrPast(asset.broadcastStartTime, asset.broadcastEndTime);

    useEffect(() => {
        if (matchState) {
            Analytics.logPageNavigation(`match-${matchState}`, {
                match_id: asset.id,
                match_name: asset.title,
            });
        }
    }, [matchState, asset?.id, asset?.title]);

    useEffect(() => {
        if (isAsset && !isMatch) {
            Analytics.logPageNavigation(`vodDetail`, {
                asset_id: asset.id,
                asset_name: asset.title,
            });
        }
    }, [asset?.id, asset?.title, isAsset, isMatch]);

    // settings
    useEffect(() => {
        if (root === 'settings' && extension) {
            Analytics.logPageNavigation(extension);
        }
    }, [root, extension]);

    // Other Pages
    let key = root;
    let type = extension && extension.startsWith(':') ? null : extension;
    useEffect(() => {
        if (!exploreId && key && type !== null) {
            Analytics.logPageNavigation(key, { type });
        }
    }, [exploreId, key, type]);
};

export default useRouteAnalytics;
