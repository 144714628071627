import { getDispatch } from 'src/state';
import { postRefreshToken } from 'src/state/stores/user/user.actions';
import { getRefreshToken } from 'src/utilities/session';

export const getAccessToken = (refreshToken: string | undefined = getRefreshToken()) => {
    if (refreshToken) {
        const dispatch = getDispatch();
        return dispatch(postRefreshToken(refreshToken));
    }
    return Promise.reject();
};
