import { LRUDEvent } from 'lal-lrud/dist/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getPageCardPos, setCardPos } from '../utilities/cardPosCache';
import { PositionState } from '../olrud';
import { getCardSize } from 'src/components/ui/Card/cardsizes';
import { calcTranslateX, isLayoutHero } from 'src/utilities/dashboard';
import { pxScale } from 'src/utilities/styles';
import { AssetType, CardLayoutType } from 'src/state/stores/dashboard/dashboard';

const useOLRUDPanel = (assets: AssetType[], layout: CardLayoutType, key: string) => {
    const [position, ss] = useState<PositionState>(getPageCardPos(key));
    const [active, setActive] = useState(true);

    const setPosition = (v: Partial<PositionState>) => {
        ss((s) => ({
            ...s,
            ...v,
        }));
    };

    const { focusedPanelIndex, focusedCardIndex } = position;

    const assetsCount = assets?.length || 0;

    let panelTranslateX = 0;
    if (layout) {
        const cardSize = getCardSize(layout);
        const { width, margin } = cardSize;

        panelTranslateX = calcTranslateX(
            width,
            margin,
            assetsCount,
            focusedCardIndex,
            pxScale(isLayoutHero(layout) ? 1920 : 1782)
        );
    }

    const pageIdRef = useRef(key);
    useEffect(() => {
        setPosition(getPageCardPos(key));
        setTimeout(() => {
            pageIdRef.current = key;
        });
    }, [key]);

    useEffect(() => {
        if (key === pageIdRef.current) {
            setCardPos(
                pageIdRef.current,
                position.focusedPanelIndex,
                position.focusedCardIndex,
                panelTranslateX
            );
        }
    }, [key, position.focusedPanelIndex, position.focusedCardIndex, panelTranslateX]);

    const onFocus = useCallback(() => {
        setActive(true);
    }, []);
    const onBlur = useCallback(() => {
        setActive(false);
    }, []);

    const selectCard = useCallback(
        (nextCard: number) => {
            setCardPos(key, focusedPanelIndex, nextCard);
            setPosition({ focusedCardIndex: nextCard });
        },
        [focusedPanelIndex, key]
    );

    const onLRUDKey = useCallback(
        (event: LRUDEvent) => {
            const stopPropagation = () => {
                event.stopPropagation();
                event.preventDefault();
            };

            const onLeft = () => {
                const nci = focusedCardIndex - 1;
                const nextCard = nci > 0 ? nci : 0;
                selectCard(nextCard);
            };

            const onRight = () => {
                const nci = focusedCardIndex + 1;
                const nextCard = nci < assetsCount ? nci : focusedCardIndex;
                selectCard(nextCard);
            };

            if (event.key === 'left') {
                stopPropagation();
                onLeft();
                return;
            }

            if (event.key === 'right') {
                stopPropagation();
                onRight();
                return;
            }

            if (event.key === 'up') {
            }

            if (event.key === 'down') {
            }
        },
        [assetsCount, focusedCardIndex, selectCard]
    );

    return {
        focusedPanelIndex,
        focusedCardIndex,
        panelTranslateX,
        active,
        onBlur,
        onFocus,
        onLRUDKey,
    };
};

export default useOLRUDPanel;
