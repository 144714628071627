import { memo, useMemo } from 'react';

import { AssetType } from 'src/state/stores/dashboard/dashboard.d';

import Typography from 'src/components/Common/Typography';
import BannerButton from './BannerButton';

import useContentCardBanner from 'src/controllers/dashboard/contentCards.banner.controller';

import { processImageUrl } from 'src/utilities/url';

import styles from './banner.module.scss';

interface BannerProps {
    focused: boolean;
    asset: AssetType;
    panelIndex: number;
}

const Banner = (props: BannerProps) => {
    const { asset, focused, panelIndex } = props;
    const { id, contentCard, title, description, 'imageUrl_11:4': dImageUrl } = asset;

    const {
        focusedButton,
        showPrimaryButton,
        showSecondaryButton,
        onClickPrimary,
        onClickSecondary,
        onClickCard,
    } = useContentCardBanner(id, focused, panelIndex);

    const bgStyle = useMemo(() => {
        const imageUrl = dImageUrl ? processImageUrl(dImageUrl, 1664, 625) : '';
        return {
            backgroundImage: `url(${imageUrl})`,
            color: '#FFF',
        };
    }, [dImageUrl]);

    const {
        primaryButtonLabel,
        secondaryButtonLabel,
        badgeLabel,
        noTextOverlay,
        bannerType = '',
    } = contentCard || {};

    const bannerClass = useMemo(() => {
        const classes = [styles.banner, styles[bannerType.toLowerCase()]];
        if (focused) {
            classes.push(styles.focused);
        }
        return classes;
    }, [focused, bannerType]);

    if (!contentCard) {
        return <></>;
    }

    return (
        <>
            <div className={bannerClass.join(' ')} style={bgStyle} onClick={onClickCard}>
                <div className={styles.mask} />
                <div className={styles.content}>
                    {!noTextOverlay ? (
                        <div className={styles.titleContainer}>
                            <Typography className={styles.title}>{title}</Typography>
                            <Typography className={styles.subTitle}>{description}</Typography>
                        </div>
                    ) : null}
                    <div className={styles.actionsContainer}>
                        {showPrimaryButton ? (
                            <BannerButton
                                label={primaryButtonLabel!}
                                focused={focused && (focusedButton === 0 || !showSecondaryButton)}
                                onClick={onClickPrimary}
                            />
                        ) : null}
                        {showSecondaryButton ? (
                            <BannerButton
                                label={secondaryButtonLabel!}
                                focused={focused && focusedButton === 1}
                                onClick={onClickSecondary}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            {badgeLabel ? (
                <Typography className={styles.badgeLabel}>{badgeLabel}</Typography>
            ) : null}
        </>
    );
};

export default memo(Banner);
