import { useMemo } from 'react';
import useUser from './auth/user.controller';

const useFavouriteTeam = () => {
    const { userData } = useUser();

    const ccOptusTeamId = useMemo(() => {
        const { favouriteTeams = [] } = userData?.settings?.football || {};
        const firstTeam = (favouriteTeams && favouriteTeams.length && favouriteTeams[0]) || null;
        return firstTeam?.optusTeamCode ? firstTeam?.optusTeamCode : null;
    }, [userData]);
    return ccOptusTeamId;
};

export default useFavouriteTeam;
