import { CardLayoutType } from 'src/state/stores/dashboard/dashboard';

export const CardLayouts = [
    'hero',
    'browse',
    'explore_tile',
    'live',
    'square_tile',
    'rectangular_tile',
    'recently_watched',
    'disaster',
    'banner',
] as const;

export const DEFAULT_CARD_LAYOUT: CardLayoutType = 'rectangular_tile';
