import { memo } from 'react';
import { FocusNode } from 'lal-lrud';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'src/config/config';
import { useScrubber } from 'src/controllers/scrubber.controller';
import AreYouStillWatching from 'src/components/ui/AreYouStillWatching';
import ChannelNowLogo from '../Live/ChannelNowLogo';
import FastForward from '../PlayerButtons/FastForward';
import GoLive from '../PlayerButtons/GoLive';
import MiniEPG from '../MiniEPG';
import PlayerAdUI from '../PlayerAdUI';
import PlayerStats from 'src/lib/bitmovin/components/PlayerStats/PlayerStats';
import PlayPause from '../PlayerButtons/PlayPause';
import ProgramNowTitle from '../Live/ProgramNowTitle';
import Rewind from '../PlayerButtons/Rewind';
import Scrubber from '../Scrubber';
import Settings from '../PlayerButtons/Settings';
import Spinner from 'src/components/Common/Spinner';
import styles from './playerUI.module.scss';
import Typography from 'src/components/Common/Typography';
import usePlayerUI from 'src/controllers/playerUI.controller';
import VideoQuality from '../Scrubber/VideoQuality';
import Subtitle from '../Subtitle';

interface PlayerUIPropsType {
    isKicked: boolean;
    isLive: boolean;
    isPlayerError: boolean;
    isShowMiniEPG?: boolean;
    title?: string;
    isMiniUI: boolean;
}

const PlayerUI = (props: PlayerUIPropsType) => {
    const {
        isKicked,
        isLive,
        isShowMiniEPG = false,
        title,
        isMiniUI = false,
        isPlayerError,
    } = props;

    const {
        visible,
        ayswOnKeyPress,
        ayswAutoExitTime,
        userWatchingState,
        timeRemaining,
        showAdUI,
        wakeUI,
        buffering,
    } = usePlayerUI(isLive, isKicked, isPlayerError, isMiniUI);

    const { t } = useTranslation(undefined, { keyPrefix: 'playerUI' });
    const { width, bufferWidth, duration, currentTime, onScrubberLRKey, onClick, seek } =
        useScrubber(visible, wakeUI);

    const showEPG = isLive && isShowMiniEPG;
    const enableAdUI = isLive ? CONFIG.adUIEnabledOnLive : CONFIG.adUIEnabledOnVod;
    const useAdUI = isLive && !enableAdUI ? false : showAdUI; // Don't use Ad UI for Live.

    const playerUIClasses = [styles.playerUI];

    if (visible) {
        playerUIClasses.push(styles.show);
    }

    if (userWatchingState === 'UNKNOWN' && !isPlayerError && Number(ayswAutoExitTime)) {
        return (
            <AreYouStillWatching
                onWatchingPress={ayswOnKeyPress}
                yTime={Number(ayswAutoExitTime)}
            />
        );
    }
    const controlsActive = !isPlayerError && !isKicked;
    return (
        <>
            {CONFIG.showPlaybackVideoQualityStatus ? <VideoQuality /> : null}
            {CONFIG.showPlayerStatus ? <PlayerStats /> : null}
            <FocusNode className={styles.continer} disabled={isKicked}>
                {showAdUI && enableAdUI ? (
                    <PlayerAdUI timeRemaining={timeRemaining} showAdUI={showAdUI} />
                ) : null}
                {buffering && controlsActive ? (
                    <Spinner className={styles.spinner}></Spinner>
                ) : null}
                {CONFIG.playbackSettingsTextActive && <Subtitle top={visible} />}
                <FocusNode
                    className={playerUIClasses.join(' ')}
                    focusedClass={styles.focused}
                    onKey={wakeUI}
                    orientation="vertical"
                    onMouseOver={wakeUI}
                >
                    <FocusNode
                        className={styles.bottom}
                        orientation="vertical"
                        focusedClass={styles.focused}
                        disabled={!controlsActive}
                    >
                        {!useAdUI && <ProgramNowTitle title={title} />}
                        {!isMiniUI && (
                            <Scrubber
                                width={width}
                                bufferWidth={bufferWidth}
                                isLive={isLive}
                                duration={duration}
                                currentTime={currentTime}
                                onScrubberLRKey={onScrubberLRKey}
                                onClick={onClick}
                                visible={visible}
                                hide={useAdUI}
                            />
                        )}
                        <FocusNode
                            className={styles.controls}
                            orientation="horizontal"
                            defaultFocusChild={1}
                        >
                            <div className={styles.left}>
                                {isLive && !useAdUI ? <ChannelNowLogo></ChannelNowLogo> : null}
                            </div>
                            <div className={styles.center}>
                                <Rewind seek={seek} hidden={useAdUI || isMiniUI} />
                                <PlayPause hidden={isMiniUI} />
                                <FastForward seek={seek} hidden={useAdUI || isMiniUI} />
                            </div>
                            <div className={styles.right}>
                                <GoLive hidden={useAdUI || !isLive} />
                                {CONFIG.playbackSettingsActive && !useAdUI && (
                                    <Settings visible={visible} hidden={false}></Settings>
                                )}
                            </div>
                        </FocusNode>
                        {showEPG ? (
                            <Typography className={styles.epgHelp}>{t('epgHelp')}</Typography>
                        ) : null}
                    </FocusNode>

                    {/* Startover streams are live but miniEPG not needed */}
                    {showEPG ? <MiniEPG isPlayerError={isPlayerError}></MiniEPG> : null}
                </FocusNode>
            </FocusNode>
        </>
    );
};

export default memo(PlayerUI);
