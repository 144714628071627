import axios from 'axios';
import { CONFIG } from 'src/config/config';
import RouteTags, { getRouteTags } from 'src/routes/routeTags';
import { SidebarType } from 'src/state/stores/app/app';
import { log } from 'src/utilities/console';
import urls from './urls';

const sidebarData: SidebarType = {
    items: [
        {
            layoutId: 'football',
            active: true,
            icon: 'football',
            title: 'Football',
            href: getRouteTags('dashboard', 'dashboard', 'football'),
            layout: urls.football_home,
            theme: 'dark',
        },
        {
            layoutId: 'fitness',
            active: true,
            icon: 'fitness',
            title: 'Fitness',
            href: getRouteTags('dashboard', 'dashboard', 'fitness'),
            layout: urls.fitness_home,
            theme: 'light',
        },
        // {
        //     layoutId: 'favourite',
        //     active: true,
        //     icon: 'likeUnfilled',
        //     title: 'Favourite',
        // },
        {
            layoutId: 'browse',
            active: true,
            icon: 'search',
            title: 'Browse',
            href: getRouteTags('browse', '', ''),
            layout: urls.browse_home,
            theme: 'dark',
        },
        {
            layoutId: 'liveTv',
            active: true,
            icon: 'liveTv',
            title: 'Live',
            href: RouteTags.livePlayback,
        },
        {
            layoutId: 'settings',
            active: true,
            icon: 'settings',
            title: 'Settings',
            href: getRouteTags('settings', '', ''),
            theme: 'dark',
        },
    ],
};

export const getSidebarItems = () => {
    return new Promise((resolve) => {
        resolve(sidebarData);
    });
};

export const getDrChannels = () => {
    if (!CONFIG.drModeCheckActive || !urls.drModeCheckURL) {
        return Promise.resolve();
    }
    return axios.get(urls.drModeCheckURL).catch((err) => {
        log('No DR Data');
    });
};
