import { useEffect, useState } from 'react';
import useAsset from './asset.controller';
import { WatchModeLiveType, WatchModeType } from 'src/state/stores/dashboard/dashboard';
import { useStateValue } from 'src/state';
import { getAssetPlaybackLatestSession } from 'src/state/stores/dashboard/dashboard.actions';
import useUser from './auth/user.controller';
import { useLocation } from 'react-router-dom';
import { CONFIG } from 'src/config/config';

interface LocationStateType {
    watchMode: WatchModeLiveType;
}

const defVP = CONFIG.PSActive ? undefined : 0;

const usePlaybackWatchmode = () => {
    const { state: locationState } = useLocation();
    const { watchMode: lsWatchMode } = (locationState as LocationStateType) || {};
    const { asset } = useAsset();

    const [viewProgress, setViewProgress] = useState<undefined | null | number>(defVP);
    const [watchMode, setWatchMode] = useState<WatchModeType | WatchModeLiveType | null>(null);

    const { user } = useUser();
    const { dispatch } = useStateValue();

    useEffect(() => {
        if (asset && viewProgress === undefined) {
            if (asset.isLive) {
                // Live asset
                if (lsWatchMode === 'startover') {
                    // const { broadcastStartTime } = asset;
                    // const start = new Date(broadcastStartTime).getTime();
                    // const d = Math.floor((start - Date.now()) / 1000) + 1; //+1 is a possible fix for CTV-1040
                    setViewProgress(0);
                    setWatchMode('startover');
                } else {
                    setViewProgress(0);
                    setWatchMode('live');
                }
            } else {
                // Not Live asset
                setViewProgress(null);
                if (user) {
                    dispatch(getAssetPlaybackLatestSession(asset.id))
                        .then(({ data }) => {
                            const {
                                item: { viewProgress: vp },
                            } = data;

                            let vpSeconds = Math.floor(vp / 1000);
                            const vpAfterCutoff =
                                asset.duration * CONFIG.PSCutoff < vpSeconds ? 0 : vpSeconds;

                            setViewProgress(vpAfterCutoff);
                            if (vpAfterCutoff) {
                                setWatchMode('resume');
                            } else {
                                setWatchMode('');
                            }
                        })
                        .catch(() => {
                            setViewProgress(0);
                            setWatchMode('');
                        });
                    // } else if (asset.isLive) {
                    //     // const { broadcastStartTime } = asset;
                    //     // const start = new Date(broadcastStartTime).getTime();
                    //     // const d = Math.floor((start - Date.now()) / 1000);
                    //     // setViewProgress(d);
                    //     setViewProgress(0);
                    //     setWatchMode('startover');
                } else {
                    // Free asset when no user
                    setViewProgress(0);
                    setWatchMode('');
                }
            }
        }
    }, [user, asset, lsWatchMode, viewProgress, dispatch]);
    return { viewProgress, watchMode };
};

export default usePlaybackWatchmode;
