export const ACTION_LOGIN_INIT = 'ACTION_LOGIN_INIT';
export const ACTION_LOGIN_POLL = 'ACTION_LOGIN_POLL';
export const ACTION_FETCH_USER = 'ACTION_FETCH_USER';

export const ACTION_RESTORE_SESSION = 'ACTION_RESTORE_SESSION';
export const ACTION_TOKEN_REFRESH = 'ACTION_TOKEN_REFRESH';

export const ACTION_LOGOUT_USER = 'ACTION_LOGOUT_USER';
export const ACTION_GLOBAL_LOGOUT_USER = 'ACTION_GLOBAL_LOGOUT_USER';

export const REDACTED_PERSONALLY_IDENTIFIABLE_INFORMATION = 'hidden-PII';
export const LOGGED_OUT_USER_TYPE = 'logged-out-user';
