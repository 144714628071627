import { useCallback } from 'react';
import { matchPath, useLocation, useMatch, useNavigate } from 'react-router-dom';
import useUser from 'src/controllers/auth/user.controller';
import useExplore from 'src/controllers/exploreRail.controller';
import RouteTags, { DASHBOARD_ROOT, getRouteTags } from 'src/routes/routeTags';
import { useStateValue } from 'src/state';
import { ContentCardActions } from 'src/state/stores/dashboard/dashboard.d';
import { resetLayout, showOverlay } from 'src/state/stores/dashboard/dashboard.actions';
import { ccGetRoute } from 'src/utilities/contentCards';

const useCCAction = (ccId?: string) => {
    const { exploreAssets } = useExplore();
    const isOnDashboard = !!useMatch(DASHBOARD_ROOT);
    const { dispatch } = useStateValue();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { user } = useUser();
    const pSplits = pathname.replace('/dashboard/', '').split('/');
    const dashboard = pSplits.length ? pSplits[0] : 'football';

    const handleCCAction = useCallback(
        (action: string) => {
            if (ccId && action === ContentCardActions.popupImageOverlay && ccId) {
                dispatch(showOverlay(ccId));
            } else if (action === ContentCardActions.home) {
                const route = DASHBOARD_ROOT;
                if (isOnDashboard) {
                    setTimeout(() => {
                        dispatch(resetLayout());
                    });
                } else {
                    navigate(route);
                }
            } else if (action === ContentCardActions.epg) {
                const route = RouteTags.livePlayback;
                navigate(route);
            } else if (action === ContentCardActions.browse) {
                const route = RouteTags.browse;
                navigate(route);
            } else if (action === ContentCardActions.login) {
                if (user) {
                    setTimeout(() => {
                        dispatch(resetLayout());
                    });
                } else {
                    navigate(RouteTags.login);
                }
            } else if (action === ContentCardActions.settings) {
                const route = getRouteTags('settings', 'setting', 'customer-support');
                navigate(route);
            } else if (action && action.startsWith(ContentCardActions.exploreStartsWith)) {
                const assetId = action.replace(ContentCardActions.exploreStartsWith, '');
                const exploreAsset = exploreAssets.find(
                    (ea) => ea.deeplinkUrl && ea.deeplinkUrl.endsWith(`/${assetId}`)
                );
                if (exploreAsset) {
                    const route = getRouteTags('browseExplore', 'explore', exploreAsset?.id);
                    navigate(route);
                }
            } else if (action && action.startsWith(ContentCardActions.videosStartsWith)) {
                const assetId = action
                    .replace(ContentCardActions.videosStartsWith, '')
                    .split('/')[0];
                const route = getRouteTags(
                    'vodDetail',
                    ['dashboard', 'assetId'],
                    [dashboard || 'football', assetId]
                );
                navigate(route);
            } else if (action) {
                const path = ccGetRoute(action);
                if (path) {
                    navigate(path);
                    console.log(path);
                }
            }
        },
        [dashboard, dispatch, navigate, exploreAssets, isOnDashboard]
    );
    return { handleCCAction };
};

export default useCCAction;
