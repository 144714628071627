import { memo } from 'react';
import useMiniEPG from 'src/controllers/miniEPG.controller';
import styles from './channelNowLogo.module.scss';

const ChannelNowLogo = () => {
    const { selectedChannel } = useMiniEPG();
    const { logo_controls } = selectedChannel || {};
    return <img alt="" src={logo_controls} className={styles.logo} />;
};

export default memo(ChannelNowLogo);
