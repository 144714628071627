import { LRUDEvent } from 'lal-lrud/dist/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import usePanels from 'src/controllers/panels.controller';
import { getCardPos, getPageCardPos, setCardPos } from '../utilities/cardPosCache';
import usePanel from '../../../controllers/panel.controller';
import { PositionState } from '../olrud';
import usePage from './page.controller';
import { getCardSize } from 'src/components/ui/Card/cardsizes';
import { calcTranslateX, isLayoutHero } from 'src/utilities/dashboard';
import { pxScale } from 'src/utilities/styles';
import { useSetFocus } from 'lal-lrud';
import focusIds from 'src/constants/focusIds';
import useViewAll from 'src/controllers/viewAll.controller';

const useOLRUDProvider = () => {
    const { pageId } = usePage();
    const { panels } = usePanels();
    const { isViewAll } = useViewAll();
    const [position, ss] = useState<PositionState>(getPageCardPos(pageId));
    const [active, setActive] = useState(true);

    const setFocus = useSetFocus();

    const setPosition = (v: Partial<PositionState>) => {
        ss((s) => ({
            ...s,
            ...v,
        }));
    };

    const { focusedPanelIndex, focusedCardIndex } = getPageCardPos(pageId);

    const {
        assetId,
        bucketKey,
        layout,
        maxTileCount,
        id: panelId,
    } = panels[focusedPanelIndex] || {};

    const { assets } = usePanel({
        assetId,
        bucketKey,
        layout,
        maxTileCount: +maxTileCount,
        panelId,
    });
    const assetsCount = assets?.length || 0;

    let panelTranslateX = useMemo(() => {
        if (layout && !isViewAll) {
            const cardSize = getCardSize(layout);
            const { width, margin } = cardSize || {};

            return calcTranslateX(
                width,
                margin,
                assetsCount,
                focusedCardIndex,
                pxScale(isLayoutHero(layout) ? 1920 : 1782)
            );
        }
        return 0;
    }, [assetsCount, focusedCardIndex, isViewAll, layout]);

    const isHero = isLayoutHero(layout);
    const pageIdRef = useRef(pageId);
    useEffect(() => {
        setPosition(getPageCardPos(pageId));
        setTimeout(() => {
            pageIdRef.current = pageId;
        });
    }, [pageId]);

    useEffect(() => {
        if (pageId === pageIdRef.current) {
            setCardPos(
                pageIdRef.current,
                position.focusedPanelIndex,
                position.focusedCardIndex,
                panelTranslateX
            );
        }
    }, [pageId, position.focusedPanelIndex, position.focusedCardIndex, panelTranslateX]);

    const onFocus = useCallback(() => {
        setActive(true);
    }, []);
    const onBlur = useCallback(() => {
        setActive(false);
    }, []);

    const selectPanel = useCallback(
        (nextPanel: number) => {
            setCardPos(pageId, nextPanel);

            setPosition({
                focusedPanelIndex: nextPanel,
                focusedCardIndex: getCardPos(pageId, nextPanel).c,
            });
        },
        [pageId]
    );

    const selectCard = useCallback(
        (nextCard: number) => {
            setCardPos(pageId, focusedPanelIndex, nextCard);
            setPosition({ focusedCardIndex: nextCard });
        },
        [focusedPanelIndex, pageId]
    );
    const onLRUDKey = useCallback(
        (event: LRUDEvent) => {
            const stopPropagation = () => {
                event.stopPropagation();
                event.preventDefault();
            };
            const onLeft = () => {
                if (focusedCardIndex === 0 && !isHero) {
                    setFocus(focusIds.sidebar);
                }
                if (isViewAll && focusedCardIndex % 4 === 0) {
                    setFocus(focusIds.sidebar);
                } else {
                    const nci = focusedCardIndex - 1;
                    const nextCard = nci > 0 ? nci : 0;
                    selectCard(nextCard);
                }
            };

            const onRight = () => {
                const nci = focusedCardIndex + 1;
                const nextCard = nci < assetsCount ? nci : focusedCardIndex;
                selectCard(nextCard);
            };

            const onUp = () => {
                if (isViewAll) {
                    const nci = focusedCardIndex - 4;
                    const nextCard = nci > 0 ? nci : 0;
                    selectCard(nextCard);
                } else {
                    const npi = focusedPanelIndex - 1;
                    const nextPanel = npi > 0 ? npi : 0;
                    selectPanel(nextPanel);
                }
            };

            const onDown = () => {
                if (isViewAll) {
                    const nci = focusedCardIndex + 4;
                    const lastCardIndex = assetsCount - 1;
                    const lastRow = Math.floor(lastCardIndex / 4);
                    const curreentRow = Math.floor(focusedCardIndex / 4);
                    const isNextRow = lastRow !== curreentRow;
                    const nextCard =
                        nci < assetsCount ? nci : isNextRow ? lastCardIndex : focusedCardIndex;
                    selectCard(nextCard);
                } else {
                    const npi = focusedPanelIndex + 1;
                    const nextPanel = npi < panels.length ? npi : focusedPanelIndex;
                    selectPanel(nextPanel);
                }
            };

            if (event.key === 'left') {
                if (!isHero) {
                    stopPropagation();
                }
                onLeft();
                return;
            }

            if (event.key === 'right') {
                if (!isHero) {
                    stopPropagation();
                }
                onRight();
                return;
            }

            if (event.key === 'up') {
                stopPropagation();
                onUp();
            }

            if (event.key === 'down') {
                stopPropagation();
                onDown();
                return;
            }
        },
        [
            assetsCount,
            focusedCardIndex,
            focusedPanelIndex,
            isHero,
            panels.length,
            selectCard,
            selectPanel,
            setFocus,
            isViewAll,
        ]
    );

    return {
        focusedPanelIndex,
        focusedCardIndex,
        panelTranslateX,
        active,
        onBlur,
        onFocus,
        onLRUDKey,
    };
};

export default useOLRUDProvider;
