import customerSupport from 'src/assets/qrcodes/customer_support.png';
import deviceLogin from 'src/assets/qrcodes/device_login.png';
import helpnFaq from 'src/assets/qrcodes/help_and_faq.png';
import myProfile from 'src/assets/qrcodes/my_profile.png';
import privacyPolicy from 'src/assets/qrcodes/privacy_policy.png';
import devices from 'src/assets/qrcodes/devices.png';

export const settingsPageQRCodes = {
    customerSupport,
    deviceLogin,
    devices,
    helpnFaq,
    myProfile,
    privacyPolicy,
};
